import React from 'react'
import { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { addImgeFeed, saveFeed } from '../../api/feed'

import { serverBaseUrl } from '../..';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from 'ckeditor5-custom-build';
import { serverApiUrl } from '../..';
export default function FeedAdd(props) {
    const { appendFeed, feed, updateFeed } = props
    const insDetails = useSelector((state) => state.ins.insDetails)
    const [isPoleActive, setPoleActive] = useState(false)
    const [feedImages, setFeedImages] = useState([])
    const [feedType, setFeedType] = useState(3);
    const [feedOptions, setFeedOptions] = useState([
        {
            pollOption: ''
        },
        {

            pollOption: ''
        }
    ]);
    const [feedOptionsCounter, setFeedOptionsCounter] = useState(2);
    const [addingFeed, setAddingFeed] = useState(false);
    const [description, setDescription] = useState('')
    const [mode, setMode] = useState('add')
    const [feedId, setFeedId] = useState(null)
    const [creationTime, setCreationTime] = useState(null)
    const [feedObj, setFeedObj] = useState({});
    const [editIndex, setEditIndex] = useState(-1)
    let feedImageChooserRef = useRef();


    useEffect(() => {
        if (feed) {
            setMode("edit");
            setFeedId(feed?.feed?.feed?.id)
            setDescription(feed?.feed?.feed?.description)
            setFeedOptionsCounter(feed?.feed?.feedPollOptions?.length ? feed?.feed?.feedPollOptions?.length : 0)
            setFeedOptions(feed?.feed?.feedPollOptions ? feed?.feed?.feedPollOptions : [])
            setFeedType(feed?.feed?.feed?.feedType)
            setFeedImages(feed?.feed?.feedImages ? feed?.feed?.feedImages : [])
            setCreationTime(feed?.feed?.feed?.creationTime)
            setEditIndex(feed?.index)
            console.log(feed?.feed?.feed?.feedType, "edit")


        }
    }, [feed])

    const addPollOptions = () => {
        let pollOptions = [...feedOptions];
        let pollOptionCounter = feedOptionsCounter + 1;
        pollOptions.push({ pollOption: '' })
        setFeedOptions(pollOptions)
        setFeedOptionsCounter(pollOptionCounter)

    }

    const removePollOption = () => {
        let pollOptions = [...feedOptions];
        let pollOptionCounter = feedOptionsCounter - 1;
        pollOptions.pop()
        setFeedOptions(pollOptions)
        setFeedOptionsCounter(pollOptionCounter)
    }

    const openImageChooser = () => {
        setFeedType(1)
        feedImageChooserRef.click();
    }
    const handleImageChange = (e) => {
        let file = e.target.files[0];
        if (file) {
            let feedImagesArr = [...feedImages]
            feedImagesArr.push(file);
            setFeedImages(feedImagesArr)
        }

    }

    const renderFeedImage = (item, index) => {

        let url = "";
        if (item instanceof File) {
            url = URL.createObjectURL(item);
        } else {
            url = serverBaseUrl + item.feedImage
        }
        return (
            <div style={{ margin: 10 }}>

                <img src={url} width={100} height={100} />
                <span style={{ position: 'relative', top: 0, right: 0, fontSize: 20 }} onClick={() => removeFeedImage(item, index)}>
                    <i className="fadeIn animated bx bx-x" style={{ color: 'red' }}></i>
                </span>
            </div>
        )

    }
    const removeFeedImage = (item, index) => {
        let feedImageArr = [...feedImages]
        feedImageArr.splice(index, 1)
        setFeedImages(feedImageArr);
    }

    const handlePollOptionInput = (index, e) => {
        let pollOptions = [...feedOptions];
        pollOptions[index]['pollOption'] = e.target.value;
        setFeedOptions(pollOptions);

    }

    const renderPollOption = (item, index) => {
        const handleInputChange = (e) => {
            const inputValue = e.target.value;
            const maxLength = 30; // Maximum character length
            if (inputValue.length <= maxLength) {
                // Only update the value if it doesn't exceed the maximum length
                handlePollOptionInput(index, e);
            }
        };

        const maxLength = 30; // Maximum character length
        const currentLength = item.pollOption.length;
        const remainingCharacters = maxLength - currentLength;

        return (
            <div style={{
                marginTop: '5%',
                display: 'flex',
                flexDirection: 'column',
                marginLeft: 10,
                marginRight: 600,
                fontFamily: 'Outfit, sans-serif',
            }}>
                <div style={{ position: 'relative' }}>
                    <input
                        type="text"
                        name="poll_option"
                        onChange={handleInputChange}
                        placeholder={"Option " + (index + 1)}
                        style={{
                            borderRadius: 5,
                            paddingTop: 20,
                            paddingBottom: 20,
                            paddingLeft: 10,
                            width: 300,
                            border: '1px solid #ededed',
                        }}
                        value={item.pollOption}
                        maxLength={30}
                    />
                    <p style={{ position: 'absolute', bottom: -4, right: -290, fontSize: 12, color: ' #A6B3BA' }}>
                        {remainingCharacters}
                    </p>
                </div>
            </div>
        )
    }




    const checkPostData = () => {
        if (feedType != 3 && feedImages?.length == 0 && feedOptions?.filter(item => item.pollOption == "")?.length == feedOptions?.length) {
            console.log("running")
            setFeedType(3);
            handleSubmitButtonClick(3)
        } else {
            handleSubmitButtonClick(feedType)
            console.log("running ", feedType)
        }
    }
    const handleSubmitButtonClick = (feedType) => {
        switch (feedType) {
            case 1:
                handleAddImageFeedBtnClick();
                break;
            case 2:
                handleAddPollFeedBtnClick();
                break;
            case 3:
                handleAddTextFeedBtnClick();
                break;
        }
    }

    const handleAddImageFeedBtnClick = () => {

        if (!addingFeed) {
            setAddingFeed(true)

            let feed = {
                feed: {
                    feedType: 1,
                    description: description,
                    postedBy: 1,
                    insId: insDetails.id,
                    pollVotedInstitutes: ",",
                    pollVotedStudents: ",",
                    pollVoterList: ",",
                    feedLikerIns: ",",
                    feedLikerStudent: ",",
                    categoryId: insDetails.category
                },
                feedPollOptions: null
            }
            if (mode == "edit") {
                feed.feed.edited = true
                feed.feed.id = feedId;
                feed.feed.creationTime = creationTime
            }
            // this.setState({feedItem:feed})
            setFeedObj(feed)
            addImgeFeed(feed, feedImages, (response) => handleAddFeedCallback(response, feed))
        }

    }
    const handleAddPollFeedBtnClick = () => {
        if (verifyPollPost(description, feedOptions)) {
            if (!addingFeed) {
                setAddingFeed(true)
                let feed = {
                    feed: {
                        feedType: 2,
                        description: description,
                        postedBy: 1,
                        insId: insDetails.id,
                        pollVotedInstitutes: ",",
                        pollVotedStudents: ",",
                        pollVoterList: ",",
                        feedLikerIns: ",",
                        feedLikerStudent: ",",
                        categoryId: insDetails.category
                    },
                    feedPollOptions: feedOptions,
                }
                if (mode == "edit") {
                    feed.feed.edited = true
                    feed.feed.id = feedId;
                    feed.feed.creationTime = creationTime

                }
                // this.setState({feedItem:feed}) 
                setFeedObj(feed)

                saveFeed(feed, (response) => handleAddFeedCallback(response, feed))
            }

        } else {
            // Toast.show('Please Fill All The Fields.');
            console.log('Please Fill All The Fields')
        }
    }
    const handleAddTextFeedBtnClick = () => {
        if (verifyTextPost(description)) {
            if (!addingFeed) {
                setAddingFeed(true);
                let feed = {
                    feed: {
                        feedType: 3,
                        description: description,
                        postedBy: 1,
                        insId: insDetails.id,
                        pollVotedInstitutes: ",",
                        pollVotedStudents: ",",
                        pollVoterList: ",",
                        feedLikerIns: ",",
                        feedLikerStudent: ",",
                        categoryId: insDetails.category
                    },
                    feedPollOptions: null
                }
                if (mode == "edit") {
                    feed.feed.edited = true
                    feed.feed.id = feedId;
                    feed.feed.creationTime = creationTime
                }
                // this.setState({feedItem:feed})
                setFeedObj(feed)

                saveFeed(feed, (response) => handleAddFeedCallback(response, feed))
            }
        }
        else {
            // Toast.show('Please Fill All The Fields.');
            console.log('Please Fill All The Fields')
        }
    }

    const verifyTextPost = (description) => description

    const handleAddFeedCallback = (response, feedObj) => {
        if (response.status == 201) {
            let feedId = response.headers.get("location")
            if (mode === "edit") {
                // Toast.show('Feed Updated Successfully.');
                console.log("Feed Updated Successfully.")
                //window.location.reload();
            }
            else {
                // Toast.show('Feed Added Successfully.');
                console.log("Feed Added Successfully.")
                //window.location.reload();
            }
            setAddingFeed(false);
            setDescription("");
            setFeedImages([]);
            setFeedOptions([
                {
                    pollOption: ''
                },
                {

                    pollOption: ''
                }
            ])
            setFeedType(3);
            //     this.setState({addFeedLoading:false,description:"",feedImageData:[],pollOptions:[
            //     {

            //         pollOption:''
            //     },
            //     {

            //         pollOption:''
            //     }
            //   ]})





            if (mode != "edit") {

                let feedItem = { feed: { ...feedObj, feed: { ...feedObj.feed, id: feedId } } };

                feedItem['posterObject'] = insDetails
                appendFeed(feedItem)
            }
            else {
                let feedItem = { feed: feedObj };
                feedItem['posterObject'] = insDetails
                updateFeed(feedItem, editIndex)
            }
            // this.props.closeModal()
        }
        else {
            // Toast.show('');
            console.log('Something Went Wrong. Please Try Again Later.')
        }
    }
    const verifyPollPost = (description, pollOptions) => description && pollOptions.filter(item => item.pollOption)

    return (
        <>
            <div class="card mb-3" style={{ width: 620, marginTop: 0 }}>

                <div style={{ padding: 20, marginBottom: -40 }}>
                    <h5 class="card-title">Create Post</h5>

                    {/* <textarea  value={description} class="form-control" rows="3" onChange={(e)=>{setDescription(e.target.value)}} placeholder="Create Something"></textarea> */}
                    <textarea
                        value={description}
                        className="form-control"
                        onChange={(e) => setDescription(e.target.value)}
                        placeholder="Write Something here..."
                        style={{
                            resize: 'none',
                            overflow: 'hidden',
                            minHeight: '100px',
                            height: 'auto',
                            borderRadius: 10,
                            color: '7A8B94',
                            backgroundColor: '#f0f2f5',
                            padding: 10,
                            fontSize: 14
                        }}
                        rows={1}
                        onFocus={(e) => {
                            e.target.style.height = `${e.target.scrollHeight}px`;
                        }}
                        onBlur={(e) => {
                            e.target.style.height = 'auto';
                            e.target.style.height = `${Math.max(100, e.target.scrollHeight)}px`;
                        }}
                        onInput={(e) => {
                            e.target.style.height = 'auto';
                            e.target.style.height = `${Math.max(100, e.target.scrollHeight)}px`;
                        }}
                    />







                    {feedType === 2 ? (
                        <div style={{ backgroundColor: 'white', paddingTop: 2, marginTop: 10, borderRadius: 15, border: '1px solid #EDEDED' }}>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <h3 style={{ fontSize: 16, fontWeight: 600, marginTop: 15, marginLeft: 10, marginBottom: -16 }}>Poll Options</h3>
                                <h3 style={{ fontSize: 16, fontWeight: 600, marginTop: 15, marginLeft: 10, marginBottom: -16, marginRight: 15, cursor: 'pointer', color: 'red' }} onClick={() => setFeedType(0)}><svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M256-213.847 213.847-256l224-224-224-224L256-746.153l224 224 224-224L746.153-704l-224 224 224 224L704-213.847l-224-224-224 224Z" /></svg></h3>

                            </div>
                            {feedOptions.map((item, index) => (
                                renderPollOption(item, index)
                            ))}

                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', margin: 10 }}>
                                <span style={{ fontSize: 14, marginLeft: 5, color: '#A6B3BA' }}>Add Options</span>
                                <div style={{ flex: 1, flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center' }}>
                                    {feedOptionsCounter > 2 ? (
                                        <button style={{ margin: 10, padding: 5, marginLeft: 120, border: 'none', borderRadius: 10, backgroundColor: '#F8F9FB' }} onClick={removePollOption}>
                                            <svg xmlns="http://www.w3.org/2000/svg" height="30" viewBox="0 -960 960 960" width="30" fill='#FA2918'><path d="M220.001-450.001v-59.998h519.998v59.998H220.001Z" /></svg>
                                        </button>
                                    ) : (null)}
                                    <button style={{ margin: 10, padding: 5, border: 'none', borderRadius: 10, backgroundColor: "#F8F9FB" }} onClick={addPollOptions}>
                                        <svg xmlns="http://www.w3.org/2000/svg" height="30" viewBox="0 -960 960 960" width="30" fill='#005CDD'><path d="M450.001-450.001h-230v-59.998h230v-230h59.998v230h230v59.998h-230v230h-59.998v-230Z" /></svg>
                                    </button>

                                </div>
                            </div>
                        </div>
                    ) : (null)}

                    <div style={{ flexDirection: 'row', display: 'flex' }}>
                        {feedType === 1 ? (

                            feedImages.map((item, index) => (
                                renderFeedImage(item, index)
                            ))

                        ) : (null)
                        }
                    </div>

                    <div class="btn-group m-1 mt-4 justify-content-start " role="group" aria-label="Basic example">
                        <button title='Add Poll Option' type="button" style={{ width: 'auto', paddingLeft: 15, paddingRight: 20, height: 40, color: '#7A8B94', backgroundColor: '#fff', border: '0px solid #7A8B94', marginRight: 12, borderRadius: 5, fontWeight: 400, fontSize: 14, outline: 'none', boxShadow: 'none', }} onClick={() => setFeedType(2)}>
                            <svg xmlns="http://www.w3.org/2000/svg" height="30" viewBox="0 -960 960 960" width="30"><path d="M467.615-562.692h197.231v-30.77H467.615v30.77Zm0 195.385h197.231v-30.77H467.615v30.77ZM354.242-541.462q15.208 0 25.868-10.563 10.659-10.564 10.659-26.052 0-14.527-10.523-25.187-10.524-10.659-25.956-10.659t-25.707 10.659q-10.275 10.66-10.275 25.572 0 14.911 10.363 25.571 10.364 10.659 25.571 10.659Zm0 195.385q15.208 0 25.868-10.563 10.659-10.564 10.659-26.052 0-14.527-10.523-25.187-10.524-10.659-25.956-10.659t-25.707 10.659q-10.275 10.66-10.275 25.571 0 14.912 10.363 25.572 10.364 10.659 25.571 10.659ZM215.384-160q-23.057 0-39.221-16.163Q160-192.327 160-215.384v-529.232q0-23.057 16.163-39.221Q192.327-800 215.384-800h529.232q23.057 0 39.221 16.163Q800-767.673 800-744.616v529.232q0 23.057-16.163 39.221Q767.673-160 744.616-160H215.384Zm0-30.769h529.232q9.23 0 16.923-7.692 7.692-7.693 7.692-16.923v-529.232q0-9.23-7.692-16.923-7.693-7.692-16.923-7.692H215.384q-9.23 0-16.923 7.692-7.692 7.693-7.692 16.923v529.232q0 9.23 7.692 16.923 7.693 7.692 16.923 7.692Zm-24.615-578.462v578.462-578.462Z" fill="#7A8B94" /></svg>
                        </button>

                        <button title='Add Image' type="button" style={{ width: 'auto', paddingLeft: 15, paddingRight: 20, height: 40, color: '#7A8B94', backgroundColor: '#fff', border: '0px solid #7A8B94', marginRight: 12, borderRadius: 5, fontWeight: 400, fontSize: 14, outline: 'none', boxShadow: 'none', }} onClick={openImageChooser}>
                            <svg xmlns="http://www.w3.org/2000/svg" height="30" viewBox="0 -960 960 960" width="30"><path d="M215.384-160q-23.057 0-39.221-16.163Q160-192.327 160-215.384v-529.232q0-23.057 16.163-39.221Q192.327-800 215.384-800h529.232q23.057 0 39.221 16.163Q800-767.673 800-744.616v529.232q0 23.057-16.163 39.221Q767.673-160 744.616-160H215.384Zm0-30.769h529.232q9.23 0 16.923-7.692 7.692-7.693 7.692-16.923v-529.232q0-9.23-7.692-16.923-7.693-7.692-16.923-7.692H215.384q-9.23 0-16.923 7.692-7.692 7.693-7.692 16.923v529.232q0 9.23 7.692 16.923 7.693 7.692 16.923 7.692Zm82.923-107.769h370.539L556.462-447.616 446.769-310.461l-71.461-87.77-77.001 99.693ZM190.769-190.769v-578.462 578.462Z" fill="#7A8B94" /></svg>&nbsp;

                        </button>
                        <button type="button" style={{ width: 'auto', paddingLeft: 15, paddingRight: 20, height: 40, color: '#fff', backgroundColor: '#005CDD', border: '1px solid #005CDD', marginRight: 12, borderRadius: 5, fontWeight: 400, fontSize: 14 }} onClick={checkPostData}>
                            {mode === " Edit " ? " Save " : " Publish "}


                        </button>
                    </div>
                </div>
                <input type="file" onChange={(e) => handleImageChange(e)} ref={(ref) => feedImageChooserRef = ref} name="feedImages" style={{ visibility: 'hidden', float: 'right' }} />
            </div>
        </>
    )
}
