import React, { useEffect, useState } from 'react';
import { fetchRevenueOverView_institutewise } from '../../../api/revenue';
import Chart from '../../../components/charts/chart';

const monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"];

const AnaylictsApiCallBack = (response, mode, setBacks) => {
  if (response.status === 200) {
    response.json().then(data => {
      let label = [];
      let dataValues = [];
      switch (mode) {
        case 'monthly':
          data.map(item => {
            label.push(monthNames[(item.x) - 1]);
            dataValues.push(item.y);
          });
          break;
        case 'weekly':
        case 'yearly':
          data.map(item => {
            label.push((item.x));
            dataValues.push(item.y);
          });
          break;
      }
      setBacks.setLeadData({
        labels: label,
        datasets: [{
          label: 'Revenue',
          data: dataValues,
          backgroundColor: 'rgba(101, 202, 131, 0.5)',
          borderColor: "#00AC72",
          pointRadius: "0",
          borderWidth: 2,
          barPercentage: 0.5
        }]
      });
    });
  }
};

const RevenueModeWiseGraph = props => {
  const { insId } = props;
  const [graphMode, setGraphMode] = useState('monthly');
  const [graphModeDisplayName, setGraphModeDisplayName] = useState('Monthly');
  const [dataTime, setDataTime] = useState(new Date().getFullYear());
  const [leadData, setLeadData] = useState([]);
  const [loadingLeadData, setLoadingLeadData] = useState(true);

  useEffect(() => {
    fetchRevenueOverView_institutewise(insId, graphMode, dataTime, (response) => AnaylictsApiCallBack(response, graphMode, { setLeadData, setLoadingLeadData }));
  }, [graphMode, dataTime, insId]);

  return (
    <div className="row" style={{ marginRight: 2 }}>
      {/* left chart */}
      <div className="col-12 col-lg-5">
        <div className="card radius-15 overflow-hidden" style={{ border: 'none' }}>
          <div className="card-body">
            <div className="d-lg-flex align-items-center">
              <p className="mb-0 font-20 font-weight-bold">Revenue</p>
              <div className="ml-lg-auto mb-2 mb-lg-0">
                <div className="btn-group-round">
                  <div className="btn-group">
                    <button
                      type="button"
                      className={`btn btn-white ${graphMode === 'monthly' ? 'active' : ''}`}
                      onClick={() => { setGraphMode('monthly'); setGraphModeDisplayName('Monthly'); }}
                      style={{
                        background: graphMode === 'monthly' ? '#005cdd' : '#ededed',
                        color: graphMode === 'monthly' ? 'white' : 'black',  // You can adjust text color based on your needs
                        border: 'none',
                    }}
                    >
                      Monthly
                    </button>
                    <button
                      type="button"
                      className='btn btn-white'
                      onClick={() => { setGraphMode('yearly'); setGraphModeDisplayName('Yearly'); }}
                      style={{
                        background: graphMode === 'yearly' ? '#005cdd' : '#ededed',
                        color: graphMode === 'yearly' ? 'white' : 'black',  // You can adjust text color based on your needs
                        border: 'none',
                    }}
                    >
                      Yearly
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <Chart height={300} chartType="pie" chart_counter={10} data={leadData} />
          </div>
        </div>
      </div>
      <div className='col-12 col-lg-7' style={{ backgroundColor: 'white',height:390 }}>
        {/* YouTube video iframe */}
        <iframe
          width="100%"
          height="350"
          src="https://www.youtube.com/embed/YsmWCtkC5w0?si=W_HXjpcNbH4nZR1w"
          title="YouTube Video"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          style={{marginTop:20,borderRadius:10}}
        ></iframe>
      </div>
    </div>
  );
};

export default RevenueModeWiseGraph;
