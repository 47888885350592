import React, { useState, useEffect } from 'react'
import { serverBaseUrl, theme } from '../../../..';
import { updateVideoDemoStatus, updateVideoStreamingStatus } from '../../../../api/video'
import ClipLoader from "react-spinners/ClipLoader";
import moment from "moment";
import Menu from '../../../../components/Menu/Menu';
import { watchLiveStream } from '../../../../api/Courses';
import Modal, { ModalBody, ModalHeader } from '../../../../components/modal/modal';
import { imageProvider } from '../../../..';


function CourseVideoRow(props) {
    const { row, index, delVideo, setEditDetails, course, insId, setModal, modal, setLiveItem } = props

    const customerId = "https://customer-y9o3cvk41jloxspj.cloudflarestream.com/"

    const [title, setTitle] = useState(row.name);
    const [description, setDescription] = useState(row.description)
    const [video, setVideo] = useState("")
    const [videoId, setVideoId] = useState(row.id);
    const [hiddenStatus, setHiddenStatus] = useState(row.hidden);
    const [delLoading, setDelLoading] = useState(false);
    const [demo, setDemo] = useState(row.demo)
    const [streaming, setStreaming] = useState(row.streaming)

    useEffect(() => {

        setDemo(row.demo);
        setStreaming(row.streaming)
    }, [row])

    useEffect(() => {
        setDelLoading(props.delLoading)
    }, [props.delLoading])


    const updateStatusCallBack = (response, status) => {
        if (response.status == 200) {
            setHiddenStatus(status)
        }
        else {
        }
    }

    const updateDemoStatusCallBack = (response, status) => {
        if (response.status == 200) {
            setDemo(status)
        }
        else {
        }
    }
    const updateStreamingStatus = (status) => {
        updateVideoStreamingStatus(status, videoId, (response) => {
            if (response.status === 200) {
                setStreaming(status)
            }
        })

    }

    const watchStream = () => {
        watchLiveStream({ courseId: course, instituteId: insId, studentId: 0, uid: row.uid }, (response) => {
            response.json().then(data => {
                if (data.status === "success") {
                    setVideo(`${customerId}${data.data.token}/watch`)
                }
                else {
                    alert(data.data)
                }
            })
        })
    }

    useEffect(() => {
        watchStream()
    }, [row.uid])

    return (
        <div className="mb-3" style={{ flexDirection: 'column', backgroundColor: '#fff', marginLeft: '22px', marginTop: '20px', maxWidth: 930, borderRadius: 5, border: '1.5px solid #F8F9FB' }}>
            <div className="row">
                <aside className="col-3">

                    <a href={video !== "" && video} target="_blank" style={{ position: 'relative', display: 'inline-block' }}>
                        <div
                            className="image-container"
                            style={{
                                position: 'relative',
                                borderRadius: 5,
                                overflow: 'hidden',
                                display: 'inline-block',
                            }}
                        >
                            <img
                                src={imageProvider(row?.videoThumb) ?? '/assets/images/videoPlaceHolder.png'}
                                className=""
                                style={{
                                    borderRadius: 5,
                                    border: '0px solid grey',
                                    marginLeft: 0,
                                    height: 155,
                                    width: 250,
                                    objectFit: 'cover', // Set the objectFit property here
                                }}
                                onError={({ currentTarget }) => {
                                    currentTarget.onerror = null; // prevents looping
                                    currentTarget.src = '/assets/images/videoPlaceHolder.png';
                                }}
                                alt="Video Thumbnail"
                            />
                            <div className="overlay"></div>
                            <svg
                                fill='#Fff'
                                xmlns="http://www.w3.org/2000/svg"
                                height="50"
                                viewBox="0 -960 960 960"
                                width="50"
                                style={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '47%',
                                    transform: 'translate(-50%, -50%)',
                                    pointerEvents: 'none',
                                    backgroundColor: 'rgba(0, 0, 0, 0.8)',
                                    borderRadius: 50,
                                    marginRight: 20
                                }}
                            >
                                <path d="M320-200v-560l440 280-440 280Z" />
                            </svg>
                        </div>
                    </a>




                </aside>
                <div style={{ flexDirection: 'column', marginTop: 25, width: 400, marginLeft: 55 }}>
                    <h4 style={{ flexDirection: 'column', fontSize: 16, color: '#242527', fontWeight: 500, marginTop: -10 }}>
                        {row.name?.length > 40 ? row.name?.slice(0, 30) + '...' : row.name}
                    </h4>
                    <h6 style={{ flexDirection: 'column', fontSize: 13, marginTop: 10, color: '#3C4852', fontWeight: 500 }}>
                        Views - {row?.views}
                    </h6>
                    <h6 style={{ flexDirection: 'column', fontSize: 13, marginTop: 10, color: '#3C4852', fontWeight: 500 }}>
                        Rtmps Key - {row?.rtmpsKey}
                    </h6>
                    <h6 style={{ flexDirection: 'column', fontSize: 13, marginTop: 10, color: '#3C4852', fontWeight: 500 }}>
                        Rtmps Url - {row?.rtmpsUrl}
                    </h6>
                    <h6 style={{ flexDirection: 'column', fontSize: 13, marginTop: 10, color: '#3C4852', fontWeight: 500 }}>
                        Date- {moment(row?.created).format('D MMM YYYY')}
                    </h6>


                    <div style={{ display: 'flex', marginTop: 5 }}>
                        <button aria-label="delete" onClick={() => delVideo(row.id, row.uid, index)} className="btn" style={{ fontSize: 12, width: 50, height: 40, paddingLeft: 12, color: '#EA4335', paddingTop: 5, fontWeight: 600, marginTop: 5, marginRight: 10, marginTop: 10, backgroundColor: '#ededed' }}>
                            {delLoading ? <ClipLoader color={theme.primaryColor} loading={delLoading} /> : <svg fill='#FA2918' xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20"><path d="M280-120q-33 0-56.5-23.5T200-200v-520h-40v-80h200v-40h240v40h200v80h-40v520q0 33-23.5 56.5T680-120H280Zm400-600H280v520h400v-520ZM360-280h80v-360h-80v360Zm160 0h80v-360h-80v360ZM280-720v520-520Z" /></svg>}
                        </button>

                        {hiddenStatus ? (
                            <button
                                title="Public"
                                aria-label="delete"
                                className="btn"
                                style={{
                                    fontSize: 12,
                                    width: 50,
                                    color: '#3C4852',
                                    height: 40,
                                    paddingTop: 5,
                                    fontWeight: 600,
                                    marginTop: 10,
                                    backgroundColor: '#ededed',
                                    marginLeft: 0
                                }}
                                onClick={() => delVideo(row.uid)}
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill='#3C4852'>
                                    <path
                                        d="m644-428-58-58q9-47-27-88t-93-32l-58-58q17-8 34.5-12t37.5-4q75 0 127.5 52.5T660-500q0 20-4 37.5T644-428Zm128 126-58-56q38-29 67.5-63.5T832-500q-50-101-143.5-160.5T480-720q-29 0-57 4t-55 12l-62-62q41-17 84-25.5t90-8.5q151 0 269 83.5T920-500q-23 59-60.5 109.5T772-302Zm20 246L624-222q-35 11-70.5 16.5T480-200q-151 0-269-83.5T40-500q21-53 53-98.5t73-81.5L56-792l56-56 736 736-56 56ZM222-624q-29 26-53 57t-41 67q50 101 143.5 160.5T480-280q20 0 39-2.5t39-5.5l-36-38q-11 3-21 4.5t-21 1.5q-75 0-127.5-52.5T300-500q0-11 1.5-21t4.5-21l-84-82Zm319 93Zm-151 75Z"
                                    />
                                </svg>
                            </button>
                        ) : (
                            <button
                                title="Private"
                                aria-label="delete"
                                className="btn"
                                style={{
                                    fontSize: 12,
                                    width: 50,
                                    color: '#3C4852',
                                    height: 40,
                                    paddingTop: 5,
                                    fontWeight: 600,
                                    marginTop: 10,
                                    backgroundColor: '#ededed',
                                    marginLeft: 0
                                }}
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill='#3C4852'>
                                    <path
                                        d="M480-320q75 0 127.5-52.5T660-500q0-75-52.5-127.5T480-680q-75 0-127.5 52.5T300-500q0 75 52.5 127.5T480-320Zm0-72q-45 0-76.5-31.5T372-500q0-45 31.5-76.5T480-608q45 0-76.5 31.5T588-500q0 45-31.5 76.5T480-392Zm0 192q-146 0-266-81.5T40-500q54-137 174-218.5T480-800q146 0 266 81.5T920-500q-54 137-174 218.5T480-200Zm0-300Zm0 220q113 0 207.5-59.5T832-500q-50-101-144.5-160.5T480-720q-113 0-207.5 59.5T128-500q50 101 144.5 160.5T480-280Z"
                                    />
                                </svg>

                            </button>
                        )}


                        {demo ? (
                            <button
                                title="Close For Demo"
                                aria-label="delete"
                                className="btn"
                                style={{
                                    fontSize: 12,
                                    width: 50,
                                    color: '#3C4852',
                                    height: 40,
                                    paddingTop: 5,
                                    fontWeight: 600,
                                    marginTop: 10,
                                    backgroundColor: '#ededed',
                                    marginLeft: 10
                                }}
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" height="25" viewBox="0 -960 960 960"
                                    width="25" fill='#08BD80' style={{ marginRight: 0, marginBottom: 0, marginLeft: 0 }}>
                                    <path d="M255.795-608.718H604.41v-85.026q0-52.282-36.289-89.013-36.289-36.73-88.13-36.73-51.841 0-88.121 36.73-36.28 36.731-36.28 89.013h-51.18q0-74.718 51.142-125.82 51.141-51.103 124.464-51.103 73.322 0 124.448 51.252 51.126 51.252 51.126 125.671v85.026h48.615q31.911 0 53.853 21.744Q780-565.231 780-532.923v366.462q0 31.91-21.942 53.852-21.942 21.942-53.853 21.942h-448.41q-31.911 0-53.853-21.942T180-166.461v-366.462q0-32.308 21.942-54.051 21.942-21.744 53.853-21.744Zm0 466.872h448.41q10.77 0 17.693-6.923 6.923-6.923 6.923-17.692v-366.462q0-10.77-6.923-17.693-6.923-6.923-17.693-6.923h-448.41q-10.77 0-17.693 6.923-6.923 6.923-6.923 17.693v366.462q0 10.769 6.923 17.692t17.693 6.923ZM480.218-280q28.423 0 48.282-19.909 19.859-19.909 19.859-48.249 0-27.714-20.077-48.137-20.078-20.423-48.5-20.423-28.423 0-48.282 20.529-19.859 20.53-19.859 48.577 0 28.048 20.077 47.83Q451.796-280 480.218-280ZM231.179-141.846v-415.693 415.693Z" />
                                </svg>

                            </button>
                        ) : (
                            <button
                                title="Open For Demo"
                                aria-label="delete"
                                className="btn"
                                style={{
                                    fontSize: 12,
                                    width: 50,
                                    color: '#3C4852',
                                    height: 40,
                                    paddingTop: 5,
                                    fontWeight: 600,
                                    marginTop: 10,
                                    backgroundColor: '#ededed',
                                    marginLeft: 10
                                }}
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" height="25" viewBox="0 -960 960 960" width="25" fill='black' style={{ marginRight: 0, marginBottom: 0, marginLeft: 0 }}>
                                    <path d="M255.795-90.667q-31.911 0-53.853-21.942T180-166.461v-366.462q0-32.308 21.942-54.051 21.942-21.744 53.853-21.744h48.615v-85.026q0-74.419 51.142-125.671 51.141-51.252 124.464-51.252 73.322 0 124.448 51.252 51.126 51.252 51.126 125.671v85.026h48.615q31.911 0 53.853 21.744Q780-565.231 780-532.923v366.462q0 31.91-21.942 53.852-21.942 21.942-53.853 21.942h-448.41Zm0-51.179h448.41q10.77 0 17.693-6.923 6.923-6.923 6.923-17.692v-366.462q0-10.77-6.923-17.693-6.923-6.923-17.693-6.923h-448.41q-10.77 0-17.693 6.923-6.923 6.923-6.923 17.693v366.462q0 10.769 6.923 17.692t17.693 6.923ZM480.218-280q28.423 0 48.282-19.909 19.859-19.909 19.859-48.249 0-27.714-20.077-48.137-20.078-20.423-48.5-20.423-28.423 0-48.282 20.529-19.859 20.53-19.859 48.577 0 28.048 20.077 47.83Q451.796-280 480.218-280ZM355.59-608.718h248.82v-85.026q0-52.282-36.289-89.013-36.289-36.73-88.13-36.73-51.841 0-88.121 36.73-36.28 36.731-36.28 89.013v85.026ZM231.179-141.846v-415.693 415.693Z" />
                                </svg>

                            </button>
                        )}


                        <button
                            title="Private"
                            aria-label="delete"
                            className="btn"
                            onClick={() => {
                                setLiveItem(() => row)
                                setModal(() => !modal)
                            }}
                            style={{
                                fontSize: 12,
                                color: '#3C4852',
                                height: 40,
                                paddingTop: 5,
                                fontWeight: 600,
                                marginTop: 10,
                                backgroundColor: '#ededed',
                                marginLeft: 20
                            }}>
                            <h6 style={{ fontSize: 15, color: 'red', fontWeight: 500 }}>Go Live</h6>

                        </button>

                    </div>

                </div>
                <div style={{ marginTop: "auto", marginLeft: "auto" }}>

                    <Menu>
                        <nav>
                            <ul className="nav">
                                <li style={{ fontWeight: 500, fontSize: 13, color: '#3C4852', paddingLeft: 15, marginLeft: -10, width: 200, marginRight: -15, marginTop: 5 }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24" fill='#3C4852'><path d="M440-280h80v-240h-80v240Zm40-320q17 0 28.5-11.5T520-640q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640q0 17 11.5 28.5T480-600Zm0 520q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" /></svg>
                                    <a href="javascript:;" onClick={() => console.log("clicked")}>Edit Video Title </a>
                                </li>
                                <li style={{ fontWeight: 500, fontSize: 13, color: '#3C4852', paddingLeft: 15, marginLeft: -10, width: 200, marginRight: -15, marginTop: 5 }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24" fill='#3C4852'><path d="M200-120q-33 0-56.5-23.5T120-200v-240h80v240h240v80H200Zm320 0v-80h240v-240h80v240q0 33-23.5 56.5T760-120H520ZM240-280l120-160 90 120 120-160 150 200H240ZM120-520v-240q0-33 23.5-56.5T200-840h240v80H200v240h-80Zm640 0v-240H520v-80h240q33 0 56.5 23.5T840-760v240h-80Zm-140-40q-26 0-43-17t-17-43q0-26 17-43t43-17q26 0 43 17t17 43q0 26-17 43t-43 17Z" /></svg>
                                    <a href="javascript:;" onClick={() => console.log("lcicked")}>Edit Thumbnail</a>
                                </li>
                            </ul>
                        </nav>
                    </Menu>
                </div>
            </div>
        </div>
    )
}

export default CourseVideoRow
