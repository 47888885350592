import React, { useEffect, useState } from "react";
import FeedTop from "./feedTop";
import FeedBottom from "./feedBottom";
import Comment from './comments'

const TextFeed = props => {
    const [showFullDescription, setShowFullDescription] = useState(false);
    const maxDescriptionLength = 150; // Maximum number of characters to show


    const { feed, setFeedToEdit, index, removeFeedFromState } = props
    const [description, setDescription] = useState(feed.feed.feed.description)
    const [about, setAbout] = useState(feed.posterObject.about)
    const [showComments, setShowComment] = useState(false)
    useEffect(() => {
        setDescription(feed.feed.feed.description)
    }, [feed])
    return (
        <div>

            <div className="card justify-content-center " >
                <div className="card-body align-items-center">

                <FeedTop feed={feed} removeFeedFromState={removeFeedFromState} index={index} setFeedToEdit={setFeedToEdit} />
                    <div style={{ marginLeft: 10, marginTop: 20 }}>

                        <div>
                            <p>
                                {showFullDescription ? (
                                    <span dangerouslySetInnerHTML={{ __html: description }}></span>
                                ) : (
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html: description.slice(0, maxDescriptionLength),
                                        }}
                                    ></span>
                                )}
                                {description.length > maxDescriptionLength && (
                                    <span
                                        style={{ cursor: "pointer", color: "#005CDD", fontWeight: 500 }}
                                        onClick={() => setShowFullDescription(!showFullDescription)}
                                    >< br />
                                        {showFullDescription ? " See less" : " See more"}
                                    </span>
                                )}
                            </p>
                        </div>
                    </div>
                    <FeedBottom feed={feed} feedId={feed.feed.feed.id} setShowComment={setShowComment} />


                    {showComments ? (

                        <Comment feed={feed} feedId={feed.feed.feed.id} />
                    ) : (
                        null
                    )}
                </div>
            </div>

        </div>

    )
}

export default TextFeed;