import React, { useState, useEffect } from 'react'
import { Link, useHistory } from "react-router-dom"
import { fetch_categories, editInstituteDetails, fetch_instituteDetails } from '../../api/institute'
import { insertImage } from '../../api/blog'
import { serverBaseUrl } from '../..'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { theme } from '../../index'
import { SET_INSTITUTE_DETAILS } from '../../Reducers/types';
import Snackbar from '@material-ui/core/Snackbar';
import ClipLoader from "react-spinners/ClipLoader";
import "./style.css"

export default function InstituteEdit(props) {

    const insDetails = useSelector((state) => state.ins.insDetails)
    let instituteId = insDetails.id
    const history = useHistory();
    const dispatch = useDispatch()
    const [categoryData, seCategoryData] = useState([])
    const [institute, setInstitute] = useState({})
    const [name, setName] = useState(insDetails.name)
    const [directorName, setDirectorName] = useState(insDetails.directorName)
    const [phone, setPhone] = useState(insDetails.phone)
    const [email, setEmail] = useState(insDetails.email)
    const [password, setPassword] = useState(insDetails.password)
    const [address, setAddress] = useState(insDetails.address)
    const [city, setCity] = useState(insDetails.city)
    const [insState, setInsState] = useState(insDetails.state)
    const [about, setAbout] = useState(insDetails.about)
    const [category, setCategory] = useState(insDetails.category)
    const [logo, setLogo] = useState(insDetails.logo)
    const [SnackBarMessage, setSnackBarMessage] = useState("")
    const [isSnackBarShow, setIsSnackBarShow] = useState(false)
    const [loading, setLoading] = useState(false)
    const [uploadImg, setUploadImg] = useState(null)
    const [status, setStatus] = useState(institute.status)
    const indianStates = [
        "Andaman and Nicobar Islands",
        "Andhra Pradesh",
        "Arunachal Pradesh",
        "Assam",
        "Bihar",
        "Chandigarh",
        "Chhattisgarh",
        "Dadra and Nagar Haveli",
        "Daman and Diu",
        "Delhi",
        "Goa",
        "Gujarat",
        "Haryana",
        "Himachal Pradesh",
        "Jammu and Kashmir",
        "Jharkhand",
        "Karnataka",
        "Kerala",
        "Ladakh",
        "Lakshadweep",
        "Madhya Pradesh",
        "Maharashtra",
        "Manipur",
        "Meghalaya",
        "Mizoram",
        "Nagaland",
        "Odisha",
        "Puducherry",
        "Punjab",
        "Rajasthan",
        "Sikkim",
        "Tamil Nadu",
        "Telangana",
        "Tripura",
        "Uttar Pradesh",
        "Uttarakhand",
        "West Bengal"
    ]
    const fetchCategoryCallback = (response) => {
        if (response.status == 200) {
            response.json().then(data => {
                console.log('getting category', data)
                seCategoryData(data)
            })
        }
    }

    useEffect(() => {
        fetch_categories(fetchCategoryCallback);
        fetch_instituteDetails(instituteId, (response) => {
            if (response.status == 200) {
                response.json().then(data => {
                    setName(data?.name);
                    setDirectorName(data?.directorName);
                    setPhone(data?.phone);
                    setEmail(data?.email);
                    setPassword(data?.password);
                    setAddress(data?.address);
                    setCity(data?.city);
                    setInsState(data?.state);
                    setAbout(data?.about);
                    setCategory(data?.category);
                    if (data.logo?.includes("https://") || data.logo?.includes("http://")) {
                        setLogo(data.logo);
                    } else {
                        setLogo(serverBaseUrl + data.logo);
                    }

                    setInstitute(data);
                })
            }
        })
    }, [instituteId])

    const editInstituteCallback = (response) => {
        if (response.status == 200) {
            var obj = { ...institute }
            obj.name = name;
            obj.directorName = directorName
            obj.phone = phone
            obj.email = email
            obj.address = address
            obj.city = city
            obj.state = insState
            obj.about = about
            obj.category = category
            obj.logo = uploadImg ? URL.createObjectURL(uploadImg) : logo
            setInstitute(obj)
            dispatch({ type: SET_INSTITUTE_DETAILS, payload: { insDetails: obj } })
            setIsSnackBarShow(true)
            setSnackBarMessage("Institute Details Updated Successfully")
            window.location.reload();

        } else {
            setSnackBarMessage("Something Went Wrong. Please Try Again Later.")
            setIsSnackBarShow(true)
        }
        setLoading(false)
    }

    const closeSnack = () => {
        setIsSnackBarShow(false)
    }

    const imageUploadCallback = (response) => {
        if (response.status == 201) {
            console.log(response.headers.get('location'))
            setLogo(serverBaseUrl + response.headers.get('location'))
            console.log('ha image uploaded successfully')
            console.log(response.headers.get('location'))
            editInstituteDetails(instituteId, name, directorName, phone, email, password, address, city, insState, about, category, response.headers.get('location'), institute.accountHolderName, institute.accountNumber, institute.bankName, institute.ifsc, institute.leads, institute.expoToken, institute.boostValue, institute.fiveStarCount, institute.followersCount, institute.fourStarCount, institute.oneStarCount, institute.status, institute.threeStarCount, institute.totalRating, institute.totalRatingCount, institute.totalRevenue, institute.twoStarCount, editInstituteCallback)

        }
    }


    const action4EditInstitute = () => {

        if (!loading) {
            if ((window.confirm("Are you Sure Save Current Changes?"))) {
                setLoading(true)
                if (uploadImg !== null) {
                    // alert('ha user updated img')
                    insertImage(uploadImg, imageUploadCallback)

                    return;
                }
                editInstituteDetails(instituteId, name, directorName, phone, email, password, address, city, insState, about, category, logo, institute.accountHolderName, institute.accountNumber, institute.bankName, institute.ifsc, institute.leads, institute.expoToken, institute.boostValue, institute.fiveStarCount, institute.followersCount, institute.fourStarCount, institute.oneStarCount, institute.status, institute.threeStarCount, institute.totalRating, institute.totalRatingCount, institute.totalRevenue, institute.twoStarCount, editInstituteCallback)
            }
        }
    }

    const action4selectedSelectCategory = (e) => {
        if (e.target.value != institute.category) {
            setStatus(institute.status)
        } else {
            setStatus(institute.status)
        }
        setCategory(e.target.value)
    }
    const handleNameChange = (e) => {
        const inputValue = e.target.value;

        // Check if the input value exceeds the character limit
        if (inputValue.length <= 40) {
            setName(inputValue);
        }
    };



    return (
        <div style={{ marginTop: -15, fontFamily: 'Outfit, sans-serif', }}>
            <div class="page-breadcrumb  d-md-flex align-items-center mb-3">
                <div class="breadcrumb-title pr-3" style={{ fontWeight: 600, fontSize: 24 }}>Edit Profile</div>
                <div class="ml-auto">
                    <div class="btn-group">


                        <button type="button" class="btn" style={{ marginRight: 5, fontSize: 14 }} onClick={() => history.goBack()}>
                            Go Back</button>

                    </div>
                </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div className='institute_section' style={{ backgroundColor: 'white', width: '60%', fontFamily: 'Outfit, sans-serif', borderRadius: 15 }}>


                    <div>
                        {/* Upload New Photo Start */}
                        <div style={{ borderBottom: '1px solid #f0f0f0' }}>
                            <div style={{ display: 'flex', marginTop: -45, justifyContent: 'flex-start' }}>


                                <div className="col-lg-2 mt-4 text-center">
                                    <div style={{ marginLeft: '-50%' }}>
                                        <img src={uploadImg ? URL.createObjectURL(uploadImg) : logo}
                                            onError={({ currentTarget }) => {
                                                currentTarget.onerror = null; // prevents looping
                                                currentTarget.src = "/assets/images/profile.png";
                                            }} className="rounded-circle" width="130" height="130" alt="logo" />
                                    </div>
                                </div>
                                <div className="form-group" style={{ marginTop: 20, marginLeft: 35 }}>
                                    <label htmlFor="file-input" className="custom-file-upload">
                                        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24" fill='#3C4852'><path d="M440-320v-326L336-542l-56-58 200-200 200 200-56 58-104-104v326h-80ZM240-160q-33 0-56.5-23.5T160-240v-120h80v120h480v-120h80v120q0 33-23.5 56.5T720-160H240Z" /></svg> Upload Your Logo


                                        <input
                                            accept="image/*"
                                            type="file"
                                            id="file-input"
                                            className="hidden-input"
                                            onChange={(e) => {
                                                const selectedFile = e.target.files[0];
                                                if (selectedFile) {
                                                    const allowedTypes = ['image/jpeg', 'image/jpg', 'image/png'];
                                                    if (allowedTypes.includes(selectedFile.type)) {
                                                        // It's a valid image file; you can proceed with it.
                                                        setUploadImg(selectedFile);
                                                    } else {
                                                        // Not a valid image file; show an error or perform some other action.
                                                        alert('Please select a JPEG or PNG image file.');
                                                    }
                                                }
                                            }}
                                        />


                                    </label>
                                    <p style={{ fontWeight: 400, fontSize: 14, color: '#3C4852' }}>At least 512x512 px recommended.</p>
                                    <p style={{ fontWeight: 400, fontSize: 14, marginTop: -15, color: '#3C4852' }}>JPG and PNG is allowed</p>
                                </div>
                                <div className="mt-5 mb-4 text-center">
                                    <button className="btn" onClick={() => { action4EditInstitute() }} style={{ backgroundColor: '#005CDD', color: 'white', marginLeft: '50%', marginTop: -6, width: 140, fontWeight: 400 }}>
                                        {loading ? (
                                            <ClipLoader color={theme.primaryColor} loading={loading} />
                                        ) : ('Save Changes')}
                                    </button>
                                </div>


                            </div>
                        </div>
                        {/* Upload New Photo End */}

                        <div className="form-row">

                            <div className="col-lg-12 px-0">
  <label htmlFor="instituteName" style={{ fontWeight: 600, color: '#000', marginRight: '8px' }}>Institute Name</label> 
  <input
    type="text"
    id="instituteName"
    className="form-control"
    onChange={handleNameChange}
    value={name}
    maxLength={40}
  />
  <p style={{ position: 'absolute', top: '72%', right: '20px', transform: 'translateY(-50%)',color:'#7A8B94' }}>{`${40 - name.length}`}</p>
                            </div>

                            <div className="col-lg-6  px-0" style={{ marginRight: 7 }}>
                                <div className="form-group">

                                    <label htmlFor="" style={{ fontWeight: 600, Color: '#000' }}>Director Name</label>
                                    <input type="text" className="form-control" onChange={(e) => setDirectorName(e.target.value)} defaultValue={institute.directorName} />
                                </div>
                            </div>

                            <div className="col-lg-6 px-2" style={{ marginRight: -7 }}>
                                <div className="form-group">
                                    <label htmlFor="" style={{ fontWeight: 600, Color: '#000' }}>Phone Number</label>
                                    <input type="tel" contentEditable={false} className="form-control" onChange={(e) => setPhone(e.target.value)} value={institute.phone} readOnly />
                                </div>
                            </div>

                            <div className="col-lg-6  px-0" style={{ marginRight: 7 }}>
                                <div className="form-group">
                                    <label htmlFor="" style={{ fontWeight: 600, Color: '#000' }}>Email address</label>
                                    <input type="email" required className="form-control" onChange={(e) => setEmail(e.target.value)} defaultValue={institute.email}  />
                                </div>
                            </div>
                            <div className="col-lg-6 px-3" style={{ marginLeft: -7 }}>
                                <div className="form-group">
                                    <label htmlFor="stateSelect" style={{ fontWeight: 600, Color: '#000' }}>State</label>
                                    <select

                                        id="stateSelect"
                                        className="form-control custom-select"
                                        onChange={(e) => {
                                            setInsState(e.target.value);
                                        }}
                                        value={insState}
                                        style={{ height: 60 }}

                                    >
                                        <option value={-1}>Select State</option>
                                        {indianStates.map((item, index) => (
                                            <option key={index} value={item}>
                                                {item}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className="col-lg-12 px-2" style={{ marginRight: -7 }}>
                                <div className="form-group custom-select-container">
                                    <label htmlFor="exampleFormControlSelect1" className="custom-label" style={{ fontWeight: 600, color: '#000' }}>
                                        Institute Category
                                    </label>
                                    <select
                                        id="exampleFormControlSelect1"
                                        value={category}
                                        className="form-control custom-select"
                                        onChange={(e) => {
                                            action4selectedSelectCategory(e);
                                        }}
                                        style={{ height: 60 }}
                                    >
                                        <option value="">None</option> {/* Default "None" option */}
                                        {categoryData.map((item) => (
                                            <option value={item.key} key={item.key}>
                                                {item.label}
                                            </option>
                                        ))}
                                    </select>
                                </div>

                            </div>

                            <div className="col-lg-12 px-2" style={{marginTop:10}}>
                                <div className="form-group">
                                    <label htmlFor="" style={{ fontWeight: 600, Color: '#000' }}>Address</label>
                                    <textarea
                                        className="form-control"
                                        onChange={(e) => setAddress(e.target.value)}
                                        defaultValue={institute.address}
                                        style={{ height: '110px' }} // Set the initial height to 70px
                                    ></textarea>
                                </div>
                            </div>
                            {/* <div className="col-lg-4 px-2" style={{marginLeft:4}}>
                        <div className="form-group">
                            <label htmlFor="">City</label>
                            <input type="text" className="form-control" onChange={(e) => setCity(e.target.value)} defaultValue={institute.city} />
                        </div>
                    </div> */}




                        </div>

                        <div className="px-0">
                            <label htmlFor="" style={{ fontWeight: 600, Color: '#000' }}>About Us</label>
                            <textarea className="form-control" rows="3" defaultValue={institute.about} onChange={(e) => setAbout(e.target.value)}>
                            </textarea>
                        </div>






                        <Snackbar
                            open={isSnackBarShow}
                            onClose={(e) => closeSnack(e)}
                            TransitionComponent="TransitionUp"
                            message={SnackBarMessage}
                        />
                    </div>


                </div>
                <div style={{ backgroundColor: 'white', width: '38%', fontFamily: 'Outfit, sans-serif', borderRadius: 15, height: 650, padding: 15, border: '1px solid #ededed' }}>

                    {/* <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 15 }}>
                        <div>
                            <p style={{ fontSize: 18, fontWeight: 500 }}>Account Details</p>
                        </div>
                        <div>
                            <button className="btn" style={{ backgroundColor: '#005CDD', color: 'white', marginTop: -6, fontWeight: 400, }}>
                                Edit
                            </button>
                        </div>
                    </div> */}

                    {/* <div className="col-lg-12 px-0">
                        <div className="form-group">

                            <label htmlFor="" style={{ fontWeight: 600, Color: '#000' }}>Bank account number</label>

                            <input type="email" className="form-control" required defaultValue={institute.accountNumber} />

                        </div>
                    </div>
                    <div className="col-lg-12 px-0" style={{ marginTop: -20 }}>
                        <div className="form-group">

                            <label htmlFor="" style={{ fontWeight: 600, Color: '#000' }}>Re-enter account number</label>

                            <input type="email" className="form-control" required defaultValue={institute.accountNumber} />

                        </div>
                    </div>
                    <div className="col-lg-12 px-0" style={{ marginTop: -20 }}>
                        <div className="form-group">

                            <label htmlFor="" style={{ fontWeight: 600, Color: '#000' }}>IFSC Code</label>

                            <input type="email" className="form-control" required defaultValue={institute.ifsc} />

                        </div>
                    </div>
                    <div className="col-lg-12 px-0" style={{ marginTop: -20 }}>
                        <div className="form-group">

                            <label htmlFor="" style={{ fontWeight: 600, Color: '#000' }}>Bank Account holder's name</label>

                            <input type="email" className="form-control" required defaultValue={institute.accountHolderName} />

                        </div>
                    </div>
                    <div className="col-lg-12 px-0" style={{ marginTop: -20 }}>
                        <div className="form-group">

                            <label htmlFor="" style={{ fontWeight: 600, Color: '#000' }}>UPI Id.</label>

                            <input type="text" className="form-control" />

                        </div>
                    </div> */}
                    <div style={{ backgroundColor: 'white', width: '107%', height: 350, marginTop: 650, marginLeft: -15, fontFamily: 'Outfit, sans-serif', borderRadius: 15, border: '1px solid #ededed' }}>

                    </div>

                </div>


            </div>
        </div>

    )
}
