import React, { useState, useEffect } from 'react';
import { dataLimit } from '../..';
import { currentMonthIncomeSumIns, fetchInsTransactions, todayIncomeSumIns, totalIncomeSumIns } from '../../api/transaction';
import TransactionRow from './TransactionRow';
import { Shimmer } from 'react-shimmer';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
 
const dataLimitPerPage = 25; 

function Transactions(props) {
  const [offset, setOffset] = useState(0);
  const [transactions, setTransactions] = useState([]);
  const [loadingTransactions, setLoadingTransactions] = useState(false);
  const [status, setStatus] = useState(localStorage.getItem('selectedStatus') || 'all');
  const [showShimmer, setShowShimmer] = useState(true);
  const [showNextButton, setShowNextButton] = useState();
  const [allDataLoaded, setAllDataLoaded] = useState(false);
  const insDetails = useSelector((state) => state.ins.insDetails);
  let insId = insDetails.id;
  const [todaysIncome, setTodaysIncome] = useState('0');
  const [totalIncome, setTotalIncome] = useState('0');
  const [currentMonthTotal, setCurrentMonthTotal] = useState('0');
  const history = useHistory();

  useEffect(() => {
    localStorage.setItem('selectedStatus', status);
  }, [status]);

  useEffect(() => {
    fetchInsTransactions(insId, offset, dataLimitPerPage, (response) => {
      if (response.status === 200) {
        response.json().then((data) => {
          const filteredData = data.filter((transaction) => {
            if (status === 'all') {
              return true; // Show all transactions
            } else {
              return transaction.transaction.status === status;
            }
          });

          if (filteredData.length === dataLimitPerPage) {
            setTransactions(filteredData);
            setShowNextButton(true);
          } else if (filteredData.length < dataLimitPerPage) {
            if (filteredData.length === 0) {
              if (offset === 0) {
                setOffset(0);
              } else {
                setOffset(offset - 1);
              }
            } else if (filteredData.length !== 0) {
              setTransactions(filteredData);
            }
            setShowNextButton(false);
            setAllDataLoaded(true);
          }
          setLoadingTransactions(false);
          setShowShimmer(false);
        });
      }
    }, status);
  }, [offset, status]);

  useEffect(() => {
    if (insId) {
      todayIncomeSumIns(insId, (response) => {
        if (response.status === 200) {
          response.json().then((data) => {
            setTodaysIncome(data);
          });
        }
      });
      currentMonthIncomeSumIns(insId, (response) => {
        if (response.status === 200) {
          response.json().then((data) => {
            setCurrentMonthTotal(data);
          });
        }
      });
      totalIncomeSumIns(insId, (response) => {
        if (response.status === 200) {
          response.json().then((data) => {
            setTotalIncome(data);
          });
        }
      });
    }
  }, [insId]);

  const nextPageHandler = () => {
    if (!allDataLoaded) {
      setOffset(offset + 1);
    } else {
      window.alert('No more data available');
    }
  };

  const prePageHandler = () => {
    if (offset > 0) {
      setOffset(offset - 1);
    } else if (offset === 0) {
      setOffset(0);
      setShowNextButton(true);
    }
    setAllDataLoaded(false);
  };

  const handleStatusFilter = (selectedStatus) => {
    // Always set the selected status when the user clicks on a filter button
    setStatus(selectedStatus === status ? 'all' : selectedStatus);
    setOffset(0); // Reset offset when changing the filter
  };

  return (
    <div>
      <div class="page-breadcrumb  d-md-flex align-items-center mb-3">
        <div style={{ fontWeight: 600, fontSize: 24 }}>Transactions</div>
        <div class="ml-auto">
          <button type="button" class="btn" style={{ marginRight: 5 }} onClick={() => history.goBack()}>
            Go Back
          </button>
        </div>
      </div>

      <div className="row mt-4" style={{ marginLeft: -15 }}>
        <div className="col-lg-4 col-md-4">
          <div className="card radius-15" style={{ background: 'linear-gradient(to right, #FED86F, #FED86F, #FED86F)', border: 'none', borderRadius: 10 }}>
            <div className="card-body">
              <div className="media align-items-center">
                <div className="media-body">
                  <p className="mb-0" style={{ fontWeight: 500 }}>
                    Today Earning
                  </p>
                  <h4 className="mb-0" style={{ fontSize: 26, fontWeight: 700, marginTop: 4 }}>
                    ₹{todaysIncome}
                  </h4>
                </div>
                <div className="widgets-icons bg-light text-light rounded-circle">
                  <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
                    <path d="M549-120 280-400v-80h140q53 0 91.5-34.5T558-600H240v-80h306q-17-35-50.5-57.5T420-760H240v-80h480v80H590q14 17 25 37t17 43h88v80h-81q-8 85-70 142.5T420-400h-29l269 280H549Z" />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-4">
          <div className="card radius-15" style={{ background: 'linear-gradient(to right, #9DBFFF,#9DBFFF)', border: 'none', borderRadius: 10 }}>
            <div className="card-body">
              <div className="media align-items-center">
                <div className="media-body">
                  <p className="mb-0" style={{ fontWeight: 500 }}>
                    This Month Earning
                  </p>
                  <h4 className="mb-0" style={{ fontSize: 26, fontWeight: 700, marginTop: 4 }}>
                    ₹{currentMonthTotal}
                  </h4>
                </div>
                <div className="widgets-icons bg-light text-light rounded-circle">
                  <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
                    <path d="M549-120 280-400v-80h140q53 0 91.5-34.5T558-600H240v-80h306q-17-35-50.5-57.5T420-760H240v-80h480v80H590q14 17 25 37t17 43h88v80h-81q-8 85-70 142.5T420-400h-29l269 280H549Z" />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-4">
          <div className="card radius-15" style={{ background: 'linear-gradient(to right, #65CA83, #65CA83, #65CA83)', border: 'none', borderRadius: 10 }}>
            <div className="card-body">
              <div className="media align-items-center">
                <div className="media-body">
                  <p className="mb-0" style={{ fontWeight: 500 }}>
                    All Time Earning
                  </p>
                  <h4 className="mb-0" style={{ fontSize: 26, fontWeight: 700, marginTop: 4 }}>
                    ₹{totalIncome}
                  </h4>
                </div>
                <div className="widgets-icons bg-light text-light rounded-circle">
                  <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
                    <path d="M549-120 280-400v-80h140q53 0 91.5-34.5T558-600H240v-80h306q-17-35-50.5-57.5T420-760H240v-80h480v80H590q14 17 25 37t17 43h88v80h-81q-8 85-70 142.5T420-400h-29l269 280H549Z" />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-3">
  <div style={{ display: 'flex', marginBottom: '15px' }}>
    <button
      style={{ 
        backgroundColor: status === 'all' ? '#007BFF' : '#e9ecef',
        color: status === 'all' ? 'white' : 'black',
        border: '1px solid #fff',
        borderRadius: '5px',
        padding: '6px 12px',
        cursor: 'pointer',
        marginRight: '8px',
        fontSize:13
      }}
      onClick={() => handleStatusFilter('all')}
    >
      All
    </button>
    <button
      style={{ 
        backgroundColor: status === 'TXN_SUCCESS' ? '#28A745' : '#e9ecef',
        color: status === 'TXN_SUCCESS' ? 'white' : 'black',
        border: '1px solid #fff',
        borderRadius: '5px',
        padding: '6px 12px',
        cursor: 'pointer',
        marginRight: '8px',
        fontSize:13
      }}
      onClick={() => handleStatusFilter('TXN_SUCCESS')}
    >
      Success
    </button>
    <button
      style={{ 
        backgroundColor: status === 'TXN_FAILURE' ? '#DC3545' : '#e9ecef',
        color: status === 'TXN_FAILURE' ? 'white' : 'black',
        border: '1px solid #fff',
        borderRadius: '5px',
        padding: '6px 12px',
        cursor: 'pointer',
        marginRight: '8px',
        fontSize:13
      }}
      onClick={() => handleStatusFilter('TXN_FAILURE')}
    >
      Failed
    </button>
    <button
      style={{ 
        backgroundColor: status === 'TXN_PENDING' ? '#FFC107' : '#e9ecef',
        color: status === 'TXN_PENDING' ? 'white' : 'black',
        border: '1px solid #fff',
        borderRadius: '5px',
        padding: '6px 12px',
        cursor: 'pointer',
        marginRight: '8px',
        fontSize:13
      }}
      onClick={() => handleStatusFilter('TXN_PENDING')}
    >
      Pending
    </button>
  </div>
</div>


      <div className="mt-3">
        <div class="table-responsive">
          <table class="table table-striped table-bordered mb-0" id="table1">
            <thead class="thead-light" style={{ borderRadius: 15 }}>
              <tr style={{ fontSize: 12, textAlign: 'center' }}>
                <th align="center">#</th>
                <th align="center">Date</th>
                <th align="center">Amount</th>
                <th align="center">Student Name</th>
                <th align="center">Student Mobile</th>
                <th align="center">Course</th>
                <th align="center">Status</th>
                <th align="center">OrderId</th>
              </tr>
            </thead>
            <tbody>
              {showShimmer ? (
                <td colspan="11">
                  <Shimmer width={'100%'} height={40} />
                </td>
              ) : (
                <>
                  {transactions.map((row, i) => (
                    <TransactionRow row={row} index={i} delTransaction={() => {}} />
                  ))}
                </>
              )}
            </tbody>
          </table>
        </div>
      </div>

      <div className="mt-3">
        <div class="modal-footer">
          {offset > 0 ? (
            <button type="button" class="btn" onClick={() => prePageHandler()}>
              Previous
            </button>
          ) : null}
          {!allDataLoaded && showNextButton ? (
            <button type="button" class="btn " onClick={() => nextPageHandler()}>
              Next
            </button>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default Transactions;
