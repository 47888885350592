import React, { useState, useEffect } from 'react'
import { dataLimit } from '../../..';
import { fetchLeads, getLeadCount, getCourseCount } from '../../../api/leads';
import LeadsRow from './LeadsRow';
import { Shimmer } from 'react-shimmer'
function LeadsCount(props) {

  const { insId } = props;
  const [leadCount, setLeadCount] = useState('')
  const [courseCount, setCourseCount] = useState('')
  const [loadingLead, setLoadingLead] = useState(true)
  const [loadingCourse, setLoadingCourse] = useState(true)

  useEffect(() => {
    getLeadCount(insId, leadCountCallBack)
    getCourseCount(insId, courseCountCallBack)
  }, [insId])

  const leadCountCallBack = (response) => {
    setLoadingLead(false)
    if (response.status == 200) {
      response.json().then(data => {
        setLeadCount(data)
      })
    }
    else {
      console.log("Erroe", response.status)
    }
  }
  const courseCountCallBack = (response) => {
    setLoadingCourse(false)
    if (response.status == 200) {
      response.json().then(data => {
        setCourseCount(data)
      })
    }
    else {
      console.log("Error", response.status)
    }
  }

  return (
    <div className="row" style={{ marginBottom: -15 }}>
      <div className="col-12 col-lg-4">
        <div className="card radius-15" style={{ background: 'linear-gradient(to right, #FED86F, #FED86F, #FED86F)', border: 'none', borderRadius: 10 }}>
          <div className="card-body">
            <div className="media align-items-center">
              <div className="media-body">
                <p className="mb-0" style={{ fontWeight: 500, }}>Total Leads</p>
                <h4 className="mb-0" style={{ fontSize: 26, fontWeight: 700, marginTop: 4 }}>{leadCount}</h4>
              </div>
              <div className="widgets-icons bg-light text-light rounded-circle"> <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M760-130.001q-37.461 0-66.731-22.5-29.269-22.5-38.576-57.5H440q-62.154 0-106.076-43.923Q290.001-297.846 290.001-360q0-62.154 43.923-106.076Q377.846-509.999 440-509.999h80q37.125 0 63.563-26.46 26.438-26.46 26.438-63.616t-26.438-63.541Q557.125-690.001 520-690.001H305.307q-9.923 35-38.884 57.5-28.962 22.5-66.423 22.5-45.833 0-77.916-32.07t-32.083-77.884q0-45.814 32.083-77.929T200-829.999q37.461 0 66.423 22.5 28.961 22.5 38.884 57.5H520q62.154 0 106.076 43.923Q669.999-662.154 669.999-600q0 62.154-43.923 106.076Q582.154-450.001 520-450.001h-80q-37.125 0-63.563 26.46-26.438 26.46-26.438 63.616t26.438 63.541q26.438 26.385 63.563 26.385h214.693q9.923-35 38.884-57.5 28.962-22.5 66.423-22.5 45.833 0 77.916 32.07t32.083 77.884q0 45.814-32.083 77.929T760-130.001ZM200-669.999q20.846 0 35.424-14.577 14.577-14.578 14.577-35.424t-14.577-35.424Q220.846-770.001 200-770.001t-35.424 14.577Q149.999-740.846 149.999-720t14.577 35.424q14.578 14.577 35.424 14.577Z" /></svg>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-12 col-lg-4">
        <div className="card radius-15" style={{ background: 'linear-gradient(to right, #65CA83, #65CA83, #65CA83)', border: 'none', borderRadius: 10 }}>
          <div className="card-body">
            <div className="media align-items-center">
              <div className="media-body">
                <p className="mb-0" style={{ fontWeight: 500, }}>Total Courses</p>
                <h4 className="mb-0" style={{ fontSize: 26, fontWeight: 700, marginTop: 4 }}>{courseCount}</h4>
              </div>
              <div className="widgets-icons bg-light text-light rounded-circle">  <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M840-280v-276L480-360 40-600l440-240 440 240v320h-80ZM480-120 200-272v-200l280 152 280-152v200L480-120Z" /></svg>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  )
}
export default LeadsCount;