import React, { useEffect, useState } from "react";
import FeedTop from "./feedTop";
import Comment from './comments'
import FeedBottom from "./feedBottom";
import PollFeedRow from "./pollFeedRow";
import { useSelector } from 'react-redux'

const PollFeed = (props) => {
  const { feed, setFeedToEdit, index, removeFeedFromState } = props;
  const insDetails = useSelector((state) => state.ins.insDetails);
  let insId = insDetails.id;

  const [showComments, setShowComment] = useState(false);
  const [totalPollVotes, setTotalPollVotes] = useState(props.feed.feed.feed.totalPollVotes);
  const [optionData, setOptionData] = useState(props.feed.feed.feedPollOptions);
  const [canUserVote, setCanUserVote] = useState(
    props.type === 1
      ? !props.feed.feed.feed.pollVotedInstitutes.includes(`,${insId},`)
      : props.type === 2
      ? !props.feed.feed.feed.pollVotedStudents.includes(`,${props.userInfo.id},`)
      : true
  );
  const [focusedOptionIndex, setFocusedOptionIndex] = useState(-1);

  useEffect(() => {
    setTotalPollVotes(props.feed.feed.feed.totalPollVotes);
    setOptionData(props.feed.feed.feedPollOptions);
    setCanUserVote(
      (props.type === 1 &&
        !props.feed.feed.feed.pollVotedInstitutes.includes(`,${insId},`)) ||
        (props.type === 2 &&
          !props.feed.feed.feed.pollVotedStudents.includes(
            `,${props.userInfo.id},`
          ))
    );
  }, [feed]);

  const handleUpdateVote = (optionId) => {
    if (!canUserVote) {
      return; // User has already voted, so do nothing
    }

    // Simulate an API call to update the vote on the server
    // Replace this with your actual API call
    // For now, we'll update the local state to simulate the update
    const updatedOptionData = optionData.map((option) =>
      option.id === optionId
        ? { ...option, upVotes: option.upVotes + 1 }
        : option
    );

    setOptionData(updatedOptionData);
    setTotalPollVotes(totalPollVotes + 1);
    setCanUserVote(false); // User can no longer vote after voting

    // Simulate sending the vote to the server
    // Replace this with your actual API call
    const voteData = {
      // Your vote data here
      optionId: optionId,
    };

    // Simulate sending the vote to the server
    // Replace this with your actual API call
    // This is an asynchronous operation, so it should be handled based on your actual implementation
    // For now, we'll use a timeout to simulate the API call
    setTimeout(() => {
      // Assuming the vote was successful on the server
      console.log("Vote submitted:", voteData);
    }, 1000);
  };

  const handleSetFocusedOptionIndex = (focusedIndex) => {
    setFocusedOptionIndex(focusedIndex);
  };

  const toggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };
  const [showFullDescription, setShowFullDescription] = useState(false);

  const truncatedDescription = feed.feed.feed.pollQuestion || feed.feed.feed.description
  .split(" ")
  .slice(0, 20)
  .join(" ");
const fullDescription = feed.feed.feed.description;

  return (
    <div className="card justify-content-center">
      <div className="card-body align-items-center">
        <FeedTop
          feed={feed}
          removeFeedFromState={removeFeedFromState}
          index={index}
          setFeedToEdit={setFeedToEdit}
        />
        <div style={{marginTop:20,marginLeft:15}}>
          <div className="mb-md-0 mb-3" >
           
            <p style={{ color:'#000', fontWeight:400 }}>
              {truncatedDescription.length > 110
                ? showFullDescription
                  ? fullDescription
                  : truncatedDescription.slice(0, 110) + "..."
                : truncatedDescription}
              {truncatedDescription.length > 110 && (
                
                <span
                  style={{ cursor: "pointer", color: "#005CDD", fontWeight:500 }}
                  onClick={toggleDescription}
                > < br/>
                    
                  {showFullDescription ? " See Less" : " See More"}
                </span>
                
              )}
            </p>
            {optionData.map((row, index) => (
              <PollFeedRow
                key={row.id}
                row={row}
                totalPollVotes={totalPollVotes}
                updateVote={handleUpdateVote}
                setFocusedOptionIndex={handleSetFocusedOptionIndex}
                canUserVote={canUserVote}
                index={index}
                userType={1}
              />
            ))}
          </div>
        </div>
        <FeedBottom feed={feed} feedId={feed.feed.feed.id} setShowComment={setShowComment} />
        {showComments ? <Comment feed={feed} feedId={feed.feed.feed.id} /> : null}
      </div>
    </div>
  );
}

export default PollFeed;