import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux"
import "./style.css"
import useStateRef from 'react-usestateref';
import { useHistory } from 'react-router';
import { serverBaseUrl } from '../..';
import { SET_AUTH_STATUS, SET_INSTITUTE_DETAILS } from '../../Reducers/types';
import { checkoutInstitute, getCoupon } from '../../api/institute';


const LeftNav = () => {
  const insDetails = useSelector((state) => state.ins.insDetails);
  const [showStatusPopup, setShowStatusPopup] = useState(false);
  const [coupon, setCoupon] = useState("")
  const [price, setPrice] = useState(5999)
  const [couponApplied, setCouponApplied] = useState(false);

  const handleCouponCode = (e) => {
    setCoupon(() => e.target.value)
  }

  const applyCoupon = (e) => {
    e.preventDefault();
    getCoupon(coupon, (res) => {
      res.json().then((data) => {
        if (data !== null) {
          setPrice(() => price - data.value);
          setCouponApplied(true); // Set couponApplied to true
        }
      });
    });
  }

  useEffect(() => {
    if (insDetails.status === 2 || insDetails.status === 3) {
      setShowStatusPopup(true);
    }
  }, [insDetails]);



  useEffect(() => {


    if (insDetails.id) {
    }
  }, [insDetails])

  const $ = window.$
  useEffect(() => {

    $(".toggle-btn").unbind("click")
    $(".toggle-btn-mobile").unbind("click")
    $(function () {
      $('.metismenu-card').metisMenu({
        toggle: false,
        triggerElement: '.card-header',
        parentTrigger: '.card',
        subMenu: '.card-body'
      });
    });
    // Metishmenu card collapse
    $(function () {
      $('.card-collapse').metisMenu({
        toggle: false,
        triggerElement: '.card-header',
        parentTrigger: '.card',
        subMenu: '.card-body'
      });

      // toggle menu button
      $(".toggle-btn").click(function () {
        console.log("sdb ", $(".wrapper").hasClass("toggled"))
        if ($(".wrapper").hasClass("toggled")) {
          // unpin sidebar when hovered
          $(".wrapper").removeClass("toggled");
          $(".sidebar-wrapper").unbind("hover");
          console.log("removed toggled")
        } else {
          $(".wrapper").addClass("toggled");
          console.log("added toggled")
          $(".sidebar-wrapper").hover(function () {
            $(".wrapper").addClass("sidebar-hovered");
          }, function () {
            $(".wrapper").removeClass("sidebar-hovered");
          })
        }
      });
    });
    $(".toggle-btn-mobile").on("click", function () {
      $(".wrapper").removeClass("toggled");
    });

    // metismenu
    $(function () {
      $('#menu').metisMenu();
      // console.log("dfkbh" , $('#menu'))
    });


    $(function () {
      for (var i = window.location, o = $(".metismenu li a").filter(function () {
        return this.href === i;
      }).addClass("").parent().addClass("mm-active"); ;) {
        if (!o.is("li")) break;
        o = o.parent("").addClass("mm-show").parent("").addClass("mm-active");
      }
    })

  }, [])

  const payment = () => {
    window.location.replace(
      `${serverBaseUrl}checkout/institute/${insDetails.id}/?coupon=${coupon}`,
    );
  }


  return (
    <>
      {insDetails.status !== 1 ? (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100vw',
            height: '100vh',
            backgroundColor: 'rgba(0, 0, 0, 0.4)',
            backdropFilter: 'blur(5px)', // Apply the blur effect
            zIndex: 9999,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              background: '#fff',
              padding: '20px',
              borderRadius: '10px',
              border: '1px solid #005CDD',
              fontFamily: 'Outfit, sans-serif', // Added a comma to separate font family names
              // textAlign: 'center',
            }}
          >
            <div style={{ width: 400, height: 500 }}>
              <p style={{ color: '#fff', fontSize: 14, fontWeight: 500, backgroundColor: '#005CDD', marginTop: -30, marginLeft: 90, marginRight: 90, textAlign: 'center', borderRadius: 25 }}>Welcome to AllCoaching</p>
              <p style={{ color: '#212121', fontSize: 24, fontWeight: 600 }}>Hi, {insDetails.name}</p>
              <p style={{ color: '#212121', fontSize: 28, fontWeight: 800 }}>₹{price}<span style={{ fontSize: 12, fontWeight: 'normal' }}>/ One Time Payment</span></p>
              <p style={{ color: '#212121', fontSize: 16 }}>Join Allcoaching, for live classes, test series, study materials, schedules, and recorded classes. With a convenient one-time payment. Start today!</p>
              <p style={{ color: '#212121', fontSize: 16 }}>Why choose a one-time fee?</p>

              <ul class="custom-list">
                <li>No Subscriptions</li>
                <li>Unlimited Access</li>
                <li>Lifetime Benefits</li>
                {/* <li>Continuous Updates</li> */}
              </ul>
              <div>
                <input
                  type="text"
                  id="couponCode"
                  value={coupon}
                  onChange={handleCouponCode}
                  placeholder="Enter Promo Code"
                  style={{
                    marginLeft: 13,
                    marginBottom: 13,
                    marginRight: 10,
                    paddingLeft: 10,
                    textTransform: 'uppercase',
                    fontWeight: 600,
                    fontSize: '16px',
                    // borderColor: couponApplied ? '0.5px solid #EDEDED' : '0.5px solid black', // Set border color to green if coupon is applied
                    backgroundColor: couponApplied ? '#EDEDED' : 'white',
                    border: '0.5px solid #EDEDED'
                  }}
                  disabled={couponApplied}
                />

                <button
                  onClick={applyCoupon}
                  style={{
                    height: 40,
                    border: 'none',
                    backgroundColor: couponApplied ? 'green' : 'black',
                    color: 'white',
                    fontSize: 14,
                    width: 134,
                    borderRadius: 5,
                  }}
                  disabled={couponApplied}
                >
                  {couponApplied ? 'Applied' : 'Apply'}
                </button>

              </div>
              <button
                onClick={payment}
                style={{
                  backgroundColor: '#005CDD',
                  border: 'none',
                  borderRadius: 5,
                  fontSize: 18,
                  padding: 10,
                  alignSelf: 'center',
                  paddingLeft: 140,
                  paddingRight: 140,
                  transition: 'background-color 0.4s, transform 0.4s',
                  marginLeft: 14,
                  color: '#fff'
                }}
                onMouseEnter={(e) => {
                  e.target.style.backgroundColor = '#005CDD'; // Change background color on hover
                  e.target.style.transform = 'scale(1.1)'; // Scale the button on hover
                }}
                onMouseLeave={(e) => {
                  e.target.style.backgroundColor = '#005CDD'; // Change back to the original background colorx
                  e.target.style.transform = 'scale(1)'; // Reset scale on hover out
                }}
              ><svg height="24" width="24" fill='#fff' viewBox="0 0 375 509" xmlns="http://www.w3.org/2000/svg"><path d="M249.685 14.125C249.685 11.5046 248.913 8.94218 247.465 6.75675C246.017 4.57133 243.957 2.85951 241.542 1.83453C239.126 0.809546 236.463 0.516683 233.882 0.992419C231.301 1.46815 228.917 2.69147 227.028 4.50999L179.466 50.1812C108.664 118.158 48.8369 196.677 2.11373 282.944C0.964078 284.975 0.367442 287.272 0.38324 289.605C0.399039 291.938 1.02672 294.226 2.20377 296.241C3.38082 298.257 5.06616 299.929 7.09195 301.092C9.11775 302.255 11.4133 302.867 13.75 302.869H129.042V494.875C129.039 497.466 129.791 500.001 131.205 502.173C132.62 504.345 134.637 506.059 137.01 507.106C139.383 508.153 142.01 508.489 144.571 508.072C147.131 507.655 149.516 506.503 151.432 504.757L172.698 485.394C247.19 417.643 310.406 338.487 359.975 250.894L373.136 227.658C374.292 225.626 374.894 223.327 374.882 220.99C374.87 218.653 374.243 216.361 373.065 214.341C371.887 212.322 370.199 210.646 368.17 209.482C366.141 208.318 363.841 207.706 361.5 207.707H249.685V14.125Z"></path></svg> Pay Now
              </button>
              <p style={{ color: '#212121', fontSize: 14, fontWeight: 400, textAlign: 'center', marginTop: 5 }}>Pay once. Build unlimited projects!</p>
            </div></div>
        </div>
      ) : (null)}
      {showStatusPopup && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100vw',
            height: '100vh',
            backgroundColor: 'rgba(0, 0, 0, 0.4)',
            backdropFilter: 'blur(5px)', // Apply the blur effect
            zIndex: 9999,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              background: '#fff',
              padding: '20px',
              borderRadius: '10px',
              border: '1px solid #EA4335',
              fontFamily: 'Outfit, sans-serif', // Added a comma to separate font family names
              // textAlign: 'center',
            }}
          >
            {insDetails.status === 2 && <div style={{ width: 400, height: 500 }}>
              <p style={{ color: '#212121', fontSize: 12, fontWeight: 500, backgroundColor: '#EA4335', marginTop: -30, marginLeft: 140, marginRight: 140, textAlign: 'center', borderRadius: 25 }}>USER BLOCKED</p>
              <p style={{ color: '#212121', fontSize: 24, fontWeight: 600 }}>Hi, {insDetails.name}</p>
              <p style={{ color: '#212121', fontSize: 28, fontWeight: 800 }}>Account Blocked – Next Steps</p>
              <p style={{ color: '#212121', fontSize: 16 }}>We're sorry, but your account has been temporarily blocked. Please follow the steps below to resolve the issue:</p>
              <ul class="custom-list">
                <li>Contact our support team at <a href="mailto:help@allcoaching.in" style={{ color: 'blue' }}>help@allcoaching.in</a></li>
                <li>Provide your account details for verification.</li>
                <li>Our team will swiftly resolve the issue for you!</li>
                <li>Check FAQs for solutions</li>
                <li>Allow time for issue investigation</li>
              </ul>
              <a target='_blank' href='https://api.whatsapp.com/send/?phone=919889977262&text=Hi,My Account is Blocked'>
                <button
                  style={{
                    backgroundColor: '#005CDD',
                    border: 'none',
                    borderRadius: 5,
                    fontSize: 18,
                    padding: 10,
                    alignSelf: 'center',
                    paddingLeft: 140,
                    paddingRight: 140,
                    transition: 'background-color 0.4s, transform 0.4s',
                    marginLeft: 14,
                    marginTop: 50,
                    color: '#fff'
                  }}
                  onMouseEnter={(e) => {
                    e.target.style.backgroundColor = '#0650B8'; // Change background color on hover
                    e.target.style.transform = 'scale(1.1)'; // Scale the button on hover
                  }}
                  onMouseLeave={(e) => {
                    e.target.style.backgroundColor = '#005CDD'; // Change back to the original background color
                    e.target.style.transform = 'scale(1)'; // Reset scale on hover out
                  }}
                >Contact Us
                </button></a>
              <p style={{ color: '#212121', fontSize: 14, fontWeight: 400, textAlign: 'center', marginTop: 5 }}>Thank you for your patience and cooperation.</p>
            </div>}
          </div>
        </div>
      )}
      <div className="sidebar-wrapper" data-simplebar="true" style={{
        backgroundColor: 'white',
        marginTop: 60,
        fontFamily: 'Outfit, sans-serif',
        zIndex: 1
      }} id="drawer">
        <ul>
          <li >
            <div>
              <div style={{ display: 'flex', marginTop: 10, backgroundColor: '#fff', borderBottom: '1px solid #EDEDED', marginBottom: 10 }}>
                <img
                  src={
                    (insDetails.logo &&
                      (insDetails.logo.includes("https://") ||
                        insDetails.logo.includes("http://")))
                      ? insDetails.logo
                      : serverBaseUrl + (insDetails.logo || "https://admin.allcoaching.in/assets/images/icon.png")
                  }
                  style={{ width: 80, height: 80, marginLeft: 10, marginTop: 10, marginBottom: 10, }}
                  resizeMode="cover"
                  className='user-img'
                  alt="user avatar"
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = "/assets/images/profile.png";
                  }}
                />
                <p style={{ fontWeight: 700, marginLeft: 25, marginRight: 10, fontSize: 14, textAlign: 'left', color: '#000', letterSpacing: 0.5, marginTop: 20 }}>{insDetails.name}
                  <br /> <span style={{ fontSize: 12, fontWeight: 500, color: '#243FD6' }}>{insDetails.directorName}</span>
                </p>


              </div>


            </div>

          </li>
        </ul>

        <ul className="metismenu" id="menu">

          <li className="new" style={{ marginTop: 3 }}>
            <Link to={"/dashboard"}>
              <div style={{ marginLeft: 0, marginRight: 10 }}>
                <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M520-600v-240h320v240H520ZM120-440v-400h320v400H120Zm400 320v-400h320v400H520Zm-400 0v-240h320v240H120Zm80-400h160v-240H200v240Zm400 320h160v-240H600v240Zm0-480h160v-80H600v80ZM200-200h160v-80H200v80Zm160-320Zm240-160Zm0 240ZM360-280Z" /></svg>
              </div>
              <div style={{ fontWeight: 500, fontSize: 15, paddingLeft: 15, paddingTop: 2, color: '#000' }} >Dashboard</div>
            </Link>
          </li>


          <li style={{ marginTop: 3 }}>
            <Link to={"/insCourses/false"}>
              <div style={{ marginLeft: 0, marginRight: 10 }}>
                <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M240-440h360v-80H240v80Zm0-120h360v-80H240v80Zm-80 400q-33 0-56.5-23.5T80-240v-480q0-33 23.5-56.5T160-800h640q33 0 56.5 23.5T880-720v480q0 33-23.5 56.5T800-160H160Zm0-80h640v-480H160v480Zm0 0v-480 480Z" /></svg>

              </div>
              <div style={{ fontWeight: 500, fontSize: 15, paddingLeft: 15, paddingTop: 2, color: '#000' }} >Courses</div>
            </Link>

          </li>

          <li style={{ marginTop: 3 }}>
            <Link to="/feeds/images">
              <div style={{ marginLeft: 0, marginRight: 10 }}>
                <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M280-240q-17 0-28.5-11.5T240-280v-80h520v-360h80q17 0 28.5 11.5T880-680v600L720-240H280ZM80-280v-560q0-17 11.5-28.5T120-880h520q17 0 28.5 11.5T680-840v360q0 17-11.5 28.5T640-440H240L80-280Zm520-240v-280H160v280h440Zm-440 0v-280 280Z" /></svg>
              </div>
              <div style={{ fontWeight: 500, fontSize: 15, paddingLeft: 15, paddingTop: 2, color: '#000' }} >Community</div>
            </Link>
          </li>


          <li style={{ marginTop: 3 }}>
            <Link to={"/notification"}>
              <div style={{ marginLeft: 0, marginRight: 10 }}>
                <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M160-200v-80h80v-280q0-83 50-147.5T420-792v-28q0-25 17.5-42.5T480-880q25 0 42.5 17.5T540-820v28q80 20 130 84.5T720-560v280h80v80H160Zm320-300Zm0 420q-33 0-56.5-23.5T400-160h160q0 33-23.5 56.5T480-80ZM320-280h320v-280q0-66-47-113t-113-47q-66 0-113 47t-47 113v280Z" /></svg>
              </div>
              <div style={{ fontWeight: 500, fontSize: 15, paddingLeft: 15, paddingTop: 2, color: '#000' }} >Send Notification</div>
            </Link>

          </li>
          <li style={{ marginTop: 3 }}>
            <Link to="/transactions/-1">
              <div style={{ marginLeft: 0, marginRight: 10 }}>
                <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M560-440q-50 0-85-35t-35-85q0-50 35-85t85-35q50 0 85 35t35 85q0 50-35 85t-85 35ZM280-320q-33 0-56.5-23.5T200-400v-320q0-33 23.5-56.5T280-800h560q33 0 56.5 23.5T920-720v320q0 33-23.5 56.5T840-320H280Zm80-80h400q0-33 23.5-56.5T840-480v-160q-33 0-56.5-23.5T760-720H360q0 33-23.5 56.5T280-640v160q33 0 56.5 23.5T360-400Zm440 240H120q-33 0-56.5-23.5T40-240v-440h80v440h680v80ZM280-400v-320 320Z" /></svg>
              </div>
              <div style={{ fontWeight: 500, fontSize: 15, paddingLeft: 15, paddingTop: 2, color: '#000' }} >Transactions</div>
            </Link>
          </li>
          <li style={{ marginTop: 3 }}>
            <Link to="/leads/-1">
              <div style={{ marginLeft: 0, marginRight: 10 }}>
                <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M760-130.001q-37.461 0-66.731-22.5-29.269-22.5-38.576-57.5H440q-62.154 0-106.076-43.923Q290.001-297.846 290.001-360q0-62.154 43.923-106.076Q377.846-509.999 440-509.999h80q37.125 0 63.563-26.46 26.438-26.46 26.438-63.616t-26.438-63.541Q557.125-690.001 520-690.001H305.307q-9.923 35-38.884 57.5-28.962 22.5-66.423 22.5-45.833 0-77.916-32.07t-32.083-77.884q0-45.814 32.083-77.929T200-829.999q37.461 0 66.423 22.5 28.961 22.5 38.884 57.5H520q62.154 0 106.076 43.923Q669.999-662.154 669.999-600q0 62.154-43.923 106.076Q582.154-450.001 520-450.001h-80q-37.125 0-63.563 26.46-26.438 26.46-26.438 63.616t26.438 63.541q26.438 26.385 63.563 26.385h214.693q9.923-35 38.884-57.5 28.962-22.5 66.423-22.5 45.833 0 77.916 32.07t32.083 77.884q0 45.814-32.083 77.929T760-130.001ZM200-669.999q20.846 0 35.424-14.577 14.577-14.578 14.577-35.424t-14.577-35.424Q220.846-770.001 200-770.001t-35.424 14.577Q149.999-740.846 149.999-720t14.577 35.424q14.578 14.577 35.424 14.577Z" /></svg>
              </div>
              <div style={{ fontWeight: 500, fontSize: 15, paddingLeft: 15, paddingTop: 2, color: '#000' }} >Leads</div>
            </Link>
          </li>
          <li style={{ marginTop: 3 }}>
            <Link to={"/insCourses/true"}>
              <div style={{ marginLeft: 0, marginRight: 10 }}>
                <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M440-320h80v-166l64 62 56-56-160-160-160 160 56 56 64-62v166ZM280-120q-33 0-56.5-23.5T200-200v-520h-40v-80h200v-40h240v40h200v80h-40v520q0 33-23.5 56.5T680-120H280Zm400-600H280v520h400v-520Zm-400 0v520-520Z" /></svg>
              </div>
              <div style={{ fontWeight: 500, fontSize: 15, paddingLeft: 15, paddingTop: 2, color: '#000' }} >Recover Courses</div>
            </Link>

          </li>


          <li style={{ fontWeight: 500, fontSize: 12, paddingLeft: 15, paddingTop: 2, color: '#000', marginTop: 50, marginLeft: 75 }}>

            <div style={{ color: '#A6B3BA', fontSize: 13 }}>V 103.146.36.1</div>
            <div style={{ color: '#A6B3BA', marginLeft: 5 }}>AllCoaching</div>


          </li>
        </ul>

      </div>
    </>
  )
}

export default LeftNav
