import React, { useState, useEffect, useRef, useReducer } from 'react';
import { signUp, signupOtp } from '../../../src/api/signup'
import { useDispatch, useSelector } from 'react-redux'
import { SET_AUTH_STATUS, SET_INSTITUTE_DETAILS } from '../../../src/Reducers/types';
import { Link, Redirect, useHistory } from 'react-router-dom'
import Snackbar from '@material-ui/core/Snackbar';
import ClipLoader from "react-spinners/ClipLoader";

const Signup = props => {
    const [userData, setUserData] = useReducer((prevData, newData) => {
        const newEvent = { ...prevData, ...newData };
        return newEvent;
    }, {
        name: "", directorName: "", phone: props.phone, error: false
    })
    const [screen, setScreen] = useState("signup");
    const [otp, setOtp] = useState("")
    const [redirect, setRedirect] = useState(false)
    const dispatch = useDispatch()
    const [SnackBarMessage, setSnackBarMessage] = useState("")
    const [isSnackBarShow, setIsSnackBarShow] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [imagePrev, setImagePrev] = useState("")
    const history = useHistory();

    const authStatus = useSelector((state) => state.ins.authStatus)
    useEffect(() => {
        if (authStatus) {
            history.push("/dashboard")
        }
    }, [])

    useEffect(() => {

    }, [])

    const createOtpCallback = (response) => {
        setIsLoading(false)
        if (response.status == 200) {
            setScreen(() => "otp")
        }
        else {
            setIsSnackBarShow(() => true);
            setSnackBarMessage(() => "Please Enter Valid Number")
        }
    }


    const signupCallBack = (response) => {
        setIsLoading(false)
        if (response.status === 201) {
            console.log('signup', response.headers.get('location'))
            var obj = {
                id: response.headers.get('location'),
                name: userData.name,
                directorName: userData.directorName,
                phone: userData.phone,
            }

            dispatch({ type: SET_INSTITUTE_DETAILS, payload: { insDetails: obj } })
            dispatch({ type: SET_AUTH_STATUS, payload: { authStatus: true } })
            localStorage.setItem('data', JSON.stringify(obj));
            setRedirect(true)
            history.push("/dashboard")

        } else {
            response.text().then(data => {
                setSnackBarMessage(data)
                setIsSnackBarShow(true)
                setIsLoading(false)
            })

        }
    }

    const signupClickHandler = (e) => {
        e.preventDefault();
        setIsLoading(true)
        signUp(userData.name, userData.directorName, otp, `91${userData.phone}`, 0, signupCallBack)
    }



    const OtpClickHandler = (e) => {
        e.preventDefault()
        if (userData.phone) {
            setIsLoading(true)
            signupOtp(`91${userData.phone}`, createOtpCallback);
        } else {
            setIsLoading(false)
            setIsSnackBarShow(true)
            setSnackBarMessage("Please enter phone")
        }
    }

    const closeSnack = () => {
        setIsSnackBarShow(false)
    }

    return (
        <div>
           
                            <div style={{
                                            background: '#fff',
                                            padding: '20px',
                                            borderRadius: '10px',
                                            //   border: '1px solid #ffbe1a',
                                            fontFamily: 'Outfit, sans-serif', // Added a comma to separate font family names
                                            // textAlign: 'center',
                                        }}>
                                {screen === "signup" ?
                                    <form onSubmit={OtpClickHandler} >
                                        <div >
                                            <div style={{ width: 400, height: 500 }}>
                                                <p style={{ color: '#fff', fontSize: 18, fontWeight: 600, backgroundColor: '#005CDD', marginTop: -33, marginLeft: 1, marginRight: 90, textAlign: 'center', borderRadius: 25 }}>Join AllCoaching</p>
                                                <p style={{ color: '#212121', fontSize: 24, fontWeight: 600, marginTop: 20 }}>Enter Your Details</p>
                                                <p style={{ color: '#212121', fontSize: 16 }}>Please Fill all the Details Correctly</p>
                                                <p style={{ color: '#212121', fontSize: 28, fontWeight: 800 }}> <input type="number" required className="form-control" value={userData?.phone} placeholder="Enter your Phone number" onChange={(e) => {


                                                    setUserData({ phone: e.target.value })
                                                }} readOnly /></p>
                                                <p style={{ color: '#212121', fontSize: 28, fontWeight: 800 }}>   <input type="text" required className="form-control" placeholder="Enter Institute Name" onChange={(e) => setUserData({ name: e.target.value })} /></p>
                                                <p style={{ color: '#212121', fontSize: 28, fontWeight: 800 }}>    <input type="text" className="form-control" placeholder="Enter Director Name" onChange={(e) => setUserData({ directorName: e.target.value })} /></p>

                                           
                                            <button
                                                style={{
                                                    backgroundColor: '#005CDD',
                                                    border: 'none',
                                                    borderRadius: 5,
                                                    fontSize: 16,
                                                    padding: 8,
                                                    alignSelf: 'center',
                                                    paddingLeft: 150,
                                                    paddingRight: 150,
                                                    transition: 'background-color 0.4s, transform 0.4s',
                                                    marginLeft: 20,
                                                    color: '#fff'
                                                }}
                                                type="submit"
                                            >   {isLoading ? (
                                                <ClipLoader color={"white"} loading={isLoading} />
                                            ) : ("Sign Up")}</button>
                                            <p style={{ color: '#212121', fontSize: 14, fontWeight: 400, textAlign: 'center', marginTop: 5 }}>
                                                Having trouble? Write us at <a href="mailto:hello@allcoaching.in" style={{ color: '#005CDD', textDecoration: 'underline' }}>hello@allcoaching.in</a>
                                            </p>
                                            </div>
                                        </div>
                                    </form>
                                    :
                        <div style={{ width: 400,height:500}}>
                            <p style={{ color: '#fff', fontSize: 18, fontWeight: 600, backgroundColor: '#005CDD', marginTop: -33, marginLeft: 1, marginRight: 90, textAlign: 'center', borderRadius: 25 }}>Join AllCoaching</p>
                            <p style={{ color: '#212121', fontSize: 24, fontWeight: 600, marginTop: 100 }}>Enter OTP</p>
                            <p style={{ color: '#212121', fontSize: 16 }}>We've sent an OTP to your registered mobile number</p>
                            <p style={{ color: '#212121', fontSize: 28, fontWeight: 800 }}> <input type="tel" className="form-control" maxLength={6} placeholder="Enter OTP " onChange={(e) => setOtp(e.target.value)} onKeyPress={(e) => {
                                            if (e.key === 'Enter') {
                                                signupClickHandler();
                                            }
                                        }}/></p>
                            <button
                                style={{
                                    backgroundColor: '#005CDD',
                                    border: 'none',
                                    borderRadius: 5,
                                    padding: 8,
                                    alignSelf: 'center',
                                    paddingLeft: 150,
                                    paddingRight: 150,
                                    transition: 'background-color 0.4s, transform 0.4s',
                                    marginLeft: 8,
                                    fontSize: 16,
                                    color: '#fff'
                                }}
                                onClick={signupClickHandler}
                            >   {isLoading ? (
                                <ClipLoader color={"white"} loading={isLoading} />

                            ) : ('Verify OTP')}</button>
                            <p style={{ color: '#212121', fontSize: 14, fontWeight: 400, textAlign: 'center', marginTop: 5 }}>
                                Having trouble? Write us at <a href="mailto:hello@allcoaching.in" style={{ color: '#005CDD', textDecoration: 'underline' }}>hello@allcoaching.in</a>
                            </p>

                        </div>


                }

            </div>

        
            <Snackbar
                open={isSnackBarShow}
                onClose={(e) => closeSnack(e)}
                TransitionComponent="TransitionUp"
                message={SnackBarMessage}
            />
        </div>
    )
}
export default Signup