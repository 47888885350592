import { serverApiUrl } from "../index"


export const signupOtp = (phone, callback) => {
    let headers = new Headers();

    headers.append('Content-Type', 'application/json');

    headers.append('Access-Control-Allow-Origin', serverApiUrl);
    headers.append('Access-Control-Allow-Credentials', 'true');

    headers.append('GET', 'POST', 'OPTIONS');
    // console.log(serverApiUrl+'institute/validate/',JSON.stringify({email,password}))
    fetch(serverApiUrl + 'institute/',
        {
            method: 'POST',
            headers,
            body: JSON.stringify({ phone })
        })
        .then((response) => callback(response))
        .catch((error) => { console.log(error) })
}

export const signUp = (name, directorName, otp, phone, status = 0, callback) => {
    let headers = new Headers();

    headers.append('Content-Type', 'application/json');

    headers.append('Access-Control-Allow-Origin', serverApiUrl);
    headers.append('Access-Control-Allow-Credentials', 'true');

    headers.append('GET', 'POST', 'OPTIONS');
    // console.log(serverApiUrl+'institute/validate/',JSON.stringify({email,password}))
    fetch(serverApiUrl + 'institute/signup/',
        {
            method: 'POST',
            headers,
            body: JSON.stringify({ name, directorName, otp, phone })
        })
        .then((response) => callback(response))
        .catch((error) => { console.log(error) })
} 
