import React from 'react';
import SingleMessageNotification from './SingleMessageNotification';

function HeaderMessages({ messageCount, messages }) {
    return (
        <>
            <a className="nav-link dropdown-toggle dropdown-toggle-nocaret position-relative"
                href="javascript:;"
                data-toggle="dropdown"
            >
                {" "}
                {messageCount > 0 ? (
                    <span className="msg-count">{messageCount}</span>
                ) : (null)}

                <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24" fill='#7A8B94'><path d="M240-400h320v-80H240v80Zm0-120h480v-80H240v80Zm0-120h480v-80H240v80ZM80-80v-720q0-33 23.5-56.5T160-880h640q33 0 56.5 23.5T880-800v480q0 33-23.5 56.5T800-240H240L80-80Zm126-240h594v-480H160v525l46-45Zm-46 0v-480 480Z" /></svg>
            </a>
            <div className="dropdown-menu dropdown-menu-right" style={{ marginTop: 5 }}>
                <a href="javascript:;">
                    <div className="msg-header" style={{ backgroundColor: '#005CDD' }}>
                        <h6 className="msg-header-title">{messageCount} New</h6>
                        <p className="msg-header-subtitle">Student Messages</p>
                    </div>
                </a>
                <div className="header-message-list" style={{ overflowY: 'scroll' }}>

                    {messages.map(message => (
                        <SingleMessageNotification
                            item={message}
                        />
                    ))}

                </div>
                {/* <a href="javascript:;">
            <div className="text-center msg-footer">View All Messages</div>
        </a> */}
            </div>
        </>
    );
}

export default HeaderMessages;
