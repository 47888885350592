import React, { useState, useEffect } from 'react';
import { validateLogin, validateOtp } from '../../api/login';
import { useDispatch, useSelector } from 'react-redux';
import { SET_AUTH_STATUS, SET_INSTITUTE_DETAILS } from '../../Reducers/types';
import { Link, Redirect, useHistory } from 'react-router-dom';
import ClipLoader from "react-spinners/ClipLoader";
import { theme } from '../..';

export default function Otp() {
    const [otp, setOtp] = useState("");
    const [error, setError] = useState(null);
    const [redirect, setRedirect] = useState(false);
    const [loading, setLoading] = useState(false);
    const [mode, setMode] = useState("login");
    const dispatch = useDispatch();
    const history = useHistory();
    const authStatus = useSelector((state) => state.ins.authStatus);

    const login = () => {
        if (validateLoginData()) {
            if (!loading) {
                setLoading(true);
                setError('');
                validateOtp(otp, loginCallBack);
            }
        } else {
            setError("Please Fill All The Fields.");
        }
    };

    const validateLoginData = () => otp !== "";

    const loginCallBack = (response) => {
        if (response.status == 200) {
            response.json().then(data => {
                if (data) {
                    dispatch({ type: SET_AUTH_STATUS, payload: { authStatus: true } });
                    dispatch({ type: SET_INSTITUTE_DETAILS, payload: { insDetails: data } });
                    localStorage.setItem('data', JSON.stringify(data));
                    setRedirect(true);
                    history.push("/dashboard");
                } else {
                    setError("Otp is incorrect");
                }
                setLoading(false);
            });
        } else {
            console.log(response.status);
        }
    };

    useEffect(() => {
        if (authStatus) {
            history.push("/dashboard");
        }
    }, []);

    useEffect(() => {
        const handleBeforeUnload = (e) => {
            e.preventDefault();
            e.returnValue = ''; // This is to show a browser-specific confirmation message
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);

    const handlePageRefresh = () => {
        const shouldRedirect = window.confirm("You are about to leave this page. Do you want to go to the home page?");
        if (shouldRedirect) {
            history.push("/");
        }
    };

    return (
        <div className="section-authentication-login d-flex align-items-center justify-content-center" style={{ backgroundColor: '#fff' }}>
            <div className="row">
                <div className="col-12 col-lg-10 mx-auto">
                    <div className="card radius-15">
                        <div className="row no-gutters">
                            <div className="col-lg-6">
                                <div
                                    style={{
                                        background: '#fff',
                                        padding: '20px',
                                        borderRadius: '10px',
                                        fontFamily: 'Outfit, sans-serif',
                                    }}
                                >
                                    <div style={{ width: 400, height: 500 }}>
                                        <p style={{ color: '#fff', fontSize: 18, fontWeight: 600, backgroundColor: '#005CDD', marginTop: -33, marginLeft: 1, marginRight: 90, textAlign: 'center', borderRadius: 25 }}>Hi, Educators</p>
                                        <p style={{ color: '#212121', fontSize: 24, fontWeight: 600, marginTop: 100 }}>Verify your mobile number</p>
                                        <p style={{ color: '#212121', fontSize: 16 }}>We've sent an OTP to your mobile number</p>
                                        <p style={{ color: '#212121', fontSize: 28, fontWeight: 800 }}> <input type="tel" className="form-control" maxLength={6} placeholder="Enter OTP" onChange={(e) => setOtp(e.target.value)} onKeyPress={(e) => {
                                            if (e.key === 'Enter') {
                                                login();
                                            }
                                        }} /></p>
                                        <p style={{ color: '#FA2918', fontSize: 14, fontWeight: 400 }}> {error ? (
                                            <span style={{ color: 'red', fontWeight: 'bold' }}>{error}</span>
                                        ) : (null)}</p>
                                        <button
                                            style={{
                                                backgroundColor: '#005CDD',
                                                border: 'none',
                                                borderRadius: 5,
                                                padding: 8,
                                                alignSelf: 'center',
                                                paddingLeft: 150,
                                                paddingRight: 150,
                                                transition: 'background-color 0.4s, transform 0.4s',
                                                marginLeft: 5,
                                                fontSize: 16,
                                                color: '#fff',
                                            }}
                                            onClick={() => login()}
                                        >  {loading ? (
                                            <ClipLoader color={theme.primaryColor} loading={loading} />
                                        ) : ('Verify OTP')}
                                        </button>
                                        <p style={{ color: '#212121', fontSize: 14, fontWeight: 400, textAlign: 'center', marginTop: 5 }}>
                                            Having trouble? Write us at <a href="mailto:hello@allcoaching.in" style={{ color: '#005CDD', textDecoration: 'underline' }}>hello@allcoaching.in</a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <img src="https://img.freepik.com/free-vector/privacy-policy-concept-illustration_114360-7853.jpg?w=740&t=st=1695498533~exp=1695499133~hmac=134c901fe2c7f40d7a4ca4d5f469c3d58f1ab8955848b35adb19dc5c2f4ad307" className="card-img login-img h-100" alt="..." />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
