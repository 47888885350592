import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import { theme } from '../../index'
import ClipLoader from "react-spinners/ClipLoader";
import { getStudentCount } from '../../api/Courses';

function CourseRow(props) {
    const { row, index, delCourse,recoverCourse, editCourse, setIsCourseModalVisible, setMode, setEditCourseId, setEditDetails, delLoadings } = props
    const [title, setTitle] = useState(row.title);
    const [description, setDescription] = useState(row.description);
    const [fees, setFees] = useState(row.fees);
    const [leads, setLeads] = useState(row.leads);
    const [courseId, setCourseId] = useState(row.id);
    const [delLoading, setDelLoading] = useState(false);
    const [studentCount, setStudentCount] = useState(0);
    const cardBackgroundClasses = ["bg-rose", "bg-primary", "bg-info"];
    const currentCourseCardColor = cardBackgroundClasses[index % cardBackgroundClasses.length];
    useEffect(() => {
        setDelLoading(delLoadings)
    }, [delLoadings])

    useEffect(() => {
        getStudentCount(row.id, response => {
            if (response.status == 200) {
                response.json().then(data => {
                    console.log(data, " student Count of course")
                    setStudentCount(data)
                })
            }
        })
    }, [row])

    const editHandler = (index) => {
        setMode("edit");
        setIsCourseModalVisible(true);
        setEditDetails(title, description, fees, index, row.id)
    }

    return (
        <>

            <div className="grid grid-cols-4 gap-4 d-flex align-items-center mb-1" style={{ flexDirection: 'row' }}>


                <div className={"m-2 "} style={{ borderRadius: 5, paddingRight: "10px", backgroundColor: "white", width: "300px", height: "280px", flexDirection: 'column', marginBottom: "0px", borderRadius: '5px' }}>
                    <div className='' style={{ width: '300px', height: '80px', background: 'linear-gradient(to bottom, #EDEDED, transparent)', borderRadius: '5px 5px 0px 0px', display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '0 20px', }}>
                        <div style={{ backgroundColor: 'white', borderRadius: '55px', padding: '6px 5px 6px 7px' }}>
                            <svg xmlns="http://www.w3.org/2000/svg" height="30" viewBox="0 -960 960 960" width="30" fill='black' style={{ marginRight: 4, marginBottom: 1 }}><path d="M840-280v-276L480-360 40-600l440-240 440 240v320h-80ZM480-120 200-272v-200l280 152 280-152v200L480-120Z" /></svg>
                        </div>
                        <div style={{ backgroundColor: 'white', borderRadius: '55px', padding: '1px 20px 1px 17px' }}>
                            <h3 style={{ color: '#3C4852', fontWeight: '400', fontSize: "12px", paddingTop: 10 }}>  <svg xmlns="http://www.w3.org/2000/svg" height="15" viewBox="0 -960 960 960" width="15" fill='#EF953E' style={{ marginRight: 2, marginBottom: 1 }}><path d="m280-80 160-300-320-40 480-460h80L520-580l320 40L360-80h-80Z" /> </svg>{studentCount} - Enrolled</h3>
                        </div>
                        <div style={{ backgroundColor: '#7A8B94', borderRadius: '55px', padding: '8px 8px 0px 8px' }}>
                            <h3 style={{ color: '#fff', fontWeight: '400', fontSize: "14px" }}>{row.sortBy}</h3>
                        </div>
                    </div>

                    <h3 style={{ marginLeft: '20px', marginRight: '10px', width: "22ch", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis", color: 'black', fontWeight: "600", fontSize: "16px", paddingTop: "10px", marginTop: '7px' }}>Title -  {row.title}</h3>

                    <h3 style={{ marginLeft: '20px', color: '#3C4852', fontWeight: '400', fontSize: "16px", paddingTop: "15px", marginTop: '13px' }}> Price - {row.fees.toLocaleString('en-IN', {
                        style: 'currency',
                        currency: 'INR', // Currency code for Indian Rupees
                    })}</h3>
                    <h2 style={{ marginLeft: '20px', color: '#3C4852', fontWeight: '400', fontSize: "16px", paddingTop: "15px", marginTop: '13px' }}>Course Duration - {row.expireDay} Days</h2>


                    <div className="d-flex row align-items-center justify-content-between" style={{ margin: 2, marginTop: -8 }}>
                        {row.deleted ? (
                            <>
                                <div title='Recover Course' style={{ display: "flex", justifyContent: "flex-start", marginTop: 18 }}>
                                    <button style={{ color: 'white' }} aria-label="delete" onClick={() => { recoverCourse(row?.id, false, index, 'temporary', () => setDelLoading("temporary")) }} className="btn " style={{ marginLeft: 10, backgroundColor: '#EDEDED', borderRadius: 5, paddingTop: 5, border: 'none' }}>
                                        {delLoading === "temporary" ? (
                                            <ClipLoader color={theme.primaryColor} loading={delLoading} />
                                        ) : (<svg xmlns="http://www.w3.org/2000/svg" height="25" viewBox="0 -960 960 960" width="25" fill='#005CDD' style={{ marginRight: 0, marginBottom: 0 }}><path d="M440-163.538q-104.077-14.231-172.039-92.808Q200-334.923 200-440q0-56 22.154-107.269 22.153-51.27 60.923-90.885l28.538 28.539q-35.692 33.23-53.653 77.077Q240-488.692 240-440q0 88 56.385 154.731Q352.769-218.538 440-203.538v40ZM520-162v-40q86.231-19.077 143.115-84.923Q720-352.769 720-440q0-100-70-170t-170-70h-25.308l64 64-28.308 28.308L378.077-700l112.307-112.308L518.692-784l-64 64H480q117.077 0 198.539 81.461Q760-557.077 760-440q0 104.846-68.346 182.654Q623.308-179.538 520-162Z" /> </svg>)}
                                    </button>
                                </div>
                                <div title='Delete Course Permanently' style={{ display: "flex", justifyContent: "flex-end", marginTop: 18, marginRight: -5 }}>
                                    <button aria-label="delete" onClick={() => { delCourse(row?.id, true, index, 'permanently', () => setDelLoading("permanently")) }} className="btn" style={{ marginRight: 10, backgroundColor: '#EDEDED', borderRadius: 5, paddingTop: 5, border: 'none' }}>
                                        {delLoading === "permanently" ? (
                                            <ClipLoader color={theme.primaryColor} loading={delLoading} />
                                        ) : (<svg xmlns="http://www.w3.org/2000/svg" height="25" viewBox="0 -960 960 960"
                                            width="25" fill='#EA4335' style={{ marginRight: 0, marginBottom: 0, }}>
                                            <path d="M304.615-160q-26.846 0-45.731-18.884Q240-197.769 240-224.615V-720h-40v-40h160v-30.77h240V-760h160v40h-40v495.385Q720-197 701.5-178.5 683-160 655.385-160h-350.77ZM680-720H280v495.385q0 10.769 6.923 17.692T304.615-200h350.77q9.23 0 16.923-7.692Q680-215.385 680-224.615V-720ZM392.307-280h40.001v-360h-40.001v360Zm135.385 0h40.001v-360h-40.001v360ZM280-720v520-520Z" />
                                        </svg>)}
                                    </button>
                                </div>
                            </>
                        ) : (
                            <>


                                <div title='Delete Course' style={{ display: "flex", justifyContent: "flex-start", marginTop: 18, marginLeft: 15 }}>
                                    <button aria-label="delete" onClick={() => {
                                        setDelLoading(true);
                                        delCourse(courseId, true, index);
                                        setDelLoading(() => false)
                                    }} className="btn" style={{ marginRight: 0, backgroundColor: '#EDEDED', borderRadius: 5, paddingTop: 5, border: 'none' }}>
                                        {delLoading ? (
                                            <ClipLoader color={theme.primaryColor} loading={delLoading} />
                                        ) : (<svg xmlns="http://www.w3.org/2000/svg" height="22" viewBox="0 -960 960 960"
                                            width="22" fill='#EA4335' style={{ marginRight: 0, marginBottom: 0, }}>
                                            <path d="M304.615-160q-26.846 0-45.731-18.884Q240-197.769 240-224.615V-720h-40v-40h160v-30.77h240V-760h160v40h-40v495.385Q720-197 701.5-178.5 683-160 655.385-160h-350.77ZM680-720H280v495.385q0 10.769 6.923 17.692T304.615-200h350.77q9.23 0 16.923-7.692Q680-215.385 680-224.615V-720ZM392.307-280h40.001v-360h-40.001v360Zm135.385 0h40.001v-360h-40.001v360ZM280-720v520-520Z" />
                                        </svg>)}
                                    </button>
                                </div>
                                <div style={{ display: "flex", justifyContent: "flex-start", marginTop: 18, marginLeft: 0 }}>
                                    <button aria-label="edit" onClick={() => editHandler(index)} className="btn btn-primary" style={{ backgroundColor: '#EDEDED', border: 'none', fontSize: 14, fontWeight: 400, color: 'black' }}>
                                        Edit
                                    </button>
                                </div>
                                <div style={{ display: "flex", justifyContent: "flex-end", marginTop: 18, marginRight: 7 }}>
                                    <Link to={"/ins/course/courseview/" + courseId + "/" + encodeURIComponent(title)} aria-label="view" className="btn btn-primary" style={{ backgroundColor: '#005CDD', border: 'none', fontSize: 14, fontWeight: 400, color: 'white' }}>
                                        View Course
                                    </Link>
                                </div>


                            </>
                        )}

                    </div>
                </div>













                {/* <td>{index + 1}</td>
            <td>{row.title}</td>
            <td>{row.fees}</td>
            <td>{studentCount}</td>
            <td>
                
                {row.deleted?(
                    <button aria-label="delete" onClick={() => {setDelLoading(true); delCourse(courseId,false, index)}} className="btn btn-success mr-1">
                        {delLoading?(
                            <ClipLoader color={theme.primaryColor}   loading={delLoading}     />
                        ):("Recover")}
                    </button>
                ):(
                    <>
                        <button aria-label="edit" onClick={() => editHandler(index)}className="btn btn-success mr-1">
                            EDIT
                        </button>
                        <button aria-label="delete" onClick={() => {setDelLoading(true); delCourse(courseId,true, index)}} className="btn btn-danger mr-1">
                            {delLoading?(
                                <ClipLoader color={theme.primaryColor}   loading={delLoading}     />
                            ):("Delete")}
                        </button>
                        <Link to={"/ins/course/courseview/"+courseId+"/"+title} aria-label="view" className="btn btn-primary">
                            VIEW
                        </Link>
                    </>

                )}
                
                
            </td> */}
            </div>
        </>
    )
}

export default CourseRow
