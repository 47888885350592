import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux"
import { serverBaseUrl } from '../..';
import { fetch_messagesForIns, getUnSeenMessagesCountForIns } from '../../api/message';
import { fetchInsTransactions, fetchInsTransactionsAnsStatusSuccess, getUnSeenTransactionsCount, fetchReport } from '../../api/transaction';
import useStateRef from 'react-usestateref'
import HeaderMessages from './HeaderMessages';
import SingleNotification from './SingleNotification';
import { SET_AUTH_STATUS, SET_INSTITUTE_DETAILS } from '../../Reducers/types';
import { useHistory } from 'react-router';
import { Link } from "react-router-dom";
import "./style.css"


const Header = () => {
  const insDetails = useSelector((state) => state.ins.insDetails)
  const [transactions, setTransactions, transactionsRef] = useStateRef([])
  const [transactionCount, setTransactionsCount, transactionCountRef] = useStateRef(0)
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const [messages, setMessages] = useState([])
  const [messageCount, setMessageCount] = useState(0)

  const [feedReportCount, setFeedReportCount] = useState(0)
  const [feedReports, setFeedReports] = useState([])

  const [fetchedTransactions, setFetchedTransactions] = useState(false);
  const [fetchedReports, setFetchedReports] = useState(false);

  const [transactionsVisible, setTransactionsVisible] = useState(false);
  const [drawerValue, setDrawer] = useState(true);

  const dispatch = useDispatch()
  const history = useHistory();


  useEffect(() => {


    if (insDetails.id) {


      //getting messages data from database


      getUnSeenMessagesCountForIns(insDetails.id, (response) => {

        if (response.status === 200) {
          response.json().then(data => {
            // console.log(data);
            setMessageCount(data)
          })
        }
      })


      fetch_messagesForIns(insDetails.id, false, "instituteCourseRelated", 0, 20, (response) => {

        if (response.status === 200) {
          response.json().then(data => {
            setMessages(data)
            // console.log(data)
          })
        }
      })

      //Getting transaction data from database
      //transactions count which are not seen by admin
      getUnSeenTransactionsCount(insDetails.id, (response) => {

        if (response.status == 200) {
          response.json().then(data => {
            setTransactionsCount(transactionCountRef.current + data)
          })
        }
      })

      //geting first 20 transaction to diplay as notification
      fetchInsTransactionsAnsStatusSuccess(insDetails.id, 0, 20, (response) => {
        if (response.status == 200) {
          response.json().then(data => {
            setTransactions([...data, ...transactionsRef.current])
            setFetchedTransactions(true)
          })
        }
      })

      //getting feedReport

      fetchReport(insDetails.id, 0, 20, (response) => {
        if (response.status == 200) {
          response.json().then(data => {
            setFeedReports((prev) => {
              return [...prev, ...data]
            })
            console.log(data)
            setFeedReportCount(data.length)
          })
        }
      })


      //getting feedReport

      /*getUnSeenReportCount((response) => {
        response.json().then(data => {
          setTransactionsCount(transactionCountRef.current + data)
        })
      })*/
    }
  }, [insDetails])

  const $ = window.$
  useEffect(() => {

    $(".toggle-btn").unbind("click")
    $(".toggle-btn-mobile").unbind("click")
    $(function () {
      $('.metismenu-card').metisMenu({
        toggle: false,
        triggerElement: '.card-header',
        parentTrigger: '.card',
        subMenu: '.card-body'
      });
    });
    // Metishmenu card collapse
    $(function () {
      $('.card-collapse').metisMenu({
        toggle: false,
        triggerElement: '.card-header',
        parentTrigger: '.card',
        subMenu: '.card-body'
      });

      // toggle menu button
      $(".toggle-btn").click(function () {
        console.log("sdb ", $(".wrapper").hasClass("toggled"))
        if ($(".wrapper").hasClass("toggled")) {
          // unpin sidebar when hovered
          $(".wrapper").removeClass("toggled");
          $(".sidebar-wrapper").unbind("hover");
          console.log("removed toggled")
        } else {
          $(".wrapper").addClass("toggled");
          console.log("added toggled")
          $(".sidebar-wrapper").hover(function () {
            $(".wrapper").addClass("sidebar-hovered");
          }, function () {
            $(".wrapper").removeClass("sidebar-hovered");
          })
        }
      });
    });
    $(".toggle-btn-mobile").on("click", function () {
      $(".wrapper").removeClass("toggled");
    });

    // metismenu
    $(function () {
      $('#menu').metisMenu();
      // console.log("dfkbh" , $('#menu'))
    });


    $(function () {
      for (var i = window.location, o = $(".metismenu li a").filter(function () {
        return this.href == i;
      }).addClass("").parent().addClass("mm-active"); ;) {
        if (!o.is("li")) break;
        o = o.parent("").addClass("mm-show").parent("").addClass("mm-active");
      }
    })

  }, [])

  const logout = () => {
    const confirmed = window.confirm("Are you sure you want to logout?");
    if (confirmed) {
      dispatch({ type: SET_AUTH_STATUS, payload: { authStatus: false } });
      dispatch({ type: SET_INSTITUTE_DETAILS, payload: { insDetails: null } });
      localStorage.removeItem('data');
      history.push("/dashboard");

      // Reload the window
      window.location.reload();
    }
  };

  const handleConfirmLogout = () => {
    dispatch({ type: SET_AUTH_STATUS, payload: { authStatus: false } });
    dispatch({ type: SET_INSTITUTE_DETAILS, payload: { insDetails: null } });
    localStorage.removeItem('data');
    history.push("/dashboard");

    // Reload the window
    window.location.reload();
  };

  const handleCancelLogout = () => {
    setShowLogoutModal(false);
  };
  const handleLogout = () => {
    setShowLogoutModal(true);
  };

  const toogleDrawer = () => {
    const drawer = document.getElementsByClassName("sidebar-wrapper")[0]
    if (window.innerWidth < 800) {
      if (drawerValue) {
        drawer.style.left = "-260px";
        setDrawer(() => false)
      }
      else {
        drawer.style.left = 0;
        setDrawer(() => true)
      }
    }

  }


  return (
    <header className="top-header" style={{ backgroundColor: 'white' }}>
      <nav className="navbar navbar-expand-lg">
        <div style={{ marginLeft: 20, marginTop: -6 }}>
          <svg viewBox="0 0 24 24" onClick={toogleDrawer}
            height="34" width="34" style={{ padding: 5, backgroundColor: 'white', borderRadius: 55, marginTop: -8, marginRight: 10 }}><g class="style-scope tp-yt-iron-icon"><path d="M21,6H3V5h18V6z M21,11H3v1h18V11z M21,17H3v1h18V17z" class="style-scope tp-yt-iron-icon"></path></g></svg>
          <img
            src='/assets/images/newlogo.png '
            style={{ width: 200, height: 50, marginLeft: 0, marginTop: 10, marginBottom: 20 }}
          />
        </div>


        <div className="right-topbar ml-auto" style={{ backgroundColor: 'white', marginTop: -19 }}>

          <ul className="navbar-nav">
            <li className="nav-item search-btn-mobile">
              <a className="nav-link position-relative" href="javascript:;">
                {" "}
                {/* <img src="https://img.icons8.com/?size=1x&id=12449&format=png" className="logo-icon-3"  alt /> */}
              </a>
            </li>
            <li className="nav-item dropdown dropdown-lg">
              <HeaderMessages
                messages={messages}
                messageCount={messageCount}
              />
            </li>

            <li className="nav-item dropdown dropdown-lg">
              <a
                className="nav-link dropdown-toggle dropdown-toggle-nocaret position-relative"
                href="javascript:;"
                data-toggle="dropdown"
              >
                {" "}
                <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24" fill='#7A8B94 '><path d="M160-200v-80h80v-280q0-83 50-147.5T420-792v-28q0-25 17.5-42.5T480-880q25 0 42.5 17.5T540-820v28q80 20 130 84.5T720-560v280h80v80H160Zm320-300Zm0 420q-33 0-56.5-23.5T400-160h160q0 33-23.5 56.5T480-80ZM320-280h320v-280q0-66-47-113t-113-47q-66 0-113 47t-47 113v280Z" /></svg>
                {transactionCount > 0 ? (
                  <span className="msg-count">{transactionCount}</span>
                ) : null}
              </a>

              <div className="dropdown-menu dropdown-menu-right" style={{ marginTop: 5 }}>
                <a href="javascript:;">
                  <div className="msg-header" style={{ backgroundColor: '#005CDD' }}>
                    <h6 className="msg-header-title">{transactionCount + !isNaN(feedReportCount)} New</h6>
                    <p className="msg-header-subtitle">
                      Transaction & Feed Notifications
                    </p>
                  </div>
                </a>
                <div className="header-notifications-list" style={{ overflowY: 'scroll' }}>

                  {transactions.map(item => (
                    <SingleNotification
                      item={item}
                    />
                  ))}
                  {feedReports.map(item => (
                    <h1>{item?.text}</h1>
                  ))}



                </div>
                {/* <a href="javascript:;">
                  <div className="text-center msg-footer">
                    View All Notifications
                  </div>
                </a> */}
              </div>
            </li>
            <li className="nav-item dropdown dropdown-user-profile">
              <a
                className="nav-link dropdown-toggle dropdown-toggle-nocaret"
                href="javascript:;"
                data-toggle="dropdown"
              >
                <div className="media user-box align-items-center">

                  <img
                    src={insDetails.logo?.includes("https://") ? insDetails.logo : serverBaseUrl + insDetails.logo}
                    className="user-img"
                    alt="user avatar"
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null; // prevents looping
                      currentTarget.src = "/assets/images/profile.png";
                    }}
                    style={{ width: 40, height: 40, marginBottom: 10, border: 'none', }}
                  />
                </div>
              </a>

              <div
                className="dropdown-menu dropdown-menu-right"
                style={{
                  backgroundColor: 'white',
                  borderRadius: 0,
                  width: 260,
                  height: 250,
                  marginTop: -10,
                  boxShadow: '1px 1px 10px 0 rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.2) 0px 2px 8px 0px',
                  fontFamily: 'Outfit, sans-serif',
                  borderRadius: 5,
                  marginRight: 10
                }}
              >
                <Link to="/instituteProfile" >
                  <div style={{ display: 'flex' }} className="profile-link">
                    <img
                      src={insDetails.logo?.includes("https://") ? insDetails.logo : serverBaseUrl + insDetails.logo}
                      className="user-img"
                      alt="user avatar"
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = "/assets/images/profile.png";
                      }}
                      style={{ width: 60, height: 60, marginBottom: 10, border: 'none', marginLeft: 10, marginTop: 5 }}
                    />
                    <p style={{ fontSize: 16, fontWeight: 500, color: '#444', paddingLeft: 15, marginTop: 5 }}>{insDetails.name}</p>
                  </div>
                  <p style={{ fontSize: 13, fontWeight: 100, color: '#9b9b9b', marginTop: -10, paddingLeft: 15, marginLeft: 70, marginTop: -25 }}>{insDetails.phone}</p>
                </Link>

                <Link to="/InstituteEdit">
                  <p className="profile-link" style={{ fontSize: 13, fontWeight: 500, color: '#444', paddingLeft: 15, borderTop: '1px solid #EDEDED', borderBottom: '1px solid #EDEDED', paddingTop: 5, paddingBottom: 5 }}><svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24" fill='#3C4852' style={{ marginBottom: 5, marginRight: 5 }}><path d="M480-480q-66 0-113-47t-47-113q0-66 47-113t113-47q66 0 113 47t47 113q0 66-47 113t-113 47ZM160-160v-112q0-34 17.5-62.5T224-378q62-31 126-46.5T480-440q66 0 130 15.5T736-378q29 15 46.5 43.5T800-272v112H160Zm80-80h480v-32q0-11-5.5-20T700-306q-54-27-109-40.5T480-360q-56 0-111 13.5T260-306q-9 5-14.5 14t-5.5 20v32Zm240-320q33 0 56.5-23.5T560-640q0-33-23.5-56.5T480-720q-33 0-56.5 23.5T400-640q0 33 23.5 56.5T480-560Zm0-80Zm0 400Z" /></svg>Edit Profile</p>
                </Link>
                <a href="https://calendly.com/supportallcoaching/30min?month=2024-03" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: '#444' }}>
                  <p className="profile-link" style={{ fontSize: 13, fontWeight: 500, color: '#444', paddingLeft: 15, paddingTop: 5, paddingBottom: 5, marginTop: -16 }}>

                    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24" fill='#3C4852' style={{ marginBottom: 5, marginRight: 5 }}>
                      <path d="M440-120v-80h320v-284q0-117-81.5-198.5T480-764q-117 0-198.5 81.5T200-484v244h-40q-33 0-56.5-23.5T80-320v-80q0-21 10.5-39.5T120-469l3-53q8-68 39.5-126t79-101q47.5-43 109-67T480-840q68 0 129 24t109 66.5Q766-707 797-649t40 126l3 52q19 9 29.5 27t10.5 38v92q0 20-10.5 38T840-249v49q0 33-23.5 56.5T760-120H440Zm-80-280q-17 0-28.5-11.5T320-440q0-17 11.5-28.5T360-480q17 0 28.5 11.5T400-440q0 17-11.5 28.5T360-400Zm240 0q-17 0-28.5-11.5T560-440q0-17 11.5-28.5T600-480q17 0 28.5 11.5T640-440q0 17-11.5 28.5T600-400Zm-359-62q-7-106 64-182t177-76q89 0 156.5 56.5T720-519q-91-1-167.5-49T435-698q-16 80-67.5 142.5T241-462Z" />
                    </svg>
                    Support
                  </p>
                </a>

                <a href="https://api.whatsapp.com/send/?phone=919889977262&text=Hi,i Have Some Query" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: '#444' }}>
                  <p className="profile-link" style={{ fontSize: 13, fontWeight: 500, color: '#444', paddingLeft: 15, paddingTop: 5, paddingBottom: 5, marginTop: -16 }}><svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24" fill='#3C4852' style={{ marginBottom: 5, marginRight: 5 }}><path d="M798-120q-125 0-247-54.5T329-329Q229-429 174.5-551T120-798q0-18 12-30t30-12h162q14 0 25 9.5t13 22.5l26 140q2 16-1 27t-11 19l-97 98q20 37 47.5 71.5T387-386q31 31 65 57.5t72 48.5l94-94q9-9 23.5-13.5T670-390l138 28q14 4 23 14.5t9 23.5v162q0 18-12 30t-30 12ZM241-600l66-66-17-94h-89q5 41 14 81t26 79Zm358 358q39 17 79.5 27t81.5 13v-88l-94-19-67 67ZM241-600Zm358 358Z" /></svg>Contact Us</p></a>

                {/* <Link to="/institutePayout">
                  <p className="profile-link" style={{ fontSize: 13, fontWeight: 500, color: '#444', paddingLeft: 15, paddingTop: 5, paddingBottom: 5, marginTop: -16 }}><svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24" fill='#3c4852' style={{ marginBottom: 5, marginRight: 5 }}><path d="M240-160q-66 0-113-47T80-320v-320q0-66 47-113t113-47h480q66 0 113 47t47 113v320q0 66-47 113t-113 47H240Zm0-480h480q22 0 42 5t38 16v-21q0-33-23.5-56.5T720-720H240q-33 0-56.5 23.5T160-640v21q18-11 38-16t42-5Zm-74 130 445 108q9 2 18 0t17-8l139-116q-11-15-28-24.5t-37-9.5H240q-26 0-45.5 13.5T166-510Z" /></svg>Payout</p>
                </Link> */}
                <a href="javascript:;" onClick={handleLogout}>
                  <p className="profile-link" style={{ fontSize: 13, fontWeight: 500, color: 'red', paddingLeft: 17, paddingTop: 5, paddingBottom: 5, marginTop: -16 }}><svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24" fill='red' style={{ marginBottom: 5, marginRight: 5 }}><path d="M200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h280v80H200v560h280v80H200Zm440-160-55-58 102-102H360v-80h327L585-622l55-58 200 200-200 200Z" /></svg>Logout</p>

                </a>
              </div>

            </li>
            {/* <li className="nav-item dropdown dropdown-language">
              <a
                className="nav-link dropdown-toggle dropdown-toggle-nocaret"
                href="javascript:;"
                data-toggle="dropdown"
              >
                <div className="lang d-flex">
                  <div>
                    <i className="flag-icon flag-icon-in" />
                  </div>
                  <div>
                    <span>IN</span>
                  </div>
                </div>
              </a>
               
            </li> */}
          </ul>
        </div>
      </nav>
      {showLogoutModal && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            background: 'rgba(0, 0, 0, 0.5)', // semi-transparent background
          }}
        >
          <div
            style={{
              background: 'white',
              padding: '20px',
              width: '500px',
              height: '180px',
              boxShadow: '0 0 10px rgba(0, 0, 0, 0.3)',
              display: 'flex',
              borderRadius: '10px'
            }}
          >
            <div style={{ marginTop: 10 }}>
              <img src='https://assets-v2.lottiefiles.com/a/b5641ed8-1152-11ee-ada0-8f4e8e17569e/BYD1hNXDDt.gif'
                width={120} height={120}></img>

            </div>
            <div>
              <p style={{ marginLeft: 20, fontSize: 18, fontWeight: 500, marginTop: 5 }}>Are you sure you want to Logout?</p>
              {/* <p style={{marginLeft:20,fontSize:14,fontWeight:400,marginTop:0}}>Are You Sure You Want to Logout?</p> */}
              <div style={{ display: 'flex', marginLeft: 15, justifyContent: 'end', height: 40 }}>
                {/* <p onClick={handleCancelLogout} style={{width:150,height:50,backgroundColor:'#00AC72',textAlign:'center',color:'white',borderRadius:5}}>Cancel</p> */}
                {/* <p onClick={handleConfirmLogout} style={{width:150,height:50,backgroundColor:'#005CDD',textAlign:'center',marginLeft:10,borderRadius:5,color:'white',paddingBottom:20}}>Logout</p> */}
                <button onClick={handleCancelLogout} type="button" class="btn" style={{ marginRight: 20, paddingLeft: 30, paddingRight: 30, backgroundColor: '' }}>
                  Cancel</button>
                <button onClick={handleConfirmLogout} type="button" class="btn" style={{ marginRight: 0, paddingLeft: 30, paddingRight: 30, backgroundColor: '#005CDD', color: 'white' }}>
                  Logout</button>
              </div>
            </div>



          </div>
        </div>
      )}

    </header>
  )
}
export default Header