import React from 'react'

function Loader({height,width}) {
  return (
    <div style={{flex:1,display:'flex',alignItems: 'center',justifyContent: 'center'}}>
        <img src="/logoblack.png" height={height} width={width}/>
    </div>
  )
}

export default Loader