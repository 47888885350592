import React, { useState, useEffect } from 'react'
import { dataLimit,theme } from '../../../../index'
import {fetch_studentList, findStudentByName, findStudentByEmail} from '../../../../api/Student'
import ClipLoader from "react-spinners/ClipLoader";

import Snackbar from '@material-ui/core/Snackbar';
import RenderSingleStudent from './RenderSingleStudent'
import {Image,  Shimmer } from 'react-shimmer'
import ExportToExcel from '../../../Dashboard/ExportToExcel';


export default function EnrolledStudents(props) {
    const { activeCourse } = props;
    const [studentData, setStudentData] = useState([]);
    const [offset, setOffset] = useState(0);
    const [showShimmer, setShowShimmer] = useState(true)
    const [blockStatus,setBlockStatus] = useState(-1);
    const [studentSearchBy, setStudentSearchBy] = useState("ByName")
    const [studentSearchValue, setStudentSearchValue] = useState("")
    const [studentDataSearchBy, setStudentDataSearchBy] = useState([])
    const [showDataSearchBy, setShowDataSearchBy] = useState(false)
    const [isLoading, setLoading] = useState(false)
    const [exportLoading,setExportLoading]= useState(false)
    const[isSnackBarShow, setIsSnackBarShow] = useState(false)
    const[SnackBarMessage, setSnackBarMessage] = useState("")
 

    const closeSnack=()=>{
        setIsSnackBarShow(false)
    }

    const fetchStudentCallback=(response) =>{
        if(response.status==200){
            response.json().then(data=>{
                console.log('getting all student data', data)
                setStudentData(data)
                setShowShimmer(false)
            })
        }
    }

    useEffect(()=>{
        fetch_studentList(activeCourse, offset, dataLimit, fetchStudentCallback)
    
    },[offset,blockStatus])
   
    const deleteAtIndex=(index)=>
    {
        let studentData_local = [...studentData] 
        studentData_local.splice(index, 1); 
        setStudentData(studentData_local)
    }

    useEffect(()=>{console.log("updated student data",studentData)},[studentData])

    const findStudentByCallback=(response)=>{
        if(response.status==200){
            response.json().then(data => {
                console.log("getting search data", data)
                setStudentDataSearchBy(data)
               
            })
        }
        setShowDataSearchBy(true)
        setShowShimmer(false)
        setLoading(false)
    }

    const action4SearchStudent=()=>{

        if(studentSearchValue!='')
        {
            setShowShimmer(true)
            setLoading(true)
    
            // if(studentSearchBy == "ByName"){
                // alert('Search by name')
                setStudentDataSearchBy(studentData.filter(student=>student.studentName.toLowerCase().includes(studentSearchValue.toLowerCase())))
                // findStudentByName(studentSearchValue, offset, dataLimit, findStudentByCallback)
            // } else if(studentSearchBy == "ByEmail"){
                // alert('Seach by Email')
                // findStudentByEmail(studentSearchValue, offset, dataLimit, findStudentByCallback)
            // }
            setShowDataSearchBy(true)
            setShowShimmer(false)
            setLoading(false)
        }else
        {
            setSnackBarMessage("Please Type A word to search")
            setIsSnackBarShow(true);
        }
        
    }


    return (
        <div style={{backgroundColor:'white', borderRadius:'10px', marginTop:30 }}>
            <br/>
            <div class="page-breadcrumb  d-md-flex align-items-center mb-3" style={{marginLeft:10, marginRight:10, marginTop:-2}}>
                <div class="breadcrumb-title pr-3">Enrolled Students</div>
             
                <div class="ml-auto">
                    {/* <button type="button" class="btn btn-primary" style={{marginRight:10}} >Export</button> */}
                    <button title="Export Data" type="button" onClick={(e)=>setExportLoading(true)} style={{ marginRight: 5, backgroundColor:'#EDEDED', borderRadius:5,paddingTop:5,border:'none',padding:8,paddingLeft:12,fontSize:14 }}>Download <svg xmlns="http://www.w3.org/2000/svg" height="22" viewBox="0 -960 960 960"
                        width="22" fill='black' style={{marginLeft:3, marginBottom:3}}>
                      <path d="M480-328.462 309.233-499.229l42.153-43.384 98.615 98.615v-336.001h59.998v336.001l98.615-98.615 42.153 43.384L480-328.462ZM252.309-180.001q-30.308 0-51.308-21t-21-51.308v-108.46H240v108.46q0 4.616 3.846 8.463 3.847 3.846 8.463 3.846h455.382q4.616 0 8.463-3.846 3.846-3.847 3.846-8.463v-108.46h59.999v108.46q0 30.308-21 51.308t-51.308 21H252.309Z"/>
                        </svg></button>
                    {exportLoading?(
                      <ExportToExcel
                        stopLoadingInParent={setExportLoading}
                        mode={"course"}
                        courseId={activeCourse}
                      />
                  ):(null)}
                </div>
            </div>

            <div>
                <div className="row mt-3">
                    <div className="col-lg-4 col-md-6 col-12 d-flex mb-4" style={{marginLeft:22}}>
                        <input type="text" className="form-control mr-3" style={{height:'50px', width:'400px'}} onChange={(e)=> setStudentSearchValue(e.target.value)} placeholder="Search Student" />
                   
                        <div class="btn-group">
                            {isLoading?(
                                 <button type="button" class="btn btn-primary px-5">
                                     <ClipLoader color={"white"} size={18} />
                                 </button>
                            ):(
                                // <button type="button" class="btn btn-primary" onClick={(e)=>action4SearchStudent()} >Search  </button>
                                <button title="Click to Search" type="button" onClick={(e)=>action4SearchStudent()} class="btn" style={{ marginRight: 5, backgroundColor:'#EDEDED', borderRadius:5,paddingTop:5,border:'none' }}><svg xmlns="http://www.w3.org/2000/svg" height="25" viewBox="0 -960 960 960"
                                width="25" fill='black' style={{marginRight:0, marginBottom:0}}>
                               <path d="M779.385-153.846 528.923-404.307q-30 25.538-69 39.538-39 14-78.385 14-96.1 0-162.665-66.529-66.566-66.529-66.566-162.577t66.529-162.702q66.529-66.654 162.577-66.654 96.049 0 162.702 66.565Q610.769-676.101 610.769-580q0 41.692-14.769 80.692-14.769 39-38.769 66.693l250.462 250.461-28.308 28.308ZM381.538-390.769q79.616 0 134.423-54.808Q570.769-500.385 570.769-580q0-79.615-54.808-134.423-54.807-54.808-134.423-54.808-79.615 0-134.423 54.808Q192.308-659.615 192.308-580q0 79.615 54.807 134.423 54.808 54.808 134.423 54.808Z"/>
                                </svg></button>
                            )}
                            
                        </div>

                    </div>
                            {/* <button type="button" class="btn btn-primary bg-split-primary dropdown-toggle dropdown-toggle-split" data-toggle="dropdown">
                            	<span class="sr-only">Toggle Dropdown</span>
                            </button>
                            <div class="dropdown-menu dropdown-menu-right dropdown-menu-lg-left">
                                <a class="dropdown-item" href="javascript:;" onClick={() => setStudentSearchBy("ByName")}>Name</a>
                                <a class="dropdown-item" href="javascript:;" onClick={() => setStudentSearchBy("ByEmail")}>Email</a>
                            </div> */}
                   
                      
                </div>
                        {showDataSearchBy?(
                            <div className="mb-3">
                            <button className="btn btn-light" style={{cursor: 'pointer', marginLeft:20}} onClick={()=>setShowDataSearchBy(false)}>Clear Search</button>
                            </div>
                        ):(null)}
            </div>

            <div>
                <div class="card-body">
                    <div>
					   

                            {showShimmer?(
                                <p>
                                  <Shimmer width={'100%'} height={40} /> 
                                </p>
                            ):(
                            <>
                            {showDataSearchBy ?(
                                <>
                                {studentDataSearchBy&&studentDataSearchBy.map((row, i) => (
                                    <RenderSingleStudent row={row} index={i} deleteAtIndex={deleteAtIndex}/>
                                ))}
                                {studentDataSearchBy <= 0?(
                                    <p>
                                        No data found, Try with another keyword.
                                    </p>
                                ):(null)}
                                </>
                            ):(
                                <div style={{display:'flex'}}>
                                {studentData&&studentData.map((row, i) => (
                                    <RenderSingleStudent row={row} index={i} deleteAtIndex={deleteAtIndex}/>
                                ))}
                                </div>
                            )}
                            
                            </>
                            )}
                           
                    </div>
                </div>
            </div>
            <Snackbar
                open={isSnackBarShow}
                onClose={(e)=>closeSnack(e)}
                TransitionComponent="TransitionUp"
                message={SnackBarMessage}
            />
        </div>
    )
}
