import moment from 'moment';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { serverBaseUrl } from '../..';
import { updateMessagesSeenStatus } from '../../api/message';

function Bull({ isVisible }) {
  return (
    <span style={{ fontSize: 25, color: "#005CDD", marginRight: 10 }}>
      {isVisible ? "•" : " "}
    </span>
  );
}
function SingleMessageNotification({ item }) {

  useEffect(() => {
    if (!item.seenByIns) {
      updateMessagesSeenStatus(item.id, true, (response) => console.log(response.status));
    }
  }, [])
  return (
    <Link to={"/studentChat/" + item.courseId + "/" + item.student.id} className="dropdown-item" href="javascript:;">
      <div className="media align-items-center">

        <Bull isVisible={!item.seenByAdmin} />
        <div className="user">
          <img
            src={`${serverBaseUrl}${item.student.studentImage}`}
            className="msg-avatar"
            alt="user avatar"
          />
        </div>
        <div className="media-body">
          <h6 className="msg-name" style={{ fontSize: 14, marginTop: 8, fontWeight: 600 }}>

            {item.messageType == "feedback" ? "FeedBack from " : "Message from "}
            {item.student.name}{" "}<br></br>
            <span style={{ marginTop: 10, fontSize: 11, color: 'blue', fontWeight: 500 }}>{moment(item.messageInitialTime).fromNow()}</span>
          </h6>
          <p className="msg-info" style={{ fontSize: 13, marginTop: 5 }}>

            {item.message.length > 30 ? (
              `${item.message.slice(0, 30)} ...`
            ) : (
              item.message
            )}
          </p>
        </div>
      </div>
    </Link>
  );
}

export default SingleMessageNotification;
