import React, { useState, useEffect }from 'react'
import { serverBaseUrl, theme } from '../../../..';
import {updateDocumentDemoStatus, updateDocumentStatus} from '../../../../api/document'
import ClipLoader from "react-spinners/ClipLoader";
import Menu from '../../../../components/Menu/Menu';
import Modal, { ModalBody, ModalFooter, ModalHeader } from '../../../../components/modal/modal';
import moment from 'moment';

const CourseDocumentRow=props=> {
const {row,index,delDocument, setEditDetails}=props

const [ title,setTitle] = useState(row.name);
const [document,setDocument] = useState(serverBaseUrl+""+row.fileAddress)
const [documentId,setDocumentId] = useState(row.id);
const [hiddenStatus,setHiddenStatus] = useState(row.hidden);
const [delLoading, setDelLoading] = useState(false);
const [demo ,setDemo] = useState(row.demo)

useEffect(() =>{

setDemo(row.demo);
},[row])
useEffect(()=>{
setDelLoading(props.delLoading)
},[props.delLoading])


const updateStatusCallBack=(response, status)=>{
if(response.status==200)
{
setHiddenStatus(status)
}
else
{
console.log(response.status)
}
}
const updateDemoStatusCallBack=(response, status)=>{
if(response.status==200)
{
setDemo(status)
}
else
{
console.log(response.status)
}
}

return (
<>
    <div style={{display:'flex' ,alignItems:'center', justifyContent:'space-between', padding:10}}>
        <div
            style={{width:300,height:250,marginLeft:10, borderRadius:'5px',border:'1px solid #EDEDED'}}>
            <div style={{display:'flex',background: 'linear-gradient(to bottom, #EDEDED, transparent)'}}>
                <div
                    style={{backgroundColor:'white', borderRadius:'55px', padding:'6px 5px 6px 7px', width:'60px', height:'60px', justifyContent:'flex-start', marginTop:10, marginLeft:12}}>
                    <svg xmlns="http://www.w3.org/2000/svg" height="35" viewBox="0 -960 960 960" width="35" fill='#ea4335'
                        style={{marginLeft:6, marginBottom:1,marginTop:6}}>
                        <path
                            d="M340-260h280v-40H340v40Zm0-160h280v-40H340v40Zm-75.385 300Q237-120 218.5-138.5 200-157 200-184.615v-590.77Q200-803 218.5-821.5 237-840 264.615-840H580l180 180v475.385Q760-157 741.5-138.5 723-120 695.385-120h-430.77ZM560-640h160L560-800v160Z" />
                        </svg>

                </div>
                
                <div title="Edit Document Details" onClick={() => setEditDetails(title, documentId, index, row.playlistId, false, true, serverBaseUrl + row.fileAddress)} style={{cursor:'pointer', backgroundColor:'#fff', borderRadius:'55px', padding:'8px 8px 0px 8px',width:'40px', height:'40px', marginLeft:'174px', marginTop:16}}>
                    <svg xmlns="http://www.w3.org/2000/svg" height="25" viewBox="0 -960 960 960" width="25" fill='black'
                        style={{marginRight:10}}>
                      <path d="M196.487-183.179h51.025l422.565-422.719-51.538-51.538-422.052 423.231v51.026Zm569.77-447.13L642.872-754.872l43.77-43.102q15.948-16.616 36.987-16.116 21.038.5 36.653 16.449l50 51q15.615 15.615 14.769 36.743-.846 21.128-16.461 37.256l-42.333 42.333ZM743-607.821 268.512-132H145.308v-123.871l474.821-474.822L743-607.821Zm-98.487-24.154-25.974-25.461 51.538 51.538-25.564-26.077Z"/>
                        </svg>
                       
                </div>
            </div>
            <div style={{marginTop:25, marginLeft:18}}>
            <h4 style={{ fontSize: 16, fontWeight: 500, marginLeft: 7, display: "-webkit-box", WebkitLineClamp: 1, WebkitBoxOrient: "vertical",letterSpacing: "0.2px", overflow: "hidden", marginRight:12 }}>{row.name}</h4>

                <p style={{marginTop:30,marginLeft:5, fontSize:12}}><svg xmlns="http://www.w3.org/2000/svg" height="25" viewBox="0 -960 960 960"
                        width="25" fill='black' style={{marginRight:5, marginBottom:4}}>
                        <path
                            d="M224.615-120Q197-120 178.5-138.5 160-157 160-184.615v-510.77Q160-723 178.5-741.5 197-760 224.615-760h70.769v-89.231h43.077V-760h286.154v-89.231h40.001V-760h70.769Q763-760 781.5-741.5 800-723 800-695.385v510.77Q800-157 781.5-138.5 763-120 735.385-120h-510.77Zm0-40h510.77q9.23 0 16.923-7.692Q760-175.385 760-184.615v-350.77H200v350.77q0 9.23 7.692 16.923Q215.385-160 224.615-160ZM200-575.385h560v-120q0-9.23-7.692-16.923Q744.615-720 735.385-720h-510.77q-9.23 0-16.923 7.692Q200-704.615 200-695.385v120Zm0 0V-720v144.615Zm280 181.539q-12.385 0-21.577-9.193-9.192-9.192-9.192-21.576 0-12.385 9.192-21.577 9.192-9.193 21.577-9.193 12.385 0 21.577 9.193 9.192 9.192 9.192 21.577 0 12.384-9.192 21.576-9.192 9.193-21.577 9.193Zm-160 0q-12.385 0-21.577-9.193-9.192-9.192-9.192-21.576 0-12.385 9.192-21.577 9.192-9.193 21.577-9.193 12.385 0 21.577 9.193 9.192 9.192 9.192 21.577 0 12.384-9.192 21.576-9.192 9.193-21.577 9.193Zm320 0q-12.385 0-21.577-9.193-9.192-9.192-9.192-21.576 0-12.385 9.192-21.577 9.192-9.193 21.577-9.193 12.385 0 21.577 9.193 9.192 9.192 9.192 21.577 0 12.384-9.192 21.576-9.192 9.193-21.577 9.193ZM480-240q-12.385 0-21.577-9.192-9.192-9.193-9.192-21.577 0-12.385 9.192-21.577 9.192-9.193 21.577-9.193 12.385 0 21.577 9.193 9.192 9.192 9.192 21.577 0 12.384-9.192 21.577Q492.385-240 480-240Zm-160 0q-12.385 0-21.577-9.192-9.192-9.193-9.192-21.577 0-12.385 9.192-21.577 9.192-9.193 21.577-9.193 12.385 0 21.577 9.193 9.192 9.192 9.192 21.577 0 12.384-9.192 21.577Q332.385-240 320-240Zm320 0q-12.385 0-21.577-9.192-9.192-9.193-9.192-21.577 0-12.385 9.192-21.577 9.192-9.193 21.577-9.193 12.385 0 21.577 9.193 9.192 9.192 9.192 21.577 0 12.384-9.192 21.577Q652.385-240 640-240Z" />
                        </svg>{moment(row.time_stamp).format("D-M-Y")}</p>
            </div>
            
            <div title="Delete Document" style={{marginTop:25, marginLeft:22}}>
                <button aria-label="delete" onClick={()=>{ delDocument(documentId,index)}} className="btn" style={{ marginRight: 0, backgroundColor:'#EDEDED', borderRadius:5,paddingTop:5,border:'none' }}>
                    {delLoading?(
                    <ClipLoader color={theme.primaryColor} loading={delLoading} />
                    ):(<svg xmlns="http://www.w3.org/2000/svg" height="25" viewBox="0 -960 960 960"
                    width="25" fill='#EA4335' style={{marginRight:0, marginBottom:0,}}>
                 <path d="M304.615-160q-26.846 0-45.731-18.884Q240-197.769 240-224.615V-720h-40v-40h160v-30.77h240V-760h160v40h-40v495.385Q720-197 701.5-178.5 683-160 655.385-160h-350.77ZM680-720H280v495.385q0 10.769 6.923 17.692T304.615-200h350.77q9.23 0 16.923-7.692Q680-215.385 680-224.615V-720ZM392.307-280h40.001v-360h-40.001v360Zm135.385 0h40.001v-360h-40.001v360ZM280-720v520-520Z"/>
                    </svg>)}
                </button>
                <a title="Download Document" href={document} target="_blank" aria-label="view" >
                <button className="btn" style={{ marginRight: 0, backgroundColor:'#EDEDED', borderRadius:5,paddingTop:5,border:'none', marginLeft:18 }}>
                <svg xmlns="http://www.w3.org/2000/svg" height="25" viewBox="0 -960 960 960"
                        width="25" fill='black' style={{marginRight:0, marginBottom:0, marginLeft:0}}>
                      <path d="M480-336.923 338.461-478.462l28.308-28.769L460-414v-346h40v346l93.231-93.231 28.308 28.769L480-336.923ZM264.615-200Q237-200 218.5-218.5 200-237 200-264.615v-96.923h40v96.923q0 9.23 7.692 16.923Q255.385-240 264.615-240h430.77q9.23 0 16.923-7.692Q720-255.385 720-264.615v-96.923h40v96.923Q760-237 741.5-218.5 723-200 695.385-200h-430.77Z"/>
                        </svg>
                </button>
                </a>
                {hiddenStatus?(
                <button title="Public" style={{ marginRight: 0, backgroundColor:'#EDEDED', borderRadius:5,paddingTop:5,border:'none', marginLeft:15 }} aria-label="delete" onClick={()=>updateDocumentStatus(false,documentId,(response) =>
                    updateStatusCallBack(response, false))} className="btn mr-1">
                   <svg xmlns="http://www.w3.org/2000/svg" height="25" viewBox="0 -960 960 960"
                        width="25" fill='black' style={{marginRight:0, marginBottom:0, marginLeft:0}}>
                             <path d="M617.846-454.154 586-486q9-52.385-29.692-90.692Q517.615-615 466-606l-31.846-31.846q10.077-4.154 21.038-6.231 10.962-2.077 24.808-2.077 61.154 0 103.654 42.5 42.5 42.5 42.5 103.654 0 13.846-2.077 25.577-2.077 11.731-6.231 20.269Zm126.462 122.923L714-358q38-29 67.5-63.5T832-500q-50-101-143.5-160.5T480-720q-29 0-57 4t-55 12l-31.231-31.231q34.846-13.154 70.923-18.962Q443.769-760 480-760q130.231 0 238.231 71.577T876.923-500q-21.461 48.231-54.346 90.654-32.884 42.423-78.269 78.115Zm44.615 216.77L633.231-269.692q-26.539 11.769-65.885 20.731Q528-240 480-240q-131 0-238.231-71.577T83.077-500q23.307-53 61.461-99.269 38.154-46.269 81.462-77.654l-111.539-112 28.308-28.308 674.462 674.462-28.308 28.308ZM254.307-648.615Q219.923-624.154 184-584.308 148.077-544.461 128-500q50 101 143.5 160.5T480-280q34.615 0 69.769-6.731 35.154-6.73 52.846-13.577L537.385-366q-9.462 5.308-26.385 8.731-16.923 3.423-31 3.423-61.154 0-103.654-42.5-42.5-42.5-42.5-103.654 0-13.308 3.423-29.846 3.423-16.539 8.731-27.539l-91.693-91.23ZM541-531Zm-112.539 56.539Z"/>
                    
                        </svg>
                </button>
                ):(
                <button title="Private" style={{ marginRight: 0, backgroundColor:'#EDEDED', borderRadius:5,paddingTop:5,border:'none', marginLeft:15 }} aria-label="delete" onClick={()=>updateDocumentStatus(true,documentId,(response) =>
                    updateStatusCallBack(response, true))} className="btn mr-1">
                    <svg xmlns="http://www.w3.org/2000/svg" height="25" viewBox="0 -960 960 960"
                        width="25" fill='black' style={{marginRight:0, marginBottom:0, marginLeft:0}}>
                    <path d="M480.181-353.846q60.973 0 103.473-42.681t42.5-103.654q0-60.973-42.681-103.473t-103.654-42.5q-60.973 0-103.473 42.681t-42.5 103.654q0 60.973 42.681 103.473t103.654 42.5ZM480-392q-45 0-76.5-31.5T372-500q0-45 31.5-76.5T480-608q45 0 76.5 31.5T588-500q0 45-31.5 76.5T480-392Zm.11 152q-129.956 0-236.879-70.731Q136.307-381.461 83.077-500q53.23-118.539 160.044-189.269Q349.934-760 479.89-760q129.956 0 236.879 70.731Q823.693-618.539 876.923-500q-53.23 118.539-160.044 189.269Q610.066-240 480.11-240ZM480-500Zm0 220q113 0 207.5-59.5T832-500q-50-101-144.5-160.5T480-720q-113 0-207.5 59.5T128-500q50 101 144.5 160.5T480-280Z"/>
                        </svg>
                </button>
                )}
                 {demo ? (
              <button
              style={{ marginRight: 0, backgroundColor: '#EDEDED', borderRadius: 5, paddingTop: 5, border: 'none', marginLeft: 15 }}
              aria-label="delete"
              onClick={() => { updateDocumentDemoStatus(false, documentId, (response) => updateDemoStatusCallBack(response, false)) }}
              className="btn mr-1"
              title="Close For Demo"
            >
            <svg xmlns="http://www.w3.org/2000/svg" height="25" viewBox="0 -960 960 960"
                        width="25" fill='#08BD80' style={{marginRight:0, marginBottom:0, marginLeft:0}}>
                 <path d="M255.795-608.718H604.41v-85.026q0-52.282-36.289-89.013-36.289-36.73-88.13-36.73-51.841 0-88.121 36.73-36.28 36.731-36.28 89.013h-51.18q0-74.718 51.142-125.82 51.141-51.103 124.464-51.103 73.322 0 124.448 51.252 51.126 51.252 51.126 125.671v85.026h48.615q31.911 0 53.853 21.744Q780-565.231 780-532.923v366.462q0 31.91-21.942 53.852-21.942 21.942-53.853 21.942h-448.41q-31.911 0-53.853-21.942T180-166.461v-366.462q0-32.308 21.942-54.051 21.942-21.744 53.853-21.744Zm0 466.872h448.41q10.77 0 17.693-6.923 6.923-6.923 6.923-17.692v-366.462q0-10.77-6.923-17.693-6.923-6.923-17.693-6.923h-448.41q-10.77 0-17.693 6.923-6.923 6.923-6.923 17.693v366.462q0 10.769 6.923 17.692t17.693 6.923ZM480.218-280q28.423 0 48.282-19.909 19.859-19.909 19.859-48.249 0-27.714-20.077-48.137-20.078-20.423-48.5-20.423-28.423 0-48.282 20.529-19.859 20.53-19.859 48.577 0 28.048 20.077 47.83Q451.796-280 480.218-280ZM231.179-141.846v-415.693 415.693Z"/>
                        </svg>
            </button>
            
                ):(
                <button style={{ marginRight: 0, backgroundColor:'#EDEDED', borderRadius:5,paddingTop:5,border:'none', marginLeft:15 }} aria-label="delete" onClick={() => { updateDocumentDemoStatus(true, documentId, (response) => updateDemoStatusCallBack(response, true)) }} className="btn mr-1"  title="Open For Demo">
                      <svg xmlns="http://www.w3.org/2000/svg" height="25" viewBox="0 -960 960 960" width="25" fill='black' style={{ marginRight: 0, marginBottom: 0, marginLeft: 0 }}>
                      <path d="M255.795-90.667q-31.911 0-53.853-21.942T180-166.461v-366.462q0-32.308 21.942-54.051 21.942-21.744 53.853-21.744h48.615v-85.026q0-74.419 51.142-125.671 51.141-51.252 124.464-51.252 73.322 0 124.448 51.252 51.126 51.252 51.126 125.671v85.026h48.615q31.911 0 53.853 21.744Q780-565.231 780-532.923v366.462q0 31.91-21.942 53.852-21.942 21.942-53.853 21.942h-448.41Zm0-51.179h448.41q10.77 0 17.693-6.923 6.923-6.923 6.923-17.692v-366.462q0-10.77-6.923-17.693-6.923-6.923-17.693-6.923h-448.41q-10.77 0-17.693 6.923-6.923 6.923-6.923 17.693v366.462q0 10.769 6.923 17.692t17.693 6.923ZM480.218-280q28.423 0 48.282-19.909 19.859-19.909 19.859-48.249 0-27.714-20.077-48.137-20.078-20.423-48.5-20.423-28.423 0-48.282 20.529-19.859 20.53-19.859 48.577 0 28.048 20.077 47.83Q451.796-280 480.218-280ZM355.59-608.718h248.82v-85.026q0-52.282-36.289-89.013-36.289-36.73-88.13-36.73-51.841 0-88.121 36.73-36.28 36.731-36.28 89.013v85.026ZM231.179-141.846v-415.693 415.693Z"/>
              </svg>
                   
                </button>
                )}
              
               
            </div>

        </div>
    </div>
  

</>
)
}

export default CourseDocumentRow