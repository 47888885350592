import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';

function TransactionRow(props) {
  const { row, index, delTransaction } = props;
  const { orderId, courseId, insId, purchaseDate, amount, status } = row.transaction;

  const { title } = row.course;
  const { name, email } = row.institute;
  const { name: studentName, mobileNumber: studentMobileNumber } = row.student;

  // Calculate the discounted amount (90% of the original amount)
  const discountedAmount = amount * 0.9;
  const statusStyle = {
    color: status === 'TXN_SUCCESS' ? 'green' : 'red',
  };


  return (
    <tr style={{ fontSize: 12, backgroundColor: 'white', textAlign: 'center' }}>
      <td align="center">{index + 1}</td>
      <td align="center">{moment(purchaseDate).format('DD-MM-YYYY HH:mm')}</td>
      <td align="center">
        {/* Display the discounted amount */}
        {discountedAmount.toFixed(2)}
      </td>
      <td align="center">{studentName}</td>
      <td align="center">{studentMobileNumber}</td>
      <td align="center">{title}</td>
      <td align="center" style={statusStyle}>{status}</td>
      <td align="center">{orderId}</td>
    </tr>
  );
}

export default TransactionRow;
