import React, { useEffect, useRef } from 'react';
import LineChartF from './LineChartF.js'

const NewFakeChart = () => {
  const chartRef = useRef(null);

  useEffect(() => {
    const xValues = [100, 200, 300, 400, 500, 600, 700, 800, 900, 1000];
    const chartData = {
      labels: xValues,
      datasets: [
        {
          label: 'Total Visitors',
          data: [860, 1140, 3760, 1060, 1070, 1110, 1330, 2210, 7830, 2478],
          backgroundColor: '#f4bd2b', // Light blue
          borderColor: '#f4bd2b',
          borderWidth: 1,
          hoverBackgroundColor: 'rgba(82, 205, 255, 1)',
          hoverBorderColor: '#f4bd2b',
          borderRadius: 25,
          barPercentage: 1, // Adjust this value to control the width of the bars (0.5 means 50% of the available space)
          categoryPercentage: 0.3, // Adjust this value to control the space between the bars (0.5 means 50% of the available space)
        },
        {
          label: 'This Week',
          data: [1600, 1700, 2700, 1900, 2000, 700, 4000, 5000, 6000, 7000],
          backgroundColor: '#317ee4',
          borderColor: '#317ee4',
          borderWidth: 1,
          hoverBackgroundColor: '#00AC72', // Hover color
          hoverBorderColor: '#1f3bb3',
          barPercentage: 1,
          categoryPercentage: 0.4,
          borderSkipped: { top: 10 },
        },
      ],
    };

    if (chartRef.current) {
      const ctx = chartRef.current.getContext('2d');
      new window.Chart(ctx, {
        type: 'bar',
        data: chartData,
        options: {
          scales: {
            x: [
              {
                gridLines: {
                  display: false,
                },
              },
            ],
            y: [
              {
                ticks: {
                  beginAtZero: true,
                  callback: function (value) {
                    if (value % 1000 === 0) {
                      return value / 1000 + 'k';
                    } else {
                      return value;
                    }
                  },
                },
              },
            ],
          },
          legend: {
            display: true,
            position: 'top',
            labels: {
              boxWidth: 20,
              padding: 15,
            },
          },
          tooltips: {
            mode: 'index',
            intersect: false,
          },
          scales: {
            xAxes: [
              {
                gridLines: {
                  display: false, // Hide x-axis gridlines
                },
              },
            ],
          },
        },
      });
    }
  }, []);

  return (
    <div style={{display:'flex',height:440,marginRight:15}}>
    <div className="col-12 col-lg-7" style={{ backgroundColor: 'white', height: 420, paddingTop: 15 }}>
      <h1 style={{ fontSize: 18, fontWeight: 700 }}>Market Overview</h1>
      <p style={{ fontWeight: 300, fontSize: 14 }}>See how User Visits on Your Section</p>
      <canvas ref={chartRef} style={{ backgroundColor: '#fff', padding: 10, borderRadius: 10, marginTop: -10,maxHeight:330 }}></canvas>
    </div>
     <div className="col-12 col-lg-5" style={{ height: 420,marginLeft:15,backgroundColor:'white',paddingTop:5 }}>
        <div style={{height:250,backgroundColor:'#3C4852',borderRadius:10,margin:10,border:'none'}}>
         <h1 style={{fontSize:18,fontWeight:600,color:'white',padding:10,paddingLeft:15}}>Status Summary</h1>
         <LineChartF />
        </div>
        <div style={{display:'flex',height:140,margin:10,justifyContent:'space-between',borderRadius:10,border:'none',marginTop:20}}>
    <div style={{backgroundColor:'#005CDD',height:120,width:'48%',borderRadius:10}}>
    <h1 style={{fontSize:14,fontWeight:500,color:'white',padding:10,paddingLeft:15}}>Total Visitors</h1>
    <h1 style={{fontSize:22,fontWeight:600,color:'white',padding:10,paddingLeft:15}}>0 <span style={{fontSize:12}}>/Month</span></h1>
        
    </div>
    <div style={{backgroundColor:'#005CDD',height:120,width:'48%',borderRadius:10}}>
    <h1 style={{fontSize:14,fontWeight:500,color:'white',padding:10,paddingLeft:15}}>Visits Per Day</h1>
    <h1 style={{fontSize:22,fontWeight:600,color:'white',padding:10,paddingLeft:15}}>0 <span style={{fontSize:12}}>/day</span></h1>

    </div>
    </div>
   </div>
   </div>
  );
};

export default NewFakeChart;
