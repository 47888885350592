import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { like_feed, unlike_feed } from '../../api/feed'
import {
    EmailShareButton,
    FacebookIcon,
    FacebookShareButton, 
    InstapaperIcon, 
    InstapaperShareButton, 
    LinkedinShareButton, 
    PinterestIcon, 
    PinterestShareButton, 
    RedditIcon, 
    RedditShareButton,
    TelegramIcon,
    TelegramShareButton, 
    TwitterIcon, 
    TwitterShareButton, 
    WhatsappIcon, 
    WhatsappShareButton, 
  } from "react-share";
  import {CopyToClipboard} from 'react-copy-to-clipboard';

const FeedBottom = props => {
    
    const { feed, feedId,setShowComment} = props;
    const [likes, setLikes] = useState(feed.feed.feed.likes)
    const [commentCount, setCommentCount] = useState(feed.feed.feed.commentCount)
    const [alreadyLiked, setAlreadyLiked] = useState(false)
    const [showShareOptions,setShowShareOptions]     = useState(false)
    const insDetails = useSelector((state) => state.ins.insDetails)
    const [copied, setCopied] = useState(false)
    const likesCallback = (response) => {
        
        if (response.status === 200) {

            setLikes(likes + 1)
            setAlreadyLiked(true)
        }
    }


    const unlikesCallback = (response) => {
        console.log(response.status)
        if (response.status === 200) {

            setLikes(likes - 1)
            setAlreadyLiked(false)
        }
    }



    useEffect(() => {
        if (feed.feed.feed.feedLikerIns.includes(`,${insDetails.id},`)) {
            setAlreadyLiked(true);
        } else {
            setAlreadyLiked(false)
        }
    }, [])


    const likeHandler = () => {
        like_feed(feedId, 1, insDetails.id, likesCallback)

    }
    const unlikeHandler = () => {
        unlike_feed(feedId, 1, insDetails.id, unlikesCallback)

    }

 


    return (
        <>
         <div style={{display:'flex', justifyContent:'space-between', marginBottom:-8, marginTop:20, marginLeft:15, marginRight:20}}>
            <p style={{fontWeight:400, color:'#7A8B94'}} > {likes} Likes</p>
            <p style={{fontWeight:400, color:'#7A8B94'}}>{commentCount} Comments</p>
            </div>
       
        <div style={{backgroundColor:'#fff', marginTop:0, padding:2,}}>
           
            {alreadyLiked ? (
                <button type="button"  style={{backgroundColor:'#f0f2f5', border:'none', width:170, borderRadius:5, padding:5, marginLeft:7}}  onClick={() => unlikeHandler()}>  <svg xmlns="http://www.w3.org/2000/svg" height="25" viewBox="0 -960 960 960" width="25" fill='#005CDD' ><path d="M721-120H254v-512l278-288 33 26q11 8 14.5 18t3.5 23v10l-45 211h322q23 0 41.5 18.5T920-572v82q0 11-2.5 25.5T910-439L794-171q-9 21-29.5 36T721-120ZM194-632v512H80v-512h114Z"/></svg></button>
            ) : (

                <button type="button" style={{backgroundColor:'#f0f2f5', border:'none', width:170, borderRadius:5, padding:5,marginLeft:7}} onClick={() => likeHandler()}><svg xmlns="http://www.w3.org/2000/svg" height="25" viewBox="0 -960 960 960" width="25" fill='#7A8B94' ><path d="M716-120H272v-512l278-288 39 31q6 5 9 14t3 22v10l-45 211h299q24 0 42 18t18 42v81.839q0 7.161 1.5 14.661T915-461L789-171q-8.878 21.25-29.595 36.125Q738.689-120 716-120Zm-384-60h397l126-299v-93H482l53-249-203 214v427Zm0-427v427-427Zm-60-25v60H139v392h133v60H79v-512h193Z"/></svg></button>
            )}

            <button type="button"  style={{backgroundColor:'#f0f2f5', border:'none',width:200, marginLeft:10, padding:5, borderRadius:5}} onClick={()=>setShowComment(true)}><svg xmlns="http://www.w3.org/2000/svg" height="25" viewBox="0 -960 960 960" width="25" fill='#7A8B94' ><path d="M80-80v-740q0-24 18-42t42-18h680q24 0 42 18t18 42v520q0 24-18 42t-42 18H240L80-80Zm134-220h606v-520H140v600l74-80Zm-74 0v-520 520Z"/></svg></button>

            <button type="button" style={{backgroundColor:'#f0f2f5', border:'none',width:170, marginLeft:10,padding:5, borderRadius:5}} onClick={() =>{if(showShareOptions){};setShowShareOptions(true)}}><svg xmlns="http://www.w3.org/2000/svg" height="25" viewBox="0 -960 960 960" width="25" fill='#7A8B94' ><path d="M727-80q-47.5 0-80.75-33.346Q613-146.693 613-194.331q0-6.669 1.5-16.312T619-228L316-404q-15 17-37 27.5T234-366q-47.5 0-80.75-33.25T120-480q0-47.5 33.25-80.75T234-594q23 0 44 9t38 26l303-174q-3-7.071-4.5-15.911Q613-757.75 613-766q0-47.5 33.25-80.75T727-880q47.5 0 80.75 33.25T841-766q0 47.5-33.25 80.75T727-652q-23.354 0-44.677-7.5T646-684L343-516q2 8 3.5 18.5t1.5 17.741q0 7.242-1.5 15Q345-457 343-449l303 172q15-14 35-22.5t46-8.5q47.5 0 80.75 33.25T841-194q0 47.5-33.25 80.75T727-80Zm.035-632Q750-712 765.5-727.535q15.5-15.535 15.5-38.5T765.465-804.5q-15.535-15.5-38.5-15.5T688.5-804.465q-15.5 15.535-15.5 38.5t15.535 38.465q15.535 15.5 38.5 15.5Zm-493 286Q257-426 272.5-441.535q15.5-15.535 15.5-38.5T272.465-518.5q-15.535-15.5-38.5-15.5T195.5-518.465q-15.5 15.535-15.5 38.5t15.535 38.465q15.535 15.5 38.5 15.5Zm493 286Q750-140 765.5-155.535q15.5-15.535 15.5-38.5T765.465-232.5q-15.535-15.5-38.5-15.5T688.5-232.465q-15.5 15.535-15.5 38.5t15.535 38.465q15.535 15.5 38.5 15.5ZM727-766ZM234-480Zm493 286Z"/></svg></button>
            {showShareOptions?(
                <>
                
                <div className="row mt-2 mb-2 d-flex align-items-center justify-content-center">
                    <div className="col-10">
                        <input type="text" className="form-control" name="feedId" value={`https://allcoaching.in/feed/${feedId}`} />
                    </div>
                    <div className="col-1">
                        <CopyToClipboard text={`https://allcoaching.in/feed/${feedId}`}
                            onCopy={() => setCopied(true)}>
                            <button className="btn"><i className="bx bx-copy"></i></button>
                        </CopyToClipboard>
                        
                    </div>
                    <div className="col-1">
                            <button type="button" onClick={() =>setShowShareOptions(false)}className="btn" ><i className="bx bx-x"></i></button>
                        
                    </div>
                </div>
                <div className="row mt-1">
                    
                    <div className="col-1">
                        <FacebookShareButton size={32} round={true} url={`https://allcoaching.in/feed/${feedId}`}>
                            <FacebookIcon size={32} round={true} />
                        </FacebookShareButton>
                        
                    </div>
                    <div className="col-1">
                        <WhatsappShareButton size={32} round={true} url={`https://allcoaching.in/feed/${feedId}`}>
                            <WhatsappIcon size={32} round={true} />
                        </WhatsappShareButton>

                    </div>
                    <div className="col-1">
                        <InstapaperShareButton size={32} round={true} url={`https://allcoaching.in/feed/${feedId}`}>
                            <InstapaperIcon  size={32} round={true}/>
                        </InstapaperShareButton>

                    </div>
                    <div className="col-1">
                        <TwitterShareButton size={32} round={true} url={`https://allcoaching.in/feed/${feedId}`}>
                            <TwitterIcon size={32} round={true} />
                        </TwitterShareButton>

                    </div>
                    <div className="col-1">
                        <RedditShareButton size={32} round={true} url={`https://allcoaching.in/feed/${feedId}`}>
                            <RedditIcon size={32} round={true} />
                        </RedditShareButton>

                    </div>
                    <div className="col-1">
                        <TelegramShareButton size={32} round={true} url={`https://allcoaching.in/feed/${feedId}`}>
                            <TelegramIcon size={32} round={true} />
                        </TelegramShareButton>

                    </div>
                    <div className="col-1">
                        <PinterestShareButton size={32} round={true} url={`https://allcoaching.in/feed/${feedId}`}>
                            <PinterestIcon size={32} round={true} />
                        </PinterestShareButton>

                    </div>
                </div>
                </>
            ):(null)}
             
        </div>
        </>
    );
}

export default FeedBottom;