import React, { useState, useEffect }from 'react'
import {fetchRevenueCountInstituteWise, getInsRevenueCourseData} from '../../../api/revenue'
import { Shimmer } from 'react-shimmer'

function RevenueCount(props) {
    const {insId,revenue} = props   
    const [todayCount, setTodayCount] = useState("") 
    const [totalCount, setTotalCount] = useState("") 
    const [loading, setLoading]=useState(true)
    
    useEffect(() => {
            fetchRevenueCountInstituteWise(insId, fetchRevenueCountInstituteWiseCallBack);
            getInsRevenueCourseData(insId, getInsRevenueCourseDataCallBack)
    },[insId])

    const fetchRevenueCountInstituteWiseCallBack=(response)=>{
        if(response.status==200)
        {
            response.json().then(data=>{
                setTotalCount(data.total)
                setTodayCount(data.today)
            })
        }
        else
        {
            
        }
    }
    const getInsRevenueCourseDataCallBack=(response)=>{
        if(response.status==200)
        {
            response.json().then(data=>{
                console.log("course rev data", data)
            })
        }
    }

    return (
        <div>
            <div className="row">
            <div className="col-12 col-lg-4">
                    
            <div className="card radius-15" style={{ background: 'linear-gradient(to right, #65CA83, #65CA83, #65CA83)', border: 'none', borderRadius: 10 }}>
                        <div className="card-body">
                    <div className="media align-items-center">
                      <div className="media-body">
                        <p className="mb-0" style={{ fontWeight: 500, }}>Today Earning</p>
                        <h4 className="mb-0" style={{ fontSize: 26, fontWeight: 700, marginTop: 4 }}>{todayCount?(todayCount):(0)}</h4>
                      </div>
                      <div className="widgets-icons bg-light text-light rounded-circle"><svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h560q33 0 56.5 23.5T840-760H520q-71 0-115.5 44.5T360-600v240q0 71 44.5 115.5T520-200h320q0 33-23.5 56.5T760-120H200Zm320-160q-33 0-56.5-23.5T440-360v-240q0-33 23.5-56.5T520-680h280q33 0 56.5 23.5T880-600v240q0 33-23.5 56.5T800-280H520Zm120-140q26 0 43-17t17-43q0-26-17-43t-43-17q-26 0-43 17t-17 43q0 26 17 43t43 17Z" /></svg>
                  </div>
                    </div>
                  </div>
                         
                        </div>
                    </div>
                <div className="col-12 col-lg-4">
                <div className="card radius-15" style={{ background: 'linear-gradient(to right, #9DBFFF,#9DBFFF)', border: 'none', borderRadius: 10 }}>
              <div className="card-body">
                <div className="media align-items-center">
                  <div className="media-body">
                    <p className="mb-0" style={{ fontWeight: 500, }}>Total Earning</p>
                    <h4 className="mb-0" style={{ fontSize: 26, fontWeight: 700, marginTop: 4 }}>{totalCount?(totalCount):(0)}</h4>
                  </div>
                  <div className="widgets-icons bg-light text-light rounded-circle"><svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h560q33 0 56.5 23.5T840-760H520q-71 0-115.5 44.5T360-600v240q0 71 44.5 115.5T520-200h320q0 33-23.5 56.5T760-120H200Zm320-160q-33 0-56.5-23.5T440-360v-240q0-33 23.5-56.5T520-680h280q33 0 56.5 23.5T880-600v240q0 33-23.5 56.5T800-280H520Zm120-140q26 0 43-17t17-43q0-26-17-43t-43-17q-26 0-43 17t-17 43q0 26 17 43t43 17Z" /></svg>
                  </div>
                </div>
              </div>
            </div>
                
                </div>
            
            </div>
        </div>
    )
}

export default RevenueCount
