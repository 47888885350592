import React, { useState, useEffect } from 'react'
import { fetch_courseTransactions } from '../../../api/transaction'
import { dataLimit } from '../../../index'
import {getStudentCount } from '../../../api/Courses'

function RevData(props) {

    const {courseId, totalRevenue, todayRevenue}= props
    const [studentData, setStudentData] = useState([]) 
    const [offset, setOffset] = useState(0) 
    const [studentCount,setStudentCount] = useState('')

    const fetch_courseTransactionsCallBack=(response)=>{
        if(response.status==200)
        {
            response.json().then(data=>{
                console.log("studentdata", data)
                setStudentData(data)
            })
        }
    }

    useEffect(() =>{
        fetch_courseTransactions(courseId, offset, dataLimit, fetch_courseTransactionsCallBack);
        getStudentCount(courseId, studentCountCallBack)
    },[courseId])

    const studentCountCallBack=(response)=>{
        if(response.status==200)
        {
            response.json().then(data=>{
                setStudentCount(data)
                console.log("stu",data)
            })
        }
        else
        {
            console.log("student",response.status)
        }
    }

    return (
        <div>
            <div className="row">
                <div className="col-12 col-lg-3">
                    <div className="card radius-15" style={{ background: 'linear-gradient(to right, #65CA83, #65CA83, #65CA83)', border:'none',borderRadius:10 }}>
                        <div className="card-body">
                            <div className="media align-items-center">
                            <div className="media-body">
                    <p className="mb-0" style={{fontWeight:500,fontSize:14}}>Today Earning</p>
                    <h4 className="mb-0" style={{fontSize:24,fontWeight:700,marginTop:4}}>₹{todayRevenue ? (todayRevenue):(0)}</h4>
                   
                  </div>
                              
                  <div className="widgets-icons bg-light text-light rounded-circle"> <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M549-120 280-400v-80h140q53 0 91.5-34.5T558-600H240v-80h306q-17-35-50.5-57.5T420-760H240v-80h480v80H590q14 17 25 37t17 43h88v80h-81q-8 85-70 142.5T420-400h-29l269 280H549Z"/></svg>
                  </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-12 col-lg-3">
                    <div className="card radius-15" style={{ background: 'linear-gradient(to right, #9DBFFF, #9DBFFF, #9DBFFF)', border:'none',borderRadius:10 }}>
                        <div className="card-body">
                            <div className="media align-items-center">
                            <div className="media-body">
                    <p className="mb-0" style={{fontWeight:500,fontSize:14}}>Total Earning</p>
                    <h4 className="mb-0" style={{fontSize:24,fontWeight:700,marginTop:4}}>₹{totalRevenue ? (totalRevenue):(0)}</h4>
                   
                  </div>
                              
                  <div className="widgets-icons bg-light text-light rounded-circle"> <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M549-120 280-400v-80h140q53 0 91.5-34.5T558-600H240v-80h306q-17-35-50.5-57.5T420-760H240v-80h480v80H590q14 17 25 37t17 43h88v80h-81q-8 85-70 142.5T420-400h-29l269 280H549Z"/></svg>
                  </div>
                           
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-12 col-lg-3">
                    <div className="card radius-15" style={{ background: 'linear-gradient(to right, #fcc93b, #fcc93b, #fcc93b)', border:'none',borderRadius:10 }}>
                        <div className="card-body">
                        <div className="media align-items-center">
                            <div className="media-body">
                    <p className="mb-0" style={{fontWeight:500,fontSize:14}}>Enrolled Students</p>
                    <h4 className="mb-0" style={{fontSize:24,fontWeight:700,marginTop:4}}>{studentCount ? (studentCount) : (0)}</h4>
                   
                  </div>
                              
                  <div className="widgets-icons bg-light text-light rounded-circle"> <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M549-120 280-400v-80h140q53 0 91.5-34.5T558-600H240v-80h306q-17-35-50.5-57.5T420-760H240v-80h480v80H590q14 17 25 37t17 43h88v80h-81q-8 85-70 142.5T420-400h-29l269 280H549Z"/></svg>
                  </div>
                           
                            </div>
                         
                        </div>
                    </div>
                </div>
            </div>
            {/* <div style={{backgroundColor:'white'}}>
              <div style={{display:'flex',justifyContent:'space-around'}}>
                <h4>No.</h4>
                <h4>Students</h4>
                <h4>Mobile</h4>
                <h4>E-mail</h4>
                <h4>Mobile</h4>
                <h4>Mobile</h4>
              </div>
            </div> */}

            <div className="table-responsive" style={{fontSize:14,backgroundColor:'white',borderRadius:10,border:'none'}} >
                <table className="table table-striped table-bordered mb-0" id="table1">
                    <thead>
                        <tr>
                            <th align="center" style={{textAlign:'center',border:'none'}}>#</th>
                            <th align="center" style={{textAlign:'center',border:'none'}}>Name</th>
                            <th align="center" style={{textAlign:'center',border:'none'}}>Mobile</th>
                            <th align="center" style={{textAlign:'center',border:'none'}}>Email</th>
                            <th align="center" style={{textAlign:'center',border:'none'}}>Status</th>
                            <th align="center" style={{textAlign:'center',border:'none'}}>Transaction Id</th>
                        </tr>
                    </thead>
                    {studentData && studentData.map((item, i) => (
                        <tbody>
                            <td style={{textAlign:'center',border:'none'}}>{i + 1}</td>
                            <td style={{textAlign:'center',border:'none'}}>{item.student.name}</td>
                            <td style={{textAlign:'center',border:'none'}}>{item.student.mobileNumber}</td>
                            <td style={{textAlign:'center',border:'none'}}>{item.student.email}</td>
                            <td style={{textAlign:'center',border:'none'}}>{item.transaction.status}</td>
                            <td style={{textAlign:'center',border:'none'}}>{item.transaction.gatewayTransactionId}</td>
                        </tbody>
                    ))}
                </table>
            </div>
        </div>

    )
}

export default RevData