import moment from 'moment';
import React, { useEffect } from 'react';
import { serverBaseUrl } from '../..';
import { updateTransactionSeenStatus } from '../../api/transaction';
import { Link } from "react-router-dom"




function Bull({ isVisible }) {
    return (
        <span style={{ fontSize: 25, color: "#673ab7", marginRight: 10 }}>
            {isVisible ? "•" : " "}
        </span>
    );
}
function SingleNotification({ item }) {


    useEffect(() => {
        if (!item.transaction?.seenByIns) {
            updateTransactionSeenStatus(item.transaction?.id, true, (response) => console.log(response.status));
        }
    }, [])

    return (<Link className="dropdown-item" to={{ pathname: "/ins/course/courseview/" + item.course.id + "/" + encodeURIComponent(item.course.title), query: { from: 'notification' } }} style={{ paddingLeft: 5 }}>
        <div className="media align-items-center">
            <Bull isVisible={!item.transaction.seenByAdmin} />
            <div className="notify bg-light-mehandi text-mehandi">
                {/* <i className="bx bx-door-open" /> */}

                <img
                    src={item?.student?.studentImage?.includes("https://") ? item.student.studentImage : serverBaseUrl + item.student.studentImage}
                    style={{ width: 40, height: 40, borderRadius: 25 }}
                    alt="Student Image"
                    onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = "/assets/images/avatars/avatar-1.png";
                    }}
                />

            </div>
            <div className="media-body" style={{ marginTop: 5 }}>
                <h6 className="msg-name" style={{ fontWeight: 'bold' }}>
                    {item.student?.name}{" "} •
                    <span style={{ marginTop: -4, fontSize: 11, color: 'blue', fontWeight: 500, marginLeft: 5 }} >
                        {moment(item.transaction.purchaseDate).fromNow()}
                    </span>
                </h6>
                <p style={{ flexWrap: 'wrap', flex: 1, width: "100%", whiteSpace: "break-spaces", marginTop: 5 }} className="msg-info">{item.student.name} purchased {item.course.title} worth of {item.transaction.amount} INR</p>
            </div>
        </div>
    </Link >);
}

export default SingleNotification;
