import React from 'react'
import { useState } from 'react'
import { useEffect, useRef } from 'react'
import { fetch_courses_banners, addBanner, deleteBanner } from '../../../../api/banners';
import { getCourseDetailsById, editCourses } from '../../../../api/Courses';
import CourseBannerRow from './CourseBannerRow';
import { Image, Shimmer } from 'react-shimmer'
import { serverApiUrl, theme } from '../../../../index'
import Modal, { ModalBody, ModalFooter, ModalHeader } from '../../../../components/modal/modal';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from 'ckeditor5-custom-build';
import Snackbar from '@material-ui/core/Snackbar';
import ClipLoader from "react-spinners/ClipLoader";

function CourseBanners(props) {
    const { activeCourse, setAllowRedirect } = props
    const [courseBaneers, setCourseBanners] = useState([]);
    const [courseDetails, setCourseDetails] = useState({});
    const [loadingBanners, setLoadingBanners] = useState(true)
    const [imageFile, setImageFile] = useState('')
    const [imagePreview, setImagePreview] = useState('')
    const [aboutCourse, setAboutCourse] = useState('')
    const [editAboutCourse, setEditAboutCourse] = useState('')
    const [mode, setMode] = useState('')
    const [showShimmer, setShowShimmer] = useState(true)
    const [isAddEditModalVisible, setIsAddEditModalVisible] = useState(false);
    const [isAboutCourseModalVisible, setIsAboutCourseModalVisible] = useState(false);
    let fileRef = useRef(null);
    const [SnackBarMessage, setSnackBarMessage] = useState("")
    const [isSnackBarShow, setIsSnackBarShow] = useState(false)
    const [addLoading, setAddLoading] = useState(false)
    const [addCourseLoading, setAddCourseLoading] = useState(false)
    const [delLoading, setDelLoading] = useState(false);


    useEffect(() => {
        fetch_courses_banners(activeCourse, courseBannerCallback)
        getCourseDetailsById(activeCourse, courseDetailsCallback)
    }, [activeCourse])



    const courseDetailsCallback = (response) => {
        if (response.status == 200) {
            response.json().then(data => {
                console.log(data)
                setCourseDetails(data)
                setAboutCourse(data.description)
            })
        }
    }

    const courseBannerCallback = (response) => {
        if (response.status == 200) {
            response.json().then(data => {
                console.log(data)
                setCourseBanners(data)
                setLoadingBanners(false)
                setShowShimmer(false)
            })

        }
    }

    const delBanner = (id, index) => {
        if (window.confirm("Are you sure you want to delete?")) {
            setDelLoading(id)
            deleteBanner(id, (response) => deleteCallBack(response, index))
        }
    }

    const deleteCallBack = (response, index) => {
        setDelLoading(false)
        if (response.status == 200) {
            const arr = [...courseBaneers]
            arr.splice(index, 1)
            setCourseBanners(arr)
            setSnackBarMessage("Banner Deleted Successfully")
            setIsSnackBarShow(true)
            window.location.reload();
        }
        else {
            console.log("unable to delete");
            setSnackBarMessage("Something went wrong")
            setIsSnackBarShow(true)
        }
    }

    const onAddSubmitHandler = (e) => {
        if (!addLoading) {
            e.preventDefault();
            setAddLoading(true)
            setAllowRedirect(false)
            addBanner(imageFile, activeCourse, addBannerCallBack)
        }
    }
    const onEditSubmitHandler = (e) => {
        if (!addLoading) {
            e.preventDefault();
            console.log("gee")
            setAddLoading(true)
            // addBanner(imageFile, activeCourse, addBannerCallBack)
        }
    }

    const addBannerCallBack = (response) => {
        setAddLoading(false)
        setAllowRedirect(true)
        if (response.status == 201) {
            var arr = response.headers.get('location').split('*')
            var obj = {
                id: arr[0],
                bannerImageLink: arr[1],
                courseId: activeCourse
            }
            var bannerArr = [...courseBaneers]
            bannerArr.push(obj)
            setCourseBanners(bannerArr)
            setSnackBarMessage("Added Successfully")
            setIsSnackBarShow(true)
            window.location.reload();
        }
        else {
            setSnackBarMessage("Something went wrong")
            setIsSnackBarShow(true)
            console.log(response.status)
        }
        setIsAddEditModalVisible(false)
    }

    const tiggerClickOnFile = () => {
        fileRef.click();
    }

    const fileOnChange = (event) => {
        var url = URL.createObjectURL(event.target.files[0]);
        setImagePreview(url)
        setImageFile(event.target.files[0])
    }

    const setEditDetails = (link) => {
        setImagePreview(link); setMode('edit'); setIsAddEditModalVisible(true)
    }
    const setAddDetails = () => {
        setImagePreview(''); setMode('add'); setIsAddEditModalVisible(true)
    }

    const onAboutCourseSubmitHandler = async (e) => {
        e.preventDefault();

        if (!addCourseLoading) {
            if (await showConfirmationDialog("Are you sure you want to edit?")) {
                setAddCourseLoading(true);

                try {
                    await editCourses(
                        courseDetails.instId,
                        courseDetails.title,
                        courseDetails.fees,
                        editAboutCourse,
                        courseDetails.id,
                        courseDetails.sortBy,
                        (response) => {
                            if (response.status === 201) {
                                // Update the state with the new data
                                const obj = editAboutCourse;
                                setAboutCourse(obj);

                                setSnackBarMessage("Updated Successfully");
                                setIsSnackBarShow(true);
                            } else {
                                setSnackBarMessage("Something went wrong");
                                setIsSnackBarShow(true);
                            }
                        }
                    );


                } catch (error) {
                    console.error("An error occurred:", error);
                    setSnackBarMessage("Something went wrong");
                    setIsSnackBarShow(true);
                } finally {
                    setAddCourseLoading(false);
                    setIsAboutCourseModalVisible(false);
                }
            }
        }
    };


    // Function to show a confirmation dialog with a modern UI
    const showConfirmationDialog = (message) => {
        return new Promise((resolve) => {
            // You can implement a custom modal or use a library like react-modal here
            const isConfirmed = window.confirm(message);
            resolve(isConfirmed);
        });
    };



    const closeSnack = () => {
        setIsSnackBarShow(false)
    }

    return (
        <div>

            <div class="page-breadcrumb  d-md-flex align-items-center mb-3" >
                <div class="ml-auto">
                    {/* <div class="btn-group">
                        <button type="button" class="btn btn-danger" style={{marginRight: 5}} onClick={()=>setAddDetails()}>Add</button>
                    </div> */}
                </div>
            </div>
            <div className="mt-3">

          
            {showShimmer?(
                                <td colspan="3">
                                    <Shimmer width={'100%'} height={40} /> 
                                </td>
                            ):(
                            <div style={{backgroundColor: 'white', borderRadius:'10px', marginTop:25}}>
                           <h6 style={{fontSize:'22px',marginTop:10, marginBottom:10,fontWeight:500,marginLeft:35, marginBottom:'5px', paddingTop:20}}>Course Banner</h6>
                           
                            <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', padding: '10px' }}>

                            {courseBaneers.map((row, i) => (
                                <CourseBannerRow row={row} index={i} delBanner={delBanner} setEditDetails={setEditDetails} delLoading={delLoading === row.id} />
                            ))}
                            <div style={{ width: '300px', height: '240px', background: '#fff', borderRadius: '15px', marginLeft: '15px', cursor: 'pointer', }} onClick={() => setAddDetails()}>
                            <img src="https://allcoaching.b-cdn.net/files/1697122252--Group_31.png" style={{width:'300px', height:'180px',resize:'contain',borderRadius:'15px'}}/>
                            <button style={{marginTop:'15px',marginLeft:'90px',padding:'7px',borderRadius:'5px',border:'none',backgroundColor:'#005CDD',color:'white',paddingLeft:'10px',paddingRight:'10px',fontSize:14}}>Add Your Banner</button>
                            </div>
                        </div></div>

                )}
                <br />
                <div className="card" style={{ borderRadius: '15px' }}>
                    <div className="card-body">
                        <div className="col-12 col-lg-12">

                            <form onSubmit={onAboutCourseSubmitHandler}>  
                                <h6 style={{fontSize:'22px',marginTop:10, marginBottom:10,fontWeight:500}}>Course Info</h6>

                                <CKEditor
                                    editor={ClassicEditor}
                                    config={{
                                        ckfinder:
                                        {
                                            uploadUrl: serverApiUrl + "files/uploadFileCkEditor"
                                        },
                                        fontFamily: {
                                            options: [
                                                'kruti_dev_010regular',
                                                "kruti_dev_010bold",
                                                "chanakyaregular",
                                                'Ubuntu, Arial, sans-serif',
                                                "walkman-chanakya-901bold",
                                                "GreekMathSymbols"
                                            ]
                                        }
                                    }}
                                    data={aboutCourse}
                                    onReady={editor => {
                                        console.log('Editor is ready to use!', editor);
                                    }}
                                    onChange={(event, editor) => {
                                        const data = editor.getData();
                                        setEditAboutCourse(data)
                                    }}
                                    onBlur={(event, editor) => {
                                    }}
                                    onFocus={(event, editor) => {
                                    }}
                                />
                                <br />
                                <button class="btn" style={{ marginRight: 5, backgroundColor: '#005CDD', fontSize: 14, color: '#fff' }}>
                                    {addCourseLoading ? (
                                        <ClipLoader color={theme.primaryColor} loading={addCourseLoading} />
                                    ) : ("Save Changes")}
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                visible={isAddEditModalVisible}
                setModalVisible={setIsAddEditModalVisible}
                modalId={"testAddEditModal"}
            >
                <form onSubmit={mode === "add" ? (onAddSubmitHandler) : (onEditSubmitHandler)}>
                    <ModalHeader>
                        <h5 className="modal-title">{mode === "add" ? ("Add Banner") : ("Edit Banner")}</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <svg xmlns="http://www.w3.org/2000/svg" height="30" viewBox="0 -960 960 960" width="30" fill='black' style={{ marginRight: 4, marginBottom: 1 }}><path d="M256-227.692 227.692-256l224-224-224-224L256-732.308l224 224 224-224L732.308-704l-224 224 224 224L704-227.692l-224-224-224 224Z" /></svg>
                        </button>
                    </ModalHeader>
                    <ModalBody>


                        <div className="form-row">
                            <div className="col-6 col-lg-6"    >
                                {/* <label>Banner Image </label> */}

                                <img src={imagePreview?imagePreview:"https://api.allcoaching.in/files/1697122252--Group_31.png"} style={{width:'420px', height:'220px', marginLeft:20}}/>
                               <input
                                    accept="image/*"
                                    className="form-control"
                                    type="file"
                                    ref={(ref) => (fileRef = ref)}
                                    onChange={(e) => {
                                        const acceptedFormats = ['image/jpeg', 'image/png', 'image/gif'];

                                        if (e.target.files.length > 0) {
                                            const selectedFile = e.target.files[0];
                                            const fileType = selectedFile.type;

                                            if (acceptedFormats.includes(fileType)) {
                                                // The selected file is in an accepted format (JPEG, PNG, or GIF).
                                                // You can proceed with handling the file.
                                                fileOnChange(e);
                                            } else {
                                                // The selected file is not in an accepted format.
                                                alert('Please select a valid image file (JPEG, PNG, or GIF).');
                                                // Optionally, you can clear the file input.
                                                e.target.value = '';
                                            }
                                        }
                                    }}
                                    style={{ visibility: 'hidden' }}

                                />

                                <button type="button" className="btn" onClick={tiggerClickOnFile} style={{ marginLeft: 150, marginTop: -50, width: 150 }}>Choose Image</button>

                            </div>

                        </div>


                    </ModalBody>
                    <ModalFooter>

                        <button type="button" className="btn" data-dismiss="modal">Close</button>
                        <button   className="btn" style={{backgroundColor:'#005CDD',color:'white'}}>
                            {addLoading?(
                                <ClipLoader color={theme.primaryColor} loading={addLoading}     />
                            ):(mode === "add"?("Add Banner"):("Edit Banner"))}

                        </button>
                    </ModalFooter>
                </form>
            </Modal>

            <Snackbar
                open={isSnackBarShow}
                onClose={(e) => closeSnack(e)}
                TransitionComponent="TransitionUp"
                message={SnackBarMessage}
            />

        </div>
    )
}

export default CourseBanners
