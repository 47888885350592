import React, { useEffect, useState } from "react";
import { deleteFeed, fetch_feeds } from '../../api/feed'
import { ImageResolver, serverBaseUrl } from "../..";
import moment from 'moment'
import Snackbar from '@material-ui/core/Snackbar';

import ClipLoader from "react-spinners/ClipLoader";

const FeedTop = props => {

    const [SnackBarMessage, setSnackBarMessage] = useState("")
    const [isSnackBarShow, setIsSnackBarShow] = useState(false)
    const { feed, setFeedToEdit, index, removeFeedFromState } = props
    const [creationTime, setCreationTime] = useState(feed.feed.feed.creationTime)
    const [logo, setLogo] = useState(feed.posterObject.logo)
    const [deleteLoader, setDeleteLoader] = useState(false)
    const [name, setName] = useState(feed.posterObject.name)

    const closeSnack = () => {
        setIsSnackBarShow(false)
    }
    useEffect(() => {
        setCreationTime(feed.feed.feed.creationTime)
    }, [feed])
    const deleteFeedBtnHandler = () => {
        if (!deleteLoader) {
            setDeleteLoader(true);
            deleteFeed(feed?.feed?.feed?.id, (response) => {
                console.log("delete feed ", response.status)
                if (response.status == 200) {
                    removeFeedFromState(index)
                } else {
                    setSnackBarMessage("Something went wrong")
                    setIsSnackBarShow(true)
                }
                setDeleteLoader(false)
            })
        }

    }

    return (
        <div className="mt-0">
            <div className="d-md-flex align-items-center">
                <div className="mb-md-0 mt-2" style={{ marginLeft: 0 }}>
                    <img src={ImageResolver(logo)} className="rounded-circle" width="60" height="60" alt="" id="userImage" onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = "/assets/images/profile.png";
                    }} />
                </div>
                <div className="ml-md-4 flex-grow-1">
                    <div className="d-flex align-items-center mb-0">
                        <h5 className="mb-0" id="username" style={{ color: '#3C4852', fontWeight: 800, fontSize: 16 }}>{name}</h5>
                        <button title="Edit Post" type="button" onClick={() => { window.scrollTo(0, 0); setFeedToEdit({ ...feed, index }) }} style={{ color: '#005CDD', marginLeft: "30%", outline: 'none', boxShadow: 'none', border: 'none', padding: 8, backgroundColor: 'white' }}> <svg xmlns="http://www.w3.org/2000/svg" height="30" viewBox="0 -960 960 960" width="30"><path d="M215.384-52q-23.057 0-39.221-16.163Q160-84.327 160-107.384v-529.232q0-23.057 16.163-39.221Q192.327-692 215.384-692h348.078l-30.77 30.769H215.384q-9.23 0-16.923 7.692-7.692 7.693-7.692 16.923v529.232q0 9.23 7.692 16.923 7.693 7.692 16.923 7.692h529.232q9.23 0 16.923-7.692 7.692-7.693 7.692-16.923v-318.77L800-456.923v349.539q0 23.057-16.163 39.22Q767.673-52 744.616-52H215.384ZM480-372Zm202.769-316.616L705-666.615l-274.231 273.23v70.616h69.616l276-274.462 21.231 20.462L513.846-292H400v-113.846l282.769-282.77Zm115.168 111.847L682.769-688.616l83.077-83.077q15.462-15.461 38.465-15.077 23.004.385 39.612 16.847l34.77 34.231q16.23 17.334 16.23 39.831 0 22.497-17.23 38.784l-79.756 80.308Z" fill="#005CDD" /></svg></button>
                        <button title="Delete Post" style={{ color: '#EA4335', paddingTop: 12, outline: 'none', boxShadow: 'none', border: 'none', backgroundColor: 'white', marginBottom: 3 }} type="button" onClick={() => deleteFeedBtnHandler()}>{deleteLoader ? (<ClipLoader color={"black"} loading={deleteLoader} size={20} />) : (<svg xmlns="http://www.w3.org/2000/svg" height="30" viewBox="0 -960 960 960" width="30"><path d="M295.615-160q-22.442 0-38.913-16.471-16.471-16.471-16.471-38.913v-518.462H200v-30.77h154.154v-26.154h251.692v26.154H760v30.77h-40.231v518.462q0 23.057-16.163 39.221Q687.443-160 664.385-160h-368.77ZM689-733.846H271v518.462q0 10.769 7.308 17.692 7.307 6.923 17.307 6.923h368.77q9.231 0 16.923-7.692Q689-206.154 689-215.384v-518.462ZM395.461-273.692h30.77v-378.231h-30.77v378.231Zm138.308 0h30.77v-378.231h-30.77v378.231ZM271-733.846v543.077-543.077Z" fill="#EA4335" /></svg>)}</button>
                    </div>
                    <p className="mb-0 text-muted" id="userStatus" style={{ color: '#3C4852', fontWeight: 400, fontSize: 12, marginTop: -5 }}>{moment(creationTime).fromNow()}</p>
                </div>
            </div>
            <Snackbar
                open={isSnackBarShow}
                onClose={(e) => closeSnack(e)}
                TransitionComponent="TransitionUp"
                message={SnackBarMessage}
            />
        </div>
    );
}

export default FeedTop;