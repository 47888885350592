import React, { useState, useEffect } from 'react'
import CourseBanners from './CourseBanners/CourseBanners'
import CourseDocument from './CourseDocument/CourseDocument'
import CourseTestSeries from './CourseTestSeries/CourseTestSeries'
import CourseTimeTable from './CourseTimeTable/CourseTimeTable'
import CourseTransaction from './CourseTransactions/CourseTransaction'
import CourseVideo from './CourseVideo/CourseVideo'
import EnrolledStudents from './EnrolledStudents/EnrolledStudents'
import { getDocumentCount, getVideoCount, getTestSeriesCount, getBannerCount, getStudentCount, getTimeTableCount } from '../../../api/Courses'
import { Link, useHistory } from "react-router-dom"
import { Shimmer } from 'react-shimmer'
import CourseLiveVideo from './CourseLiveVideos/CourseLiveVideo'
import YTStreamKeyUi from './CourseLiveVideos/YTStreamKeyUi'
import { Prompt } from 'react-router'
import "./style.css"


function CourseView(props) {
    console.log(props)
    let activeCourse = props.match.params.id
    let courseName = decodeURIComponent(props.match.params.name)
    const [activeTab, setActiveTab] = useState(null)
    const [docCount, setDocCount] = useState('')
    const [videoCount, setVideoCount] = useState('')
    const [tsCount, setTsCount] = useState('')
    const [bannerCount, setBannerCount] = useState('')
    const [studentCount, setStudentCount] = useState('')
    const [docCountLoading, setDocCountLoading] = useState(true)
    const [studentCountLoading, setStudentCountLoading] = useState(true)
    const [vidCountLoading, setVidCountLoading] = useState(true)
    const [tsCountLoading, setTsCountLoading] = useState(true)
    const [timeTableCountLoading, setTimeTableCountLoading] = useState(true)
    const [timeTableCount, setTimeTableCount] = useState(0)
    const [allowRedirect, setAllowRedirect] = useState(true)
    const [processing, setProcessing] = useState(false)
    const [progress, setProgress] = useState(0)
    const [testseriesCountingLoading, setTestseriesCountingLoading] = useState(true)
    const history = useHistory();
    const [liveVideoCount, setLiveVideoCount] = useState(0)

    useEffect(() => {
        getDocumentCount(activeCourse, docCountCallBack)
        getVideoCount(activeCourse, "offline", videoCountCallBack)
        getVideoCount(activeCourse, "live", liveVideoCountCallBack)
        getTestSeriesCount(activeCourse, tsCountCallBack)
        getBannerCount(activeCourse, bannerCountCallBack)
        getStudentCount(activeCourse, studentCountCallBack)
        getTimeTableCount(activeCourse, timeTableCallBack)
    }, [activeCourse])

    const timeTableCallBack = (response) => {
        setTimeTableCountLoading(false)
        if (response.status == 200) {
            response.json().then(data => {

                setTimeTableCount(data)
            })

        }
    }

    const docCountCallBack = (response) => {
        setDocCountLoading(false)
        if (response.status == 200) {
            response.json().then(data => {
                setDocCount(data)
            })
        }
        else {
            console.log(response.status)
        }
    }

    const videoCountCallBack = (response) => {
        setVidCountLoading(false)
        if (response.status == 200) {
            response.json().then(data => {
                setVideoCount(data)
            })
        }
        else {
            console.log(response.status)
        }
    }
    const liveVideoCountCallBack = (response) => {
        setVidCountLoading(false)
        if (response.status == 200) {
            response.json().then(data => {
                setLiveVideoCount(data)
            })
        }
        else {
            console.log(response.status)
        }
    }

    const tsCountCallBack = (response) => {
        setTsCountLoading(false)
        if (response.status == 200) {
            response.json().then(data => {
                setTsCount(data)
            })
        }
        else {
            console.log(response.status)
        }
    }
    const bannerCountCallBack = (response) => {
        setTestseriesCountingLoading(false)
        if (response.status == 200) {
            response.json().then(data => {
                setBannerCount(data)
            })
        }
        else {
            console.log(response.status)
        }
    }
    const studentCountCallBack = (response) => {
        setStudentCountLoading(false)
        if (response.status == 200) {
            response.json().then(data => {
                setStudentCount(data)
            })
        }
        else {
            console.log("student", response.status)
        }
    }

    useEffect(() => {
        if (activeTab) {
            localStorage.setItem(activeCourse + "activeTab", activeTab)
        }

    }, [activeTab])

    useEffect(() => {
        const tab = localStorage.getItem(activeCourse + "activeTab")
        // console.log(tab," tab value")
        if (props.location.query) {
            setActiveTab("enrolledStudents")
        }
        else if (tab && tab != "null") {
            setActiveTab(tab)
        } else {
            setActiveTab("videos")
        }
    }, [])

    const renderTabContent = (activeTab, activeCourse) => {
        // console.log(activeTab)
        switch (activeTab) {
            case 'banners':
                return (<CourseBanners setAllowRedirect={setAllowRedirect} activeCourse={activeCourse} />)

            case 'videos':
                return (<CourseVideo activeCourse={activeCourse} setProgressInParent={setProgress} setProcessingInParent={setProcessing} setAllowRedirect={setAllowRedirect} setVideoCount={setVideoCount} videoCount={videoCount} />)
            case 'liveVideos':
                return (<YTStreamKeyUi activeCourse={activeCourse} setAllowRedirect={setAllowRedirect} setLiveVideoCount={setLiveVideoCount} liveVideoCount={liveVideoCount} />)

            case 'documents':
                return (
                    <CourseDocument setProgressInParent={setProgress} setProcessingInParent={setProcessing} activeCourse={activeCourse} setAllowRedirect={setAllowRedirect} setDocCount={setDocCount} docCount={docCount} />
                )
            case 'testseries':
                return <CourseTestSeries setAllowRedirect={setAllowRedirect} activeCourse={activeCourse} />
            case 'timetable':
                return (<CourseTimeTable setAllowRedirect={setAllowRedirect} activeCourse={activeCourse} setTimeTableCount={setTimeTableCount} timeTableCount={timeTableCount} />)
            case 'enrolledStudents':
                return (<EnrolledStudents setAllowRedirect={setAllowRedirect} activeCourse={activeCourse} />)
        }
    }
    return (
        <div style={{ fontFamily: 'Outfit, sans-serif' }}>
            <Prompt
                when={!allowRedirect}
                message='You have unsaved changes, are you sure you want to leave?'
            />
            <div class="page-breadcrumb  d-md-flex align-items-center mb-3 text-yellow-300" style={{ marginLeft: '-10px' }}>

                <div class="breadcrumb-title pr-3" style={{ fontWeight: 600, fontSize: 24 }}>{courseName}</div>
                <div class="ml-auto">

                    <Link title='Students Doubts' to={"/studentChat/" + activeCourse} onClick={() => { setActiveTab('timetable') }} class="btn" style={{ marginRight: 5, backgroundColor: '#005CDD', fontSize: 14, color: '#fff' }}>Students Doubts</Link>
                </div>
                <button type="button" class="btn" style={{ marginRight: 5, }} onClick={() => history.goBack()}>
                    Go Back</button>
            </div>
            <div className="row" style={{ margin: 5 }}>
                {processing ? (
                    <div className="progress mt-3 w-100" style={{ height: 30, marginBottom: 10, borderRadius: 50 }}>
                        <div
                            className="progress-bar"
                            role="progressbar"
                            style={{ width: (progress - 1) + '%' }}
                            aria-valuenow={progress - 1}
                            aria-valuemin="0"
                            aria-valuemax="99"
                        >
                        </div>
                        <span style={{ color: '#fff', position: "absolute", left: '93%', marginTop: 15, fontWeight: 500 }}>{progress - 1} %</span>
                    </div>
                ) : (
                    null
                )}
                <div style={{ backgroundColor: 'white', height: '50px', borderRadius: 5 }}>
                    <div style={activeTab === 'liveVideos' ? { borderWidth: 0, borderBottom: '3px solid #FA2918', borderStyle: 'solid', borderRadius: 5 } : {}}>
                        {vidCountLoading ? (
                            <Shimmer width={'100%'} height={40} />
                        ) : (
                            <div
                                onClick={() => setActiveTab('liveVideos')}
                                className="hover-effect"
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    cursor: 'pointer',
                                    padding: '10px 10px 10px 10px'

                                }}
                            >
                                {/* <h4 className="mb-0 font-weight-bold">{liveVideoCount}</h4> */}

                                <p className="mb-0" style={{ marginRight: 5, fontWeight: 500, color: '#FA2918' }}><svg xmlns="http://www.w3.org/2000/svg" height="30" viewBox="0 -960 960 960" width="30" fill='#FA2918' style={{ marginRight: 5, marginBottom: 1 }}><path d="M480-480Zm295.385 280H578.462q0-10-.539-20-.538-10-1.615-20h199.077q10.769 0 17.692-6.923T800-264.615v-430.77q0-10.769-6.923-17.692T775.385-720h-590.77q-10.769 0-17.692 6.923T160-695.385v39.077q-10-1.077-20-1.615-10-.539-20-.539v-36.923q0-26.654 18.981-45.634Q157.961-760 184.615-760h590.77q26.654 0 45.634 18.981Q840-722.039 840-695.385v430.77q0 26.654-18.981 45.634Q802.039-200 775.385-200ZM120-200v-76.923q31.538 0 54.231 22.692 22.692 22.693 22.692 54.231H120Zm172.308 0q0-71.461-50.424-121.115Q191.461-370.769 120-372.308v-40q88.538 0 150.423 61.797Q332.308-288.714 332.308-200h-40Zm150.769 0q0-67.158-25.423-125.81-25.423-58.651-69.308-102.536-43.885-43.885-102.536-69.308-58.652-25.423-125.81-25.423v-40q75.615 0 141.509 28.417 65.894 28.416 115.314 77.837 49.421 49.42 77.837 115.314Q483.077-275.615 483.077-200h-40Z" /></svg>Live Now</p>
                            </div>
                        )}

                    </div>
                </div>
                <div style={{ backgroundColor: 'white', height: '50px', borderRadius: 5, marginLeft: 10 }}>

                    <div style={activeTab === 'videos' ? { borderWidth: 0, borderBottom: '3px solid #005CDD', borderStyle: 'solid', borderRadius: 5 } : {}}>
                        {vidCountLoading ? (
                            <Shimmer width={'100%'} height={40} />
                        ) : (
                            <div
                                onClick={() => { setActiveTab('videos') }}
                                className="hover-effect"
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    cursor: 'pointer',
                                    padding: '10px 10px 10px 10px'
                                }}
                            >
                                {/* <h4 className="mb-0 font-weight-bold">{liveVideoCount}</h4> */}

                                <p className="mb-0" style={{ marginRight: 5, fontWeight: 500 }}><svg xmlns="http://www.w3.org/2000/svg" height="30" viewBox="0 -960 960 960" width="30" fill='#3C4852' style={{ marginRight: 5, marginBottom: 1 }}><path d="m184.615-760 60 120h120l-60-120h80l60 120h120l-60-120h80l60 120h120l-60-120h70.77Q803-760 821.5-741.5 840-723 840-695.385v430.77Q840-237 821.5-218.5 803-200 775.385-200h-590.77Q157-200 138.5-218.5 120-237 120-264.615v-430.77Q120-723 138.5-741.5 157-760 184.615-760ZM160-600v335.385q0 10.769 6.923 17.692T184.615-240h590.77q10.769 0 17.692-6.923T800-264.615V-600H160Zm0 0v360-360Z" /></svg>Videos</p>
                            </div>

                        )}
                    </div>

                </div>
                <div style={{ backgroundColor: 'white', height: '50px', borderRadius: 5, marginLeft: 10 }}>

                    <div style={activeTab === 'documents' ? { borderWidth: 0, borderBottom: '3px solid #005CDD', borderStyle: 'solid', borderRadius: 5 } : {}}>
                        {docCountLoading ? (
                            <Shimmer width={'100%'} height={40} />
                        ) : (
                            <div
                                onClick={() => { setActiveTab('documents') }}
                                className="hover-effect"
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    cursor: 'pointer',
                                    padding: '10px 10px 10px 10px'
                                }}
                            >
                                {/* <h4 className="mb-0 font-weight-bold">{docCount}</h4> */}

                                <p className="mb-0" style={{ marginRight: 5, fontWeight: 500 }}><svg xmlns="http://www.w3.org/2000/svg" height="30" viewBox="0 -960 960 960" width="30" fill='#3C4852' style={{ marginRight: 5, marginBottom: 1 }}><path d="M340-260h280v-40H340v40Zm0-160h280v-40H340v40Zm-75.385 300Q237-120 218.5-138.5 200-157 200-184.615v-590.77Q200-803 218.5-821.5 237-840 264.615-840H580l180 180v475.385Q760-157 741.5-138.5 723-120 695.385-120h-430.77ZM560-640v-160H264.615q-9.23 0-16.923 7.692Q240-784.615 240-775.385v590.77q0 9.23 7.692 16.923Q255.385-160 264.615-160h430.77q9.23 0 16.923-7.692Q720-175.385 720-184.615V-640H560ZM240-800v160-160 640-640Z" /></svg>PDF</p>
                            </div>
                        )}
                    </div>

                </div>
                <div style={{ backgroundColor: 'white', height: '50px', borderRadius: 5, marginLeft: 10 }}>

                    <div style={activeTab === 'testseries' ? { borderWidth: 0, borderBottom: '3px solid #005CDD', borderStyle: 'solid', borderRadius: 5 } : {}}>
                        {tsCountLoading ? (
                            <Shimmer width={'100%'} height={40} />
                        ) : (
                            <div
                                onClick={() => { setActiveTab('testseries') }}
                                className="hover-effect"
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    cursor: 'pointer',
                                    padding: '10px 10px 10px 10px',
                                }}
                            >
                                {/* <h4 className="mb-0 font-weight-bold">{tsCount}</h4> */}

                                <p className="mb-0" style={{ marginRight: 5, fontWeight: 500 }}><svg xmlns="http://www.w3.org/2000/svg" height="30" viewBox="0 -960 960 960" width="30" fill='#3C4852' style={{ marginRight: 5, marginBottom: 1 }}><path d="M279.231-120q-32.693 0-55.962-21.654Q200-163.308 200-195.231v-534.154q0-31.077 21.192-54.538 21.192-23.462 52.269-29.923L624.615-888v584.615l-354.384 76.77q-12.077 2.769-21.154 11.038T240-195.231q0 15.616 11.692 25.423Q263.385-160 279.231-160H720v-600h40v640H279.231Zm56.154-160.693 249.23-54.384v-503.384l-249.23 52.846v504.922Zm-40 8.308v-504.923l-17.308 3.77q-16.385 3.538-27.231 15.653Q240-745.769 240-729.385v474.692q7.308-4.307 14.731-7.346 7.423-3.038 15.5-4.807l25.154-5.539ZM240-769v514.307V-769Z" /></svg>Test Series</p>
                            </div>
                        )}
                    </div>

                </div>
                <div style={{ backgroundColor: 'white', height: '50px', borderRadius: 5, marginLeft: 10 }}>

                    <div style={activeTab === 'timetable' ? { borderWidth: 0, borderBottom: '3px solid #005CDD', borderStyle: 'solid', borderRadius: 5 } : {}}>
                        {timeTableCountLoading ? (
                            <Shimmer width={'100%'} height={40} />
                        ) : (
                            <div
                                onClick={() => { setActiveTab('timetable') }}
                                className="hover-effect"
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    cursor: 'pointer',
                                    padding: '10px 10px 10px 10px'
                                }}
                            >
                                {/* <h4 className="mb-0 font-weight-bold">{timeTableCount}</h4> */}

                                <p className="mb-0" style={{ marginRight: 5, fontWeight: 500 }}><svg xmlns="http://www.w3.org/2000/svg" height="30" viewBox="0 -960 960 960" width="30" fill='#3C4852' style={{ marginRight: 5, marginBottom: 1 }}><path d="M224.615-120Q197-120 178.5-138.5 160-157 160-184.615v-510.77Q160-723 178.5-741.5 197-760 224.615-760h70.769v-89.231h43.077V-760h286.154v-89.231h40.001V-760h70.769Q763-760 781.5-741.5 800-723 800-695.385v510.77Q800-157 781.5-138.5 763-120 735.385-120h-510.77Zm0-40h510.77q9.23 0 16.923-7.692Q760-175.385 760-184.615v-350.77H200v350.77q0 9.23 7.692 16.923Q215.385-160 224.615-160ZM200-575.385h560v-120q0-9.23-7.692-16.923Q744.615-720 735.385-720h-510.77q-9.23 0-16.923 7.692Q200-704.615 200-695.385v120Zm0 0V-720v144.615Zm280 181.539q-12.385 0-21.577-9.193-9.192-9.192-9.192-21.576 0-12.385 9.192-21.577 9.192-9.193 21.577-9.193 12.385 0 21.577 9.193 9.192 9.192 9.192 21.577 0 12.384-9.192 21.576-9.192 9.193-21.577 9.193Zm-160 0q-12.385 0-21.577-9.193-9.192-9.192-9.192-21.576 0-12.385 9.192-21.577 9.192-9.193 21.577-9.193 12.385 0 21.577 9.193 9.192 9.192 9.192 21.577 0 12.384-9.192 21.576-9.192 9.193-21.577 9.193Zm320 0q-12.385 0-21.577-9.193-9.192-9.192-9.192-21.576 0-12.385 9.192-21.577 9.192-9.193 21.577-9.193 12.385 0 21.577 9.193 9.192 9.192 9.192 21.577 0 12.384-9.192 21.576-9.192 9.193-21.577 9.193ZM480-240q-12.385 0-21.577-9.192-9.192-9.193-9.192-21.577 0-12.385 9.192-21.577 9.192-9.193 21.577-9.193 12.385 0 21.577 9.193 9.192 9.192 9.192 21.577 0 12.384-9.192 21.577Q492.385-240 480-240Zm-160 0q-12.385 0-21.577-9.192-9.192-9.193-9.192-21.577 0-12.385 9.192-21.577 9.192-9.193 21.577-9.193 12.385 0 21.577 9.193 9.192 9.192 9.192 21.577 0 12.384-9.192 21.577Q332.385-240 320-240Zm320 0q-12.385 0-21.577-9.192-9.192-9.193-9.192-21.577 0-12.385 9.192-21.577 9.192-9.193 21.577-9.193 12.385 0 21.577 9.193 9.192 9.192 9.192 21.577 0 12.384-9.192 21.577Q652.385-240 640-240Z" /> </svg>Time Table</p>
                            </div>
                        )}
                    </div>
                </div>
                <div style={{ backgroundColor: 'white', height: '50px', borderRadius: 5, marginLeft: 10 }}>

                    <div style={activeTab === 'enrolledStudents' ? { borderWidth: 0, borderBottom: '3px solid #005CDD', borderStyle: 'solid', borderRadius: 5 } : {}}>

                        <div
                            onClick={() => { setActiveTab('enrolledStudents') }}
                            className="hover-effect"
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                cursor: 'pointer',
                                padding: '10px 10px 10px 10px'
                            }}
                        >
                            {/* <h4 className="mb-0 font-weight-bold">{timeTableCount}</h4> */}

                            <p className="mb-0" style={{ marginRight: 5, fontWeight: 500 }}><svg xmlns="http://www.w3.org/2000/svg" height="30" viewBox="0 -960 960 960" width="30" fill='#3C4852' style={{ marginRight: 5, marginBottom: 1 }}><path d="M103.846-215.384v-65.847q0-27.846 14.423-47.884 14.423-20.039 38.765-32.029 52.043-24.779 103.35-39.51 51.308-14.731 123.462-14.731t123.462 14.731q51.307 14.731 103.35 39.51 24.342 11.99 38.765 32.029 14.423 20.038 14.423 47.884v65.847h-560Zm640 0v-67.693q0-34.769-14.074-65.64-14.075-30.871-39.926-52.976 29.462 6 56.769 16.654 27.308 10.654 54.001 23.962 26 13.077 40.769 33.469 14.769 20.393 14.769 44.531v67.693H743.846Zm-360-289.231q-49.5 0-84.75-35.25t-35.25-84.75q0-49.501 35.25-84.751 35.25-35.25 84.75-35.25t84.75 35.25q35.25 35.25 35.25 84.751 0 49.5-35.25 84.75t-84.75 35.25Zm290.77-120q0 49.5-35.25 84.75t-84.75 35.25q-2.539 0-6.462-.577-3.923-.577-6.462-1.269 20.325-24.895 31.239-55.235 10.915-30.34 10.915-63.015 0-32.674-11.423-62.443t-30.731-55.616q3.231-1.153 6.462-1.5 3.231-.346 6.462-.346 49.5 0 84.75 35.25t35.25 84.751Zm-530.77 369.23h480v-25.846q0-14.077-7.038-24.615-7.039-10.539-25.27-20.154-44.769-23.923-94.384-36.654-49.616-12.731-113.308-12.731-63.692 0-113.308 12.731-49.615 12.731-94.384 36.654-18.231 9.615-25.27 20.154-7.038 10.538-7.038 24.615v25.846Zm240-289.23q33 0 56.5-23.5t23.5-56.5q0-33-23.5-56.5t-56.5-23.5q-33 0-56.5 23.5t-23.5 56.5q0 33 23.5 56.5t56.5 23.5Zm0 289.23Zm0-369.23Z" /></svg>Students</p>
                        </div>

                    </div>
                </div>
                <div style={{ backgroundColor: 'white', height: '50px', borderRadius: 5, marginLeft: 10 }}>

                    <div style={activeTab === 'banners' ? { borderWidth: 0, borderBottom: '3px solid #005CDD', borderStyle: 'solid', borderRadius: 5 } : {}}>

                        <div
                            onClick={() => { setActiveTab('banners') }}
                            className="hover-effect"
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                cursor: 'pointer',
                                padding: '10px 10px 10px 10px'
                            }}
                        >

                            <p style={{ marginRight: 5, fontWeight: 500 }} className="mb-0" ><svg xmlns="http://www.w3.org/2000/svg" height="30" viewBox="0 -960 960 960" width="30" fill='#3C4852' style={{ marginRight: 3, marginBottom: 1 }}><path d="M460-300h40v-220h-40v220Zm20-276.923q10.462 0 17.539-7.077 7.076-7.077 7.076-17.539 0-10.461-7.076-17.538-7.077-7.077-17.539-7.077-10.462 0-17.539 7.077-7.076 7.077-7.076 17.538 0 10.462 7.076 17.539 7.077 7.077 17.539 7.077ZM480.134-120q-74.673 0-140.41-28.339-65.737-28.34-114.365-76.922-48.627-48.582-76.993-114.257Q120-405.194 120-479.866q0-74.673 28.339-140.41 28.34-65.737 76.922-114.365 48.582-48.627 114.257-76.993Q405.194-840 479.866-840q74.673 0 140.41 28.339 65.737 28.34 114.365 76.922 48.627 48.582 76.993 114.257Q840-554.806 840-480.134q0 74.673-28.339 140.41-28.34 65.737-76.922 114.365-48.582 48.627-114.257 76.993Q554.806-120 480.134-120ZM480-160q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" /></svg> Course Info</p>
                        </div>

                    </div>
                </div>

            </div>

            {renderTabContent(activeTab, activeCourse)}
        </div>
    )
}

export default CourseView
