import React, { useState } from "react";
import FeedTop from "./feedTop";
import FeedBottom from "./feedBottom";
import Comment from "./comments";
import { serverBaseUrl } from "../..";

const ImageFeed = (props) => {
  const { feed, setFeedToEdit, index, removeFeedFromState } = props;
  const [showComments, setShowComment] = useState(false);
  const [showFullDescription, setShowFullDescription] = useState(false);

  const toggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };

  const renderDescription = () => {
    const description = feed.feed.feed.description;
    const maxWords = 20;
    
    if (description.split(" ").length > maxWords && !showFullDescription) {
      const truncated = description
        .split(" ")
        .slice(0, maxWords)
        .join(" ");
      return (
        <>
          {truncated}{" "}
          <span
            style={{ cursor: "pointer", color: "blue" }}
            onClick={toggleDescription}
          > < br/>
            See More
          </span>
        </>
      );
    }
    
    return (
      <>
        {description}{" "}
        {description.split(" ").length > maxWords && (
          <span
            style={{ cursor: "pointer", color: "blue" }}
            onClick={toggleDescription}
          > < br/>
            See Less
          </span>
        )}
      </>
    );
  };

  return (
    <div>
      <div className="card">
        <div className="card-body">
          <FeedTop
            feed={feed}
            index={index}
            removeFeedFromState={removeFeedFromState}
            setFeedToEdit={setFeedToEdit}
          />
          <div className="card-title mt-4 w-100 h-25">
            <p className="mb-0" style={{ color:'black', fontWeight:400 }}>{renderDescription()}</p>
          </div>
          {/* <hr /> */}
          <div
            id="carouselExampleSlidesOnly"
            className="carousel slide"
            data-ride="carousel"
          >
            <div className="carousel-inner">
              {feed.feed.feedImages.map((row, index) => (
                <div
                  className={`carousel-item ${index === 0 ? "active" : ""}`}
                  key={index}
                >
                  <img
                    src={serverBaseUrl + (typeof row === "object" ? row.feedImage : row)}
                    className="d-block "
                    style={{ height: '50%', width: '100%', borderRadius: 15 }}
                    resizeMode="contain"
                    alt="..."
                  />
                </div>
              ))}
            </div>
          </div>
          <FeedBottom feed={feed} feedId={feed.feed.feed.id} setShowComment={setShowComment} />
          {showComments ? <Comment feed={feed} feedId={feed.feed.feed.id} /> : null}
        </div>
      </div>
    </div>
  );
};

export default ImageFeed;
