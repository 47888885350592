import React, { useEffect } from 'react';
import "./style.css"

export const ModalFooter = props => {
    return <div className="modal-footer fixed-footer">{props.children}</div>;
  };
  
export const ModalHeader = props => {
    return <div className="modal-header fixed-header">{props.children}</div>;
  };
  
  
export const ModalBody = props => {
    return <div className="modal-body">{props.children}</div>;
};

const Modal = props => {
  const { visible, modalId, setModalVisible } = props;
  const modalBody = props.children;

  useEffect(() => {
    if (visible) {
      window.$("#modal" + modalId).modal('show');
      document.body.style.overflow = 'hidden'; // Disable scrolling on the body
    } else {
      window.$("#modal" + modalId).modal('hide');
      document.body.style.overflow = 'auto'; // Enable scrolling on the body
    }
  }, [visible]);

  useEffect(() => {
    return window.$("#modal" + modalId).on('hide.bs.modal', () => {
      setModalVisible(false);
      document.body.style.overflow = 'auto'; // Enable scrolling on the body
    });
  }, []);

  return (
    <div className="modal fade" data-backdrop="static" data-keyboard="false" id={"modal" + modalId} tabIndex="-1" role="dialog" aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-scrollable-content">{modalBody}</div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
