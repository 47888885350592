import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { votePoll } from '../../api/feed';
import ClipLoader from 'react-spinners/ClipLoader';
import { theme } from '../..';

const PollFeedRow = (props) => {
  const { row, totalPollVotes, setFocusedOptionIndex } = props;
  const percentage = () => Math.round((row.upVotes / totalPollVotes) * 100, 0);
  const insDetails = useSelector((state) => state.ins.insDetails);
  const [loading, setLoading] = useState(false);
  let insId = insDetails.id;

  const voteForPoll = (poll_id, option_id) => {
    votePoll(poll_id, option_id, 1, insId, handleVotePollCallback);
    setLoading(true);
  };

  const handleVotePollCallback = (response, option_id) => {
    console.log(response.status);
    if (response.status === 200) {
      props.updateVote(option_id);
      setLoading(false);
    } else {
      console.log("failed");
    }
  };

  console.log("row", row.upVotes);

  const renderVoteButton = () => {
    if (props.canUserVote) {
      return (
        <div>
          <div
            className="progress mt-3"
            style={{ height: 40,width:400,marginLeft:10 }}
            onClick={() => voteForPoll(row.feedId, row.id)}
          >
            {loading ? (
              <ClipLoader color={theme.primaryColor} loading={loading} />
            ) : (
              <button
                role="progressbar"
                style={{ width: "100%",border:'1px solid #ededed',backgroundColor:'white',borderRadius:5 }}
                aria-valuenow={0}
                aria-valuemin="0"
                aria-valuemax="100"
              >
                <span style={{ color: "#3C4852", marginTop: 2, fontSize:14 }}>
                  {row.pollOption}
                </span>
              </button>
            )}
          </div>
        </div>
      );
    } else {
      return (
        <div className=" text-center">
          <div
            className="progress mt-3 text-center"
            style={{ height: 40,width:400,marginLeft:10 }}
            onClick={() => setFocusedOptionIndex(props.index)}
          >
            {loading ? (
              <ClipLoader color={theme.primaryColor} loading={loading} />
            ) : (
              <button
                role="progressbar"
                style={{
                  width: "100%",border:'1px solid #ededed',backgroundColor:'white',borderRadius:5 
                }}
                // aria-valuenow={percentage() ? percentage() : 1}
                // aria-valuemin="0"
                // aria-valuemax="100"
              >
                <span style={{ color: "#000", marginTop: 2, fontSize:14 }}>
                  {row.pollOption}
                </span>
              </button>
            )}
          </div>
        </div>
      );
    }
  };

  return renderVoteButton();
};

export default PollFeedRow;
