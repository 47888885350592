import React, { useState, useEffect } from 'react';
import { dataLimit } from '../..';
import { currentMonthIncomeSumIns, fetchInsTransactions, todayIncomeSumIns, totalIncomeSumIns } from '../../api/transaction';
import TransactionRow from './LeadsRow';
import { Shimmer } from 'react-shimmer';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

const dataLimitPerPage = 30;

function Transactions(props) {
  const [offset, setOffset] = useState(0);
  const [transactions, setTransactions] = useState([]);
  const [loadingTransactions, setLoadingTransactions] = useState(false);
  const [status, setStatus] = useState(localStorage.getItem('selectedStatus') || 'TXN_PENDING');
  const [showShimmer, setShowShimmer] = useState(true);
  const [showNextButton, setShowNextButton] = useState();
  const [allDataLoaded, setAllDataLoaded] = useState(false);
  const insDetails = useSelector((state) => state.ins.insDetails);
  let insId = insDetails.id;
  const [todaysIncome, setTodaysIncome] = useState('0');
  const [totalIncome, setTotalIncome] = useState('0');
  const [currentMonthTotal, setCurrentMonthTotal] = useState('0');
  const history = useHistory();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  useEffect(() => {
    localStorage.setItem('selectedStatus', status);
  }, [status]);

  useEffect(() => {
    fetchInsTransactions(insId, offset, dataLimitPerPage, (response) => {
      if (response.status === 200) {
        response.json().then((data) => {
          const filteredData = data.filter((transaction) => {
            // Include only 'TXN_PENDING' and 'TXN_FAILURE' transactions
            return transaction.transaction.status === 'TXN_PENDING' || transaction.transaction.status === 'TXN_FAILURE';
          });

          if (filteredData.length === dataLimitPerPage) {
            setTransactions(filteredData);
            setShowNextButton(true);
          } else if (filteredData.length < dataLimitPerPage) {
            if (filteredData.length === 0) {
              if (offset === 0) {
                setOffset(0);
              } else {
                setOffset(offset - 1);
              }
            } else if (filteredData.length !== 0) {
              setTransactions(filteredData);
            }
            setShowNextButton(false);
            setAllDataLoaded(true);
          }
          setLoadingTransactions(false);
          setShowShimmer(false);
        });
      }
    }, status);
  }, [offset, status]);

  useEffect(() => {
    if (insId) {
      todayIncomeSumIns(insId, (response) => {
        if (response.status === 200) {
          response.json().then((data) => {
            setTodaysIncome(data);
          });
        }
      });
      currentMonthIncomeSumIns(insId, (response) => {
        if (response.status === 200) {
          response.json().then((data) => {
            setCurrentMonthTotal(data);
          });
        }
      });
      totalIncomeSumIns(insId, (response) => {
        if (response.status === 200) {
          response.json().then((data) => {
            setTotalIncome(data);
          });
        }
      });
    }
  }, [insId]);

  const nextPageHandler = () => {
    if (!allDataLoaded) {
      setOffset(offset + 1);
    } else {
      window.alert('No more data available');
    }
  };

  const prePageHandler = () => {
    if (offset > 0) {
      setOffset(offset - 1);
    } else if (offset === 0) {
      setOffset(0);
      setShowNextButton(true);
    }
    setAllDataLoaded(false);
  };

  const handleDateFilter = () => {
    // Fetch transactions based on the selected date range
    fetchInsTransactions(insId, offset, dataLimitPerPage, (response) => {
      if (response.status === 200) {
        response.json().then((data) => {
          const filteredData = data.filter((transaction) => {
            const transactionDate = new Date(transaction.purchaseDate).getTime();
            const startTimestamp = startDate ? new Date(startDate).getTime() : null;
            const endTimestamp = endDate ? new Date(endDate).getTime() : null;

            // Include transactions within the selected date range
            return (
              (!startTimestamp || transactionDate >= startTimestamp) &&
              (!endTimestamp || transactionDate <= endTimestamp) &&
              (transaction.transaction.status === 'TXN_PENDING' || transaction.transaction.status === 'TXN_FAILURE')
            );
          });

          if (filteredData.length === dataLimitPerPage) {
            setTransactions(filteredData);
            setShowNextButton(true);
          } else if (filteredData.length < dataLimitPerPage) {
            if (filteredData.length === 0) {
              if (offset === 0) {
                setOffset(0);
              } else {
                setOffset(offset - 1);
              }
            } else if (filteredData.length !== 0) {
              setTransactions(filteredData);
            }
            setShowNextButton(false);
            setAllDataLoaded(true);
          }
          setLoadingTransactions(false);
          setShowShimmer(false);
        });
      }
    }, status);
  };



  return (
    <div>
      <div class="page-breadcrumb  d-md-flex align-items-center mb-3">
        <div style={{ fontWeight: 600, fontSize: 24 }}>Leads Section</div>
        <div class="ml-auto">
          <button type="button" class="btn" style={{ marginRight: 5 }} onClick={() => history.goBack()}>
            Go Back
          </button>
        </div>
      </div>

      <div className="row mt-4" style={{ marginLeft: -15 }}>
        <div className="col-lg-4 col-md-4">
          <div className="card radius-15" style={{ background: 'linear-gradient(to right, #FED86F, #FED86F, #FED86F)', border: 'none', borderRadius: 10 }}>
            <div className="card-body">
              <div className="media align-items-center">
                <div className="media-body">
                  <p className="mb-0" style={{ fontWeight: 500 }}>
                    Today Leads
                  </p>
                  <h4 className="mb-0" style={{ fontSize: 26, fontWeight: 700, marginTop: 4 }}>
                    {todaysIncome}
                  </h4>
                </div>
                <div className="widgets-icons bg-light text-light rounded-circle">
                  <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M760-130.001q-37.461 0-66.731-22.5-29.269-22.5-38.576-57.5H440q-62.154 0-106.076-43.923Q290.001-297.846 290.001-360q0-62.154 43.923-106.076Q377.846-509.999 440-509.999h80q37.125 0 63.563-26.46 26.438-26.46 26.438-63.616t-26.438-63.541Q557.125-690.001 520-690.001H305.307q-9.923 35-38.884 57.5-28.962 22.5-66.423 22.5-45.833 0-77.916-32.07t-32.083-77.884q0-45.814 32.083-77.929T200-829.999q37.461 0 66.423 22.5 28.961 22.5 38.884 57.5H520q62.154 0 106.076 43.923Q669.999-662.154 669.999-600q0 62.154-43.923 106.076Q582.154-450.001 520-450.001h-80q-37.125 0-63.563 26.46-26.438 26.46-26.438 63.616t26.438 63.541q26.438 26.385 63.563 26.385h214.693q9.923-35 38.884-57.5 28.962-22.5 66.423-22.5 45.833 0 77.916 32.07t32.083 77.884q0 45.814-32.083 77.929T760-130.001ZM200-669.999q20.846 0 35.424-14.577 14.577-14.578 14.577-35.424t-14.577-35.424Q220.846-770.001 200-770.001t-35.424 14.577Q149.999-740.846 149.999-720t14.577 35.424q14.578 14.577 35.424 14.577Z" />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-4">
          <div className="card radius-15" style={{ background: 'linear-gradient(to right, #9DBFFF,#9DBFFF)', border: 'none', borderRadius: 10 }}>
            <div className="card-body">
              <div className="media align-items-center">
                <div className="media-body">
                  <p className="mb-0" style={{ fontWeight: 500 }}>
                    This Month Leads
                  </p>
                  <h4 className="mb-0" style={{ fontSize: 26, fontWeight: 700, marginTop: 4 }}>
                    {currentMonthTotal}
                  </h4>
                </div>
                <div className="widgets-icons bg-light text-light rounded-circle">
                  <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M760-130.001q-37.461 0-66.731-22.5-29.269-22.5-38.576-57.5H440q-62.154 0-106.076-43.923Q290.001-297.846 290.001-360q0-62.154 43.923-106.076Q377.846-509.999 440-509.999h80q37.125 0 63.563-26.46 26.438-26.46 26.438-63.616t-26.438-63.541Q557.125-690.001 520-690.001H305.307q-9.923 35-38.884 57.5-28.962 22.5-66.423 22.5-45.833 0-77.916-32.07t-32.083-77.884q0-45.814 32.083-77.929T200-829.999q37.461 0 66.423 22.5 28.961 22.5 38.884 57.5H520q62.154 0 106.076 43.923Q669.999-662.154 669.999-600q0 62.154-43.923 106.076Q582.154-450.001 520-450.001h-80q-37.125 0-63.563 26.46-26.438 26.46-26.438 63.616t26.438 63.541q26.438 26.385 63.563 26.385h214.693q9.923-35 38.884-57.5 28.962-22.5 66.423-22.5 45.833 0 77.916 32.07t32.083 77.884q0 45.814-32.083 77.929T760-130.001ZM200-669.999q20.846 0 35.424-14.577 14.577-14.578 14.577-35.424t-14.577-35.424Q220.846-770.001 200-770.001t-35.424 14.577Q149.999-740.846 149.999-720t14.577 35.424q14.578 14.577 35.424 14.577Z" /></svg>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-4">
          <div className="card radius-15" style={{ background: 'linear-gradient(to right, #65CA83, #65CA83, #65CA83)', border: 'none', borderRadius: 10 }}>
            <div className="card-body">
              <div className="media align-items-center">
                <div className="media-body">
                  <p className="mb-0" style={{ fontWeight: 500 }}>
                    Total Leads
                  </p>
                  <h4 className="mb-0" style={{ fontSize: 26, fontWeight: 700, marginTop: 4 }}>
                    {transactions.length}
                  </h4>
                </div>
                <div className="widgets-icons bg-light text-light rounded-circle">
                  <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M760-130.001q-37.461 0-66.731-22.5-29.269-22.5-38.576-57.5H440q-62.154 0-106.076-43.923Q290.001-297.846 290.001-360q0-62.154 43.923-106.076Q377.846-509.999 440-509.999h80q37.125 0 63.563-26.46 26.438-26.46 26.438-63.616t-26.438-63.541Q557.125-690.001 520-690.001H305.307q-9.923 35-38.884 57.5-28.962 22.5-66.423 22.5-45.833 0-77.916-32.07t-32.083-77.884q0-45.814 32.083-77.929T200-829.999q37.461 0 66.423 22.5 28.961 22.5 38.884 57.5H520q62.154 0 106.076 43.923Q669.999-662.154 669.999-600q0 62.154-43.923 106.076Q582.154-450.001 520-450.001h-80q-37.125 0-63.563 26.46-26.438 26.46-26.438 63.616t26.438 63.541q26.438 26.385 63.563 26.385h214.693q9.923-35 38.884-57.5 28.962-22.5 66.423-22.5 45.833 0 77.916 32.07t32.083 77.884q0 45.814-32.083 77.929T760-130.001ZM200-669.999q20.846 0 35.424-14.577 14.577-14.578 14.577-35.424t-14.577-35.424Q220.846-770.001 200-770.001t-35.424 14.577Q149.999-740.846 149.999-720t14.577 35.424q14.578 14.577 35.424 14.577Z" />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div style={{ display: 'flex', marginBottom: '15px' }}>
        <input
          type="date"
          value={startDate ? startDate.toISOString().split('T')[0] : ''}
          onChange={(e) => setStartDate(new Date(e.target.value))}
          style={{ marginRight: '8px' }}
        />
        <input
          type="date"
          value={endDate ? endDate.toISOString().split('T')[0] : ''}
          onChange={(e) => setEndDate(new Date(e.target.value))}
          style={{ marginRight: '8px' }}
        />

        <button
          onClick={() => handleDateFilter()}
          style={{
            backgroundColor: '#007BFF',
            color: 'white',
            border: '1px solid #fff',
            borderRadius: '5px',
            padding: '6px 12px',
            cursor: 'pointer',
            fontSize: 13,
          }}
        >
          Search
        </button>
      </div>


      <div className="mt-3">
        <div class="table-responsive">
          <table class="table table-striped table-bordered mb-0" id="table1">
            <thead class="thead-light" style={{ borderRadius: 15 }}>
              <tr style={{ fontSize: 14, textAlign: 'center' }}>
                <th align="center">#</th>
                <th align="center">Date</th>
                <th align="center">Student Name</th>
                <th align="center">Student Mobile</th>
                <th align="center">Course</th>
              </tr>
            </thead>
            <tbody>
              {showShimmer ? (
                <td colspan="11">
                  <Shimmer width={'100%'} height={40} />
                </td>
              ) : (
                <>
                  {transactions.map((row, i) => (
                    <TransactionRow row={row} index={i} delTransaction={() => { }} />
                  ))}
                </>
              )}
            </tbody>
          </table>
        </div>
      </div>

      <div className="mt-3">
        <div class="modal-footer">
          {offset > 0 ? (
            <button type="button" class="btn" onClick={() => prePageHandler()}>
              Previous
            </button>
          ) : null}
          {!allDataLoaded && showNextButton ? (
            <button type="button" class="btn " onClick={() => nextPageHandler()}>
              Next
            </button>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default Transactions;
