import React, { useState, useEffect } from 'react';
import { fetchLeadsOverView_institutewise } from '../../../api/leads';
import Chart from '../../../components/charts/chart';
// import FakeChart from './FakeChart';
import { useSelector } from 'react-redux';
// import "./style.css";

const monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"];

const AnaylictsApiCallBack = (response, mode, setBacks) => {
  if (response.status === 200) {
    response.json().then(data => {
      let label = [];
      let dataValues = [];

      switch (mode) {
        case 'monthly':
          data.map(item => {
            label.push(monthNames[(item.x) - 1]);
            dataValues.push(item.y);
          });
          break;
        case 'weekly':
        case 'yearly':
          data.map(item => {
            label.push(item.x);
            dataValues.push(item.y);
          });
          break;
      }

      setBacks.setLeadData({
        labels: label,
        datasets: [{
          label: 'Leads',
          data: dataValues,
          fill: true,
          borderColor: '#FA2918',
          pointBackgroundColor: '#FA2918',
          pointRadius: 5,
          borderWidth: 1,
          backgroundColor: 'rgba(250, 41, 24, 0.2)',
          lineTension: 0.5,

        }]
      });
    });
  }
}

const Leads = props => {
  const [graphMode, setGraphMode] = useState('monthly'); // Initialize with 'monthly'
  const [graphModeDisplayName, setGraphModeDisplayName] = useState('This Year');
  const [dataTime, setDataTime] = useState(new Date().getFullYear());
  const [leadData, setLeadData] = useState([]);
  const [loadingLeadData, setLoadingLeadData] = useState(true);
  const insDetails = useSelector((state) => state.ins.insDetails);
  const insId = insDetails.id;

  useEffect(() => {
    // Fetch data for 'monthly' mode when the component mounts
    fetchLeadsOverView_institutewise(insId, 'monthly', dataTime, (response) => AnaylictsApiCallBack(response, 'monthly', { setLeadData, setLoadingLeadData }));
  }, [insId, dataTime]); // Only run on mount

  useEffect(() => {
    // Fetch data when graphMode or dataTime changes
    fetchLeadsOverView_institutewise(insId, graphMode, dataTime, (response) => AnaylictsApiCallBack(response, graphMode, { setLeadData, setLoadingLeadData }));
  }, [insId, graphMode, dataTime]);

  return (
    <div className="row" style={{marginRight:5,height:430}}>
      <div className="col-12 col-lg-6" style={{height:420}}>
        <div className="card radius-15" style={{ border: 'none', borderRadius: 10 }}>
          <div className="card-body">
            <div className="d-lg-flex align-items-center">
              <p className="mb-0 font-20 font-weight-bold">Leads</p>
              
              <div className="ml-lg-auto mb-2 mb-lg-0">
                <div className="btn-group">
                                  <button
                                      type="button"
                                      className="btn btn"
                                      onClick={() => { setGraphMode('monthly'); setGraphModeDisplayName("Monthly") }}
                                      style={{
                                          background: graphMode === 'monthly' ? '#005cdd' : '#ededed',
                                          color: graphMode === 'monthly' ? 'white' : 'black',  // You can adjust text color based on your needs
                                          border: 'none',
                                      }}
                                  >
                                      Monthly
                                  </button>


                  <button
                    type="button"
                    className="btn btn"
                    onClick={() => { setGraphMode('yearly'); setGraphModeDisplayName("Yearly") }}
                    style={{
                        background: graphMode === 'yearly' ? '#005cdd' : '#ededed',
                        color: graphMode === 'yearly' ? 'white' : 'black',  // You can adjust text color based on your needs
                        border: 'none',
                    }}
                >
                    Yearly
                  </button>
                </div>
              </div>
            </div>
            <p style={{fontWeight:300,fontSize:14,marginTop:-5,marginBottom:20}}>Your Leads Analytics</p>
            <Chart height={300} chartType="bar" chart_counter={1} data={leadData} />
          </div>
        </div>
      </div>
          
      <div className="col-12 col-lg-6" style={{ backgroundColor: 'white',paddingTop:0,height:410 }}>
      <div className="card radius-15" style={{ border: 'none', borderRadius: 10 }}>
          <div className="card-body">
            <div>
              <p className="mb-0 font-20 font-weight-bold">Today Leads</p>
             <p style={{fontWeight:300,fontSize:14,marginTop:-5,marginBottom:20}}>Today Leads Analytics Coming Soon...</p>
              
         
            </div>
            
     <img src='https://i.pinimg.com/originals/cc/fe/1f/ccfe1f7fee5fe18a65127d4ed1f4c036.gif' width={400}></img>
          </div>
        </div>
         </div>
    </div>
  );
};

export default Leads;
