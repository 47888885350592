import React, { useState, useEffect } from 'react'
import { deleteStudent, updateStudentStatus } from '../../../../api/Student'

import { Link } from 'react-router-dom'
import { imageProvider } from '../../../..'

export default function RenderSingleStudent(props) {

    const { row, index } = props
    const [isBlock, setBlockstatus] = useState(row.blocked)




    // const deleteStudentCallback=(response,index) =>{
    //     if(response.status==200){
    //         console.log('data has been delete for student')
    //         // response.json().then(data=>{
    //         //     console.log('deleting Student data', data)
    //         // })
    //         props.deleteAtIndex(index)
    //     } else {
    //         console.log('Ooops! Something went wrong while deleting!')
    //     }
    // }

    // const action4DeleteStudent=(e, name,index)=>{
    //     console.log('yeh id for delete', e)

    //     if(window.confirm('Are you sure to Delete data for '+ name)){

    //         deleteStudent(e, (response)=>deleteStudentCallback(response,index))

    //     } else{
    //         console.log('cancel mission del')
    //     }

    // }


    const updateStudentStatusCallback = (response, status) => {
        console.log(response.status)
        if (response.status == 200) {
            setBlockstatus(status)
        } else {
            console.log('Oops!, something went wrong while change the student status')
        }
    }


    const action4ChangeStudentStatus = (id, name, status) => {
        // value 1 hai to approved hai aur,  0 hai toh block hai

        if (window.confirm('Are you sure to Change the Status for ' + name)) {
            updateStudentStatus(status, id, (response) => updateStudentStatusCallback(response, status))
        } else {
            console.log('cancel mission 4 update student status')
        }

    }

    return (
        <>

{/* <tr style={{ fontSize: 14, backgroundColor: 'white', textAlign: 'left' }}>
                <td>{index + 1}</td>
                <td><img
                    onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = "/assets/images/avatars/avatar-1.png";
                    }} className="image-responsive" style={{ height: 40, width: 40, borderRadius: 45 }}
                    src={imageProvider(row.studentImage)} /> {row.studentName}</td>
                <td>{row.studentEmail}</td>
                <td>{row.studentMobile}</td>


            </tr> */}
            <div style={{width:'250px',height:'300px',backgroundColor:'#F5F5F5',marginBottom:10,marginRight:10,borderRadius:12}}>
                    <div style={{backgroundColor:'#005CDD',height:45,display:'flex',justifyContent:'space-between',paddingTop:10,borderRadius:10}}>
                        <p style={{fontSize:16,backgroundColor:'white',width:30,height:25,borderRadius:45,textAlign:'center',marginLeft:15,fontWeight:700}}>{index + 1}</p>
                        {/* <p style={{marginRight:20}}>{row.studentName}</p> */}
                    </div>
                <div>
                    <p><img
                    onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = "/assets/images/avatars/avatar-1.png";
                    }} className="image-responsive" style={{ height: 100, width: 100, borderRadius: 10,marginTop:15,marginLeft:70 }}
                    src={imageProvider(row.studentImage)} /></p>
                </div>
                <div>
                    <p style={{textAlign:'center',fontSize:16,fontWeight:700}}>{row.studentName}</p>
                </div>
                <div>
                    <p style={{textAlign:'center',fontSize:16,fontWeight:500,color:'#005CDD'}}>{row.studentMobile}</p>
                </div>
                <div>
                    <p style={{textAlign:'center',fontSize:16,fontWeight:400}}>{row.studentEmail}</p>
                </div>
                {/* <div>
                    <p style={{textAlign:'center',fontSize:16,fontWeight:400}}>{row.studentUserid}</p>
                </div> */}
            </div>

        </>
    )
}
