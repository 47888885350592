import React,{ useState, useEffect} from 'react'
import { Link } from 'react-router-dom';
import { serverBaseUrl, theme } from '../../../..';
import ClipLoader from "react-spinners/ClipLoader";
 
function CourseBannerRow(props) {
    const {row,index,delBanner,setEditDetails}=props 
      
    const [ bannerImage,setBannerImage] = useState(serverBaseUrl+""+ row.bannerImageLink);
    const [bannerId,setBannerId] = useState(row.id);
    // const [delLoading, setDelLoading] = useState(false);


    // useEffect(()=>{
    //     setDelLoading(props.delLoading)
    // },[props.delLoading])

    return (
        <div style={{ display: 'flex', flexWrap: 'wrap', padding:'15px' }}>
        <div style={{width:'300px', height:'240px',background: 'linear-gradient(to top, #F8F9FB, transparent)', borderRadius:'25px'}}>
        <img src={serverBaseUrl+row.bannerImageLink} style={{width:'300px', height:'180px', borderRadius:'15px 15px 0px 0px'}}/>
        <div style={{display:'flex', justifyContent:'space-between',margin:10}}>
        {/* <button aria-label="delete" onClick={()=>setEditDetails(serverBaseUrl+row.bannerImageLink)} className="btn btn-primary" style={{backgroundColor:'#005CDD',border:'none', fontSize:13, fontWeight:400,}}>
            EDIT
        </button>  */}
        
        <p aria-label="delete" onClick={()=>{ delBanner(bannerId,index)}} className="btn" style={{outline: 'none',boxShadow: 'none',}}>
           <svg xmlns="http://www.w3.org/2000/svg" height="30" viewBox="0 -960 960 960" width="30" fill='#EA4335' style={{marginRight:4, marginBottom:1}}><path d="M280-120q-33 0-56.5-23.5T200-200v-520h-40v-80h200v-40h240v40h200v80h-40v520q0 33-23.5 56.5T680-120H280Zm80-160h80v-360h-80v360Zm160 0h80v-360h-80v360Z"/> </svg>
        </p> 
        </div>
        </div>
    </div>
    )
}

export default CourseBannerRow
