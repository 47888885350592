import React, { useState, useEffect } from 'react';
import { sendNotification } from '../../api/notification';
import { dataLimit } from '../../index';
import Snackbar from '@material-ui/core/Snackbar';
import { fetch_institute_courses } from '../../api/Courses';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

const notificationUser = [
    "courseStudents"
]

export default function Notification() {
    const [message, setMessage] = useState('');
    const [url, setUrl] = useState('');
    const [title, setTitle] = useState('');
    const [offset, setOffset] = useState(0);
    const [courses, setCourses] = useState([]);
    const [selectedCourse, setSelectedCourse] = useState('');
    const [selectedCourseId, setSelectedCourseId] = useState('');
    const [targetEmail, setTargetEmail] = useState('');
    const [targetUserId, setTargetUserId] = useState('');
    const [targetUserType, setTargetUserType] = useState('');
    const insDetails = useSelector((state) => state.ins.insDetails);
    const history = useHistory();
    const [showProcessing, setShowProcessing] = useState(false);
    const [SnackBarMessage, setSnackBarMessage] = useState(null);
    const [isSnackBarShow, setIsSnackBarShow] = useState(false);

    const handleCourseCallback = (response) => {
        if (response.status === 200) {
            response.json().then((data) => {
                setCourses(data);
            });
        } else {
            setIsSnackBarShow(true);
            setSnackBarMessage('Something Went Wrong. Please Try Again Later.');
        }
    };

    useEffect(() => {
        fetch_institute_courses(insDetails.id, false, handleCourseCallback);
    }, []);

    const setSelectedCourseData = (e) => {
        const index = e.target.selectedIndex;
        const el = e.target.childNodes[index]
        const option = el.getAttribute('id');
        setSelectedCourseId(option);
        setSelectedCourse(e.target.value);
    };

    const sendNotificationCallBack = (response) => {
        if (response.status === 200) {
            setSnackBarMessage('Notifications Sent Successfully');
            setIsSnackBarShow(true);
        } else {
            setSnackBarMessage('No User Enrolled in this Course');
            setIsSnackBarShow(true);
        }
        setShowProcessing(false);
    };

    const closeSnack = () => {
        setIsSnackBarShow(false);
    };

    const send = () => {
        if (
            title &&
            message &&
            (selectedCourseId || targetUserId || targetEmail) &&
            targetUserType
        ) {
            setShowProcessing(true);
            sendNotification(
                '',
                title,
                url,
                message,
                selectedCourseId || targetUserId,
                targetUserType,
                targetEmail,
                sendNotificationCallBack
            );
        } else {
            setSnackBarMessage(
                'Please Fill All The Fields And Make Sure You Have Selected A Category and Target Users!!'
            );
            setIsSnackBarShow(true);
        }
    };

    return (
        <div style={{ fontFamily: 'Outfit, sans-serif' }}>
            <div class="page-breadcrumb d-md-flex align-items-center mb-3">
                <div style={{ fontWeight: 600, fontSize: 24, marginLeft: 5 }}>Send Notification</div>
                <div class="ml-auto">
                    <button type="button" class="btn" style={{ marginRight: 5, }} onClick={() => history.goBack()}>
                        Go Back</button>
                </div>
            </div>
            <div className="card" style={{ backgroundColor: 'white', width: 600, borderRadius: 15 }}>
                <div className="card-body">
                    <div className="row">
                        {targetUserType === 'singleInstitute' || targetUserType === 'singleUser' ? (
                            <>
                                <div className="col-6">
                                    <label>Enter Target Email:</label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        onChange={(e) => setTargetEmail(e.target.value)}
                                    />
                                </div>
                                <div className="col-6">
                                    <label>or Target Unique Id: </label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        onChange={(e) => setTargetUserId(e.target.value)}
                                    />
                                </div>
                            </>
                        ) : null}
                    </div>
                    <div class="form-group">
                        <label>Title:</label>
                        <input type="text" class="form-control" onChange={(e) => setTitle(e.target.value)} />
                    </div>
                    <label htmlFor="exampleFormControlSelect1" className="custom-label" style={{ fontWeight: 600, Color: '#000' }}>
                        Choose Course
                    </label>
                    <select
                        value={selectedCourse}
                        className="form-control custom-select"
                        onChange={(e) => setSelectedCourseData(e)}
                        style={{ height: 60 }}
                    >
                        <option value="">Choose Course</option>
                        {courses.map((row) => (
                            <option value={row.title} key={row.id} id={row.id}>
                                {row.title}
                            </option>
                        ))}
                    </select>

                    <label htmlFor="exampleFormControlSelect1" className="custom-label" style={{ fontWeight: 600, Color: '#000' }}>
                        Choose User
                    </label>
                    <select
                        value={targetUserType}
                        className="form-control custom-select"
                        onChange={(e) => setTargetUserType(()=>e.target.value)}
                        style={{ height: 60 }}
                    >
                        <option value="">Choose User</option>
                        {notificationUser.map((row,index) => (
                            <option value={row} key={index} id={index}>
                                {row}
                            </option>
                        ))}
                    </select>
                    <div class="mb-3">
                        <label for="validationTextarea">Message</label>
                        <textarea
                            class="form-control"
                            id="validationTextarea"
                            required
                            onChange={(e) => setMessage(e.target.value)}
                        ></textarea>
                    </div>
                    {showProcessing ? (
                        <button
                            class="btn"
                            style={{
                                marginRight: 5,
                                backgroundColor: '#005CDD',
                                fontSize: 14,
                                color: '#fff',
                                fontWeight: 400,
                                paddingLeft: 25,
                                paddingRight: 25,
                                border: 'none',
                                borderRadius: 5,
                            }}
                            disabled
                        >
                            Processing...
                        </button>
                    ) : (
                        <button
                            class="btn"
                            style={{
                                marginRight: 5,
                                backgroundColor: '#005CDD',
                                fontSize: 14,
                                color: '#fff',
                                fontWeight: 400,
                                paddingLeft: 25,
                                paddingRight: 25,
                                border: 'none',
                                borderRadius: 5,
                            }}
                            onClick={() => send()}
                        >
                            Send
                        </button>
                    )}
                </div>
            </div>
            <Snackbar
                open={isSnackBarShow}
                onClose={(e) => closeSnack(e)}
                TransitionComponent="TransitionUp"
                message={SnackBarMessage}
            />
        </div>
    );
}
