import React, { useState, useEffect }from 'react'
import { Link } from 'react-router-dom';

function LeadsRow(props) {
    const {row,index} = props; 
    return (
        <>
      
        <div>
            <div style={{display:'flex',justifyContent:'space-between',marginRight:'10px',marginTop:'10px',marginBottom:10,marginLeft:10}}>
           <div style={{width:'10%',backgroundColor:'rgba(233, 236, 239, 0.2)',textAlign:'center',paddingTop:12,borderRadius:5,height:45}}>
            <p style={{fontWeight:600,fontSize:14}}>{index+1}</p>
           </div>
           <div style={{width:'50%',backgroundColor:'rgba(233, 236, 239, 0.2)',textAlign:'center',paddingTop:12,borderRadius:5,height:45}}>
            <p style={{fontWeight:600,fontSize:14}}>{row.courseName}</p>
           </div>
           <div style={{width:'35%',backgroundColor:'rgba(233, 236, 239, 0.2)',textAlign:'center',paddingTop:12,borderRadius:5,height:45}}>
            <p style={{fontWeight:600,fontSize:14}}> {row.leads}</p>
           </div>
           </div>
            
        </div>
        </>
    )
}

export default LeadsRow
