import React, { useState, useEffect } from 'react';
import { validateLogin } from '../../api/login';
import { useDispatch, useSelector } from 'react-redux';
import { SET_AUTH_STATUS, SET_INSTITUTE_DETAILS } from '../../Reducers/types';
import { Link, Redirect, useHistory } from 'react-router-dom';
import ClipLoader from "react-spinners/ClipLoader";
import { theme } from '../..';
import ForgotPassword from '../../Pages/ForgotPassword/ForgotPassword';
import Signup from '../../Pages/signup/signup';

const Login = (props) => {
    const [phone, setPhone] = useState("");
    const [error, setError] = useState(null);
    const [redirect, setRedirect] = useState(false);
    const [loading, setLoading] = useState(false);
    const [mode, setMode] = useState("login");
    const [successMessage, setSuccessMessage] = useState(""); // State for success message
    const dispatch = useDispatch();
    const history = useHistory();
    const authStatus = useSelector((state) => state.ins.authStatus);

    const loginCallBack = (response) => {
        if (response.status === 200) {
            // Show success message
            setSuccessMessage("OTP sent successfully!");
            setTimeout(() => {
                setSuccessMessage("");
                history.push("/otp");
                setLoading(false);
            }, 3000); // Hide the success message after 3 seconds

        } else if (response.status === 400) {
            response.text().then((data) => {
                setLoading(false);
                setError(data);
                setMode("signup");
            });
        } else {
            response.json().then((data) => {
                console.log(data);
            });
        }
    };

    const login = () => {
        if (validateLoginData()) {
            if (!loading) {
                setLoading(true);
                setError('');
                validateLogin(`91${phone}`, loginCallBack);
            }
        } else {
            setError("Please Fill All The Fields.");
        }
    };

    const validateLoginData = () => phone !== "";

    const renderLoginForm = () => {
        return (
            <>
                <div
                    style={{
                        background: '#fff',
                        padding: '20px',
                        borderRadius: '10px',
                        fontFamily: 'Outfit, sans-serif',
                    }}
                >
                    <div style={{ width: 400, height: 500 }}>
                        <p style={{ color: '#fff', fontSize: 18, fontWeight: 600, backgroundColor: '#005CDD', marginTop: -33, marginLeft: 1, marginRight: 90, textAlign: 'center', borderRadius: 25 }}>Hi, Educators</p>
                        <p style={{ color: '#212121', fontSize: 24, fontWeight: 600, marginTop: 100 }}>Join AllCoaching</p>
                        <p style={{ color: '#212121', fontSize: 16 }}>Access your account to explore our services and features. Let's get started!</p>
                        <p style={{ color: '#212121', fontSize: 28, fontWeight: 800 }}> <input type="tel" className="form-control" style={{ color: 'black', fontSize: 16 }} maxLength={12} placeholder="Enter Your Mobile Number" onChange={(e) => setPhone(e.target.value)}  onKeyPress={(e) => {
        if (e.key === 'Enter') {
            login();
        }
    }}/></p>
                        <p style={{ color: '#FA2918', fontSize: 14, fontWeight: 400 }}> {error ? (
                            <span style={{ color: '#FA2918', fontSize: 12, fontWeight: 500 }}>{error}</span>
                        ) : (null)}</p>
                        <button
                            style={{
                                backgroundColor: '#005CDD',
                                border: 'none',
                                borderRadius: 5,
                                padding: 8,
                                alignSelf: 'center',
                                paddingLeft: 170,
                                paddingRight: 170,
                                transition: 'background-color 0.4s, transform 0.4s',
                                marginLeft: 8,
                                fontSize: 16,
                                color: '#fff',
                            }}
                            onClick={() => login()}
                        >
                            {loading ? (
                                <ClipLoader color={theme.primaryColor} loading={loading} />
                            ) : ('Log In')}
                        </button>
                        <p style={{ color: '#212121', fontSize: 14, fontWeight: 400, textAlign: 'center', marginTop: 5 }}>
                            Having trouble? Write us at <a href="mailto:hello@allcoaching.in" style={{ color: '#005CDD', textDecoration: 'underline' }}>hello@allcoaching.in</a>
                        </p>
                        {successMessage && (
                            <div style={{ color: 'green', textAlign: 'center' }}>{successMessage}</div>
                        )}
                    </div>
                </div>
            </>
        );
    };

    return (
        <div className="section-authentication-login d-flex align-items-center justify-content-center" style={{ backgroundColor: '#fff' }}>
            <div className="row">
                <div className="col-12 col-lg-10 mx-auto">
                    <div className="card radius-15">
                        <div className="row no-gutters">
                            <div className="col-lg-6">
                                {mode === "login" ? (
                                    renderLoginForm()
                                ) : (
                                    <Signup
                                        setMode={setMode}
                                        phone={phone}
                                    />
                                )}
                            </div>
                            <div className="col-lg-6">
                                <img src="https://img.freepik.com/free-vector/privacy-policy-concept-illustration_114360-7853.jpg?w=740&t=st=1695498533~exp=1695499133~hmac=134c901fe2c7f40d7a4ca4d5f469c3d58f1ab8955848b35adb19dc5c2f4ad307" className="card-img login-img h-100" alt="..." />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;
