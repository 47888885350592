import React from 'react'
import { useState, useEffect } from 'react'
import { deleteCourse, editCourses, fetch_institute_courses, add_institute_courses, deleteCoursePermanently } from
    '../../api/Courses'
import { serverBaseUrl, theme } from '../../index'
import "./style.css"
import CourseRow from './CourseRow'
import { Image, Shimmer } from 'react-shimmer'
import { useSelector } from 'react-redux'
import { Link, useHistory } from "react-router-dom"
import Modal, { ModalBody, ModalHeader, ModalFooter } from '../../components/modal/modal'
import Snackbar from '@material-ui/core/Snackbar';
import { useParams } from 'react-router'
import ClipLoader from "react-spinners/ClipLoader";
import { addStudent, checkStudentEnroll, enrollStudent, getStudentByUserId } from '../../api/Student'



function InstitueCourse(props) {
    const [courses, setCourses] = useState([])
    const [addCourses, setAddCourses] = useState([])
    const [showShimmer, setShowShimmer] = useState(true)
    const [isCourseModalVisible, setIsCourseModalVisible] = useState(false);
    const [title, setTitle] = useState();
    const [fees, setFees] = useState();
    const [description, setDescription] = useState();
    const [index, setIndex] = useState();
    const [id, setId] = useState();
    const [courseId, setCourseId] = useState();
    const [mode, setMode] = useState('');
    const insDetails = useSelector((state) => state.ins.insDetails)
    let insId = insDetails.id
    const { isDeleted } = useParams();
    const history = useHistory();
    const [SnackBarMessage, setSnackBarMessage] = useState("")
    const [isSnackBarShow, setIsSnackBarShow] = useState(false)
    const [delLoading, setDelLoading] = useState(false)
    const [addLoading, setAddLoading] = useState(false)
    const [addStudentModalVisible, setAddStudentModalVisible] = useState(false);
    const [studentName, setStudentName] = useState("")
    const [studentEmail, setStudentEmail] = useState("")
    const [studentMobileNumber, setStudentMobileNumber] = useState("")
    const [stateOfStudent, setStateOfStudent] = useState("")
    const [userId, setUserId] = useState("")
    const [addStudentLoading, setAddStudentLoading] = useState(false)
    const [studentCourse, setStudentCourse] = useState(null)
    const [sortBy, setSortBy] = useState()
    // Add these state variables at the beginning of your component
    const [isPaymentPopupVisible, setIsPaymentPopupVisible] = useState(true);
    const [paymentLink, setPaymentLink] = useState("true");

    const indianStates = [
        "Andaman and Nicobar Islands",
        "Andhra Pradesh",
        "Arunachal Pradesh",
        "Assam",
        "Bihar",
        "Chandigarh",
        "Chhattisgarh",
        "Dadra and Nagar Haveli",
        "Daman and Diu",
        "Delhi",
        "Goa",
        "Gujarat",
        "Haryana",
        "Himachal Pradesh",
        "Jammu and Kashmir",
        "Jharkhand",
        "Karnataka",
        "Kerala",
        "Ladakh",
        "Lakshadweep",
        "Madhya Pradesh",
        "Maharashtra",
        "Manipur",
        "Meghalaya",
        "Mizoram",
        "Nagaland",
        "Odisha",
        "Puducherry",
        "Punjab",
        "Rajasthan",
        "Sikkim",
        "Tamil Nadu",
        "Telangana",
        "Tripura",
        "Uttar Pradesh",
        "Uttarakhand",
        "West Bengal"
    ]
    const [courseDuration, setCourseDuration] = useState(1); // Initial value: 1 day

    // Generate an array of numbers from 1 to 1000
    const durationOptions = Array.from({ length: 1000 }, (_, index) => index + 1);

    // Handle change for course duration dropdown
    const handleDurationChange = (e) => {
        setCourseDuration(parseInt(e.target.value, 10));
    };
    const addCoursesCallback = (response) => {
        setAddLoading(false)
        if (response.status === 201) {
            let courseId = response.headers.get('location')
            var obj = {
                description: description,
                fees: fees,
                id: courseId,
                instId: insId,
                leads: 0,
                title: title,
                sortBy: sortBy,
                expireDay: courseDuration
            }
            let coursesLocal = [...courses];
            coursesLocal.push(obj)
            setIsCourseModalVisible(false);
            setCourses(coursesLocal);
            setSnackBarMessage("Course Added Successfully")
            setIsSnackBarShow(true)

        } else {
            setSnackBarMessage("Something went wrong")
            setIsSnackBarShow(true)
        }
        setIsCourseModalVisible(false)
    }
    const addCourse = () => {
        if (window.confirm("Are You Sure You Want To Add?")) {
            setAddLoading(true)
            add_institute_courses(insId, title, fees, description, sortBy, courseDuration, addCoursesCallback)
        }
    }


    useEffect(() => {
        setShowShimmer(true)
        fetch_institute_courses(insId, isDeleted, coursesCallBack)
    }, [insId, isDeleted])

    const coursesCallBack = (response) => {
        setShowShimmer(false)
        if (response.status === 200) {
            response.json().then((data) => {

                console.log("courses data")
                console.log(data)
                setCourses(data)
            })
        }
    }

    const delCourse = (id, status, index, mode, loaderFun) => {
        if (window.confirm("Are you sure you want to delete?")) {
            if (loaderFun) {
                loaderFun();
            }

            if (mode == "permanently") {
                deleteCoursePermanently(id, (response) => deleteCourseCallBack(response, index))
            } else {
                deleteCourse(id, status, (response) => deleteCourseCallBack(response, index))
            }

        }
    }

    const recoverCourse = (id, status, index, mode, loaderFun) => {
        if (window.confirm("Are you sure you want to recover?")) {
            if (loaderFun) {
                loaderFun();
            }

            if (mode == "permanently") {
                deleteCoursePermanently(id, (response) => deleteCourseCallBack(response, index))
            } else {
                deleteCourse(id, status, (response) => deleteCourseCallBack(response, index))
            }

        }
    }

    const deleteCourseCallBack = (response, index) => {
        setDelLoading(false)
        if (response.status === 200) {
            let arr = [...courses]
            arr.splice(index, 1)
            setCourses(arr)
            setSnackBarMessage("Course Deleted Successfully")
            setIsSnackBarShow(true)
            window.location.reload();
        }
        else {
            setSnackBarMessage("Something went wrong")
            setIsSnackBarShow(true)
            console.log("unable to delete");
        }
    }

    const editCourseDetails = () => {
        if (window.confirm("Are You Sure You Want To Edit?")) {
            setAddLoading(true)
            editCourses(insId, title, fees, description, courseId, sortBy, courseDuration, editCourseCallBack)
        }
    }

    const editCourseCallBack = (response) => {
        setAddLoading(false)
        if (response.status === 201) {
            let arr = [...courses]
            arr[index] = {
                description: description,
                fees: fees,
                id: courseId,
                instId: insId,
                leads: 0,
                title: title,
                sortBy: sortBy,
                expireDay: courseDuration
            }
            setCourses(arr)
            setSnackBarMessage("Course Details Updated Successfully")
            setIsSnackBarShow(true)
            window.location.reload();
        } else {
            setSnackBarMessage("Something went wrong")
            setIsSnackBarShow(true)
        }
        setIsCourseModalVisible(false)
    }

    const setEditValues = (id, title, description, fees, index) => {
        setMode('edit'); setTitle(title); setDescription(description); setFees(fees); setCourseId(id); setIndex(index);
        setIsCourseModalVisible(true)
    }

    const setAddValues = () => {
        setMode('add'); setTitle(''); setDescription(''); setFees(''); setCourseId(''); setIndex(''); setSortBy('')
        setIsCourseModalVisible(true)
    }
    const setEditDetails = (title, description, fees, index, id, sortBy) => {
        setTitle(title)
        setDescription(description)
        setFees(fees)
        setIndex(index)
        setCourseId(id)
        setSortBy(sortBy)
    }
    const editHandler = () => {
        mode == "add" ? (addCourse()) : (editCourseDetails())
    }

    const closeSnack = () => {
        setIsSnackBarShow(false)
    }

    const addStudentBtnHandler = (e) => {
        e.preventDefault()
        setAddStudentLoading(true)
        getStudentByUserId(userId, (response) => {
            response.json().then((data) => {
                if (data === null) {
                    setSnackBarMessage("User Id does not exists!")
                    setAddStudentLoading(false)
                    setIsSnackBarShow(true)
                }
                else {
                    if (studentCourse) {
                        checkStudentEnroll(studentCourse, data.id, (response) => {
                            if (response.status === 200) {
                                response.json().then((datas) => {
                                    if (datas === true) {
                                        setIsSnackBarShow(true)
                                        setSnackBarMessage("Student Already Enrolled in this Course")
                                    }
                                    else {
                                        enrollStudent(data.id, studentCourse, insDetails.id, 1, (response) => {
                                            if (response.status == 201) {
                                                setAddStudentModalVisible(false)
                                                setIsSnackBarShow(true)
                                                setSnackBarMessage("Student Added Successfully")
                                                setStudentName("")
                                                setStudentEmail("")
                                                setStateOfStudent("")
                                                setStudentCourse("")
                                                setStudentMobileNumber("")
                                                setUserId("")
                                            }
                                        })
                                    }
                                })
                            }
                            else {
                                setAddStudentLoading(false)
                                setIsSnackBarShow(true)
                                setSnackBarMessage("Something went wrong")
                            }

                        })

                    } else {
                        setIsSnackBarShow(true)
                        setSnackBarMessage("Please select a course to enroll")
                    }
                    setAddStudentLoading(false)
                }
            }).catch((error) => {
                setAddStudentLoading(false)
                setIsSnackBarShow(true)
                setSnackBarMessage("Something went wrong")
            })
        })
        /*addStudent(studentEmail, studentName, stateOfStudent, studentMobileNumber, userId, (response) => {

            if (response.status == 201) {
                if (studentCourse) {
                    const studentId = response.headers.get('location')

                    enrollStudent(studentId, studentCourse, insDetails.id, (response) => {
                        if (response.status == 201) {
                            setAddStudentModalVisible(false)
                            setIsSnackBarShow(true)
                            setSnackBarMessage("Student Added Successfully")
                            setStudentName("")
                            setStudentEmail("")
                            setStateOfStudent("")
                            setStudentCourse("")
                            setStudentMobileNumber("")
                            setUserId("")
                        }
                    })
                } else {
                    setIsSnackBarShow(true)
                    setSnackBarMessage("Student Added Successfully But Failed To Add Course")
                }
                setAddStudentLoading(false)


            } else {

                response.json().then(data => {
                    if (data.message?.includes("ConstraintViolationException")) {
                        setSnackBarMessage("Email or Mobile Number Already Exists")
                    } else {
                        setSnackBarMessage("Something went wrong")
                    }
                    setAddStudentLoading(false)
                    setIsSnackBarShow(true)
                })

            }
        })*/

    }
    const handleTitleChange = (e) => {
        const inputValue = e.target.value;

        // Limit the title to 100 characters
        if (inputValue.length <= 100) { setTitle(inputValue); }
    }; return (<div style={{ fontFamily: 'Outfit, sans-serif' }}>
        <div class="page-breadcrumb  d-md-flex align-items-center mb-3">

            <div style={{ fontWeight: 600, fontSize: 24 }}>Course Section</div>
            <div class="ml-auto">

                <div class="btn-group">

                    {isDeleted !== "true" ? (
                        <>
                            <div><button type="button" style={{ marginRight: 5, background: 'linear-gradient(45deg, #FFD700, #FFA500)', fontSize: 10, color: '#000', border: 'none', marginBottom: -5, borderRadius: 2, position: 'absolute', top: '-30%', right: '53px', transform: 'translateY(-50%)' }} > Premium</button></div>
                            <button type="button" class="btn" style={{ marginRight: 5, backgroundColor: '#005CDD', fontSize: 14, color: '#fff' }} onClick={() =>
                                setAddStudentModalVisible(true)}> Add Student</button>
                        </>
                    ) : (null)}
                </div>
                <button type="button" class="btn" style={{ marginRight: 5, }} onClick={() => history.goBack()}>
                    Go Back</button>
            </div>
        </div>
        <div className="row mt-3">

            {showShimmer ? (
                <>
                    <div className="col-12 col-lg-4 col-md-4 col-sm-4" style={{ width: '100%' }}>
                        <Shimmer width={'100%'} height={40} />
                    </div>
                </>
            ) : (
                <>
                    {courses.map((row, i) => (
                        <CourseRow row={row} index={i} setEditDetails={setEditDetails} delCourse={delCourse}
                            recoverCourse={recoverCourse}
                            setIsCourseModalVisible={setIsCourseModalVisible} setMode={setMode}
                            setEditCourseId={setCourseId} delLoadings={delLoading} />
                    ))}
                </>
            )}
            {isDeleted !== "true" ? (
                <div className={"m-2 "} style={{ borderRadius: 5, paddingRight: "10px", backgroundColor: "#EDEDED", width: "300px", height: "280px", flexDirection: 'column', marginBottom: "0px", borderRadius: '5px', cursor: 'pointer' }} onClick={() => setAddValues()} >

                    <svg xmlns="http://www.w3.org/2000/svg" height="120" viewBox="0 -960 960 960" width="120" fill='#fff' style={{ marginRight: 4, marginBottom: 1, marginTop: 30, marginLeft: 90 }}>
                        <path d="M200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h360v80H200v560h560v-360h80v360q0 33-23.5 56.5T760-120H200Zm120-160v-80h320v80H320Zm0-120v-80h320v80H320Zm0-120v-80h320v80H320Zm360-80v-80h-80v-80h80v-80h80v80h80v80h-80v80h-80Z" />

                    </svg>
                    <button style={{ marginLeft: 90, marginTop: 60, padding: 8, borderRadius: 5, paddingLeft: 12, paddingRight: 12, backgroundColor: '#005CDD', color: 'white', border: 'none' }}>Create Course</button>
                </div>

            ) : (null)}
            {/* </tbody>
            </table>
        </div>*/}
        </div>

        <Modal visible={isCourseModalVisible} setModalVisible={setIsCourseModalVisible} modalId={"courseModal"}>
            <ModalHeader>
                <h5 className="modal-title">{mode === "add" ? ("Add Course") : ("Edit Course")}</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close"><svg xmlns="http://www.w3.org/2000/svg" height="30" viewBox="0 -960 960 960" width="30" fill='black' style={{ marginRight: 4, marginBottom: 1 }}><path d="M256-227.692 227.692-256l224-224-224-224L256-732.308l224 224 224-224L732.308-704l-224 224 224 224L704-227.692l-224-224-224 224Z" /></svg>
                </button>
            </ModalHeader>
            <ModalBody>
                <form>
                    <div className="form-row">
                        <label>Course Title</label>
                        <input required className="form-control" value={title} placeholder="Title (max 100 characters)"
                            onChange={handleTitleChange} />

                    </div>
                    <div className="form-row">
                        <label>Price</label>
                        <input
                            required
                            className="form-control"
                            value={fees}
                            placeholder="Enter Course Price"
                            onChange={(e) => {
                                // Remove non-numeric characters (except for a dot if it's a decimal separator)
                                const sanitizedValue = e.target.value.replace(/[^0-9.]/g, '');

                                // Convert the sanitized value to a number
                                const numericValue = parseFloat(sanitizedValue);

                                // Check if numericValue is a valid number
                                if (!isNaN(numericValue)) {
                                    // Format the number as Indian Rupees (INR)
                                    const formattedValue = numericValue.toLocaleString('en-IN', {
                                        style: 'currency',
                                        currency: 'INR', // Currency code for Indian Rupees
                                        minimumFractionDigits: 0, // Number of decimal places
                                    });

                                    // Set the formatted value in the state
                                    setFees(numericValue);
                                } else {
                                    // If the input is not a valid number, set it as is
                                    setFees(sanitizedValue);
                                }
                            }}
                        />
                    </div>
                    <div className="form-row" style={{ marginTop: 10, position: 'relative' }}>
                        {fees && (
                            <>
                                <input
                                    className="form-control"
                                    value={(fees * 0.9).toLocaleString('en-IN', {
                                        style: 'currency',
                                        currency: 'INR',
                                        minimumFractionDigits: 0,
                                    })}
                                    readOnly
                                    style={{ textAlign: 'right' }}
                                />
                                <span style={{ position: 'absolute', left: '10px', top: '50%', transform: 'translateY(-50%)', color: '#000', fontSize: '14px' }}>
                                    Course Price after Platform Fee:
                                </span>
                            </>
                        )}
                    </div>






                    <div className="form-row">
                        <label>Order Courses</label>
                        <input className="form-control" type='number' required value={sortBy} min={0} placeholder="Add Your Value 0 - 100" onChange={(e) => {
                            setSortBy(parseInt(e.target.value))
                        }} />
                    </div>
                    <div className="form-row">
                        <label>Course Duration</label>
                        <select
                            className="form-control"
                            value={courseDuration}
                            onChange={handleDurationChange}
                            required
                        >
                            {durationOptions.map((value) => (
                                <option key={value} value={value}>
                                    {value} Day{value !== 1 ? 's' : ''} {/* Pluralize "day" if more than 1 */}
                                </option>
                            ))}
                        </select>
                    </div>

                </form>
            </ModalBody>
            <ModalFooter>

                <button type="button" className="btn" data-dismiss="modal">Close</button>
                <button type="button" className="btn" style={{ backgroundColor: '#005CDD', color: 'white' }} onClick={() => editHandler()}>{addLoading ? (
                    <ClipLoader color={theme.primaryColor} loading={addLoading} />) : (mode === "add" ? ("Add Course") : ("Edit Course"))}</button>

            </ModalFooter>
        </Modal>

        <Modal visible={addStudentModalVisible} setModalVisible={setAddStudentModalVisible} modalId={"addStudentModal"}>
            {!insDetails.premium ? (

                <div
                    style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100vw',
                        height: '100vh',
                        backgroundColor: 'rgba(0, 0, 0, 0.4)',
                        backdropFilter: 'blur(5px)', // Apply the blur effect
                        zIndex: 9999,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        transition: 'opacity 0.3s ease', // Add transition for opacity
                        opacity: addStudentModalVisible ? 1 : 0, // Show or hide based on the modal visibility
                        pointerEvents: addStudentModalVisible ? 'auto' : 'none',
                    }}
                >
                    <div
                        style={{
                            background: '#fff',
                            padding: '20px',
                            borderRadius: '10px',
                            border: '2px solid #EF953E',
                            fontFamily: 'Outfit, sans-serif', // Added a comma to separate font family names
                            // textAlign: 'center',
                        }}
                    >
                        <div style={{ width: 400, height: 450 }}>
                            <p style={{ color: '#212121', fontSize: 12, fontWeight: 500, background: 'linear-gradient(45deg, #FFD700, #FFA500)', marginTop: -30, marginLeft: 140, marginRight: 140, textAlign: 'center', borderRadius: 25 }}>Premium Feature</p>
                            <p style={{ color: '#212121', fontSize: 24, fontWeight: 600 }}>Hi, {insDetails.name}</p>
                            <p style={{ color: '#212121', fontSize: 28, fontWeight: 800 }}>Add Your Offline Student</p>
                            <p style={{ color: '#212121', fontSize: 16 }}>Our premium feature allows you to add your offline students with ease, providing you with a range of benefits:</p>
                            <ul class="custom-list">
                                <li>Integrate your offline teaching effortlessly</li>
                                <li>Effortless Student Onboarding</li>
                                <li>Streamlined Communication</li>
                                <li>Performance Tracking</li>
                                <li>Exclusive Insights</li>
                            </ul>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <a type="button" data-dismiss="modal" aria-label="Close">
                                    <button
                                        style={{
                                            background: 'linear-gradient(45deg, #005CDD, #243FD6)',
                                            border: 'none',
                                            borderRadius: 5,
                                            fontSize: 16,
                                            padding: 10,
                                            alignSelf: 'center',
                                            paddingLeft: 70,
                                            paddingRight: 70,
                                            transition: 'background-color 0.4s, transform 0.4s',
                                            marginLeft: 4,
                                            marginTop: 25,
                                            color: '#fff'
                                        }}
                                        onMouseEnter={(e) => {
                                            e.target.style.backgroundColor = '#0650B8'; // Change background color on hover
                                            e.target.style.transform = 'scale(1.1)'; // Scale the button on hover
                                        }}
                                        onMouseLeave={(e) => {
                                            e.target.style.backgroundColor = '#005CDD'; // Change back to the original background color
                                            e.target.style.transform = 'scale(1)'; // Reset scale on hover out
                                        }}
                                    >Close
                                    </button></a>
                                <a href={serverBaseUrl+`/checkout/premium/${insDetails.id}`} target='_blank'>
                                    <button
                                        style={{
                                            background: 'linear-gradient(45deg, #FFD700, #FFA500)',
                                            border: 'none',
                                            borderRadius: 5,
                                            fontSize: 16,
                                            padding: 10,
                                            alignSelf: 'center',
                                            paddingLeft: 70,
                                            paddingRight: 70,
                                            transition: 'background-color 0.4s, transform 0.4s',
                                            marginLeft: 4,
                                            marginTop: 25,
                                            color: '#000',
                                            fontWeight: 500
                                        }}
                                        onMouseEnter={(e) => {
                                            e.target.style.backgroundColor = '#0650B8'; // Change background color on hover
                                            e.target.style.transform = 'scale(1.1)'; // Scale the button on hover
                                        }}
                                        onMouseLeave={(e) => {
                                            e.target.style.backgroundColor = '#005CDD'; // Change back to the original background color
                                            e.target.style.transform = 'scale(1)'; // Reset scale on hover out
                                        }}
                                    >₹2,999
                                    </button></a>
                            </div>

                        </div>
                    </div>
                </div>
            ) : (
                <form onSubmit={addStudentBtnHandler}>
                    <ModalHeader>
                        <h5 className="modal-title">{"Add Student"}</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close"> <span
                            aria-hidden="true">&times;</span>
                        </button>
                    </ModalHeader>
                    <ModalBody>
                        <div className="form-row">
                            <label>Student User Id</label>
                            <input className="form-control" value={userId} placeholder="User Id" onChange={(e) =>
                                setUserId(() => e.target.value)} maxLength={20} minLength={5} />
                        </div>
                        <div className="form-row">
                            <label>Select Course to Enroll</label>
                            <select required class=" form-control" onChange={(e) => setStudentCourse(e.target.value)}
                                value={studentCourse}>
                                <option value={-1}>Select Course</option>
                                {courses.map((item) => (
                                    <option value={item.id}>{item.title}</option>
                                ))}
                            </select>
                        </div>

                    </ModalBody>
                    <ModalFooter>

                        <button type="button" className="btn" data-dismiss="modal">Close</button>
                        <button className="btn" style={{ backgroundColor: '#005CDD', color: 'white' }}>{addStudentLoading ? (
                            <ClipLoader color={theme.primaryColor} loading={addStudentLoading} />) : ("Add Student")}</button>

                    </ModalFooter>
                </form>
            )}
        </Modal>
        <Snackbar open={isSnackBarShow} onClose={(e) => closeSnack(e)}
            TransitionComponent="TransitionUp"
            message={SnackBarMessage}
        />
    </div>
    )
}

export default InstitueCourse