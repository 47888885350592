import React, { Component } from 'react'
// import MenuIcon from 'images/menu.svg'
import onClickOutside from 'react-onclickoutside'
import "./style.css"
class Menu extends Component {
  constructor() {
    super()
    this.state = {
      open: false
    }
  }
  handleToggle = () => {
    this.setState({ open: !this.state.open })
    // window.document.addEventListener('click', this.close, false)
  }
  close = () => {
    this.setState({ open: false })
    window.document.removeEventListener('click', this.close, false)
  }
  handleClickOutside = ev => {
    this.close()
  }
  componentWillReceiveProps(nextProps) {
    this.close()
  }
  render() {
    const status = this.state.open ? 'open' : 'close'
    const MenuIcon = 'https://img.icons8.com/?size=512&id=16252&format=png'
    return (
      <span className='menu-wrapper'>
        <a onClick={this.handleToggle}>
        <button aria-label="delete"  className="btn" style={{ fontSize: 12,  width: 50, height: 40, paddingLeft: 12, color: '#EA4335', paddingTop: 5, fontWeight: 600, marginTop: 5,marginRight:50,marginTop:10,backgroundColor:'#ededed',marginLeft:10 }}>
             <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill='#3C4852'><path d="M200-200h56l345-345-56-56-345 345v56Zm572-403L602-771l56-56q23-23 56.5-23t56.5 23l56 56q23 23 24 55.5T829-660l-57 57Zm-58 59L290-120H120v-170l424-424 170 170Zm-141-29-28-28 56 56-28-28Z"/></svg>
          </button>
        </a>
        <div className={`menu menu-${status}`}>
          {this.props.children}
        </div>
      </span>
    )
  }
}

export default onClickOutside(Menu)
