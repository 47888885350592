import React, { useState, useEffect }from 'react'
import {getInsRevenueCourseData} from '../../../api/revenue'
import ExportToExcel from '../../Dashboard/ExportToExcel'
import RevData from './RevData'

import ClipLoader from "react-spinners/ClipLoader";
function CourseWiseRevenue(props) {
    const {insId} = props   
    const [courseData, setCourseData] = useState([]) 
    const [studentData, setStudentData] = useState([]) 
    const [offset, setOffset] = useState(0) 
    
    const [exportLoading,setExportLoading]= useState(false)
    
    useEffect(() => {getInsRevenueCourseData(insId,getInsRevenueCourseDataCallBack)},[insId])

    const getInsRevenueCourseDataCallBack=(response)=>{
        if(response.status === 200)
        {
            response.json().then(data=>{
                // console.log("course rev data", data)
                setCourseData(data)
            })
        }
    }

    

    return(
        <div className="card" style={{border:'none'}}>
          <div className="card-body">
              <div className="row">
                  <div className="ml-3 ">
                      <h5 style={{fontWeight:700,marginLeft:5}}>Course Wise Revenue</h5>
                  </div>
                  {courseData.length?(<div className="ml-auto mr-3" >
                    <button className="btn " onClick={()=>setExportLoading(true)} style={{ backgroundColor: '#005CDD', color: 'white' }}> 
                        {exportLoading?(
                            <ClipLoader size={20} loading={exportLoading} color="#fff"/>
                        ):("Export Data")}
                        </button>
                    </div>):(null)}
                  {exportLoading?(
                      <ExportToExcel 
                        stopLoadingInParent={setExportLoading}
                      />
                  ):(null)}
              </div>
            {courseData&&courseData.map((row, index) => (
              <div id="accordion1" class="accordion" style={{backgroundColor:'rgba(233, 236, 239, 0.5)',marginTop:10,paddingBottom:10,borderRadius:10,paddingTop:10}}>
                  <div className="card-header collapsed" data-toggle="collapse" href={"#collapse"+index} style={{border:'none'}}>	
                      <a className="card-title" style={{fontWeight:600}}>
                          {row.course.title}
                      </a>
                  </div>
                  <div id={"collapse"+index} className="card-body collapse" data-parent="#accordion1">
                      <RevData courseId={row.course.id} todayRevenue={row.todayRevenue} totalRevenue={row.totalRevenue}/>
                  </div>
                </div>
            ))}
          </div>
        </div>
    )
}
export default CourseWiseRevenue
