import React, { useState, useEffect } from 'react';
import { adminRevenueCategoryWise, getOverAllRevenueCount, getInsDataCategoryWise } from '../../api/dashboard'
import { fetchRevenueCountInstituteWise } from '../../api/revenue'
import { Image, Shimmer } from 'react-shimmer'
import Leads from '../Analytics/Leads'
import NewFakeChart from '../Analytics/NewFakeChart'
import Revenue from '../Analytics/Revenue'
import { useSelector } from 'react-redux'
import CourseWiseRevenue from '../Institute/InstituteRevenue/CourseWiseRevenue'
import RevenueModeWiseGraph from '../Institute/InstituteRevenue/RevenueModeWiseGraph'
import { Link, useHistory } from "react-router-dom"
import { fetch_institute_courses } from '../../api/Courses';

const Dashboard = props => {
  const history = useHistory();
  const [todayCount, setTodayCount] = useState("")
  const [totalCount, setTotalCount] = useState("")
  const [totalCourse, setTotalCourse] = useState(0);
  const [categoryData, setCategoryData] = useState([])
  const [insData, setInsData] = useState([])
  const [showInsShimmer, setShowInsShimmer] = useState(true)
  const [showCategoryShimmer, setShowCategorySimmer] = useState(true)
  const [showCountShimmer, setshowCountShimmer] = useState(true)
  const insDetails = useSelector((state) => state.ins.insDetails)
  const [greetingText, setGreetingText] = useState('');


  let insId = insDetails.id

  useEffect(() => {
    fetchRevenueCountInstituteWise(insId, fetchRevenueCountInstituteWiseCallBack)
    fetch_institute_courses(insId, false, (response) => {
      if (response.status == 200) {
        response.json().then(data => setTotalCourse(() => data.length)).catch(error => console.log(error))
      }

    })
  }, [])

  const fetchRevenueCountInstituteWiseCallBack = (response) => {
    if (response.status == 200) {
      response.json().then(data => {
        setTodayCount(data.today)
        setTotalCount(data.total)
        setshowCountShimmer(false)
      })
    }
    else {
      console.log("rev count", response.status)
    }
  }

  const getInsDataCategoryWiseCallBack = (response) => {
    if (response.status == 200) {
      response.json().then(data => {
        setShowInsShimmer(false)
        setInsData(data)
      })
    }
    else {
      console.log("insdataCategory", response.status)
    }
  }
  useEffect(() => {
    // Get the current hour
    const currentHour = new Date().getHours();

    // Define different greetings based on the time of the day
    let greeting = '';
    if (currentHour >= 5 && currentHour < 12) {
      greeting = 'Good Morning';
    } else if (currentHour >= 12 && currentHour < 17) {
      greeting = 'Good Afternoon';
    } else {
      greeting = 'Good Evening';
    }

    // Update the state with the greeting text
    setGreetingText(`${greeting}`);
  }, []);

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>
          <h2 style={{ fontSize: 24, color: '#8d8d8d' }}>
            {greetingText && (
              <>
                {greetingText}, <span style={{ color: '#3C4852', fontWeight: 700 }}>{insDetails?.directorName}</span>
              </>
            )}
          </h2>
          <p style={{ color: '#8d8d8d', fontSize: 16, fontWeight: 300 }}>Your performance summary</p>
        </div>
        <div>
          <Link to={"/instituteProfile"}>
            <button className="btn" style={{ backgroundColor: '#005CDD', color: 'white' }}>See Profile</button>
          </Link>
          <button type="button" class="btn" style={{ marginRight: 5, marginLeft: 10 }} onClick={() => history.goBack()}>
            Go Back</button>
        </div>

      </div>

      {showCountShimmer ? (
        <Shimmer width={'100%'} height={'10%'} />
      ) : (
        <div className="row" style={{ marginTop: 10 }}>


          <div className="col-12 col-lg-3">
            <div className="card radius-15" style={{ background: 'linear-gradient(to right, #FED86F, #FED86F, #FED86F)', border: 'none', borderRadius: 10 }}>
              <div className="card-body">
                <div className="media align-items-center">
                  <div className="media-body">
                    <p className="mb-0" style={{ fontWeight: 500, }}>Today Earning</p>
                    <h4 className="mb-0" style={{ fontSize: 26, fontWeight: 700, marginTop: 4 }}>₹{todayCount ? (todayCount) : (0)}</h4>

                  </div>
                  <div className="widgets-icons bg-light text-light rounded-circle"> <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M549-120 280-400v-80h140q53 0 91.5-34.5T558-600H240v-80h306q-17-35-50.5-57.5T420-760H240v-80h480v80H590q14 17 25 37t17 43h88v80h-81q-8 85-70 142.5T420-400h-29l269 280H549Z" /></svg>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <div className="col-12 col-lg-3">
            <div className="card radius-15" style={{ background: 'linear-gradient(to right, #9DBFFF,#9DBFFF)', border: 'none', borderRadius: 10 }}>
              <div className="card-body">
                <div className="media align-items-center">
                  <div className="media-body">
                    <p className="mb-0" style={{ fontWeight: 500, }}>Total Earning</p>
                    <h4 className="mb-0" style={{ fontSize: 26, fontWeight: 700, marginTop: 4 }}>₹{totalCount ? (totalCount) : (0)}</h4>
                  </div>
                  <div className="widgets-icons bg-light text-light rounded-circle"><svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h560q33 0 56.5 23.5T840-760H520q-71 0-115.5 44.5T360-600v240q0 71 44.5 115.5T520-200h320q0 33-23.5 56.5T760-120H200Zm320-160q-33 0-56.5-23.5T440-360v-240q0-33 23.5-56.5T520-680h280q33 0 56.5 23.5T880-600v240q0 33-23.5 56.5T800-280H520Zm120-140q26 0 43-17t17-43q0-26-17-43t-43-17q-26 0-43 17t-17 43q0 26 17 43t43 17Z" /></svg>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 col-lg-3">
            <div className="card radius-15" style={{ background: 'linear-gradient(to right, #FFA59D, #FFA59D, #FFA59D)', border: 'none', borderRadius: 10 }}>
              <div className="card-body">
                <div className="media align-items-center">
                  <div className="media-body">
                    <p className="mb-0" style={{ fontWeight: 500, }}>Total Leads</p>
                    <h4 className="mb-0" style={{ fontSize: 26, fontWeight: 700, marginTop: 4 }}>{insDetails?.leads}</h4>
                  </div>
                  <div className="widgets-icons bg-light text-light rounded-circle"> <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M760-130.001q-37.461 0-66.731-22.5-29.269-22.5-38.576-57.5H440q-62.154 0-106.076-43.923Q290.001-297.846 290.001-360q0-62.154 43.923-106.076Q377.846-509.999 440-509.999h80q37.125 0 63.563-26.46 26.438-26.46 26.438-63.616t-26.438-63.541Q557.125-690.001 520-690.001H305.307q-9.923 35-38.884 57.5-28.962 22.5-66.423 22.5-45.833 0-77.916-32.07t-32.083-77.884q0-45.814 32.083-77.929T200-829.999q37.461 0 66.423 22.5 28.961 22.5 38.884 57.5H520q62.154 0 106.076 43.923Q669.999-662.154 669.999-600q0 62.154-43.923 106.076Q582.154-450.001 520-450.001h-80q-37.125 0-63.563 26.46-26.438 26.46-26.438 63.616t26.438 63.541q26.438 26.385 63.563 26.385h214.693q9.923-35 38.884-57.5 28.962-22.5 66.423-22.5 45.833 0 77.916 32.07t32.083 77.884q0 45.814-32.083 77.929T760-130.001ZM200-669.999q20.846 0 35.424-14.577 14.577-14.578 14.577-35.424t-14.577-35.424Q220.846-770.001 200-770.001t-35.424 14.577Q149.999-740.846 149.999-720t14.577 35.424q14.578 14.577 35.424 14.577Z" /></svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-3">
            <div className="card radius-15" style={{ background: 'linear-gradient(to right, #65CA83, #65CA83, #65CA83)', border: 'none', borderRadius: 10 }}>
              <div className="card-body">
                <div className="media align-items-center">
                  <div className="media-body">
                    <p className="mb-0" style={{ fontWeight: 500, }}>Total Courses</p>
                    <h4 className="mb-0" style={{ fontSize: 26, fontWeight: 700, marginTop: 4 }}>{totalCourse}</h4>
                  </div>
                  <div className="widgets-icons bg-light text-light rounded-circle">  <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M840-280v-276L480-360 40-600l440-240 440 240v320h-80ZM480-120 200-272v-200l280 152 280-152v200L480-120Z" /></svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>)}


      <Leads />
      <CourseWiseRevenue insId={insId} />
      <NewFakeChart />
      <RevenueModeWiseGraph insId={insId} />


    </div>)

}
export default Dashboard  