import React, { useEffect, useRef } from 'react';

const FakeChart = () => {
  const chartRef = useRef(null);

  useEffect(() => {
    const xValues = [100, 200, 300, 400, 500, 600, 700, 800, 900, 1000];
    const chartData = {
      labels: xValues,
      datasets: [
        {
          label: 'Total Visitors',
          data: [860, 1140, 3760, 1060, 1070, 1110, 1330, 2210, 1830, 2478],
          borderColor: '#52cdff',
          backgroundColor: 'rgba(82, 205, 255, 0.2)', 
          pointBackgroundColor: '#52cdff',
          fill: true,
          borderWidth: 1,
        },
        {
          label: 'Total Conversion',
          data: [1600, 1700, 2700, 1900, 2000, 700, 4000, 5000, 6000],
          borderColor: '#1f3bb3',
          backgroundColor: 'rgba(31, 59, 179, 0.2)', 
          pointBackgroundColor: '#1f3bb3',
          fill: true,
          borderWidth: 1,
        },
    
      ],
    };

    if (chartRef.current) {
      const ctx = chartRef.current.getContext('2d');
      new window.Chart(ctx, {
        type: 'line',
        data: chartData,
        options: {
          legend: { display: true },
          tooltips: {
            mode: 'index',
            intersect: false,
            callbacks: {
              label: function (tooltipItem, data) {
                const datasetLabel = data.datasets[tooltipItem.datasetIndex].label || '';
                return `${datasetLabel}: ${tooltipItem.yLabel}`;
              },
            },
          },
          scales: {
            xAxes: [
              {
                gridLines: {
                  display: false, // Hide x-axis gridlines
                },
              },
            ],
          },
        },
      });
    }
  }, []);

  return (
    <div>
        <h1 style={{fontSize:18,fontWeight:700}} >Performance Analytics</h1>
        <p style={{fontWeight:300,fontSize:14}}>You Can See How Many Visitors Visit Your page</p>
      <canvas ref={chartRef} style={{backgroundColor:'#fff',padding:10,borderRadius:10,marginTop:20,maxHeight:310}}></canvas>
    </div>
  );
};

export default FakeChart;
