import React from 'react'
import { useEffect } from 'react';
import { useState, useRef } from 'react';
import { dataLimit, theme } from '../../../..';
import { deleteVideo, fetch_courses_videos } from '../../../../api/Courses';
import {
    fetch_video_playlist, addVideo,
    createVideoPlayList,
    deleteVideoPlayList, editVideoDetails, editVideoFile, editVideoThumb
} from '../../../../api/video';
import CourseVideoRow from './CourseVideoRow'
import { Image, Shimmer } from 'react-shimmer'
import Modal, { ModalBody, ModalFooter, ModalHeader } from '../../../../components/modal/modal';
import ClipLoader from "react-spinners/ClipLoader";
import CourseVideoPlaylistRow from './CourseVideoPlaylistRow'
import Snackbar from '@material-ui/core/Snackbar';
import "./style.css"

function CourseVideo(props) {
    const { activeCourse, setVideoCount, videoCount, setAllowRedirect, setProcessingInParent, setProgressInParent } = props;
    const [videos, setVideos] = useState([])
    const [courseVideoLoaded, setCourseVideoLoaded] = useState(false);
    const [isCourseVideoLoading, setIsCourseVideoLoading] = useState(true);
    const [offset, setOffset] = useState(0)
    const [mode, setMode] = useState('')
    const [showShimmer, setShowShimmer] = useState(true)
    const [videoFile, setVideoFile] = useState('')
    const [videoPreview, setVideoPreview] = useState('')
    const [imageFile, setImageFile] = useState('')
    const [id, setId] = useState('')
    const [imagePreview, setImagePreview] = useState('')
    const [index, setIndex] = useState('')
    const [title, setTitle] = useState('')
    const [subTitle, setSubTitle] = useState('')
    const [playlistId, setPlaylistId] = useState(0)
    const [courseVideoPlaylist, setCourseVideoPlaylist] = useState([])
    const [isAddEditModalVisible, setIsAddEditModalVisible] = useState(false);
    const [editDetail, setEditDetail] = useState(false);
    const [editVideo, setEditVideo] = useState(false);
    const [editThumb, setEditThumb] = useState(false)
    const [processing, setProcessing] = useState(false)
    const [selectedPlaylistId, setSelectedPlaylistId] = useState(-1)
    const [selectedPlaylistName, setSelectedPlaylistName] = useState("All")
    const [addPlayListLoading, setAddPlayListLoading] = useState(false)
    let fileRef = useRef(null);
    let imageFileRef = useRef(null);


    const [playlistName, setPlaylistName] = useState('')
    const [playlistEditIndex, setPlaylistEditIndex] = useState(-1);
    const [playlistEditId, setPlaylistEditId] = useState(-1);
    const [isShowPlaylist, setIsShowPlaylist] = useState(false);
    const [playlistMode, setPlaylistMode] = useState("Add");
    const [allDataLoaded, setAllDataLoaded] = useState(false)
    const [showNextButton, setShowNextButton] = useState()
    const [SnackBarMessage, setSnackBarMessage] = useState("")
    const [isSnackBarShow, setIsSnackBarShow] = useState(false)
    const [progress, setProgress] = useState()
    const [delLoading, setDelLoading] = useState(false);


    useEffect(() => {
        if (!playlistId) {
            setPlaylistId(0)
        }
    }, [playlistId])
    useEffect(() => {
        if (!allDataLoaded) {
            if (selectedPlaylistId != -1) {
                fetch_courses_videos(activeCourse, offset, dataLimit, courseVideoCallback, selectedPlaylistId);
            }
            else {
                fetch_courses_videos(activeCourse, offset, dataLimit, courseVideoCallback);
            }
        }
    }, [activeCourse, offset, selectedPlaylistId])


    useEffect(() => {
        fetch_video_playlist(activeCourse, courseVideoPlaylistCallback);
    }, [activeCourse])

    const courseVideoPlaylistCallback = (response) => {
        if (response.status == 200) {
            response.json().then(data => {
                var playlist = { "courseId": activeCourse, "id": -1, "name": "All" }
                data.unshift(playlist)
                setCourseVideoPlaylist(data)
                setSelectedPlaylistId(data[0].id)
                setSelectedPlaylistName(data[0].name)
            })
        }
    }

    const nextPageHandler = () => {
        if (!allDataLoaded) {
            setOffset(offset + 1)
        } else {
            window.alert("No more data available")
        }

    }
    const prePageHandler = () => {
        if (offset > 0) {
            setOffset(offset - 1)
        }
        else if (offset == 0) {
            setOffset(0)
            setShowNextButton(true)
        }
        setAllDataLoaded(false)

    }

    const courseVideoCallback = (response) => {
        if (response.status == 200) {
            response.json().then(data => {
                if (data.length == dataLimit) {
                    setVideos(data)
                    setShowNextButton(true)
                }
                else if (data.length < dataLimit) {
                    if (data.length == 0) {
                        if (offset == 0) {
                            setOffset(0)
                        } else {
                            setOffset(offset - 1)
                        }
                    }
                    else if (data.length != 0) {
                        setVideos(data)
                    }
                    setShowNextButton(false)
                    setAllDataLoaded(true)
                }
                setCourseVideoLoaded(true)
                setIsCourseVideoLoading(false)
                setShowShimmer(false)
            })
        }
    }

    const deleteCourseVideo = (id, index) => {
        if (window.confirm("Are you sure you want to delete?")) {
            setDelLoading(id)
            deleteVideo(id, (response) => deleteCallBack(response, index))

        }
    }

    const deleteCallBack = (response, index) => {
        setDelLoading(false)
        if (response.status == 200) {

            setVideoCount(videoCount - 1)
            const arr = [...videos]
            arr.splice(index, 1)
            setVideos(arr)
            setSnackBarMessage("Video Deleted Successfully")
            setIsSnackBarShow(true)
            window.location.reload();
        }
        else {
            setSnackBarMessage("Something went wrong")
            setIsSnackBarShow(true)
        }
    }

    const addVideoCallBack = (response) => {
        setAllowRedirect(true)
        setProcessingInParent(false)
        setProgressInParent(0)
        if (response.status == 201) {
            setVideoCount(videoCount + 1)
            var details = response.headers.location.split('*')
            var obj = {
                id: details[0],
                courseId: activeCourse,
                description: subTitle,
                name: title,
                playlistId: playlistId,
                videoLocation: details[1],
                videoThumb: imagePreview,
            }
            var arr = [...videos]
            arr.push(obj)
            setVideos(arr)
            setSnackBarMessage("Video Added Successfully")
            setIsSnackBarShow(true)
            window.location.reload();
        }
        else {
            setSnackBarMessage("Something went wrong")
            setIsSnackBarShow(true)
        }
        setProcessing(false)
        setIsAddEditModalVisible(false)
    }

    const onAddSubmitHandler = (e) => {
        e.preventDefault();
        if (imageFile) {
            if (window.confirm("Are you sure you want to add this video?")) {
                setProcessing(true)
                setAllowRedirect(false)
                setProcessingInParent(true)
                addVideo(imageFile, videoFile, title, subTitle, activeCourse, playlistId, '0', false, addVideoCallBack, addVideoCallBackProgress)
            }
        } else {
            setSnackBarMessage("Choose Video Thumbnail")
            setIsSnackBarShow(true)
        }

    }

    const addVideoCallBackProgress = (progress) => {


        setProgressInParent(progress)
        setProgress(progress)
        // console.log(progress)
    }
    const onEditSubmitHandler = (e) => {
        e.preventDefault();
        if (window.confirm("Are You Sure You Want To Edit?")) {
            setProcessing(true)
            if (editDetail) {
                editVideoDetails(activeCourse, subTitle, id, title, playlistId, imageFile, videoFile, "offline", editDetailsCallBack)
            }
            else if (editVideo) {
                editVideoFile(videoFile, id, editVideoFileCallBack, addVideoCallBackProgress)
            }
            else if (editThumb) {
                editVideoThumb(imageFile, id, editVideoThumbCallBack, addVideoCallBackProgress)
            }
        }
    }

    const editVideoThumbCallBack = (response) => {
        if (response.status == 201) {
            var arr = [...videos]
            arr[index].videoThumb = response.headers.location;
            setVideos(arr)
            setSnackBarMessage("Video Thumbnail Updated Successfully")
            setIsSnackBarShow(true)
        } else {
            setSnackBarMessage("Something went wrong")
            setIsSnackBarShow(true)
        }
        setIsAddEditModalVisible(false)
        setProcessing(false)
    }

    const editVideoFileCallBack = (response) => {
        if (response.status == 201) {
            var arr = [...videos]
            arr[index].videoLocation = response.headers.location;
            setVideos(arr)
            setSnackBarMessage("Video File Updated Successfully")
            setIsSnackBarShow(true)
            window.location.reload();
        } else {
            setSnackBarMessage("Something went wrong")
            setIsSnackBarShow(true)
        }
        setIsAddEditModalVisible(false)
        setProcessing(false)
    }

    const editDetailsCallBack = (response) => {
        if (response.status == 200) {
            var arr = [...videos]
            arr[index].name = title;
            arr[index].description = subTitle;
            arr[index].playlistId = playlistId;
            setVideos(arr)
            setSnackBarMessage("Video Details Updated Successfully")
            setIsSnackBarShow(true)
            window.location.reload();
        } else {
            setSnackBarMessage("Something went wrong")
            setIsSnackBarShow(true)
        }
        setIsAddEditModalVisible(false)
        setProcessing(false)
    }

    const setEditDetails = (index, id, title, description, imageLink, link, playlistId, details, video, thumb) => {
        setId(id)
        setTitle(title);
        setIndex(index);
        setSubTitle(description);
        setImagePreview(imageLink);
        setVideoFile(link);
        setImageFile(imageLink);
        setPlaylistId(playlistId)
        setVideoPreview(link);
        setMode('edit');
        setIsAddEditModalVisible(true);
        setEditDetail(details);
        setEditVideo(video);
        setEditThumb(thumb);
    }
    const setAddDetails = () => {
        setId('')
        setTitle('');
        setIndex('');
        setSubTitle('');
        setImagePreview('');
        setVideoFile('');
        setImageFile('');
        setPlaylistId('')
        setVideoPreview('');
        setMode('add');
        setIsAddEditModalVisible(true);
        setEditDetail(false);
        setEditVideo(false);
        setEditThumb(false);
    }

    const tiggerClickOnFile = () => {
        fileRef.click();
    }

    const fileOnChange = (event) => {
        var url = event.target.files[0] ? (URL.createObjectURL(event.target.files[0])) : ('');
        setVideoPreview(url)
        setVideoFile(event.target.files[0])
    }
    const tiggerClickOnImageFile = () => {
        imageFileRef.click();
    }

    const imageFileOnChange = (event) => {
        var url = event.target.files[0] ? (URL.createObjectURL(event.target.files[0])) : ('');
        setImagePreview(url)
        setImageFile(event.target.files[0])
    }



    const addVideoPlaylistCallback = (response) => {
        setAddPlayListLoading(false)
        if (response.status == 201) {

            let playlistId = response.headers.get('location')
            document.getElementById('addPlaylistCloseBtn').click();
            let videoPlaylist = [...courseVideoPlaylist]
            videoPlaylist.push({
                id: playlistId,
                name: playlistName,
                courseId: activeCourse
            })
            setCourseVideoPlaylist(videoPlaylist)
            setSnackBarMessage("Video Playlist Added Successfully")
            setIsSnackBarShow(true)
            window.location.reload();

        }
        else {
            setSnackBarMessage("Something went wrong")
            setIsSnackBarShow(true)
        }
    }


    const action4addPlaylist = () => {
        setAddPlayListLoading(true)
        createVideoPlayList(activeCourse, playlistName, addVideoPlaylistCallback)
    }
    const action4EditPlaylist = () => {
        setAddPlayListLoading(true)
        createVideoPlayList(activeCourse, playlistName, editVideoPlaylistCallback, playlistEditId)
    }
    const editVideoPlaylistCallback = (response) => {
        setAddPlayListLoading(false)
        if (response.status == 201) {
            let playlistId = response.headers.get('location')
            document.getElementById('addPlaylistCloseBtn').click();
            let videoPlaylist = [...courseVideoPlaylist]
            videoPlaylist[playlistEditIndex] = {
                id: playlistId,
                name: playlistName,
                courseId: activeCourse
            }
            setCourseVideoPlaylist(videoPlaylist)
            setSnackBarMessage("Video Playlist Updated Successfully")
            setIsSnackBarShow(true)
        } else {
            setSnackBarMessage("Something went wrong")
            setIsSnackBarShow(true)
        }
    }
    const deleteVideoPlaylistHandler = (playlistId, index) => {
        deleteVideoPlayList(playlistId, (response) => deletePlaylistCallback(response, index))
    }
    const deletePlaylistCallback = (response, index) => {
        if (response.status == 200) {
            let playlistArr = [...courseVideoPlaylist]
            playlistArr.splice(index, 1)
            setCourseVideoPlaylist(playlistArr);
            setSnackBarMessage("Video Playlist Deleted Successfully")
            setIsSnackBarShow(true)
        } else {
            setSnackBarMessage("Something went wrong")
            setIsSnackBarShow(true)
        }
    }
    const setEditValues = (name, id, index) => {
        setPlaylistName(name);
        setPlaylistEditId(id);
        setPlaylistEditIndex(index)
        setPlaylistMode("edit");
    }


    const closeSnack = () => {
        setIsSnackBarShow(false)
    }
    const handleTitleChange = (e) => {
        const inputValue = e.target.value;
        // Check if the input value is within the character limit
        if (inputValue.length <= 100) {
            setTitle(inputValue);
        }
    };

    return (
        <div style={{ backgroundColor: 'white', borderRadius: '10px', marginTop: 28 }}>
            <div class="page-breadcrumb  d-md-flex align-items-center mb-3 ml-2" style={{ marginRight: 10 }}>
                <div class="btn-group">
                    <button type="button" data-toggle="dropdown" style={{ marginTop: 18, paddingLeft: 20, marginLeft: 20, borderRadius: 5, paddingTop: 5, border: 'none', fontSize: 14, fontWeight: 400 }} class="btn mr-3">{selectedPlaylistName}<svg xmlns="http://www.w3.org/2000/svg" height="30" viewBox="0 -960 960 960"
                        width="30" fill='black' style={{ marginLeft: 5, marginBottom: 0 }}>
                        <path d="M480-387.692 327.692-540h304.616L480-387.692Z" />
                    </svg></button>
                    <div class="dropdown-menu dropdown-menu-right dropdown-menu-lg-left" >
                        {courseVideoPlaylist.map((row, i) => (
                            <a class="dropdown-item" href="javascript:;" onClick={() => { setAllDataLoaded(false); setSelectedPlaylistId(row.id); setOffset(0); setSelectedPlaylistName(row.name); setVideos([]); setShowShimmer(true) }}>{row.name}</a>
                        ))}

                        {/* <a class="dropdown-item" href="javascript:;">Another action</a>
                        <a class="dropdown-item" href="javascript:;">Something else here</a>
                        <div class="dropdown-divider"></div>	<a class="dropdown-item" href="javascript:;">Separated link</a> */}
                    </div>
                </div>
                <div class="ml-auto">
                    <br />
                    <div class="btn-group">
                        <button title="Upload Video" type="button" onClick={setAddDetails} style={{ marginRight: 5, backgroundColor: '#EDEDED', borderRadius: 5, paddingTop: 5, border: 'none', marginRight: 10, paddingRight: 15, fontWeight: 500, fontSize: 14 }}><svg xmlns="http://www.w3.org/2000/svg" height="25" viewBox="0 -960 960 960"
                            width="25" fill='black' style={{ marginRight: 0, marginBottom: 5 }}>
                            <path d="M300-200v-40h360v40H300Zm160-135.385v-349.307L335.231-560.154l-27.539-27.538L480-760l172.308 172.308-27.539 27.538L500-684.692v349.307h-40Z" />
                        </svg> Upload Video</button>
                        {isShowPlaylist ? (

                            <button class="btn mr-3" onClick={() => setIsShowPlaylist(false)} style={{ marginRight: 5, borderRadius: 5, paddingTop: 5, border: 'none', fontWeight: 500 }}>View Videos</button>

                        ) : (
                            <button type="button" class="btn mr-2" onClick={() => setIsShowPlaylist(true)} style={{ marginRight: 5, borderRadius: 5, paddingTop: 5, border: 'none', fontWeight: 500 }}>View Playlist</button>
                        )}
                        <button title="Add Your Playlist" type="button" class="btn mr-3" data-toggle="modal" data-target="#addPlayListModal" onClick={() => setPlaylistMode("add")} style={{ marginRight: 5, backgroundColor: '#EDEDED', borderRadius: 5, paddingTop: 5, border: 'none' }}><svg xmlns="http://www.w3.org/2000/svg" height="25" viewBox="0 -960 960 960"
                            width="25" fill='black' style={{ marginRight: 0, marginBottom: 0 }}>
                            <path d="M140-340v-40h280v40H140Zm0-160v-40h440v40H140Zm0-160v-40h440v40H140Zm520 480v-160H500v-40h160v-160h40v160h160v40H700v160h-40Z" />
                        </svg></button>

                    </div>
                </div>
            </div>


            {isShowPlaylist ? (
                <div className="mt-3">
                    <div class="table-responsive">
                        <table class="table table-striped table-bordered mb-0" id="table1">
                            <thead class="thead-dark">
                                <tr>
                                    <th align="center">#</th>
                                    <th align="center">Title</th>
                                    <th align="center">Actions</th>
                                </tr>
                            </thead>
                            <tbody>

                                {showShimmer ? (
                                    <td colspan="4">
                                        <Shimmer width={'100%'} height={40} />
                                    </td>
                                ) : (
                                    <>
                                        {courseVideoPlaylist.map((row, i) => (
                                            <CourseVideoPlaylistRow setEditValues={setEditValues} deleteVideoPlaylistHandler={deleteVideoPlaylistHandler} row={row} index={i} delVideo={deleteCourseVideo} setEditDetails={setEditDetails} />
                                        ))}
                                    </>

                                )}

                            </tbody>
                        </table>
                    </div>
                </div>
            ) : (
                <div className="mt-3">
                    {/* <div class="table-responsive">
                    <table class="table table-striped table-bordered mb-0" id="table1">
                        <thead class="thead-dark">
                            <tr>
                                <th align="center">#</th>
                                <th align="center">Title</th>
                                <th align="center">Description</th>
                                <th align="center">Actions</th>
                            </tr>
                        </thead>
                        <tbody> */}

                    {showShimmer ? (
                        <div className="row">
                            <div className="col-12">
                                <Shimmer width={'100%'} height={40} />
                            </div>
                            <div className="col-12">
                                <Shimmer width={'100%'} height={40} />
                            </div>
                        </div>
                    ) : (
                        <>
                            {videos.map((row, i) => (
                                <CourseVideoRow row={row} index={i} delVideo={deleteCourseVideo} setEditDetails={setEditDetails} delLoading={delLoading == row.id} />
                            ))}
                        </>

                    )}

                    {/* </tbody>
                    </table>
                </div> */}
                </div>
            )}


            <Modal
                visible={isAddEditModalVisible}
                setModalVisible={setIsAddEditModalVisible}
                modalId={"testAddEditModal"}
            >
                <form onSubmit={mode === "add" ? (onAddSubmitHandler) : (onEditSubmitHandler)}>
                    <ModalHeader>
                        <h5 className="modal-title">{mode == "add" ? ("Video Details") : ("Edit Video Details")}</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <svg xmlns="http://www.w3.org/2000/svg" height="30" viewBox="0 -960 960 960" width="30" fill='black' style={{ marginRight: 4, marginBottom: 1 }}><path d="M256-227.692 227.692-256l224-224-224-224L256-732.308l224 224 224-224L732.308-704l-224 224 224 224L704-227.692l-224-224-224 224Z" /></svg>
                        </button>

                    </ModalHeader>
                    <ModalBody>
                        {mode == "add" ? (
                            <div>
                                <div className="form-row" style={{ marginLeft: 18, marginRight: 18 }}>
                                    <label>Title <span style={{ fontSize: 12, color: '#7A8B94', fontWeight: 400 }}>(required)</span></label>
                                    <textarea
                                        className="form-control"
                                        value={title}
                                        placeholder="Title (max 100 characters)"
                                        onChange={handleTitleChange}
                                        maxLength={100}
                                        style={{ height: '80px', fontWeight: 400 }}
                                    />
                                    <p style={{ position: 'absolute', top: 120, right: 50, fontSize: 12, color: '#A6B3BA' }}>
                                        {title.length}
                                    </p>
                                </div>
                                {/* <div className="form-row">
                                    <label>Description</label>
                                    <input className="form-control" value={subTitle} placeholder="Title" onChange={(e)=>setSubTitle(e.target.value)} />
                                </div>                 */}

                                <div className="col-12">
                                    {videoPreview ? (
                                        <div style={{ borderRadius: 15, alignItems: "center", justifyContent: "center", display: "flex", flexDirection: "column", backgroundColor: '#F8F9FB', marginTop: 25 }}>
                                            <div style={{ alignSelf: "flex-end", marginRight: 15, marginTop: 8 }}>
                                                <a href="javascript:;" onClick={() => { setVideoPreview(""); setVideoFile("") }}><svg xmlns="http://www.w3.org/2000/svg" height="30" viewBox="0 -960 960 960" width="30" fill='#EA4335' style={{ marginRight: 4, marginBottom: 1 }}><path d="M304.615-160q-26.846 0-45.731-18.884Q240-197.769 240-224.615V-720h-40v-40h160v-30.77h240V-760h160v40h-40v495.385Q720-197 701.5-178.5 683-160 655.385-160h-350.77ZM680-720H280v495.385q0 10.769 6.923 17.692T304.615-200h350.77q9.23 0 16.923-7.692Q680-215.385 680-224.615V-720ZM392.307-280h40.001v-360h-40.001v360Zm135.385 0h40.001v-360h-40.001v360ZM280-720v520-520Z" /> </svg></a>

                                            </div>
                                            <video src={videoPreview} width="100%" height="100%" controls style={{ marginTop: 10 }} />
                                        </div>
                                    ) : (
                                        <>
                                            <div className="col-12 mt-1 mb-1 pb-2" style={{ borderRadius: 15, alignItems: "center", justifyContent: "center", display: "flex", flexDirection: "column" }}>
                                                <input
                                                    className="form-control"
                                                    type="file"
                                                    ref={(ref) => (fileRef = ref)}
                                                    onChange={(e) => {
                                                        const acceptedFormats = ['video/mp4', 'video/webm', 'video/quicktime', 'video/x-msvideo', 'video/x-flv'];

                                                        if (e.target.files.length > 0) {
                                                            const selectedFile = e.target.files[0];
                                                            const fileType = selectedFile.type;

                                                            if (acceptedFormats.includes(fileType)) {
                                                                // The selected file is in an accepted format (MP4, WebM, MOV, AVI, FLV).
                                                                // You can proceed with handling the file using fileOnChange.
                                                                fileOnChange(e);
                                                            } else {
                                                                // The selected file is not in an accepted format.
                                                                alert('Please select a valid video file (MP4, WebM, MOV, AVI, FLV).');
                                                                // Optionally, you can clear the file input.
                                                                e.target.value = '';
                                                            }
                                                        }
                                                    }}
                                                    style={{ visibility: 'hidden' }}
                                                    accept=".mp4, .webm, .mov, .avi, .flv"
                                                />
                                                <div style={{ borderRadius: 15, alignItems: "center", justifyContent: "center", display: "flex", flexDirection: "column", backgroundColor: '#F8F9FB', marginTop: -28, width: 428, height: 290 }}>

                                                    <svg onClick={tiggerClickOnFile} xmlns="http://www.w3.org/2000/svg" height="140" viewBox="0 -960 960 960" width="140" fill='#A6B3BA' style={{ padding: 10, backgroundColor: 'white', borderRadius: 50, cursor: 'pointer' }}><path d="M270.391-145.869v-79.218h419.218v79.218H270.391Zm170-189.218v-345.826L329-569.522l-55.566-55.566L480-831.654l206.566 206.566L631-569.522 519.609-680.913v345.826h-79.218Z" /></svg>
                                                    <button type="button" className="btn" onClick={tiggerClickOnFile} style={{ marginTop: 15, backgroundColor: '#005CDD', color: '#fff', paddingLeft: 30, paddingRight: 30 }}>Select Video</button>
                                                    <p></p>
                                                </div>


                                            </div>

                                        </>
                                    )}

                                </div>

                                <div className="form-row">
                                    <div className="col-12 mt-2">
                                        {/* <label>Thumbnail Image </label> */}
                                        {imagePreview ? (
                                            <div style={{ borderRadius: 15, alignItems: "center", justifyContent: "center", display: "flex", flexDirection: "column", backgroundColor: '#F8F9FB', marginTop: 25, idth: 428, height: 290 }}>
                                                <div style={{ alignSelf: "flex-end", marginRight: 15, marginTop: 8 }}>
                                                    {/* const [imageFile, setImageFile] = useState('') */}
                                                    <a href="javascript:;" onClick={() => { setImagePreview(""); setImageFile("") }}><svg xmlns="http://www.w3.org/2000/svg" height="30" viewBox="0 -960 960 960" width="30" fill='#EA4335' style={{ marginRight: 4, marginBottom: 1 }}><path d="M304.615-160q-26.846 0-45.731-18.884Q240-197.769 240-224.615V-720h-40v-40h160v-30.77h240V-760h160v40h-40v495.385Q720-197 701.5-178.5 683-160 655.385-160h-350.77ZM680-720H280v495.385q0 10.769 6.923 17.692T304.615-200h350.77q9.23 0 16.923-7.692Q680-215.385 680-224.615V-720ZM392.307-280h40.001v-360h-40.001v360Zm135.385 0h40.001v-360h-40.001v360ZM280-720v520-520Z" /> </svg></a>

                                                </div>
                                                <img src={imagePreview} style={{ width: 438, height: 240, marginLeft: 0 }} />
                                            </div>
                                        ) : <div style={{ borderRadius: 15, alignItems: "center", justifyContent: "center", display: "flex", flexDirection: "column", backgroundColor: '#F8F9FB', marginTop: -28, width: 425, height: 290, marginTop: 10, marginLeft: 18 }}>

                                            <svg onClick={tiggerClickOnImageFile} xmlns="http://www.w3.org/2000/svg" height="140" viewBox="0 -960 960 960" width="140" fill='#A6B3BA' style={{ padding: 30, backgroundColor: 'white', borderRadius: 50, cursor: 'pointer' }}><path d="M192-90q-44.475 0-75.571-30.9-31.095-30.9-31.095-75.1v-567.334q0-44.474 31.095-75.57Q147.525-870 192-870h355.334v106.666H192V-196h567.334v-356.334h105.999V-196q0 44.2-31.583 75.1Q802.167-90 759.334-90H192Zm508.834-521.334v-93.333h-93.833V-785.5h93.333V-879h80.832v92.667H875v81.166h-93.334v93.833h-80.832ZM235-280.667h482L569.333-476l-124 164.334-93-123L235-280.667Zm-43-482.667V-196v-567.334Z" /></svg>
                                            <button type="button" className="btn" onClick={tiggerClickOnImageFile} style={{ marginTop: 15, backgroundColor: '#005CDD', color: '#fff', paddingLeft: 30, paddingRight: 30 }}>Select Thumbnail</button>
                                            <p style={{ marginTop: 10 }}>Image Size Must be 1280 x 720 Pixel</p>
                                        </div>}

                                        <input
                                            className="form-control"
                                            type="file"
                                            ref={(ref) => (imageFileRef = ref)}
                                            onChange={(e) => {
                                                const acceptedFormats = ['image/jpeg', 'image/png', 'image/jpg'];

                                                if (e.target.files.length > 0) {
                                                    const selectedFile = e.target.files[0];
                                                    const fileType = selectedFile.type;

                                                    if (acceptedFormats.includes(fileType)) {
                                                        // The selected file is in an accepted format (JPEG, PNG, or GIF).
                                                        // You can proceed with handling the file using imageFileOnChange.
                                                        imageFileOnChange(e);
                                                    } else {
                                                        // The selected file is not in an accepted format.
                                                        alert('Please select a valid image file (JPEG, PNG, or JPG).');
                                                        // Optionally, you can clear the file input.
                                                        e.target.value = '';
                                                    }
                                                }
                                            }}
                                            style={{ visibility: 'hidden' }}
                                        />


                                    </div>

                                </div>
                                <div className="form-row" style={{ marginTop: -35, width: 300, marginLeft: 25 }}>
                                    <label >Choose Playlist <br /><span style={{ fontSize: 12, color: '#7A8B94', fontWeight: 400 }}>Add your video to one or more playlists to organize your content for viewers.</span></label>
                                    <select className="form-control" onChange={(e) => { console.log(e.target.value); setPlaylistId(e.target.value) }} style={{ height: 40 }}>
                                        {courseVideoPlaylist.map((row, index) => (
                                            <option value={row.id} selected={playlistId === row.id ? (true) : (false)}>{row.name}</option>
                                        ))}
                                    </select>

                                </div>

                            </div>
                        ) : (null)}

                        {mode == "edit" ? (
                            <>
                                {editDetail ? (
                                    <div>
                                        <div className="form-row">
                                            <label>Title</label>
                                            <textarea
                                                className="form-control"
                                                value={title}
                                                placeholder="Title (max 100 characters)"
                                                onChange={(e) => setTitle(e.target.value)}
                                                maxLength={100}
                                                style={{ height: '80px', fontWeight: 400 }}
                                            />
                                            <p style={{ position: 'absolute', top: 120, right: 50, fontSize: 12, color: '#A6B3BA' }}>
                                                {title.length}
                                            </p>
                                            {/* <input className="form-control" value={title} placeholder="Title" onChange={(e)=>setTitle(e.target.value)} /> */}
                                        </div>
                                        {/* <div className="form-row">
                                    <label>Description</label>
                                    <input className="form-control" value={subTitle} placeholder="Description" onChange={(e)=>setSubTitle(e.target.value)} />
                                </div>                 */}
                                        <div className="form-row">
                                            <label>Select Playlist</label>
                                            <select className="form-control" onChange={(e) => setPlaylistId(e.target.value)}>
                                                {courseVideoPlaylist.map((row, index) => (
                                                    <option value={row.id} selected={playlistId === row.id ? (true) : (false)}>{row.name}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                ) : (null)}
                                {editVideo ? (<div className="form-row">

                                    <div className="col-6 col-lg-6"    >
                                        <label>Video</label>
                                        <input
                                            className="form-control"
                                            type="file"
                                            ref={(ref) => (fileRef = ref)}
                                            onChange={(e) => {
                                                const acceptedFormats = ['video/mp4', 'video/webm', 'video/quicktime', 'video/x-msvideo', 'video/x-flv'];

                                                if (e.target.files.length > 0) {
                                                    const selectedFile = e.target.files[0];
                                                    const fileType = selectedFile.type;

                                                    if (acceptedFormats.includes(fileType)) {
                                                        // The selected file is in an accepted format (MP4, WebM, MOV, AVI, FLV).
                                                        // You can proceed with handling the file using fileOnChange.
                                                        fileOnChange(e);
                                                    } else {
                                                        // The selected file is not in an accepted format.
                                                        alert('Please select a valid video file (MP4, WebM, MOV, AVI, FLV).');
                                                        // Optionally, you can clear the file input.
                                                        e.target.value = '';
                                                    }
                                                }
                                            }}
                                            style={{ visibility: 'hidden' }}
                                            accept=".mp4, .webm, .mov, .avi, .flv"
                                        />

                                        <button type="button" className="btn" onClick={tiggerClickOnFile} >Choose Video</button>
                                    </div>
                                    <div className="col-6 col-lg-6">

                                        <video src={videoPreview ? videoPreview : "http://placehold.it/200/200"} width="200" height="200" controls style={{ marginTop: 20 }} />
                                    </div>

                                </div>) : (null)}
                                {editThumb ? (<div className="form-row">
                                    <div className="col-6 col-lg-6"    >
                                        <label>Thumbnail Image </label>
                                        <input
                                            className="form-control"
                                            type="file"
                                            ref={(ref) => (imageFileRef = ref)}
                                            onChange={(e) => {
                                                const acceptedFormats = ['image/jpeg', 'image/png', 'image/gif'];

                                                if (e.target.files.length > 0) {
                                                    const selectedFile = e.target.files[0];
                                                    const fileType = selectedFile.type;

                                                    if (acceptedFormats.includes(fileType)) {
                                                        // The selected file is in an accepted format (JPEG, PNG, or GIF).
                                                        // You can proceed with handling the file using imageFileOnChange.
                                                        imageFileOnChange(e);
                                                    } else {
                                                        // The selected file is not in an accepted format.
                                                        alert('Please select a valid image file (JPEG, PNG, or GIF).');
                                                        // Optionally, you can clear the file input.
                                                        e.target.value = '';
                                                    }
                                                }
                                            }}
                                            style={{ visibility: 'hidden' }}
                                        />

                                        <button type="button" className="btn" onClick={tiggerClickOnImageFile} >Choose Thumbnail</button>
                                    </div>
                                    <div className="col-6 col-lg-6">

                                        <img src={imagePreview ? imagePreview : "http://placehold.it/200/200"} className="img-responsive w-100 h-100" />
                                    </div>

                                </div>) : (null)}
                            </>
                        ) : (null)}
                    </ModalBody>
                    <ModalFooter>
                        <button type="button" className="btn " data-dismiss="modal">Close</button>
                        <button className="btn" disabled={processing} style={{ backgroundColor: '#005CDD', color: 'white' }}>
                            {processing ? (
                                <ClipLoader color="white" loading={processing} size={20} />
                            ) : (mode === "add" ? ("Upload") : ("Publish Edit"))}
                        </button>
                        {processing ? (
                            <div className="progress mt-3 w-100" style={{ height: 30 }}>
                                <div
                                    className="progress-bar"
                                    role="progressbar"
                                    style={{ width: (progress - 1) + '%' }}
                                    aria-valuenow={progress - 1}
                                    aria-valuemin="0"
                                    aria-valuemax="99"
                                >
                                </div>
                                <span style={{ color: 'white', position: "absolute", left: '5%', marginTop: 15 }}>
                                    {progress - 1} %
                                </span>
                            </div>

                        ) : (
                            null
                        )}
                    </ModalFooter>
                </form>
            </Modal>



            {/* Video PlayList Modal */}

            <div class="modal fade" data-backdrop="static" data-keyboard="false" id="addPlayListModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Add Video Playlist</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <svg xmlns="http://www.w3.org/2000/svg" height="30" viewBox="0 -960 960 960" width="30" fill='black' style={{ marginRight: 4, marginBottom: 1 }}><path d="M256-227.692 227.692-256l224-224-224-224L256-732.308l224 224 224-224L732.308-704l-224 224 224 224L704-227.692l-224-224-224 224Z" /></svg>
                            </button>
                        </div>
                        <div class="modal-body">
                            <label>Playlist name</label>
                            <input className="form-control" value={playlistName} placeholder="Playlist Name" onChange={(e) => setPlaylistName(e.target.value)} />
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn " data-dismiss="modal" id="addPlaylistCloseBtn">Close</button>
                            <button type="button" class="btn" style={{ backgroundColor: '#005CDD', color: 'white' }} onClick={() => playlistMode === "edit" ? action4EditPlaylist() : action4addPlaylist()}>
                                {addPlayListLoading ? (
                                    <ClipLoader color={theme.primaryColor} loading={addPlayListLoading} />
                                ) : ("Save changes")}</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                {offset > 0 ? (

                    <button type="button" class="btn btn-primary" onClick={() => prePageHandler()}>Previous</button>
                ) : (null)}
                {!allDataLoaded && showNextButton ? (
                    <button type="button" class="btn btn-primary " onClick={() => nextPageHandler()}>Next</button>
                ) : (null)}

            </div>
            <Snackbar
                open={isSnackBarShow}
                onClose={(e) => closeSnack(e)}
                TransitionComponent="TransitionUp"
                message={SnackBarMessage}
            />
        </div>
    )
}

export default CourseVideo
