import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react'
import { theme } from '../../../index'
import { accountDetails, editInstituteDetails2, linkAccount, updateAccountDetails, verifyAccount } from '../../../api/institute';
import Modal, { ModalBody, ModalFooter, ModalHeader } from '../../../components/modal/modal';
import Snackbar from '@material-ui/core/Snackbar';
import { Shimmer } from 'react-shimmer'
import ClipLoader from "react-spinners/ClipLoader";



function InstituteAccountDetails(props) {
    const [isAccountVerified, setIsAccountVerified] = useState(false);
    const [data, setData] = useState({});
    const [ifsc, setIfsc] = useState();
    const [accHolderName, setAccHolderName] = useState();
    const [upi, setUpi] = useState();
    const [accNumber, setAccNumber] = useState();
    const [accNumberCnf, setAccNumberCnf] = useState();
    const [bankName, setBankName] = useState();
    const [isAddEditModalVisible, setIsAddEditModalVisible] = useState(false);
    const [SnackBarMessage, setSnackBarMessage] = useState("")
    const [isAccountDetailLoaded, setIsAccountDetailLoaded] = useState(false)
    const [isSnackBarShow, setIsSnackBarShow] = useState(false)
    const [loading, setLoading] = useState(false)
    const { insId, insData } = props


    useEffect(() => {
        accountDetails(insId, detailsCallBack)
    }, [insId])
    useEffect(() => {
        // Check account verification status when component mounts
        verifyAccount(insId, (response) => {
            response.json().then(datas => {
                if (datas.status === "success") {
                    const details = JSON.parse(datas.data)
                    if (details?.activation_status) {
                        setIsAccountVerified(true);
                        editInstituteDetails2({ ...insData, account_status: details.activation_status })
                    }
                }
            })
        });
    }, []);

    const detailsCallBack = (response) => {
        if (response.status == 200) {
            response.json().then(data => {
                setData(data)
            })
        }
        else {

        }
        setIsAccountDetailLoaded(true)
    }


    const onSubmitHandler = (e) => {
        e.preventDefault();
        if (accNumber == accNumber) {
            if (!loading) {
                if (window.confirm("Are You Sure You Want To Save Changes?")) {
                    setIsAccountDetailLoaded(false)
                    setLoading(true)
                    updateAccountDetails(accHolderName, accNumber, bankName, ifsc, upi, insId, updateCallback)
                }
            }
        } else {
            setIsSnackBarShow(true)
            setSnackBarMessage("Account Number Mismatch")
        }

    }

    const verify = () => {
        setLoading(true)
        verifyAccount(insId, (response) => {
            response.json().then(datas => {
                if (datas.status === "success") {
                    const accountDeatails = JSON.parse(datas.data);
                    editInstituteDetails2({ ...insData, account_status: accountDeatails.activation_status })
                    window.location.reload()
                }
                else {
                    setSnackBarMessage("Cant update the Account Status Now!")
                    setIsSnackBarShow(true)
                    setLoading(false)
                }
            })
        })
    }

    const updateCallback = (response) => {
        setLoading(false)
        if (response.status == 200) {
            var obj = { ...data }
            obj.ifsc = ifsc
            obj.bankName = bankName
            obj.accountNumber = accNumber
            obj.accountHolderName = accHolderName
            obj.upi = upi
            setData(obj)
            linkAccount(insId, (response) => {
                response.json().then(data => {
                    if (data.status === "success") {
                        verify()
                        setSnackBarMessage("Bank Details Updated Successfully")
                        setIsSnackBarShow(true)
                    } else {
                        setSnackBarMessage(data.data)
                        setIsSnackBarShow(true)
                    }
                })

            })
        }
        else {
            setSnackBarMessage("Something went wrong")
            setIsSnackBarShow(true)
        }
        setIsAddEditModalVisible(false)
        setIsAccountDetailLoaded(true)
    }

    const setDetails = () => {
        setAccHolderName(data.accountHolderName);
        setBankName(data.bankName);
        setAccNumber(data.accountNumber)
        setIfsc(data.ifsc)
        setUpi(data.upi)
        setIsAddEditModalVisible(true)
    }

    const closeSnack = () => {
        setIsSnackBarShow(false)
    }
    const getStatusColor = (status) => {
        switch (status) {
            case 'created':
                return '#FFD700'; // Yellow for pending
            case 'activated':
                return '#00A859'; // Green for activated
            case 'needs_clarification':
                return '#FF5733'; // Orange for needs clarification
            default:
                return '#808080'; // Gray for other statuses
        }
    };
    const getStatusText = (status) => {
        switch (status) {
            case 'created':
                return 'Pending Approval';
            case 'activated':
                return 'Activated';
            case 'needs_clarification':
                return 'Needs Clarification';
            default:
                return 'Unknown Status';
        }
    };


    return (
        <div>

            <div className="card radius-15 mt-3">
                <div className="card-body">
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <h1 style={{ marginLeft: 18, marginBottom: 20, paddingTop: 0, fontSize: 24 }}>Account Details</h1>
                        <button
                            type="button"
                            className="btn"
                            style={{
                                marginLeft: 15,
                                backgroundColor: isAccountVerified ? '#ccc' : '#005CDD',
                                fontSize: 14,
                                color: '#fff',
                                marginTop: 0,
                                marginBottom: 15,
                                height: 40,
                                pointerEvents: isAccountVerified ? 'none' : 'auto', // Disable button if account is verified
                            }}
                            onClick={setDetails}
                        >
                            Edit Account
                        </button>
                    </div>
                    {isAccountDetailLoaded && isAccountDetailLoaded ? (
                        <div className="col-12 col-lg-5" style={{ maxWidth: 417 }}>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div style={{ backgroundColor: '#7A8B94', padding: 5, height: '40px', paddingLeft: 10, paddingRight: 10, borderRadius: 5, paddingTop: 9, width: 180 }}><p style={{ fontWeight: 500, color: 'white', fontSize: 15 }}>Account Holder Name:</p></div>
                                <div style={{ backgroundColor: '#ECEDEF', padding: 5, height: '40px', paddingLeft: 10, paddingRight: 10, borderRadius: 5, paddingTop: 10, width: 180 }}><p style={{ fontSize: 13, textAlign: 'center' }}>{data.accountHolderName}</p></div>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 10 }}>
                                <div style={{ backgroundColor: '#7A8B94', padding: 5, height: '40px', paddingLeft: 10, paddingRight: 10, borderRadius: 5, paddingTop: 9, width: 180 }}><p style={{ fontWeight: 500, color: 'white', fontSize: 15 }}>Account Number:</p></div>
                                <div style={{ backgroundColor: '#ECEDEF', padding: 5, height: '40px', paddingLeft: 10, paddingRight: 10, borderRadius: 5, paddingTop: 10, width: 180 }}><p style={{ fontSize: 13, textAlign: 'center' }}>{data.accountNumber}</p></div>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 10 }}>
                                <div style={{ backgroundColor: '#7A8B94', padding: 5, height: '40px', paddingLeft: 10, paddingRight: 10, borderRadius: 5, paddingTop: 9, width: 180 }}><p style={{ fontWeight: 500, color: 'white', fontSize: 15 }}>IFSC Code:</p></div>
                                <div style={{ backgroundColor: '#ECEDEF', padding: 5, height: '40px', paddingLeft: 10, paddingRight: 10, borderRadius: 5, paddingTop: 10, width: 180 }}><p style={{ fontSize: 13, textAlign: 'center' }}>{data.ifsc}</p></div>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 10 }}>
                                <div style={{ backgroundColor: '#7A8B94', padding: 5, height: '40px', paddingLeft: 10, paddingRight: 10, borderRadius: 5, paddingTop: 9, width: 180 }}><p style={{ fontWeight: 500, color: 'white', fontSize: 15 }}>Bank Name:</p></div>
                                <div style={{ backgroundColor: '#ECEDEF', padding: 5, height: '40px', paddingLeft: 10, paddingRight: 10, borderRadius: 5, paddingTop: 10, width: 180 }}><p style={{ fontSize: 13, textAlign: 'center' }}>{data.bankName}</p></div>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'start', marginTop: 10 }}>
                                <div style={{ backgroundColor: '#7A8B94', padding: 5, height: '40px', paddingLeft: 10, paddingRight: 10, borderRadius: 5, paddingTop: 9, width: 180 }}><p style={{ fontWeight: 500, color: 'white', fontSize: 15 }}>Account Status:</p></div>
                                <div style={{
                                    backgroundColor: getStatusColor(insData?.account_status),
                                    padding: 5,
                                    height: '40px',
                                    paddingLeft: 10,
                                    paddingRight: 10,
                                    borderRadius: 5,
                                    paddingTop: 9,
                                    marginLeft: '7%',
                                    width: "50%"
                                }}>
                                    <p style={{ color: 'white', textTransform: 'capitalize', fontSize: 14, textAlign: 'center' }}>
                                        {getStatusText(insData?.account_status)}
                                    </p>
                                </div>

                                <div onClick={verify} style={{ backgroundColor: '#005CDD', padding: 5, height: '40px', paddingLeft: 10, paddingRight: 10, borderRadius: 5, paddingTop: 7, marginLeft: 7 }}>
                                    {loading ? (
                                        <ClipLoader color={theme.primaryColor} loading={loading} />
                                    ) :
                                        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" fill='white' width="24"><path d="M481.539-180.001q-125.625 0-212.812-87.17-87.187-87.169-87.187-212.768t87.187-212.829q87.187-87.231 212.812-87.231 70.154 0 132.769 31.193 62.615 31.192 104.153 88.039v-119.232h59.999v244.613H533.847v-59.998h157.999q-31.615-57.923-87.692-91.27Q548.077-720 481.539-720q-100 0-170 70t-70 170q0 100 70 170t170 70q77 0 139-44t87-116h63.229q-27.231 97.922-107.269 158.961-80.038 61.038-181.96 61.038Z" />
                                        </svg>
                                    }
                                </div>


                            </div>
                            {/* <table className="table table-sm table-borderless mt-md-0 mt-3">
                                <tbody>
                                    <tr>
                                        <th style={{ fontWeight: 600, fontSize: 14, color: '#242527' }}>Account Holder Name :</th>
                                        <td >{data.accountHolderName}</td>
                                    </tr>
                                    <tr>
                                        <th style={{ fontWeight: 600, fontSize: 14, color: '#242527' }}>Account Number :</th>
                                        <td  >{data.accountNumber}</td>
                                    </tr>
                                    <tr>
                                        <th style={{ fontWeight: 600, fontSize: 14, color: '#242527' }}>IFSC Code :</th>
                                        <td >{data.ifsc}</td>
                                    </tr>
                                    <tr>
                                        <th style={{ fontWeight: 600, fontSize: 14, color: '#242527' }}>Bank Name :</th>
                                        <td >{data.bankName}</td>
                                    </tr>
                                
                                    <tr>
                                        <th style={{ fontWeight: 600, fontSize: 14, color: '#242527' }}>Account Status :</th>
                                       <td style={{backgroundColor:'#00A859',border:'none',color:'white',borderRadius:5, textTransform:'capitalize',width:'100px',textAlign:'center'}}>{insData?.account_status}</td>
                                       <td style={{backgroundColor:'#00A859',border:'none',color:'white',borderRadius:5, textTransform:'capitalize',width:'10px',textAlign:'center'}}>  <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M160-200v-80h80v-280q0-83 50-147.5T420-792v-28q0-25 17.5-42.5T480-880q25 0 42.5 17.5T540-820v28q80 20 130 84.5T720-560v280h80v80H160Zm320-300Zm0 420q-33 0-56.5-23.5T400-160h160q0 33-23.5 56.5T480-80ZM320-280h320v-280q0-66-47-113t-113-47q-66 0-113 47t-47 113v280Z" /></svg></td>
                                   
                                    </tr>
                                   
                                </tbody>
                            </table> */}
                        </div>
                    ) : (
                        <div className="col-12 col-lg-12">
                            <Shimmer width={'100%'} height={80} />
                        </div>

                    )}
                </div>
            </div>
            <Modal
                visible={isAddEditModalVisible}
                setModalVisible={setIsAddEditModalVisible}
                modalId={"testAddEditModal"}
            >
                <form onSubmit={onSubmitHandler}>
                    <ModalHeader>
                        <h5 className="modal-title">Account Details</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">	<span aria-hidden="true">&times;</span>
                        </button>
                    </ModalHeader>
                    <ModalBody>

                        <div className="form-row">
                            <label>Account Holder Name</label>
                            <input className="form-control" value={accHolderName} placeholder="Account Holder Name" onChange={(e) => setAccHolderName(e.target.value)} />
                        </div>
                        <div className="form-row">
                            <label>Account No.</label>
                            <input className="form-control" value={accNumber} placeholder="Account Number" onChange={(e) => setAccNumber(e.target.value)} />
                        </div>
                        {/* <div className="form-row">
                            <label>Confirm Account No.</label>
                            <input className="form-control" value={accNumber} placeholder="Account Number" onChange={(e) => setAccNumber(e.target.value)} />
                        </div> */}
                        <div className="form-row">
                            <label>IFSC Code</label>
                            <input className="form-control" value={ifsc} placeholder="IFSC Code" onChange={(e) => setIfsc(e.target.value)} />
                        </div>
                        <div className="form-row">
                            <label>Bank Name</label>
                            <input className="form-control" value={bankName} placeholder="Bank Name" onChange={(e) => setBankName(e.target.value)} />
                        </div>
                        {/* <div className="form-row">
                            <label>UPI Id</label>
                            <input className="form-control" value={upi} placeholder="Bank Name" onChange={(e) => setUpi(e.target.value)} />
                        </div> */}
                    </ModalBody>
                    <ModalFooter>
                        <button type="button" className="btn" data-dismiss="modal">Close</button>
                        <button className="btn " style={{ backgroundColor: '#005CDD', color: 'white' }}>
                            {loading ? (
                                <ClipLoader color={theme.primaryColor} loading={loading} />
                            ) : ('Save Changes')}
                        </button>
                    </ModalFooter>
                </form>
            </Modal>
            <Snackbar
                open={isSnackBarShow}
                onClose={(e) => closeSnack(e)}
                TransitionComponent="TransitionUp"
                message={SnackBarMessage}
            />
        </div>
    )
}

export default InstituteAccountDetails
