import React, { useState, useEffect } from 'react'
import { ImageResolver, serverBaseUrl } from '../..'
import { fetch_instituteDetails, fetch_categories } from '../../api/institute'
import { fetchRevenueOverview } from '../../api/revenue';
import InstituteAccountDetails from './InstituteAccountDetails/InstituteAccountDetails'

// import InstituteCourse from './InstituteCourses/InstituteCourse'
import InstituteReviews from './InstituteReviews/InstituteReviews'
import InstituteLeads from './InstituteLeads/InstituteLeads'
import InstituteRevenue from './InstituteRevenue/InstituteRevenue'
import CourseRevenueAnalytics from './InstituteRevenue/CourseRevenueAnalytics';
import RevenueModeWiseGraph from './InstituteRevenue/RevenueModeWiseGraph';
import RevenueCount from './InstituteRevenue/RevenueCount';
import CourseWiseRevenue from './InstituteRevenue/CourseWiseRevenue';
import LeadsCount from './InstituteLeads/LeadsCount';
import LeadsGraph from './InstituteLeads/LeadsGraph';
import { Link, useHistory } from "react-router-dom"

import { Image, Shimmer } from 'react-shimmer'

import { useSelector } from 'react-redux'


function InstituteView(props) {

    const insDetails = useSelector((state) => state.ins.insDetails)
    let instituteId = insDetails.id
    const [institute, setInstitute] = useState({})
    const [activeTab, setActiveTab] = useState(() => {
        // Initialize with the last active tab from localStorage or a default tab
        return localStorage.getItem('activeTab') || 'reviews';
      });
    const history = useHistory();
    const [revenue, setRevenue] = useState({})
    const [showShimmer, setShowShimmer] = useState(true)
    const [categoryData, seCategoryData] = useState([])
    const [catName, setCatName] = useState("");

    const fetchCategoryCallback = (response) => {
        if (response.status == 200) {
            response.json().then(data => {
                seCategoryData(data)
                const value = data.filter((item) => item.key == institute.category)
                setCatName(value[0]?.label)
            })
        }
    }

    useEffect(() => {
        fetch_instituteDetails(instituteId, (response) => {
            if (response.status == 200) {
                response.json().then(data => {
                    setInstitute(data);
                    setShowShimmer(false)
                })
            }
        })

    }, [instituteId])

    useEffect(() => {
        fetch_categories(fetchCategoryCallback);
    }, [institute.category])

 // Update localStorage when the active tab changes
 useEffect(() => {
    localStorage.setItem('activeTab', activeTab);
  }, [activeTab]);

    const renderTabContent = (activeTab, insId) => {

        switch (activeTab) {
            case 'courses':
            // return(<InstituteCourse insId={insId}/>)

            case 'reviews':
                return (<InstituteReviews insId={insId} />)

            case 'leads':
                return (
                    <div>
                        <br />
                        <LeadsCount insId={insId} />
                        <InstituteLeads insId={insId} />
                        <LeadsGraph insId={insId} />
                    </div>)
                break;
            case 'analytics':
                return (<div>
                    <br />
                    <RevenueCount insId={insId} />
                    <CourseWiseRevenue insId={insId} />
                    <CourseRevenueAnalytics insId={insId} />
                    <RevenueModeWiseGraph insId={insId} />
                    <InstituteRevenue insId={insId} />

                </div>)
                break;
            case 'acc_details':
                return (<InstituteAccountDetails insId={insId} insData={institute} />)
                break;


        }
    }

    return (
        <div style={{ fontFamily: 'Outfit, sans-serif' }}>
            <div class="page-breadcrumb  d-md-flex align-items-center mb-3">
                <div class="breadcrumb-title pr-3" style={{ fontWeight: 600, fontSize: 24 }}>Profile Section</div>
                <div class="ml-auto">
                    <div class="btn-group">

                        <Link to="/InstituteEdit" class="btn" style={{ marginRight: 5, backgroundColor:'#005CDD', fontSize:14, color:'#fff' }}>Edit Profile</Link>
                        <button type="button" class="btn" style={{ marginRight: 5, fontSize:14 }} onClick={() => history.goBack()}>
                    Go Back</button>

                    </div>
                </div>
            </div>
            <div className="user-profile-page">
                <div className="card radius-15" style={{ borderRadius: 15 }}>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-12 col-lg-7 border-right">
                                <div className="d-md-flex align-items-center">
                                    <div className="mb-md-0 mb-3">
                                        <img src={ImageResolver(institute?.logo)} width="130" height="130" alt="" id="userImage"  onError={({ currentTarget }) => {
                      currentTarget.onerror = null; // prevents looping
                      currentTarget.src = "/assets/images/profile.png";
                    }} />
                                    </div>
                                    <div className="ml-md-4 flex-grow-1" style={{ marginLeft: 20 }}>
                                        <div className="d-flex align-items-center mb-1">
                                            <h4 id="username" style={{ fontWeight: 700, marginTop: -10 }}>{institute?.name}</h4>
                                        </div>
                                        <p id="userStatus" style={{ color: '#005CDD', marginTop: -5, fontWeight: 500 }}>{institute?.directorName}</p>
                                        <p id="userStatus" style={{ color: '#A6B3BA', marginTop: -1, fontWeight: 400 }}>{institute?.uniqueUserId ? "@" + institute?.uniqueUserId : ""}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-lg-5">
                                <table className="table table-sm table-borderless mt-md-0 mt-3">
                                    <tbody>
                                        <tr>
                                            <th style={{ fontWeight: 600, fontSize: 14, color: '#242527' }}>Phone:</th>
                                            <td id="likes">
                                                {showShimmer ? (
                                                    <Shimmer width={200} height={20} />
                                                ) : (
                                                    <>
                                                        {institute.phone}
                                                    </>
                                                )}

                                            </td>
                                        </tr>
                                        <tr>
                                            <th style={{ fontWeight: 600, fontSize: 14, color: '#242527' }}>Email:</th>
                                            <td id="comments">
                                                {showShimmer ? (
                                                    <Shimmer width={'40%'} height={20} />
                                                ) : (
                                                    <>
                                                        {institute?.email}
                                                    </>
                                                )}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th style={{ fontWeight: 600, fontSize: 14, color: '#242527' }}>State:</th>
                                            <td id="postedOn">
                                                {showShimmer ? (
                                                    <Shimmer width={'100%'} height={40} />
                                                ) : (
                                                    <>
                                                        {institute?.state}
                                                    </>
                                                )}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th style={{ fontWeight: 600, fontSize: 14, color: '#242527' }}>Institute Category:</th>
                                            <td id="postedOn">
                                                {showShimmer ? (
                                                    <Shimmer width={'100%'} height={80} />
                                                ) : (
                                                    <>
                                                        {catName}
                                                    </>
                                                )}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <button type="button" className={activeTab == "reviews" ? "btn" : "btn"} onClick={() => { setActiveTab('reviews') }} style={{ marginLeft: 16, borderBottom: activeTab === "reviews" ? "3px solid #005CDD" : "3px solid #fff", color: '#242527', fontWeight: 400, backgroundColor: 'white', fontSize: 15, marginRight: 15, }}>
                    <svg xmlns="http://www.w3.org/2000/svg" height="24" style={{ marginRight: 5 }} viewBox="0 -960 960 960" width="24" fill='#242527'><path d="M371.847-401.539 480-467.154l108.153 65.615-28.692-123 95.923-82.691-126.23-10.616L480-733.845l-49.154 115.999-126.23 10.616 95.923 82.691-28.692 123ZM100.001-118.464v-669.227q0-30.308 21-51.308t51.308-21h615.382q30.308 0 51.308 21t21 51.308v455.382q0 30.308-21 51.308t-51.308 21H241.539L100.001-118.464Zm116-201.536h571.69q4.616 0 8.463-3.846 3.846-3.847 3.846-8.463v-455.382q0-4.616-3.846-8.463-3.847-3.846-8.463-3.846H172.309q-4.616 0-8.463 3.846-3.846 3.847-3.846 8.463v523.076L216.001-320ZM160-320v-480V-320Z" /></svg> Reviews
                </button>
                <button type="button" className={activeTab == "acc_details" ? "btn" : "btn"} onClick={() => { setActiveTab('acc_details') }} style={{ marginLeft: 0, borderBottom: activeTab === "acc_details" ? "3px solid #005CDD" : "3px solid #fff", color: '#242527', fontWeight: 400, backgroundColor: 'white', fontSize: 15, marginRight: 15 }}>
                    <svg xmlns="http://www.w3.org/2000/svg" height="24" style={{ marginRight: 5 }} viewBox="0 -960 960 960" width="24" fill='#242527'><path d="M230.002-280v-300.001h59.999V-280h-59.999Zm219.999 0v-300.001h59.998V-280h-59.998Zm-339.23 139.999V-200h738.458v59.999H110.771ZM669.999-280v-300.001h59.999V-280h-59.999ZM110.771-660.001v-56.922L480-897.691l369.229 180.768v56.922H110.771ZM252.615-720h454.77-454.77Zm0 0h454.77L480-830 252.615-720Z" /></svg> Account Details
                </button>
                <button type="button" className={activeTab == "leads" ? "btn" : "btn"} onClick={() => { setActiveTab('leads') }} style={{ marginLeft: 0, borderBottom: activeTab === "leads" ? "3px solid #005CDD" : "3px solid #fff", color: '#242527', fontWeight: 400, backgroundColor: 'white', fontSize: 15, marginRight: 15 }}>
                    <svg xmlns="http://www.w3.org/2000/svg" height="24" style={{ marginRight: 5 }} viewBox="0 -960 960 960" width="24" fill='#242527'><path d="M140.001-180.001v-459.996l144.615 108.461 199.23-279.616 189.23 151.153h146.923v479.998H140.001ZM320-280l160-220 280 218v-318H652L496-725 298-447l-98-73v144l120 96Z" /></svg> Leads
                </button>
                <button type="button" className={activeTab == "analytics" ? "btn" : "btn"} onClick={() => { setActiveTab('analytics') }} style={{ marginLeft: 0, borderBottom: activeTab === "analytics" ? "3px solid #005CDD" : "3px solid #fff", color: '#242527', fontWeight: 400, backgroundColor: 'white', fontSize: 15, marginRight: 15 }}>
                    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24" fill='#242527' style={{ marginRight: 5 }}><path d="M545.538-140.001 290.001-406.154v-66.153H420q56.846 0 100.924-36.423 44.077-36.424 48.615-97.424H250.001v-59.999h311.385q-14.692-41.154-53-67.501Q470.078-760 420-760H250.001v-59.999h459.998V-760H566.152q21.308 17 37.309 41.423 16 24.424 21.615 52.424h84.923v59.999h-79.846q-4.153 85-65.384 139.423Q503.538-412.309 420-412.309h-52.463l261.307 272.308h-83.306Z" /></svg>Revenue
                </button>


            </div>
            {renderTabContent(activeTab, instituteId)}

        </div>
    )
}

export default InstituteView
