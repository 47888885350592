import React, { useEffect, useRef } from 'react';

const FakeChart = () => {
  const chartRef = useRef(null);

  useEffect(() => {
    const xValues = ['Mon','Tue','Wed','Thu','Fri','Sat','Sun'];
    const chartData = {
      labels: xValues,
      datasets: [
        {
          label: 'Total Conversion',
          data: [1000,2000,2000,4000,5000,6000,7000,],
          borderColor: '#fff',
          pointBackgroundColor: '#fff',
          fill: true,
          borderWidth: 7,
        },
      ],
    };

    if (chartRef.current) {
      const ctx = chartRef.current.getContext('2d');
      new window.Chart(ctx, {
        type: 'line',
        data: chartData,
        options: {
          legend: { display: true },
          tooltips: {
            mode: 'index',
            intersect: false,
            callbacks: {
              label: function (tooltipItem, data) {
                const datasetLabel = data.datasets[tooltipItem.datasetIndex].label || '';
                return `${datasetLabel}: ${tooltipItem.yLabel}`;
              },
            },
          },
          scales: {
            xAxes: [
              {
                gridLines: {
                  display: false, // Hide x-axis gridlines
                },
                ticks: {
                  fontColor: '#fff', // Set x-axis text color to white
                },
              },
            ],
            yAxes: [
              {
                ticks: {
                  fontColor: '#fff', // Set y-axis text color to white
                },
              },
            ],
          },
        },
      });
    }
  }, []);

  return (
    <div>
      <canvas ref={chartRef} style={{ padding: 10, borderRadius: 10, maxHeight: 180,marginTop:-10 }}></canvas>
    </div>
  );
};

export default FakeChart;
