import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom"
import { serverBaseUrl, theme } from '../../../..';
import {updateTestSeriesDemoStatus, updateTestSeriesStatus,getStudentCount} from '../../../../api/TestSeries'

import Snackbar from '@material-ui/core/Snackbar';
function CourseTestSeriesRow(props) {
    const {row,index,activeCourse,setPublishModal,setSeriesDetails,deleteCourseTestSeries}=props    
     
    const [hiddenStatus,setHiddenStatus] = useState(row.hidden);
    const [demo ,setDemo] = useState(row.demo)

    const [attempCount,setAttempCount] = useState(0)
    const [SnackBarMessage, setSnackBarMessage] = useState("")
    const [isSnackBarShow, setIsSnackBarShow] = useState(false)
    useEffect(() =>{

        setDemo(row.demo);
        if(row.id)
        {
            getStudentCount(row.id,response=>{
                if(response.status==200)
                {
                    response.json().then(data=>{
                        setAttempCount(data);
                    })
                }
            })
        }
    },[row])

    const updateDemoStatusCallBack=(response, status)=>{
        if(response.status==200)
        {
            setDemo(status)
        }
        else
        {
            console.log(response.status)
        }
    }
    // const [title, setTitle] = useState(row.title);
    // const [maxMarks, setMaxMarks] = useState(row.maxMarks)
    // const [duration, setDuration] = useState(row.timeDuration)
    // const [questionCount, setQuestionCount] = useState(row.questionCount)
    // const [seriesId, setSeriesId] = useState(row.id);
    // const [hiddenStatus, setHiddenStatus] = useState(row.hidden);
    const closeSnack = () => {
        setIsSnackBarShow(false)
    }

    const publishBtnHandler = ()=>
    {
            
        if(row.questionCount<=0)
        {
            setIsSnackBarShow(true)
            setSnackBarMessage("Please Add Questions To publish Test")
        }else
        {
            setPublishModal(true);
            setSeriesDetails(row,publishModelCallBack,index)
        }
    }
    const publishModelCallBack=()=>
    {
        setHiddenStatus(false)
    }
    const updateStatusCallBack = (response, status) => {
        if (response.status == 200) {
            setHiddenStatus(status)
        }
        else {
            console.log(response.status)
        }
    }

    return (
        <>
        <div style={{width:680,height:170,marginLeft:28,borderRadius:10,backgroundColor:'white',border:'1px solid #ededed',marginTop:20}}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <p style={{ fontSize: 20, fontWeight: 600, marginLeft: 30, paddingTop: 20 }}>
  {row.title.length > 50 ? row.title.substring(0, 50) + '...' : row.title}
</p>

  <p style={{ fontSize: 16, fontWeight: 500, marginLeft: 30, paddingTop: 20,marginRight:30,color:'#005CDD' }}>
  {index + 1}
  </p>
</div>

            <div style={{display:'flex'}}>
            <p style={{marginLeft:32}}>Duration - {row.timeDuration}</p>
            <p style={{marginLeft:20}}>Max Marks - {row.maxMarks}</p>
            <p style={{marginLeft:20}}>Total Question - {row.questionCount}</p>
            <p style={{marginLeft:20}}>Attempt Counts - {attempCount}</p>
            {/* <p style={{marginLeft:20,backgroundColor:'blue',paddingLeft:20,paddingRight:20,paddingTop:10,paddingBottom:10,color:'white'}}>Edit</p> */}
           
            </div>
            <div style={{display:'flex', marginTop:12,marginLeft:30}}>
            <button title='Delete' aria-label="delete"  onClick={()=>deleteCourseTestSeries(row.id,index)} className="btn mr-1" style={{backgroundColor:'#ededed'}}>
                <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24" fill='#FA2918'><path d="M304.615-160q-26.846 0-45.731-18.884Q240-197.769 240-224.615V-720h-40v-40h160v-30.77h240V-760h160v40h-40v495.385Q720-197 701.5-178.5 683-160 655.385-160h-350.77ZM680-720H280v495.385q0 10.769 6.923 17.692T304.615-200h350.77q9.23 0 16.923-7.692Q680-215.385 680-224.615V-720ZM392.307-280h40.001v-360h-40.001v360Zm135.385 0h40.001v-360h-40.001v360ZM280-720v520-520Z"/></svg>
        </button>
            <Link to={"/leaderboard/"+row.id}>
                    <button title='Leaderboard' aria-label="delete" className="btn mr-1" style={{backgroundColor:'#ededed'}}>
                    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M150.769-160v-400h166.154v400H150.769Zm238.462 0v-640h181.538v640H389.231Zm253.846 0v-320h166.154v320H643.077Z"/></svg>
                    </button>
                </Link>
              
        {hiddenStatus ? (
        
        <button title='Public' aria-label="delete" onClick={() => publishBtnHandler()} className="btn mr-1" style={{backgroundColor:'#ededed'}}>
            <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M617.846-454.154 586-486q9-52.385-29.692-90.692Q517.615-615 466-606l-31.846-31.846q10.077-4.154 21.038-6.231 10.962-2.077 24.808-2.077 61.154 0 103.654 42.5 42.5 42.5 42.5 103.654 0 13.846-2.077 25.577-2.077 11.731-6.231 20.269Zm126.462 122.923L714-358q38-29 67.5-63.5T832-500q-50-101-143.5-160.5T480-720q-29 0-57 4t-55 12l-31.231-31.231q34.846-13.154 70.923-18.962Q443.769-760 480-760q130.231 0 238.231 71.577T876.923-500q-21.461 48.231-54.346 90.654-32.884 42.423-78.269 78.115Zm44.615 216.77L633.231-269.692q-26.539 11.769-65.885 20.731Q528-240 480-240q-131 0-238.231-71.577T83.077-500q23.307-53 61.461-99.269 38.154-46.269 81.462-77.654l-111.539-112 28.308-28.308 674.462 674.462-28.308 28.308ZM254.307-648.615Q219.923-624.154 184-584.308 148.077-544.461 128-500q50 101 143.5 160.5T480-280q34.615 0 69.769-6.731 35.154-6.73 52.846-13.577L537.385-366q-9.462 5.308-26.385 8.731-16.923 3.423-31 3.423-61.154 0-103.654-42.5-42.5-42.5-42.5-103.654 0-13.308 3.423-29.846 3.423-16.539 8.731-27.539l-91.693-91.23ZM541-531Zm-112.539 56.539Z"/></svg>
        </button>
    ) : (
        <button title='Private' aria-label="delete" onClick={() => updateTestSeriesStatus(true, row.id, (response) => updateStatusCallBack(response, true))} className="btn mr-1" style={{backgroundColor:'#ededed'}}>
            <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M480.181-353.846q60.973 0 103.473-42.681t42.5-103.654q0-60.973-42.681-103.473t-103.654-42.5q-60.973 0-103.473 42.681t-42.5 103.654q0 60.973 42.681 103.473t103.654 42.5ZM480-392q-45 0-76.5-31.5T372-500q0-45 31.5-76.5T480-608q45 0 76.5 31.5T588-500q0 45-31.5 76.5T480-392Zm.11 152q-129.956 0-236.879-70.731Q136.307-381.461 83.077-500q53.23-118.539 160.044-189.269Q349.934-760 479.89-760q129.956 0 236.879 70.731Q823.693-618.539 876.923-500q-53.23 118.539-160.044 189.269Q610.066-240 480.11-240ZM480-500Zm0 220q113 0 207.5-59.5T832-500q-50-101-144.5-160.5T480-720q-113 0-207.5 59.5T128-500q50 101 144.5 160.5T480-280Z"/></svg>
       
        </button>
    )}
     {demo?(

<button title='Close For Demo' href="javascript:;" className="btn" style={{backgroundColor:'#ededed'}} onClick={()=>{updateTestSeriesDemoStatus(false,row.id,(response) => updateDemoStatusCallBack(response, false))}}>
<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24" fill='#08BD80'><path d="M264.615-600H600v-80q0-50-35-85t-85-35q-50 0-85 35t-35 85h-40q0-66.846 46.577-113.423T480-840q66.846 0 113.423 46.577T640-680v80h55.385q26.846 0 45.731 18.884Q760-562.231 760-535.385v350.77q0 26.846-18.884 45.731Q722.231-120 695.385-120h-430.77q-26.846 0-45.731-18.884Q200-157.769 200-184.615v-350.77q0-26.846 18.884-45.731Q237.769-600 264.615-600Zm0 440h430.77q10.769 0 17.692-6.923T720-184.615v-350.77q0-10.769-6.923-17.692T695.385-560h-430.77q-10.769 0-17.692 6.923T240-535.385v350.77q0 10.769 6.923 17.692T264.615-160ZM480-300q25.308 0 42.654-17.346Q540-334.692 540-360q0-25.308-17.346-42.654Q505.308-420 480-420q-25.308 0-42.654 17.346Q420-385.308 420-360q0 25.308 17.346 42.654Q454.692-300 480-300ZM240-160v-400 400Z"/></svg>
   </button>
):(

<button title='Open For Demo' href="javascript:;" className="btn" style={{backgroundColor:'#ededed'}} onClick={()=>{updateTestSeriesDemoStatus(true,row.id,(response) => updateDemoStatusCallBack(response, true))}}> <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M264.615-120q-26.846 0-45.731-18.884Q200-157.769 200-184.615v-350.77q0-26.846 18.884-45.731Q237.769-600 264.615-600H320v-80q0-66.846 46.577-113.423T480-840q66.846 0 113.423 46.577T640-680v80h55.385q26.846 0 45.731 18.884Q760-562.231 760-535.385v350.77q0 26.846-18.884 45.731Q722.231-120 695.385-120h-430.77Zm0-40h430.77q10.769 0 17.692-6.923T720-184.615v-350.77q0-10.769-6.923-17.692T695.385-560h-430.77q-10.769 0-17.692 6.923T240-535.385v350.77q0 10.769 6.923 17.692T264.615-160ZM480-300q25.308 0 42.654-17.346Q540-334.692 540-360q0-25.308-17.346-42.654Q505.308-420 480-420q-25.308 0-42.654 17.346Q420-385.308 420-360q0 25.308 17.346 42.654Q454.692-300 480-300ZM360-600h240v-80q0-50-35-85t-85-35q-50 0-85 35t-35 85v80ZM240-160v-400 400Z"/></svg></button>
)} 
 <Link to={"/addTestSeries/edit/" + row.id + "/" + activeCourse} style={{marginLeft:350,}}>
                    <button title='Edit Your Test' aria-label="delete" className="btn" style={{backgroundColor:'#005CDD',paddingLeft:20,paddingRight:20,paddingTop:8,paddingBottom:8,color:'white',fontWeight:500,marginTop:0}}>
                        Edit
                    </button>
                </Link>
            </div>


        </div>

        {/* <tr>
            <td align="center">{index + 1}</td>
            <td align="center">{row.title}</td>
            <td align="center">{row.timeDuration}</td>
            <td align="center">{row.maxMarks}</td>
            <td align="center">{row.questionCount}</td>
            <td align="center">{attempCount}</td>
            <td align="center">
                <Link to={"/addTestSeries/edit/" + row.id + "/" + activeCourse}>
                    <button aria-label="delete" className="btn btn-success mr-1">
                        EDIT
                    </button>
                </Link>
                <Link to={"/leaderboard/"+row.id}>
                    <button aria-label="delete" className="btn btn-success mr-1">
                    leaderboard
                    </button>
                </Link>
                 
                    <button aria-label="delete"  onClick={()=>deleteCourseTestSeries(row.id,index)} className="btn btn-danger mr-1">
                        Delete
                    </button>
                 
                    
                {hiddenStatus ? (
                    // <button aria-label="delete" onClick={() => updateTestSeriesStatus(false, seriesId, (response) => updateStatusCallBack(response, false))} className="btn btn-warning mr-1">
                    //     PUBLISH
                    // </button>
                    <button aria-label="delete" onClick={() => publishBtnHandler()} className="btn btn-warning mr-1">
                        PUBLISH
                    </button>
                ) : (
                    <button aria-label="delete" onClick={() => updateTestSeriesStatus(true, row.id, (response) => updateStatusCallBack(response, true))} className="btn btn-warning mr-1">
                        HIDE
                    </button>
                )}

                {demo?(

                    <button href="javascript:;" className="btn btn-info" onClick={()=>{updateTestSeriesDemoStatus(false,row.id,(response) => updateDemoStatusCallBack(response, false))}}>Close For Non Paid Users</button>
                ):(

                    <button href="javascript:;" className="btn btn-info" onClick={()=>{updateTestSeriesDemoStatus(true,row.id,(response) => updateDemoStatusCallBack(response, true))}}>Open For All (Free)</button>
                )} 
            </td>
            <Snackbar
            open={isSnackBarShow}
            onClose={(e) => closeSnack(e)}
            TransitionComponent="TransitionUp"
            message={SnackBarMessage}
            />
        </tr> */}
        </>
    )
}

export default CourseTestSeriesRow
