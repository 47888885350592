import React from 'react'
import { useEffect } from 'react';
import { useState, useRef } from 'react';
import { dataLimit, theme } from '../../../..';
import {
    deleteDocument, fetch_courses_documents,
    getDocumentPlaylist, createDocumentPlaylist, deleteDocuentPlayList
} from '../../../../api/Courses';
import { fetch_document_playlist, addDocument, editDocumentDetails, editDocumentFile } from '../../../../api/document';
import CourseDocumentRow from './CourseDocumentRow';
import { Image, Shimmer } from 'react-shimmer'
import Modal, { ModalBody, ModalFooter, ModalHeader } from '../../../../components/modal/modal';
import CourseVideoPlaylistRow from '../CourseVideo/CourseVideoPlaylistRow';
import CourseDocumentPlaylistRow from './CourseDocumentPlaylistRow';
import ClipLoader from "react-spinners/ClipLoader";
import { max } from 'moment';
import Snackbar from '@material-ui/core/Snackbar';

function CourseDocument(props) {
    const { activeCourse,setDocCount,docCount,setAllowRedirect,setProcessingInParent,setProgressInParent } = props
    const [courseDocuments, setCourseDocuments] = useState([])
    const [courseDocumentLoaded, setCourseDocumentLoaded] = useState(false);
    const [isCourseDocumentLoading, setIsCourseDocumentLoading] = useState(true);
    const [offset, setOffset] = useState(0)
    const [showShimmer, setShowShimmer] = useState(true)
    const [mode, setMode] = useState('')
    const [isAddEditModalVisible, setIsAddEditModalVisible] = useState(false);
    const [id, setId] = useState();
    const [index, setIndex] = useState();
    const [title, setTitle] = useState();
    const [docFile, setDocFile] = useState();
    const [playlistId, setPlaylistId] = useState(-1)
    const [editDetail, setEditDetail] = useState(false);
    const [editDocFile, setEditDocFile] = useState(false);
    const [courseDocPlaylist, setCourseDocPlaylist] = useState([])
    let fileRef = useRef(null);
    const [selectedDocument,setSelectedDocument] = useState(null)
    const [isShowPlaylist, setIsShowPlaylist] = useState(false);
    const [documentPlaylistData, setDocumentPlaylistData] = useState([]);
    const [playlistName, setPlaylistName] = useState('')
    const [playlistEditIndex, setPlaylistEditIndex] = useState(-1);
    const [playlistEditId, setPlaylistEditId] = useState(-1);
    const [playlistMode, setPlaylistMode] = useState("Add");
    const [processing, setProcessing] = useState(false)
    const [allDataLoaded,setAllDataLoaded]=useState(false)
    const [showNextButton, setShowNextButton]=useState()
    const [selectedPlaylistId, setSelectedPlaylistId] = useState(-1)
    const [selectedPlaylistName, setSelectedPlaylistName] = useState("All")
    const [SnackBarMessage ,setSnackBarMessage]=useState()
    const [isSnackBarShow, setIsSnackBarShow]=useState(false)
    const [addPlayListLoading, setAddPlayListLoading] = useState(false)
    const [progress, setProgress] = useState()
    const [showProgress, setShowProgress] = useState(false)
    const [delLoading, setDelLoading] = useState(false);

    const maxCharacters = 25;

    const handlePlaylistNameChange = (e) => {
      const inputValue = e.target.value;
      if (inputValue.length <= maxCharacters) {
        setPlaylistName(inputValue);
      }
    };
    useEffect(() => {
        if(selectedPlaylistId!=-1)
        {
            fetch_courses_documents(activeCourse,offset,dataLimit,courseDocumentCallback, selectedPlaylistId);
        }
        else
        {
            fetch_courses_documents(activeCourse,offset,dataLimit,courseDocumentCallback);
        }
    }, [activeCourse, offset, selectedPlaylistId])

    useEffect(() => {
        fetch_document_playlist(activeCourse,courseDocumentPlaylistCallback);
    }, [activeCourse])


    const courseDocumentPlaylistCallback=(response)=>{
        if(response.status==200)
        {
            response.json().then(data=>
            {
                
                var playlist={"courseId": activeCourse, "id": -1, "name": "All"}
                data.unshift(playlist)
                setCourseDocPlaylist(data)
                // setSelectedPlaylistId(data[0].id)
                // setSelectedPlaylistName(data[0].name)
            })
        }
    }
    
    const courseDocumentCallback=(response)=>
    {
        if(response.status==200)
        {
            response.json().then(data => {
                if (data.length == dataLimit) 
                {
                    setCourseDocuments(data)
                    setShowNextButton(true)
                } 
                else if(data.length<dataLimit) 
                {
                    if(data.length==0) 
                    {
                        if(offset==0)
                        {
                            setOffset(0)
                        }else
                        {
                            setOffset(offset-1)
                        }
                    }
                    else if(data.length!=0)
                    {     
                        setCourseDocuments(data)
                    }
                    setShowNextButton(false)
                    setAllDataLoaded(true)
                }
                setCourseDocumentLoaded(true)
                setIsCourseDocumentLoading(false)
                setShowShimmer(false)
            })
        }
    }

    const nextPageHandler = () => {
        if (!allDataLoaded) {
            setOffset(offset + 1)
        } else {
            window.alert("No more data available")

        }

    }
    
    const prePageHandler = () => {
        if (offset > 0) {
            setOffset(offset - 1)
        }
        else if (offset == 0) {
            setOffset(0)
            setShowNextButton(true)
        }
        setAllDataLoaded(false)

    }

    const deleteCourseDocument = (id, index) => {
        if (window.confirm("Are you sure you want to delete?")) {
            setDelLoading(id)
            deleteDocument(id, (response) => deleteCallBack(response, index))
        }
    }

    const deleteCallBack = (response, index) => {
        setDelLoading(false)
        if (response.status == 200) {
            setDocCount(docCount - 1)
            const arr = [...courseDocuments]
            arr.splice(index, 1)
            setCourseDocuments(arr)
            setSnackBarMessage("Document Deleted Successfully")
            setIsSnackBarShow(true)
            window.location.reload();
        }
        else {
            console.log("unable to delete");
            setSnackBarMessage("Something went wrong")
            setIsSnackBarShow(true)
        }
    }
const addDocumentUploadProgressCallback=(progress) => {

    // upload progress 
    setProgress(progress)
    setShowProgress(true)
    
    
    setProgressInParent(progress)
}
    const onAddSubmitHandler = (e) => {
        e.preventDefault();
        if (window.confirm("Are you sure you want to add this document?")) {
            setProcessing(true)
            setAllowRedirect(false)
            setProcessingInParent(true)
            addDocument(docFile, title, activeCourse, playlistId, addDocCallback,addDocumentUploadProgressCallback)
        }
    }

    const addDocCallback = (response) => {
        setAllowRedirect(true)
        
        setProcessingInParent(false)
        setProgressInParent(0)
        if (response.status == 201) {
            setDocCount(docCount + 1)
            console.log(response.headers.location)
            var arr=response.headers.location.split('*')
            var obj = {
                id: arr[0],
                name: title,
                courseId: activeCourse,
                playlistId: playlistId,
                file: arr[1]
            }
            var docArr = [...courseDocuments]
            docArr.push(obj)
            setCourseDocuments(docArr)
            setSnackBarMessage("Document Added Successfully")
            setIsSnackBarShow(true)
            window.location.reload();
        }
        else{
            setSnackBarMessage("Something went wrong")
            setIsSnackBarShow(true)
        }
        setIsAddEditModalVisible(false)
        setProcessing(false)
    }

    const onEditSubmitHandler = (e) => {
        e.preventDefault();
        if ("Are You Sure You Want To Save Changes?") {
            setProcessing(true)
            if (editDetail) {
                editDocumentDetails(activeCourse,docFile,id, title, playlistId,editDetailsCallback)
            }
            else if (editDocFile) {
                editDocumentFile(docFile, id,  editDocumentFileCallback, addDocumentUploadProgressCallback)
            }
        }
    }

    const editDetailsCallback = (response) => {
        console.log(response.status)
        if (response.status == 200) {
            var arr = [...courseDocuments]
            arr[index].name = title
            arr[index].playlistId = playlistId
            setCourseDocuments(arr)
            setSnackBarMessage("Document Updated Successfully")
            setIsSnackBarShow(true)
            window.location.reload();
        }else{
            setSnackBarMessage("Something went wrong")
            setIsSnackBarShow(true)
        }
        setIsAddEditModalVisible(false)
        setProcessing(false)
    }

    const editDocumentFileCallback = (response) => {
        if (response.status == 201) {
            var arr = [...courseDocuments]
            arr[index].fileAddress = response.headers.get('location')
            setCourseDocuments(arr)
            setSnackBarMessage("Document Updated Successfully")
            setIsSnackBarShow(true)
            window.location.reload();
        }else{
            setSnackBarMessage("Something went wrong")
            setIsSnackBarShow(true)
        }
        setIsAddEditModalVisible(false)
        setProcessing(false)
    }

    const setEditDetails = (title, id, index, playlist, file, detail, link) => {
        setMode('edit');
        setTitle(title);
        setId(id);
        setPlaylistId(playlist);
        setIndex(index);
        setEditDetail(detail);
        setEditDocFile(file);
        setDocFile(link);
        setIsAddEditModalVisible(true)

    }
    const setAddDetails = () => {
        setMode('add'); setTitle(''); setIsAddEditModalVisible(true)
    }

    const tiggerClickOnFile = () => {
        fileRef.click();
    }

    const fileOnChange = (event) => {
        var url = event.target.files[0] ? (URL.createObjectURL(event.target.files[0])) : ('');
        setDocFile(event.target.files[0]) 
    }

    const getDocumentPlaylistCallback = (response) => {
        if (response.status == 200) {
            response.json().then(data => {
                console.log('getting document playlist', data)
                setDocumentPlaylistData(data)
            })
        }
    }

    const action4viewDocumentPlaylist = () => {
        setIsShowPlaylist(true)
        if (documentPlaylistData.length <= 0) {
            getDocumentPlaylist(activeCourse, getDocumentPlaylistCallback)
        }

    }


    const addPlaylistCallback = (response) => {
        setAddPlayListLoading(false)
        if (response.status == 201) {
            console.log('document playlist has been created')
            document.getElementById('addPlaylistCloseBtn').click();
            let playlistId = response.headers.get('location')
            let playlistArr = [...documentPlaylistData]
            playlistArr.push({
                id: playlistId,
                name: playlistName,
                courseId: activeCourse
            })
            setDocumentPlaylistData(playlistArr)
            setSnackBarMessage("Playlist added Successfully")
            setIsSnackBarShow(true)
        }else{
            setSnackBarMessage("Something went wrong")
            setIsSnackBarShow(true)
        }
    }

    const action4EditPlaylist = () => {
        setAddPlayListLoading(true)
        createDocumentPlaylist(activeCourse, playlistName, editDocumentPlaylistCallback, playlistEditId)
    }
    const editDocumentPlaylistCallback = (response) => {
        setAddPlayListLoading(false)
        if (response.status == 201) {
            let playlistId = response.headers.get('location')
            console.log("video playlist added successfully", playlistId, playlistEditIndex)
            document.getElementById('addPlaylistCloseBtn').click();
            let documentPlaylistArr = [...documentPlaylistData]

            documentPlaylistArr[playlistEditIndex] = {
                id: playlistId,
                name: playlistName,
                courseId: activeCourse
            }
            console.log(documentPlaylistArr, documentPlaylistData)
            setDocumentPlaylistData(documentPlaylistArr)
            setSnackBarMessage("Playlist Document Updated Successfully")
            setIsSnackBarShow(true)
        }else{
            setSnackBarMessage("Something went wrong")
            setIsSnackBarShow(true)
        }
    }
    const action4addPlaylist = () => {
        setAddPlayListLoading(true)
        createDocumentPlaylist(activeCourse, playlistName, addPlaylistCallback)
    }
    const deletePlaylistCallback = (response, index) => {
        if (response.status == 200) {
            let playlistArr = [...documentPlaylistData]
            playlistArr.splice(index, 1);
            setDocumentPlaylistData(playlistArr)
            setSnackBarMessage("Playlist deleted Successfully")
            setIsSnackBarShow(true)
        }
    }
    const deletePlaylistHandler = (id, index) => {
        deleteDocuentPlayList(id,(response)=>deletePlaylistCallback(response,index))
    }

    const setEditValues = (name, id, index) => {
        setPlaylistName(name);
        setPlaylistEditId(id);
        setPlaylistEditIndex(index)
        setPlaylistMode("edit");
        console.log("running")
    }

    const closeSnack=()=>{
        setIsSnackBarShow(false)
    }

    const handleTitleChange = (e) => {
      const inputValue = e.target.value;
  
      // Check if the input value exceeds the maximum character limit (100)
      if (inputValue.length <= 100) {
        setTitle(inputValue);
      }
    };
    return (
        <div style={{backgroundColor:'white', borderRadius:'10px', marginTop:28 }}>
            <div class="page-breadcrumb  d-md-flex align-items-center mb-3" style={{margin:10}}>
                <div class="btn-group">
                        <button type="button" data-toggle="dropdown" style={{marginTop:18, paddingLeft:20,marginLeft: 20,  borderRadius:5,paddingTop:5,border:'none', fontSize:14, fontWeight:400 }} class="btn mr-3">{selectedPlaylistName}<svg xmlns="http://www.w3.org/2000/svg" height="30" viewBox="0 -960 960 960"
                        width="30" fill='black' style={{marginLeft:5, marginBottom:0}}>
                      <path d="M480-387.692 327.692-540h304.616L480-387.692Z"/>
                        </svg></button>
                   
                    <div class="dropdown-menu dropdown-menu-right dropdown-menu-lg-left" >
                        {courseDocPlaylist.map((row, i) => (
                            <a class="dropdown-item" href="javascript:;" onClick={() => {setAllDataLoaded(false); setSelectedPlaylistId(row.id); setOffset(0); setSelectedPlaylistName(row.name); setCourseDocuments([]); setShowShimmer(true)}}>{row.name}</a>
                        ))}
                    </div>
                </div>
                <div class="ml-auto">

                <br/>
                    <div class="btn-group">
                    <button title="Upload Your Document" type="button" onClick={() => setAddDetails()} style={{ marginRight: 5, backgroundColor:'#EDEDED', borderRadius:5,paddingTop:5,border:'none',marginRight:10,paddingRight:15,fontWeight:500,fontSize:14 }}><svg xmlns="http://www.w3.org/2000/svg" height="25" viewBox="0 -960 960 960"
                        width="25" fill='black' style={{marginRight:0, marginBottom:0}}>
                       <path d="M300-200v-40h360v40H300Zm160-135.385v-349.307L335.231-560.154l-27.539-27.538L480-760l172.308 172.308-27.539 27.538L500-684.692v349.307h-40Z"/>
                        </svg>Upload PDF</button>
                        {isShowPlaylist ? (
                           
                                <button type="button" style={{ marginRight: 5, borderRadius:5,paddingTop:5,border:'none', fontSize:14, fontWeight:500 }} class="btn mr-3" onClick={() => setIsShowPlaylist(false)}>View Documents</button>
                           
                        ) : (
                            <button type="button" style={{ marginRight: 5, borderRadius:5,paddingTop:5,border:'none',fontWeight:500 }} class="btn mr-3" onClick={() => action4viewDocumentPlaylist()}>View Playlist</button>
                        )}

                        <button title="Add Your Playlist" type="button" class="btn mr-3" data-toggle="modal" data-target="#addPlayListModal" onClick={() => setPlaylistMode("add")} style={{ marginRight: 5, backgroundColor:'#EDEDED', borderRadius:5,paddingTop:5,border:'none',marginLeft:-10 }}><svg xmlns="http://www.w3.org/2000/svg" height="25" viewBox="0 -960 960 960"
                        width="25" fill='black' style={{marginRight:0, marginBottom:0}}>
                       <path d="M140-340v-40h280v40H140Zm0-160v-40h440v40H140Zm0-160v-40h440v40H140Zm520 480v-160H500v-40h160v-160h40v160h160v40H700v160h-40Z"/>
                        </svg></button>
                       
                    </div>
                </div>
            </div>





            <div className="mt-3"> 
                {isShowPlaylist ?(
                    <div class="table-responsive">
                        <table class="table table-striped table-bordered mb-0" id="table1">
                            <thead class="thead-dark">
                                <tr>
                                    <th align="center">#</th>
                                    <th align="center">Title</th>
                                    <th align="center">Actions</th>
                                </tr>
                            </thead>
                            <tbody>

                                {showShimmer ? (
                                    <td colspan="4">
                                        <Shimmer width={'100%'} height={40} />
                                    </td>
                                ) : (
                                    <>
                                        {documentPlaylistData.map((row, i) => (
                                                <CourseDocumentPlaylistRow setEditValues={setEditValues} deletePlaylistHandler={deletePlaylistHandler} row={row} index={i} />
                                            ))}
                                    </>

                                )}

                            </tbody>
                        </table>
                    </div>
                ):(
            
                    showShimmer ? (
                            <div className="row">
                            <div className="col-12">
                                <Shimmer width={'100%'} height={40} />          
                            </div> 
                        </div>
                    ) : (
                        <>
                                  <div style={{display: 'flex', flexWrap: 'wrap', alignItems: 'center', padding: '10px' }}>
                           
                                    {courseDocuments.map((row, i) => (
                                        <CourseDocumentRow row={row} index={i} delDocument={deleteCourseDocument} setEditDetails={setEditDetails} delLoading={delLoading === row.id} />
                                    ))}
                                    

                            </div>


                        </>
                    )
                     
            
                )}
            </div>
            <Modal
                visible={isAddEditModalVisible}
                setModalVisible={setIsAddEditModalVisible}
                modalId={"testAddEditModal"}
            >
                <form onSubmit={mode === "add" ? (onAddSubmitHandler) : (onEditSubmitHandler)}>
                    <ModalHeader>
                        <h5 className="modal-title">{mode === "add" ? ("PDF Details") : ("Edit PDF Details")}</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close"><svg xmlns="http://www.w3.org/2000/svg" height="30" viewBox="0 -960 960 960" width="30" fill='black' style={{marginRight:4, marginBottom:1}}><path d="M256-227.692 227.692-256l224-224-224-224L256-732.308l224 224 224-224L732.308-704l-224 224 224 224L704-227.692l-224-224-224 224Z"/></svg>
                        </button>
                    </ModalHeader>
                    <ModalBody>
                        {mode === "add" ? (
                            <div>
                                <div className="form-row">
                                    <label>PDF Title</label>
                                       <textarea
    className="form-control"
    value={title}
    placeholder="Title (max 100 characters)"
    onChange={handleTitleChange}
    maxLength={100}
    style={{ height: '80px', fontWeight: 400 }}
  />
     <p style={{ position: 'absolute', top: 120, right: 50, fontSize: 12, color: '#A6B3BA' }}>
    {title.length}
  </p>
                                   
                                </div>
                                <div className="form-row">
                                    <label>Select Playlist</label>
                                    <select className="form-control" onChange={(e) => setPlaylistId(e.target.value)}>
                                        {courseDocPlaylist.map((row, index) => (
                                            <option style={{margin:10}} value={row.id} selected={playlistId === row.id ? (true) : (false)}>{row.name}</option>
                                        ))}
                                    </select>
                                </div>

                                <div className="form-row">

                                    <div className="col-6 col-lg-6">
                                      
                                        <div  style={{borderRadius:15,alignItems: "center",justifyContent: "center",display: "flex",flexDirection: "column",backgroundColor:'#F8F9FB',marginTop:-28,width:425,height:290, marginTop:10,marginLeft:18}}>

                                        <p style={{marginTop:10,fontSize:18,fontWeight:500}}>{docFile?.name}</p>
                                    <svg onClick={tiggerClickOnFile} xmlns="http://www.w3.org/2000/svg" height="140" viewBox="0 -960 960 960" width="140" fill='#A6B3BA' style={{padding:30,backgroundColor:'white',borderRadius:50,cursor:'pointer'}}><path d="M447.667-206h67.666v-195.334l78 78 47-47.666-160.666-156.666-158 157.999L369-322l78.667-79.334V-206ZM236-50.667q-42.425 0-74.212-31.087Q130-112.842 130-156.666v-646.668q0-44.099 31.788-75.382Q193.575-910 236-910h349l245.666 243.666v509.668q0 43.824-31.983 74.912Q766.7-50.667 724-50.667H236Zm293.668-561.334v-191.333H236v646.668h488v-455.335H529.668ZM236-803.334v191.333-191.333 646.668-646.668Z"/></svg>
                                        <button onClick={tiggerClickOnFile} type="button" className="btn"  style={{marginTop:15, backgroundColor:'#005CDD',color:'#fff',paddingLeft:30,paddingRight:30}}>Select PDF</button>
                                                                                    </div>
                                        <input className="form-control" type="file" ref={ref => fileRef = ref} onChange={(e) => { fileOnChange(e) }} accept=".pdf" style={{ visibility: 'hidden' }} />
                                    </div>
                                </div>
                            </div>
                        ) : (null)}
                        {mode === "edit" ? (
                            <div>
                                {editDetail ? (
                                    <div>
                                        <div className="form-row">
                                            <label>Title</label>
                                            {/* <input className="form-control" value={title} placeholder="Title" onChange={(e) => setTitle(e.target.value)} /> */}
                                            <textarea
    className="form-control"
    value={title}
    placeholder="Title (max 100 characters)"
    onChange={(e) => setTitle(e.target.value)}
    maxLength={100}
    style={{ height: '80px', fontWeight: 400 }}
  />
     <p style={{ position: 'absolute', top: 120, right: 50, fontSize: 12, color: '#A6B3BA' }}>
    {title.length}
  </p>
                                        </div>
                                        <div className="form-row">
                                            <label>Select Playlist</label>
                                            <select className="form-control" onChange={(e) => setPlaylistId(e.target.value)}>
                                                {courseDocPlaylist.map((row, index) => (
                                                    <option value={row.id} selected={playlistId === row.id ? (true) : (false)}>{row.name}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="form-row">

                                            <div className="col-6 col-lg-6">
                                                {/* <label>Document</label> */}
                                                <label style={{}}>
  {docFile?.name && docFile.name.length > 40
    ? docFile.name.slice(0, 40) + "..."
    : docFile.name}
    
</label>

                                                <input className="form-control" type="file" ref={ref => fileRef = ref} onChange={(e) => { fileOnChange(e) }} accept=".pdf" style={{ visibility: 'hidden' }}  />
                                
                                                <button onClick={tiggerClickOnFile} type="button" className="btn"  style={{width: 200,marginTop:15, backgroundColor:'#005CDD',color:'#fff',paddingLeft:30,paddingRight:30, marginLeft: 120}}>Select PDF</button>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    null
                                )}
                                {editDocFile ? (
                                    <div className="form-row">
                                        <div className="col-6 col-lg-6">
                                            <label>Document</label>
                                            <input
                                                className="form-control"
                                                type="file"
                                                ref={(ref) => (fileRef = ref)}
                                                accept=".pdf" // Specify accepted file types
                                                onChange={(e) => {
                                                    fileOnChange(e);
                                                }}
                                                style={{ visibility: 'hidden' }}
                                            />
                                            <button type="button" className="btn btn-primary" onClick={tiggerClickOnFile}>
                                                Choose Document
                                            </button>
                                        </div>

                                    </div>
                                ) : (null)}
                            </div>
                        ) : (null)}
                    </ModalBody>
                    <ModalFooter>
                        <button type="button" className="btn" data-dismiss="modal">Close</button>
                        <button className="btn"style={{backgroundColor:'#005CDD',color:'white'}} disabled={processing}>{processing?(
                            <ClipLoader color="white" loading={processing} size={20} />
                        ):(mode === "add" ? ("Upload") : ("Edit Document"))}</button>
                        {processing ? (
                             <div className="progress mt-3 w-100" style={{ height: 30 }}>
                             <div
                               className="progress-bar"
                               role="progressbar"
                               style={{ width: (progress - 1) + '%' }} 
                               aria-valuenow={progress - 1}
                               aria-valuemin="0"
                               aria-valuemax="99" 
                             >
                             </div>
                             <span style={{ color: 'white', position: "absolute", left: '5%', marginTop: 15 }}>
                               {progress - 1} %
                             </span>
                           </div>
                        ):(
                            null
                        )}
                    </ModalFooter>
                </form>
            </Modal>


            {/* document PlayList Modal */}

            <div class="modal fade" data-backdrop="static" data-keyboard="false" id="addPlayListModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Add Document Playlist</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <label>Playlist Name</label>
                            <input
        className="form-control"
        value={playlistName}
        placeholder="Playlist Name"
        onChange={handlePlaylistNameChange}
      />
      <p>
        {maxCharacters - playlistName.length}/{maxCharacters}
      </p>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn" data-dismiss="modal" id="addPlaylistCloseBtn">Close</button>
                                <button type="button" class="btn" style={{backgroundColor:'#005CDD',color:'white'}} onClick={() => playlistMode == "edit" ? action4EditPlaylist() : action4addPlaylist()}>{addPlayListLoading?(
                                    <ClipLoader color={theme.primaryColor}   loading={addPlayListLoading}     />
                                ):("Save changes")}
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal-footer">
                {offset > 0 ? (

                    <button type="button" class="btn btn-primary" onClick={() => prePageHandler()}>Previous</button>
                ) : (null)}
                {!allDataLoaded && showNextButton ? (
                    <button type="button" class="btn btn-primary " onClick={() => nextPageHandler()}>Next</button>
                ) : (null)}

            </div>

            <Snackbar
                open={isSnackBarShow}
                onClose={(e)=>closeSnack(e)}
                TransitionComponent="TransitionUp"
                message={SnackBarMessage}
            />
        </div>
    )
}

export default CourseDocument
