import React from 'react'
import { useEffect } from 'react';
import { useState, useRef } from 'react';
import { dataLimit, theme } from '../../../..';
import { createLiveStream, deleteLiveStream, deleteVideo, fetch_courses_live_videos, fetch_courses_videos, getLiveStream, updateLiveStream } from '../../../../api/Courses';
import {
    fetch_video_playlist, addVideo,
    createVideoPlayList,
    deleteVideoPlayList, editVideoDetails, editVideoFile, editVideoThumb, addLiveVideo, updateLiveVideoLink
} from '../../../../api/video';
import CourseVideoRow from './CourseVideoRow'
import { Image, Shimmer } from 'react-shimmer'
import Modal, { ModalBody, ModalFooter, ModalHeader } from '../../../../components/modal/modal';
import ClipLoader from "react-spinners/ClipLoader";
import CourseVideoPlaylistRow from './CourseVideoPlaylistRow'
import Snackbar from '@material-ui/core/Snackbar';
import { sendNotification } from '../../../../api/notification';
import { useSelector } from 'react-redux';
import Stack from "react-bootstrap/Stack";
import CopyToClipboard from 'react-copy-to-clipboard';


const LiveModal = ({ visible, setVisible, item }) => {
    const [keyCopied, setKeyCopied] = useState(false)
    const [urlCopied, setUrlCopied] = useState(false)
    return (
        <Modal
            visible={visible}
            setModalVisible={setVisible}
            modalId={"GoLiveModal"}
        >
            <ModalHeader>
                <h2>Stream Setup Help</h2>
            </ModalHeader>
            <ModalBody>
                <Stack gap={3}>
                    <div className='mb-4'>
                        <img src='./assets/images/stream.jpg' style={{ resize: "contain", width: "100%", height: "140px" }} />
                    </div>
                    <div>
                        <h6>1. Download and setup your streaming software</h6>
                        <a href='https://obsproject.com/' target='_blank' className="link">Learn more about streaming software</a>
                    </div>
                    <div>
                        <h6>2. Paste the Stream key into your software</h6>
                        <label for="basic-url" className="form-label">Stream key</label>
                        <div className="input-group">
                            <input type="text" className="form-control input-small" value={item?.rtmpsKey}
                                id="basic-url" aria-describedby="basic-addon3" />

                            <CopyToClipboard text={item?.rtmpsKey} onCopy={() => {
                                setKeyCopied(() => true)
                            }}>
                                <button type='button' className='btn ml-3'>{!keyCopied ? "Copy" : "Copied"}</button>
                            </CopyToClipboard>

                        </div>
                        <label for="basic-url" class="form-label">Stream URL</label>
                        <div class="input-group">
                            <input type="text" class="form-control input-small" value={item?.rtmpsUrl}
                                id="basic-url" aria-describedby="basic-addon3" />

                            <CopyToClipboard text={item?.rtmpsUrl} onCopy={() => {
                                setUrlCopied(() => true)
                            }}>
                                <button type='button' className='btn ml-3'>{!urlCopied ? "Copy" : "Copied"}</button>
                            </CopyToClipboard>
                        </div>
                        <h7>Allcoaching supports RTMPS for secure connection</h7>
                    </div>

                    <div>
                        <h6>3. In your streaming software, start sending us your video stream</h6>
                    </div>

                </Stack>

            </ModalBody>
            <ModalFooter>
                <button type='button' onClick={() => setVisible(() => !visible)} className='btn'>Done</button>
            </ModalFooter>
        </Modal>
    )
}


function CourseLiveVideo(props) {
    const insDetails = useSelector((state) => state.ins.insDetails)
    const { activeCourse, liveVideoCount, setLiveVideoCount } = props;
    const [videos, setVideos] = useState([])
    const [courseVideoLoaded, setCourseVideoLoaded] = useState(false);
    const [isCourseVideoLoading, setIsCourseVideoLoading] = useState(true);
    const [offset, setOffset] = useState(0)
    const [mode, setMode] = useState('')
    const [showShimmer, setShowShimmer] = useState(true)
    const [videoFile, setVideoFile] = useState('')
    const [videoPreview, setVideoPreview] = useState('')
    const [imageFile, setImageFile] = useState('')
    const [id, setId] = useState('')
    const [imagePreview, setImagePreview] = useState('')
    const [index, setIndex] = useState('')
    const [title, setTitle] = useState('')
    const [subTitle, setSubTitle] = useState('')
    const [playlistId, setPlaylistId] = useState(0)
    const [courseVideoPlaylist, setCourseVideoPlaylist] = useState([])
    const [isAddEditModalVisible, setIsAddEditModalVisible] = useState(false);
    const [editDetail, setEditDetail] = useState(false);
    const [editVideo, setEditVideo] = useState(false);
    const [editThumb, setEditThumb] = useState(false)
    const [processing, setProcessing] = useState(false)
    const [selectedPlaylistId, setSelectedPlaylistId] = useState(-1)
    const [selectedPlaylistName, setSelectedPlaylistName] = useState("All")
    const [addPlayListLoading, setAddPlayListLoading] = useState(false)
    let fileRef = useRef(null);
    let imageFileRef = useRef(null);
    const [playlistName, setPlaylistName] = useState('')
    const [playlistEditIndex, setPlaylistEditIndex] = useState(-1);
    const [playlistEditId, setPlaylistEditId] = useState(-1);
    const [isShowPlaylist, setIsShowPlaylist] = useState(false);
    const [playlistMode, setPlaylistMode] = useState("Add");
    const [allDataLoaded, setAllDataLoaded] = useState(false)
    const [showNextButton, setShowNextButton] = useState()
    const [SnackBarMessage, setSnackBarMessage] = useState("")
    const [isSnackBarShow, setIsSnackBarShow] = useState(false)
    const [progress, setProgress] = useState()
    const [delLoading, setDelLoading] = useState(false);
    const [date, setDate] = useState()
    const [time, setTime] = useState()
    const [videoLocation, setVideoLocation] = useState('')
    const [showModal, setShowModal] = useState(false)
    const [liveItem, setLiveItem] = useState(null);

    useEffect(() => {

        setDate(getCurrenDate());
    }, [])

    const getCurrenDate = () => {
        var curr = new Date();
        curr.setDate(curr.getDate());
        var date = curr.toISOString().substr(0, 10);
        return date;
    }

    useEffect(() => {
        if (!playlistId) {
            setPlaylistId(0)
        }
    }, [playlistId])


    useEffect(() => {
        /*if (!allDataLoaded) {

            fetch_courses_live_videos(activeCourse, offset, dataLimit, courseVideoCallback);

        }*/
        getLiveStream(insDetails.id, activeCourse, (response) => {
            response.json().then(data => {
                if (data.status === "success") {
                    setVideos(data.data)
                    setCourseVideoLoaded(true)
                    setIsCourseVideoLoading(false)
                    setShowShimmer(false)
                    setShowNextButton(false)
                    setAllDataLoaded(true)
                }
                else {
                    setCourseVideoLoaded(true)
                    setIsCourseVideoLoading(false)
                    setShowShimmer(false)
                    setShowNextButton(false)
                    setAllDataLoaded(true)
                }
            })
        })
    }, [activeCourse, offset, selectedPlaylistId])


    useEffect(() => {
        fetch_video_playlist(activeCourse, courseVideoPlaylistCallback);
    }, [activeCourse])

    const courseVideoPlaylistCallback = (response) => {
        if (response.status == 200) {
            response.json().then(data => {
                var playlist = { "courseId": activeCourse, "id": -1, "name": "All" }
                data.unshift(playlist)
                setCourseVideoPlaylist(data)
                setSelectedPlaylistId(data[0].id)
                setSelectedPlaylistName(data[0].name)
            })
        }
    }

    const nextPageHandler = () => {
        if (!allDataLoaded) {
            setOffset(offset + 1)
        } else {
            window.alert("No more data available")
        }

    }
    const prePageHandler = () => {
        if (offset > 0) {
            setOffset(offset - 1)
        }
        else if (offset == 0) {
            setOffset(0)
            setShowNextButton(true)
        }
        setAllDataLoaded(false)

    }

    const courseVideoCallback = (response) => {
        if (response.status == 200) {
            response.json().then(data => {
                if (data.length == dataLimit) {
                    setVideos(data)
                    setShowNextButton(true)
                }
                else if (data.length < dataLimit) {
                    if (data.length == 0) {
                        if (offset == 0) {
                            setOffset(0)
                        } else {
                            setOffset(offset - 1)
                        }
                    }
                    else if (data.length != 0) {
                        setVideos(data)
                    }
                    setShowNextButton(false)
                    setAllDataLoaded(true)
                }
                setCourseVideoLoaded(true)
                setIsCourseVideoLoading(false)
                setShowShimmer(false)
            })
        }
    }

    const deleteCourseVideo = (id, index) => {
        if (window.confirm("Are you sure you want to delete?")) {
            setDelLoading(id)
            deleteVideo(id, (response) => deleteCallBack(response, index))
        }
    }

    const delLiveStream = (id, uid, index) => {
        if (window.confirm("Are you sure you want to delete?")) {
            setDelLoading(id)
            deleteLiveStream(uid, (response) => deleteStreamCallback(response, index))
        }
    }

    const deleteCallBack = (response, index) => {
        setDelLoading(false)
        if (response.status == 200) {

            setLiveVideoCount(liveVideoCount - 1)
            const arr = [...videos]
            arr.splice(index, 1)
            setVideos(arr)
            setSnackBarMessage("Video Deleted Successfully")
            setIsSnackBarShow(true)
        }
        else {
            setSnackBarMessage("Something went wrong")
            setIsSnackBarShow(true)
        }
    }

    const addStreamVideo = () => {
        if (window.confirm("Are you sure you want to add this video?")) {
            setProcessing(true)
            createLiveStream(title, insDetails.id, insDetails.name, activeCourse, imageFile, date, time, false, createStreamCallback);
        }
    }

    const updateStreamVideo = () => {
        if (window.confirm("Are You Sure You Want To Edit?")) {
            setProcessing(true)
            updateLiveStream({ name: title, uid: videos[id].uid }, updateStreamCallback);
        }
    }


    const createStreamCallback = ({ status, data }) => {
        if (data.status === "success") {
            setVideos([...videos, data.data])
            setSnackBarMessage("Live Stream Created Successfully")
            setIsSnackBarShow(true)
            sendNotification("", insDetails.name + ": Live Class Annoucement", "", "A Live Class has been scheduled for " + time + " on " + date, activeCourse, "courseStudents", null, (response) => { console.log(response.status, " notification response") })
            setProcessing(false)
            setIsAddEditModalVisible(false)
        }
        else {
            setSnackBarMessage(data.data ?? "You cant create more than 1 stream in this course")
            setIsSnackBarShow(true)
            setProcessing(false)
            setIsAddEditModalVisible(false)
        }
    }

    const deleteStreamCallback = (response, index) => {
        setDelLoading(false)
        response.json().then(data => {
            if (data.status === "success") {
                const arr = [...videos]
                arr.splice(index, 1)
                setVideos(arr)
                setSnackBarMessage("Live Stream Deleted Successfully")
                setIsSnackBarShow(true)
            }
            else {
                setSnackBarMessage(data.data)
                setIsSnackBarShow(true)
            }
        })
    }

    const updateStreamCallback = (response) => {
        response.json().then(data => {
            if (data.status === "success") {
                setSnackBarMessage("Video Details Updated Successfully")
                setIsSnackBarShow(true)
                setIsAddEditModalVisible(false)
                setProcessing(false)
            }
            else {
                setSnackBarMessage("Something went wrong")
                setIsSnackBarShow(true)
                setIsAddEditModalVisible(false)
                setProcessing(false)
            }
        }).catch(error => {
            setIsAddEditModalVisible(false)
            setProcessing(false)
        })
    }

    const addVideoCallBack = (response) => {
        if (response.status == 201) {
            setLiveVideoCount(liveVideoCount + 1)
            var details = response.headers.location.split('*')
            var obj = {
                id: details[0],
                courseId: activeCourse,
                description: subTitle,
                name: title,
                playlistId: playlistId,
                videoLocation: decodeURIComponent(details[1]),
                videoThumb: imagePreview,
                liveClassDate: date,
                liveClassTime: time,

            }
            var arr = [...videos]
            if (mode == "edit") {
                arr[index] = obj
            } else {

                arr.push(obj)
                sendNotification("", insDetails.name + ": Live Class Annoucement", "", "A Live Class has been scheduled for " + time + " on " + date, activeCourse, "courseStudents", null, (response) => { console.log(response.status, " notification response") })
            }

            setVideos(arr)
            setSnackBarMessage("Video Added Successfully")
            setIsSnackBarShow(true)
        }
        else {
            setSnackBarMessage("Something went wrong")
            setIsSnackBarShow(true)
        }
        setProcessing(false)
        setIsAddEditModalVisible(false)
    }

    const onAddSubmitHandler = (e) => {
        e.preventDefault();
        if (window.confirm("Are you sure you want to add this video?")) {
            setProcessing(true)
            addLiveVideo(imageFile, videoLocation, title, subTitle, activeCourse, false, date, time, id, addVideoCallBack, addVideoCallBackProgress)
        }
    }

    const addVideoCallBackProgress = (progress) => {
        setProgress(progress)
        // console.log(progress)
    }
    const onEditSubmitHandler = (e) => {
        e.preventDefault();
        if (window.confirm("Are You Sure You Want To Edit?")) {
            setProcessing(true)
            if (editDetail) {
                editVideoDetails(activeCourse, subTitle, id, title, playlistId, imageFile, videoFile, "live", editDetailsCallBack)
            }
            else if (editVideo) {
                updateLiveVideoLink(videoLocation, id, editVideoFileCallBack, addVideoCallBackProgress)
            }
            else if (editThumb) {
                editVideoThumb(imageFile, id, editVideoThumbCallBack, addVideoCallBackProgress)
            }
        }
    }

    const editVideoThumbCallBack = (response) => {
        if (response.status == 201) {
            var arr = [...videos]
            arr[index].videoThumb = response.headers.location;
            setVideos(arr)
            setSnackBarMessage("Video Thumbnail Updated Successfully")
            setIsSnackBarShow(true)
        } else {
            setSnackBarMessage("Something went wrong")
            setIsSnackBarShow(true)
        }
        setIsAddEditModalVisible(false)
        setProcessing(false)
    }

    const editVideoFileCallBack = (response) => {
        if (response.status == 201) {
            var arr = [...videos]
            arr[index].videoLocation = response.headers.location;
            setVideos(arr)
            setSnackBarMessage("Video File Updated Successfully")
            setIsSnackBarShow(true)
            sendNotification("", insDetails.name + ": Live Class Annoucement", "", "A Live Class has been scheduled for " + arr[index].liveClassTime + " on " + arr[index].liveClassDate, activeCourse, "courseStudents", null, (response) => { console.log(response.status, " notification response") })
        } else {
            setSnackBarMessage("Something went wrong")
            setIsSnackBarShow(true)
        }
        setIsAddEditModalVisible(false)
        setProcessing(false)
    }

    const editDetailsCallBack = (response) => {
        if (response.status == 200) {
            var arr = [...videos]
            arr[index].name = title;
            arr[index].description = subTitle;
            arr[index].playlistId = playlistId;
            setVideos(arr)
            setSnackBarMessage("Video Details Updated Successfully")
            setIsSnackBarShow(true)
        } else {
            setSnackBarMessage("Something went wrong")
            setIsSnackBarShow(true)
        }
        setIsAddEditModalVisible(false)
        setProcessing(false)
    }

    const setEditDetails = (index, id, title, description, imageLink, link, playlistId, details, video, thumb, date, time) => {
        setId(id)
        setTitle(title);
        setIndex(index);
        setSubTitle(description);
        setImagePreview(imageLink);
        setVideoLocation(link);
        setImageFile(imageLink);
        setPlaylistId(playlistId)
        // setVideoPreview(link); 
        setMode('edit');
        setDate(date)
        setTime(time)
        setIsAddEditModalVisible(true);
        setEditDetail(details);
        setEditVideo(video);
        setEditThumb(thumb);
    }
    const setAddDetails = () => {
        setId('')
        setTitle('');
        setIndex('');
        setSubTitle('');
        setImagePreview('');
        setVideoFile('');
        setImageFile('');
        setPlaylistId('')
        setVideoPreview('');
        setMode('add');
        setIsAddEditModalVisible(true);
        setEditDetail(false);
        setEditVideo(false);
        setEditThumb(false);
    }

    const tiggerClickOnFile = () => {
        fileRef.click();
    }

    const fileOnChange = (event) => {
        var url = event.target.files[0] ? (URL.createObjectURL(event.target.files[0])) : ('');
        setVideoPreview(url)
        setVideoFile(event.target.files[0])
    }
    const tiggerClickOnImageFile = () => {
        imageFileRef.click();
    }

    const imageFileOnChange = (event) => {
        var url = event.target.files[0] ? (URL.createObjectURL(event.target.files[0])) : ('');
        setImagePreview(url)
        setImageFile(event.target.files[0])
    }



    const addVideoPlaylistCallback = (response) => {
        setAddPlayListLoading(false)
        if (response.status == 201) {

            let playlistId = response.headers.get('location')
            document.getElementById('addPlaylistCloseBtn').click();
            let videoPlaylist = [...courseVideoPlaylist]
            videoPlaylist.push({
                id: playlistId,
                name: playlistName,
                courseId: activeCourse
            })
            setCourseVideoPlaylist(videoPlaylist)
            setSnackBarMessage("Video Playlist Added Successfully")
            setIsSnackBarShow(true)
        }
        else {
            setSnackBarMessage("Something went wrong")
            setIsSnackBarShow(true)
        }
    }


    const action4addPlaylist = () => {
        setAddPlayListLoading(true)
        createVideoPlayList(activeCourse, playlistName, addVideoPlaylistCallback)
    }
    const action4EditPlaylist = () => {
        setAddPlayListLoading(true)
        createVideoPlayList(activeCourse, playlistName, editVideoPlaylistCallback, playlistEditId)
    }
    const editVideoPlaylistCallback = (response) => {
        setAddPlayListLoading(false)
        if (response.status == 201) {
            let playlistId = response.headers.get('location')
            document.getElementById('addPlaylistCloseBtn').click();
            let videoPlaylist = [...courseVideoPlaylist]
            videoPlaylist[playlistEditIndex] = {
                id: playlistId,
                name: playlistName,
                courseId: activeCourse
            }
            setCourseVideoPlaylist(videoPlaylist)
            setSnackBarMessage("Video Playlist Updated Successfully")
            setIsSnackBarShow(true)
        } else {
            setSnackBarMessage("Something went wrong")
            setIsSnackBarShow(true)
        }
    }
    const deleteVideoPlaylistHandler = (playlistId, index) => {
        deleteVideoPlayList(playlistId, (response) => deletePlaylistCallback(response, index))
    }
    const deletePlaylistCallback = (response, index) => {
        if (response.status == 200) {
            let playlistArr = [...courseVideoPlaylist]
            playlistArr.splice(index, 1)
            setCourseVideoPlaylist(playlistArr);
            setSnackBarMessage("Video Playlist Deleted Successfully")
            setIsSnackBarShow(true)
        } else {
            setSnackBarMessage("Something went wrong")
            setIsSnackBarShow(true)
        }
    }
    const setEditValues = (name, id, index) => {
        setPlaylistName(name);
        setPlaylistEditId(id);
        setPlaylistEditIndex(index)
        setPlaylistMode("edit");
    }


    const closeSnack = () => {
        setIsSnackBarShow(false)
    }

    return (
        <div style={{ backgroundColor: 'white', marginTop: 28, borderRadius: 10 }}>
            <div class="page-breadcrumb  d-md-flex align-items-center mb-3 ml-2" style={{ marginRight: 10 }}>
                <div class="btn-group">
                    <button type="button" data-toggle="dropdown" style={{ marginTop: 18, paddingLeft: 20, marginLeft: 20, backgroundColor: '#EDEDED', borderRadius: 5, paddingTop: 5, border: 'none', fontSize: 14, fontWeight: 400 }} class="btn mr-3">{selectedPlaylistName}<svg xmlns="http://www.w3.org/2000/svg" height="30" viewBox="0 -960 960 960"
                        width="30" fill='black' style={{ marginLeft: 5, marginBottom: 0 }}>
                        <path d="M480-387.692 327.692-540h304.616L480-387.692Z" />
                    </svg></button>
                    <div class="dropdown-menu dropdown-menu-right dropdown-menu-lg-left" >
                        {courseVideoPlaylist.map((row, i) => (
                            <a class="dropdown-item" href="javascript:;" onClick={() => { setAllDataLoaded(false); setSelectedPlaylistId(row.id); setOffset(0); setSelectedPlaylistName(row.name); setVideos([]); setShowShimmer(true) }}>{row.name}</a>
                        ))}


                    </div>
                </div>
                <div class="ml-auto">
                    <br />
                    <div class="btn-group">
                        {isShowPlaylist ? (

                            <button class="btn mr-3" onClick={() => setIsShowPlaylist(false)} style={{ marginRight: 5, backgroundColor: '#EDEDED', borderRadius: 5, paddingTop: 5, border: 'none', fontWeight: 400 }}>View Videos</button>


                        ) : (
                            <button type="button" class="btn mr-3" onClick={() => setIsShowPlaylist(true)} style={{ marginRight: 5, backgroundColor: '#EDEDED', borderRadius: 5, paddingTop: 5, border: 'none', fontWeight: 400 }}>View Playlist</button>

                        )}
                        <button title="Add Your Playlist" type="button" class="btn mr-3" data-toggle="modal" data-target="#addPlayListModal" onClick={() => setPlaylistMode("add")} style={{ marginRight: 5, backgroundColor: '#EDEDED', borderRadius: 5, paddingTop: 5, border: 'none' }}><svg xmlns="http://www.w3.org/2000/svg" height="25" viewBox="0 -960 960 960"
                            width="25" fill='black' style={{ marginRight: 0, marginBottom: 0 }}>
                            <path d="M140-340v-40h280v40H140Zm0-160v-40h440v40H140Zm0-160v-40h440v40H140Zm520 480v-160H500v-40h160v-160h40v160h160v40H700v160h-40Z" />
                        </svg></button>
                        <button title="Go Live" type="button" onClick={setAddDetails} class="btn" style={{ marginRight: 5, backgroundColor: '#EDEDED', borderRadius: 5, paddingTop: 5, border: 'none' }}><svg xmlns="http://www.w3.org/2000/svg" height="25" viewBox="0 -960 960 960"
                            width="25" fill='black' style={{ marginRight: 0, marginBottom: 0 }}>
                            <path d="M300-200v-40h360v40H300Zm160-135.385v-349.307L335.231-560.154l-27.539-27.538L480-760l172.308 172.308-27.539 27.538L500-684.692v349.307h-40Z" />
                        </svg></button>
                    </div>
                </div>
            </div>


            {isShowPlaylist ? (
                <div className="mt-3">
                    <div class="table-responsive">
                        <table class="table table-striped table-bordered mb-0" id="table1">
                            <thead class="thead-dark">
                                <tr>
                                    <th align="center">#</th>
                                    <th align="center">Title</th>
                                    <th align="center">Actions</th>
                                </tr>
                            </thead>
                            <tbody>

                                {showShimmer ? (
                                    <td colspan="4">
                                        <Shimmer width={'100%'} height={40} />
                                    </td>
                                ) : (
                                    <>
                                        {courseVideoPlaylist.map((row, i) => (
                                            <CourseVideoPlaylistRow
                                                setEditValues={setEditValues}
                                                deleteVideoPlaylistHandler={deleteVideoPlaylistHandler}
                                                row={row}
                                                index={i}
                                                delVideo={deleteCourseVideo}
                                                setEditDetails={setEditDetails} />
                                        ))}
                                    </>

                                )}

                            </tbody>
                        </table>
                    </div>
                </div>
            ) : (
                <div className="mt-3">
                    <div class="table-responsive">
                        <table class="table table-striped table-bordered mb-0" id="table1">
                            <thead class="thead-dark">
                                <tr>
                                    <th align="center">#</th>
                                    <th align="center">Title</th>
                                    <th align="center">Description</th>
                                    <th align="center">Actions</th>
                                </tr>
                            </thead>
                            <tbody>

                                {showShimmer ? (
                                    <div className="row">
                                        <div className="col-12">
                                            <Shimmer width={'100%'} height={40} />
                                        </div>
                                        <div className="col-12">
                                            <Shimmer width={'100%'} height={40} />
                                        </div>
                                    </div>
                                ) : (
                                    <>
                                        {videos.map((row, i) => (
                                            <CourseVideoRow row={row} course={activeCourse} insId={insDetails.id}
                                                index={i} delVideo={delLiveStream} setEditDetails={setEditDetails}
                                                delLoading={delLoading == row.id}
                                                modal={showModal} setModal={setShowModal}
                                                setLiveItem={setLiveItem} />
                                        ))}

                                    </>
                                )}

                            </tbody>
                        </table>
                    </div>
                </div>
            )}

            <LiveModal visible={showModal} setVisible={setShowModal} item={liveItem} />

            <Modal
                visible={isAddEditModalVisible}
                setModalVisible={setIsAddEditModalVisible}
                modalId={"testAddEditModal"}
            >
                <form onSubmit={mode === "add" || editDetail ? (addStreamVideo) : (updateStreamVideo)}>
                    <ModalHeader>
                        <h5 className="modal-title">{mode === "add" ? ("Create Live Stream") : ("Edit Live Stream")}</h5>

                        <button type="button" className="close" data-dismiss="modal" aria-label="Close"> <svg xmlns="http://www.w3.org/2000/svg" height="30" viewBox="0 -960 960 960" width="30" fill='black' style={{ marginRight: 4, marginBottom: 1 }}><path d="M256-227.692 227.692-256l224-224-224-224L256-732.308l224 224 224-224L732.308-704l-224 224 224 224L704-227.692l-224-224-224 224Z" /></svg>
                        </button>

                    </ModalHeader>
                    <ModalBody>
                        {mode === "add" || editDetail ? (
                            <div>
                                <div className="form-row">
                                    <label>Title <span style={{ fontSize: 12, color: '#7A8B94', fontWeight: 400 }}>(required)</span></label>
                                    <textarea
                                        className="form-control"
                                        value={title}
                                        placeholder="Title (max 100 characters)"
                                        onChange={(e) => setTitle(e.target.value)}
                                        maxLength={100}
                                        style={{ height: '80px', fontWeight: 400 }}
                                    />
                                    <p style={{ position: 'absolute', top: 120, right: 50, fontSize: 12, color: '#A6B3BA' }}>
                                        {title.length}
                                    </p>
                                </div>
                                <div className="form-row">
                                    <div className="col-6">
                                        <label>Date</label>
                                        <input type="date" className="form-control" value={date} placeholder="Date" onChange={(e) => setDate(e.target.value)} />
                                    </div>
                                    <div className="col-6">
                                        <label>Time</label>
                                        <input type="time" className="form-control" value={time} placeholder="Time" onChange={(e) => setTime(e.target.value)} />
                                    </div>

                                </div>

                                {!editDetail ? (
                                    <div className="form-row">
                                        <div className="col-12 mt-2">
                                            <div style={{ borderRadius: 15, alignItems: "center", justifyContent: "center", display: "flex", flexDirection: "column", backgroundColor: '#F8F9FB', marginTop: -28, width: 425, height: 290, marginTop: 10, marginLeft: 18 }}>

                                                <svg onClick={tiggerClickOnImageFile} xmlns="http://www.w3.org/2000/svg" height="140" viewBox="0 -960 960 960" width="140" fill='#A6B3BA' style={{ padding: 30, backgroundColor: 'white', borderRadius: 50, cursor: 'pointer' }}>
                                                    <path d="M192-90q-44.475 0-75.571-30.9-31.095-30.9-31.095-75.1v-567.334q0-44.474 31.095-75.57Q147.525-870 192-870h355.334v106.666H192V-196h567.334v-356.334h105.999V-196q0 44.2-31.583 75.1Q802.167-90 759.334-90H192Zm508.834-521.334v-93.333h-93.833V-785.5h93.333V-879h80.832v92.667H875v81.166h-93.334v93.833h-80.832ZM235-280.667h482L569.333-476l-124 164.334-93-123L235-280.667Zm-43-482.667V-196v-567.334Z" /></svg>
                                                <button type="button" className="btn" onClick={tiggerClickOnImageFile} style={{ marginTop: 15, backgroundColor: '#005CDD', color: '#fff', paddingLeft: 30, paddingRight: 30 }}>Select Thumbnail</button>
                                                <p style={{ marginTop: 10 }}>Image Size Must be 1280 x 720 Pixel</p>
                                            </div>

                                            {imagePreview ? (
                                                <div style={{ borderRadius: 15, alignItems: "center", justifyContent: "center", display: "flex", flexDirection: "column", backgroundColor: '#F8F9FB', marginTop: 25, idth: 428, height: 290 }}>
                                                    <div style={{ alignSelf: "flex-end", marginRight: 15, marginTop: 8 }}>
                                                        {/* const [imageFile, setImageFile] = useState('') */}
                                                        <a href="javascript:;" onClick={() => { setImagePreview(""); setImageFile("") }}><svg xmlns="http://www.w3.org/2000/svg" height="30" viewBox="0 -960 960 960" width="30" fill='#EA4335' style={{ marginRight: 4, marginBottom: 1 }}><path d="M304.615-160q-26.846 0-45.731-18.884Q240-197.769 240-224.615V-720h-40v-40h160v-30.77h240V-760h160v40h-40v495.385Q720-197 701.5-178.5 683-160 655.385-160h-350.77ZM680-720H280v495.385q0 10.769 6.923 17.692T304.615-200h350.77q9.23 0 16.923-7.692Q680-215.385 680-224.615V-720ZM392.307-280h40.001v-360h-40.001v360Zm135.385 0h40.001v-360h-40.001v360ZM280-720v520-520Z" /> </svg></a>

                                                    </div>
                                                    <img src={imagePreview} style={{ width: 438, height: 240, marginLeft: 0 }} />
                                                </div>
                                            ) : (null)}
                                        </div>

                                        <input
                                            className="form-control"
                                            type="file"
                                            ref={(ref) => (imageFileRef = ref)}
                                            accept='image/*'
                                            onChange={(e) => {
                                                const acceptedFormats = ['image/jpeg', 'image/png', 'image/jpg'];

                                                if (e.target.files.length > 0) {
                                                    const selectedFile = e.target.files[0];
                                                    const fileType = selectedFile.type;

                                                    if (acceptedFormats.includes(fileType)) {
                                                        // The selected file is in an accepted format (JPEG, PNG, or GIF).
                                                        // You can proceed with handling the file using imageFileOnChange.
                                                        imageFileOnChange(e);
                                                    } else {
                                                        // The selected file is not in an accepted format.
                                                        alert('Please select a valid image file (JPEG, PNG, or JPG).');
                                                        // Optionally, you can clear the file input.
                                                        e.target.value = '';
                                                    }
                                                }
                                            }}
                                            style={{ visibility: 'hidden' }}
                                        />

                                    </div>) : (null)}
                            </div>
                        ) : (null)}

                        {mode == "edit" && (
                            <>
                                {editVideo ? (
                                    <div className="form-row">

                                        <label>Title <span style={{ fontSize: 12, color: '#7A8B94', fontWeight: 400 }}>(required)</span></label>
                                        <textarea
                                            className="form-control"
                                            value={title}
                                            placeholder="Title (max 100 characters)"
                                            onChange={(e) => setTitle(e.target.value)}
                                            maxLength={100}
                                            style={{ height: '80px', fontWeight: 400 }}
                                        />
                                        <p style={{ position: 'absolute', top: 120, right: 50, fontSize: 12, color: '#A6B3BA' }}>
                                            {title.length}
                                        </p>


                                    </div>) : (null)}
                                {editThumb ? (
                                    <div className="form-row">
                                        <div className="col-12 mt-2">
                                            <div style={{ borderRadius: 15, alignItems: "center", justifyContent: "center", display: "flex", flexDirection: "column", backgroundColor: '#F8F9FB', marginTop: -28, width: 425, height: 290, marginTop: 10, marginLeft: 18 }}>

                                                <svg onClick={tiggerClickOnImageFile} xmlns="http://www.w3.org/2000/svg" height="140" viewBox="0 -960 960 960" width="140" fill='#A6B3BA' style={{ padding: 30, backgroundColor: 'white', borderRadius: 50, cursor: 'pointer' }}>
                                                    <path d="M192-90q-44.475 0-75.571-30.9-31.095-30.9-31.095-75.1v-567.334q0-44.474 31.095-75.57Q147.525-870 192-870h355.334v106.666H192V-196h567.334v-356.334h105.999V-196q0 44.2-31.583 75.1Q802.167-90 759.334-90H192Zm508.834-521.334v-93.333h-93.833V-785.5h93.333V-879h80.832v92.667H875v81.166h-93.334v93.833h-80.832ZM235-280.667h482L569.333-476l-124 164.334-93-123L235-280.667Zm-43-482.667V-196v-567.334Z" /></svg>
                                                <button type="button" className="btn" onClick={tiggerClickOnImageFile} style={{ marginTop: 15, backgroundColor: '#005CDD', color: '#fff', paddingLeft: 30, paddingRight: 30 }}>Select Thumbnail</button>
                                                <p style={{ marginTop: 10 }}>Image Size Must be 1280 x 720 Pixel</p>
                                            </div>

                                            <input
                                                className="form-control"
                                                type="file"
                                                ref={(ref) => (imageFileRef = ref)}
                                                accept='image/*'
                                                onChange={(e) => {
                                                    const acceptedFormats = ['image/jpeg', 'image/png', 'image/jpg'];

                                                    if (e.target.files.length > 0) {
                                                        const selectedFile = e.target.files[0];
                                                        const fileType = selectedFile.type;

                                                        if (acceptedFormats.includes(fileType)) {
                                                            // The selected file is in an accepted format (JPEG, PNG, or GIF).
                                                            // You can proceed with handling the file using imageFileOnChange.
                                                            imageFileOnChange(e);
                                                        } else {
                                                            // The selected file is not in an accepted format.
                                                            alert('Please select a valid image file (JPEG, PNG, or JPG).');
                                                            // Optionally, you can clear the file input.
                                                            e.target.value = '';
                                                        }
                                                    }
                                                }}
                                                style={{ visibility: 'hidden' }}
                                            />

                                            <div style={{ borderRadius: 15, alignItems: "center", justifyContent: "center", display: "flex", flexDirection: "column", backgroundColor: '#F8F9FB', marginTop: 25, idth: 428, height: 290 }}>
                                                <div style={{ alignSelf: "flex-end", marginRight: 15, marginTop: 8 }}>
                                                    <a href="javascript:;" onClick={() => { setImagePreview(""); setImageFile("") }}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" height="30" viewBox="0 -960 960 960" width="30" fill='#EA4335' style={{ marginRight: 4, marginBottom: 1 }}><path d="M304.615-160q-26.846 0-45.731-18.884Q240-197.769 240-224.615V-720h-40v-40h160v-30.77h240V-760h160v40h-40v495.385Q720-197 701.5-178.5 683-160 655.385-160h-350.77ZM680-720H280v495.385q0 10.769 6.923 17.692T304.615-200h350.77q9.23 0 16.923-7.692Q680-215.385 680-224.615V-720ZM392.307-280h40.001v-360h-40.001v360Zm135.385 0h40.001v-360h-40.001v360ZM280-720v520-520Z" /> </svg></a>
                                                </div>
                                                <img src={imagePreview ? imagePreview : "http://placehold.it/200/200"} style={{ width: 438, height: 240, marginLeft: 0 }} />
                                            </div>
                                        </div>

                                    </div>) : (null)}
                            </>
                        )}
                    </ModalBody>
                    <ModalFooter>
                        <button type="button" className="btn" data-dismiss="modal">Close</button>
                        <button className="btn" disabled={processing} style={{ backgroundColor: '#005CDD', color: 'white' }}>
                            {processing ? (
                                <ClipLoader color="white" loading={processing} size={20} />
                            ) : (mode == "add" ? ("Create Stream") : ("Edit Stream"))}
                        </button>
                        {processing ? (
                            <div className="progress mt-3 w-100" style={{ height: 30 }}>
                                <div className="progress-bar bg-success"
                                    role="progressbar" style={{ width: progress + "%" }}
                                    aria-valuenow={progress} aria-valuemin="0"
                                    aria-valuemax="100">
                                </div>
                                <span style={{ color: 'black', marginTop: 12 }}>{progress} %</span>
                            </div>
                        ) : (
                            null
                        )}
                    </ModalFooter>
                </form>
            </Modal>





            {/* Video PlayList Modal */}

            <div class="modal fade" data-backdrop="static" data-keyboard="false" id="addPlayListModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Add Video Playlist</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <svg xmlns="http://www.w3.org/2000/svg" height="30" viewBox="0 -960 960 960" width="30" fill='black' style={{ marginRight: 4, marginBottom: 1 }}><path d="M256-227.692 227.692-256l224-224-224-224L256-732.308l224 224 224-224L732.308-704l-224 224 224 224L704-227.692l-224-224-224 224Z" /></svg>
                            </button>
                        </div>
                        <div class="modal-body">
                            <label>Playlist name</label>
                            <input className="form-control" value={playlistName} placeholder="Playlist Name" onChange={(e) => setPlaylistName(e.target.value)} />
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal" id="addPlaylistCloseBtn">Close</button>
                            <button type="button" class="btn" style={{ backgroundColor: 'blue', color: 'white' }} onClick={() => playlistMode == "edit" ? action4EditPlaylist() : action4addPlaylist()}>
                                {addPlayListLoading ? (
                                    <ClipLoader color={theme.primaryColor} loading={addPlayListLoading} />
                                ) : ("Save changes")}</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                {offset > 0 ? (

                    <button type="button" class="btn btn-primary" onClick={() => prePageHandler()}>Previous</button>
                ) : (null)}
                {!allDataLoaded && showNextButton ? (
                    <button type="button" class="btn btn-primary " onClick={() => nextPageHandler()}>Next</button>
                ) : (null)}

            </div>
            <Snackbar
                open={isSnackBarShow}
                onClose={(e) => closeSnack(e)}
                TransitionComponent="TransitionUp"
                message={SnackBarMessage}
            />
        </div>
    )
}

export default CourseLiveVideo
