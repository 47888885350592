import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import { serverBaseUrl, theme } from '../../../index';
import ClipLoader from "react-spinners/ClipLoader";

function ReviewRow(props) {
  const { row, index, delReview, setEditDetails } = props;
  const [student, setStudent] = useState(row.studentName);
  const [rating, setRating] = useState(row.insReview.rating);
  const [review, setReview] = useState(row.insReview.review);
  const [reply, setReply] = useState(row.insReview.reply);
  const [reviewId, setReviewId] = useState(row.insReview.id);
  const [delLoading, setDelLoading] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [seeMore, seeLess] = useState(false);

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };
  const toggleSeeMore = () => {
    seeLess(!seeMore);
  };



  useEffect(() => {
    setDelLoading(props.delLoading)
  }, [props.delLoading])




  return (
    <>
      {review!==null ? (
        <div style={{ width: 300, minHeight: 200, borderRadius: 15, marginLeft: 15, border: '1px solid #ededed', marginBottom: 20 }}>
          <p style={{ marginLeft: 15, paddingTop: 15, fontSize: 16, fontWeight: 700, marginTop: 0 }}>{student} • <span style={{ fontSize: 12 }}> {rating}
            <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill='#EF953E' style={{ marginTop: -5 }}><path d="m293-163.076 49.615-212.539-164.923-142.847 217.231-18.846L480-737.693l85.077 200.385 217.231 18.846-164.923 142.847L667-163.076 480-275.923 293-163.076Z" /></svg></span></p>
          <p style={{ marginLeft: 15, fontSize: 14, marginRight: 20 }}>
            {showMore ? review : `${review?.slice(0, 40)}...`}
            {review.length > 40 ? (
              <span
                style={{ color: '#005CDD', cursor: 'pointer' }}
                onClick={toggleShowMore}
              >
                {showMore ? ' See Less' : ' See more'}
              </span>
            ) : null}
          </p>

          <div style={{ backgroundColor: '#F5F5F5', marginLeft: 15, marginRight: 15, borderRadius: 5, padding: 5 }}>
          {reply ? (
        <p style={{ marginLeft: 5, fontSize: 14 }}>
          {seeMore ? reply : `${reply.slice(0, 40)}...`}
          {reply.length > 40 ? (
            <span style={{ color: '#005CDD', cursor: 'pointer' }} onClick={toggleSeeMore}>
              {seeMore ? ' See Less' : ' See more'}
            </span>
          ) : null}
        </p>
      ) : (
        <p style={{ marginLeft: 5, fontSize: 14 }}>Please Reply Here...</p>
      )}
          </div>

          <button aria-label="delete" onClick={() => { setDelLoading(true); delReview(reviewId, index) }} className="btn" style={{ marginRight: 0, backgroundColor: '#EDEDED', borderRadius: 5, paddingTop: 5, border: 'none', marginLeft: 15, marginTop: 15, marginBottom: 20 }}>
            {delLoading ? (
              <ClipLoader color={theme.primaryColor} loading={delLoading} />
            ) : (<svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960"
              width="20" fill='#EA4335' style={{ marginRight: 0, marginBottom: 0, }}>
              <path d="M304.615-160q-26.846 0-45.731-18.884Q240-197.769 240-224.615V-720h-40v-40h160v-30.77h240V-760h160v40h-40v495.385Q720-197 701.5-178.5 683-160 655.385-160h-350.77ZM680-720H280v495.385q0 10.769 6.923 17.692T304.615-200h350.77q9.23 0 16.923-7.692Q680-215.385 680-224.615V-720ZM392.307-280h40.001v-360h-40.001v360Zm135.385 0h40.001v-360h-40.001v360ZM280-720v520-520Z" />
            </svg>)}
          </button>
          <button aria-label="reply" onClick={() => setEditDetails(reviewId, index, reply)} className="btn" style={{ backgroundColor: '#005CDD', border: 'none', fontSize: 13, fontWeight: 400, color: 'white', marginTop: 15, marginLeft: 15, marginBottom: 20 }}>
            Reply
          </button>

        </div>
      ) : (null)}
    </>

  )
}

export default ReviewRow
